import React from "react";
import { useSelector } from "react-redux";
import ScrollToTopSvg from "../../footer/coloredSvgs/ScrollToTopSvg";
import { addAlpha, getImageUrl, handleClickScroll } from "../../modals/Toast";
import { FlexRowBetween, RespFont } from "../../Style";

const UserFooter = (props) => {
  const { user } = useSelector((state) => state.authReducer);
  let logoPic =
    user.logoPic !== ""
      ? `${getImageUrl()}${user._id}/${user.logoPic}`
      : `${getImageUrl()}sys/defaultAgentLogo.png`;
  return (
    <FlexRowBetween
      onClick={() => handleClickScroll(props.id)}
      style={{
        justifyContent: "space-around",
        padding: "10px 10px",
        backgroundColor: user.settings.colors.compNav,
        zIndex: 1,
      }}
    >
      <div>
        <FlexRowBetween
          style={{
            fontFamily: "ralewayLight",
            justifyContent: "center",
            fontStyle: "italic",
          }}
        >
          <RespFont style={{ color: user.settings.colors.compNavFont }}>
            כל הזכויות שמורות ל{user.settings.contacts.footer}
          </RespFont>
        </FlexRowBetween>
        <FlexRowBetween
          style={{
            fontWeight: "100",
            justifyContent: "center",
            fontSize: "12px",
            fontStyle: "oblique",
            marginTop: "10px",
          }}
        >
          <a
            onClick={(e) => e.stopPropagation()}
            style={{
              textDecoration: "none",
              color: user.settings.colors.compNavFont,
            }}
            target="_blank"
            rel="noreferrer"
            href={`https://nadlan.agency`}
          >
            <RespFont pSize={"12px"} cSize={"14px"}>
              אתר זה נבנה באמצעות פלטפורמת הנדלן מבית Be Simple
            </RespFont>
          </a>
        </FlexRowBetween>
      </div>
      <img src={logoPic} alt="logo" style={{ width: "80px" }} />
    </FlexRowBetween>
  );
};

export default UserFooter;
