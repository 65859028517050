import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import gallery from "../../assets/nadlan/galleryWhite.svg";
import {
  DarkenBackgroundImage,
  FlexRowBetween,
  MainDiv,
  RespDiv,
  RespFont,
  Section,
} from "../Style";
import { Link } from "react-router-dom";
import {
  addAlpha,
  availHeight,
  getImageUrl,
  GetToast,
} from "../common/modals/Toast";
import ClientRoutes from "../../navigation/Routes";
import defaultUserPic from "./defaultUserPic.png";
import defaultLogoPic from "./defaultLogoPic.jpeg";
import {
  FlexRowColumnOpposite,
  GenericDiv,
  RespImgH,
  Responsive,
} from "../common/Style";
import EditPics from "./images/EditPics";
import GenericModal from "../common/modals/GenericModal";
import { editDetails } from "../../store/reducers/authReducer";
import { deletePhoto } from "../../store/reducers/photosReducer";
import GenericSvg from "../common/footer/coloredSvgs/GenericSvg";
import { svgs } from "../common/footer/coloredSvgs/svgs";

const Face = (props) => {
  const { user } = useSelector((state) => state.authReducer);
  const [kind, setKind] = useState(false);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const width = window.screen.availWidth;
  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoOnly = async (key) => {
    await deletePhoto1(user[key]);
    dispatch(editDetails({ [key]: "" })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };
  let userPic =
    user.profilePic !== ""
      ? `${getImageUrl()}${user._id}/${user.profilePic}`
      : `${getImageUrl()}sys/defaultAgentPic.png`;
  let userBackgroundPic =
    user.backgroundPic !== ""
      ? `${getImageUrl()}${user._id}/${user.backgroundPic}`
      : `${getImageUrl()}sys/defaultBackgroundAgentPic.png`;
  return (
    <MainDiv
      id="faceAgent-id"
      className="animate__animated animate__fadeIn"
      style={{
        position: "relative",
        backgroundImage: `url(${userBackgroundPic})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "800px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DarkenBackgroundImage />
      <FlexRowColumnOpposite
        style={{ position: "relative", justifyContent: "space-evenly" }}
      >
        {/* <div
          style={{
            width: "100%",
            height: "100%",
          }}
        > */}
        <RespImgH
          cSize={"700px"}
          src={userPic}
          style={{ marginBottom: "-100px" }}
        />
        {/* </div> */}
        <RespDiv
          cSize={"40%"}
          pSize={"90%"}
          style={{
            direction: "rtl",
            textAlign: "center",
            color: user.settings.colors.mainContent,
          }}
        >
          <Section
            pSize={"34px"}
            cSize={"80px"}
            style={{
              margin: "0px auto",
              color: user.settings.colors.mainContent,
            }}
          >
            {user.settings.mainContent.part0}
          </Section>
          <Section pSize={"25px"} cSize={"33px"}>
            {user.settings.mainContent.part1}
          </Section>

          <RespFont pSize={"20px"} cSize={"28px"}>
            {user.settings.mainContent.part2}
          </RespFont>
          <Section pSize={"20px"} cSize={"28px"}>
            {user.settings.mainContent.part3}
          </Section>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://wa.me/972${
              user.contactPhone !== "" ? user.contactPhone : user.phone
            }/?text=היי,`}
            style={{
              display: "flex",
              width: "400px",
              textDecoration: "none",
              margin: "0 auto",
            }}
          >
            <RespFont
              pSize={"20px"}
              cSize={"24px"}
              style={{
                background: user.settings.colors.buttonsColor,
                width: "100%",
                padding: "12px 10px",
                fontFamily: "hebM",
                fontWeight: "700",
                borderRadius: "2px",
                color: user.settings.colors.buttonsFontColor,
                boxShadow: "0px 7.2174px 10.3947px rgba(245, 245, 245, 0.2)",
              }}
            >
              קבעו פגישה עוד היום!
            </RespFont>
          </a>
          {/* <GenericDiv as={Link} to={ClientRoutes.User.user}
                    style={{ width: "min-content", margin: "0 auto", padding: "10px 20px", fontSize: "16px", fontWeight: "700" }} background={user.controls}>לנכסים שלי</GenericDiv> */}
        </RespDiv>
      </FlexRowColumnOpposite>

      {kind && (
        <GenericModal
          init={setKind}
          div={<EditPics kind={kind} init={setKind} />}
        />
      )}
    </MainDiv>
  );
};

export default Face;
