import React, { useEffect } from "react";
import { DisplayNoneComputer, FlexRowBetween, RespImg } from "../Style";
import { HashLink } from "react-router-hash-link";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PersonIcon from "@material-ui/icons/Person";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import HomeIcon from "@material-ui/icons/Home";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import ClientRoutes from "../../../navigation/Routes";
import { svgs } from "../footer/coloredSvgs/svgs";
import GenericSvg from "../footer/coloredSvgs/GenericSvg";
import l1 from "../../../assets/zaks/l1.svg";
import RecommendOutlinedIcon from "@mui/icons-material/RecommendOutlined";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "rgba(255,255,255,0)",
    width: "min-content",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
    fontFamily: "logo",
    letterSpacing: "1px",
    color: "#3A3C3F",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const BurgerMenuUser = ({ logoPic, user, assets }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      setOpen(false);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <div onClick={handleDrawerOpen} className={clsx(open && classes.hide)}>
          <img
            src={l1}
            style={{
              width: "45px",
              backgroundColor: user.settings.colors.buttonsColor,
              padding: "10px",
              borderRadius: "50%",
              boxShadow: "-1px 1px 15px rgba(9, 20, 49, 0.11)",
              marginTop: "15px",
              marginRight: "15px",
            }}
          />
        </div>
      </AppBar>
      <img
        src={logoPic}
        style={{
          width: "65px",
          position: "absolute",
          left: "5px",
          top: "5px",
          zIndex: 1,
        }}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {/* {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div onClick={() => handleClickScroll("assetsA-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText primary={"נכסים"} style={{ display: "flex" }} />
              <ListItemIcon style={{ display: "flow-root" }}>
                <HomeIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          {user.settings.showPages.page3 ? (
            <div
              onClick={() => {
                handleClickScroll("steps");
                handleClickScroll("aboutMe-id");
              }}
            >
              <ListItem
                button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  direction: "rtl",
                }}
              >
                <ListItemText primary={"קצת עלי"} style={{ display: "flex" }} />
                <ListItemIcon style={{ display: "flow-root" }}>
                  <PermContactCalendarIcon />
                </ListItemIcon>
              </ListItem>
            </div>
          ) : null}

          {user.settings.showPages.page4 ? (
            <div
              onClick={() => {
                handleClickScroll("rrr");
              }}
            >
              <ListItem
                button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  direction: "rtl",
                }}
              >
                <ListItemText primary={"המלצות"} style={{ display: "flex" }} />
                <ListItemIcon style={{ display: "flow-root" }}>
                  <RecommendOutlinedIcon />
                </ListItemIcon>
              </ListItem>
            </div>
          ) : null}
          {user.settings.showPages.page5 ? (
            <div onClick={() => handleClickScroll("quesAndAns-id")}>
              <ListItem
                button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  direction: "rtl",
                }}
              >
                <ListItemText
                  primary={"שאלות ותשובות"}
                  style={{ display: "flex" }}
                />
                <ListItemIcon style={{ display: "flow-root" }}>
                  <QuestionAnswerIcon />
                </ListItemIcon>
              </ListItem>
            </div>
          ) : null}
          {user.settings.showPages.page6 ? (
            <div onClick={() => handleClickScroll("articleA-id")}>
              <ListItem
                button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  direction: "rtl",
                }}
              >
                <ListItemText primary={"כתבות"} style={{ display: "flex" }} />
                <ListItemIcon style={{ display: "flow-root" }}>
                  <MenuBookIcon />
                </ListItemIcon>
              </ListItem>
            </div>
          ) : null}
          <div onClick={() => handleClickScroll("waiting-list-client-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText
                primary={"מצא לי נכס"}
                style={{ display: "flex" }}
              />
              <ListItemIcon style={{ display: "flow-root" }}>
                <AccessAlarmsIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <FlexRowBetween
            style={{
              width: "min-content",
              justifyContent: "flex-start",
              marginRight: "15px",
              marginTop: "10px",
            }}
          >
            {user.instagram && (
              <a target="_blank" rel="noreferrer" href={user.instagram}>
                <GenericSvg
                  fill={user.controls}
                  size={"25px"}
                  svg={svgs.instagram}
                  viewBox={"0 0 1024 1024"}
                />
              </a>
            )}
            {user.instagram && user.facebook && (
              <div style={{ width: "15px" }}></div>
            )}
            {user.facebook && (
              <a target="_blank" rel="noreferrer" href={user.facebook}>
                <GenericSvg
                  fill={user.controls}
                  size={"27px"}
                  svg={svgs.facebook}
                  viewBox={"0 0 24 24"}
                />
              </a>
            )}
          </FlexRowBetween>
        </List>
      </Drawer>
    </div>
  );
};

export default BurgerMenuUser;
