import React, { useMemo, useEffect, useState } from "react";
import {
  getAll,
  cleanAll,
  deleteLead,
} from "../../../store/reducers/leadsReducer";
import Switch from "react-switch";

import { postCsv } from "../../../store/reducers/dataReducer";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  addAlpha,
  availHeight,
  getImageUrl,
  GetToast,
  parseDate,
} from "../../common/modals/Toast";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  ClientCard,
  AssetPic,
  AssetsHolder,
  constants,
  FlexRowBetween,
  GenericDiv,
  ClosenessTitle2,
  PageTitle,
  DropOption,
  Section,
  RespFont,
  FlexColumnBetween,
  GenericLink,
} from "../../Style";
import sort from "../../../assets/nadlan/sort.svg";
import GenericModal from "../../common/modals/GenericModal";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import {
  linkSvg,
  noWaitings,
  svgs,
  zaxEdit,
} from "../../common/footer/coloredSvgs/svgs";
import errors from "../../../assets/nadlan/errors1.svg";
import downArrowB from "../../../assets/nadlan/downArrowB.svg";
import downArrowW from "../../../assets/nadlan/downArrowW.svg";
import upArrowB from "../../../assets/nadlan/upArrowB.svg";
import upArrowW from "../../../assets/nadlan/upArrowW.svg";

import YesNoModal from "../../common/modals/YesNoModal";
import OpacityLoading from "../../common/loader/OpacityLoading";
import AskForDownload from "../../common/modals/AskForDownload.js.js";

import readXlsxFile from "read-excel-file";
import { useExcelDownloder } from "react-xls";
import EditNotes from "./EditNotes";
import { editStatus } from "../../../store/reducers/leadsReducer";
let dic = {
  minSquareMeters: "מ״ר מינימלי",
  minRooms: "מס׳ חדרים מינימלי",
  minPrice: "מחיר מינימלי",
  maxSquareMeters: "מ״ר מקסימלי",
  maxRooms: "מס׳ חדרים מקסימלי",
  maxPrice: "מחיר מקסימלי",
  preferredCity: "עיר מועדפת",
};
let multyChoise = {};
let multyChoiseCounter = 0;
const Leads = (props) => {
  let widthS = window.innerWidth; // 1728

  const { ExcelDownloder, Type } = useExcelDownloder();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { leads, leadsError, leadsLoading } = useSelector(
    (state) => state.leadsReducer
  );
  const { dataError } = useSelector((state) => state.dataReducer);

  const { user } = useSelector((state) => state.authReducer);
  const [askForDownload, setAskForDownload] = useState(false);
  const [deleteGuests, setDeleteGuests] = useState(false);
  const [deleteGuest, setDeleteGuest] = useState(false);
  const [showEditNote, setShowEditNote] = useState(false);
  const [selected, setSelected] = useState(0);

  const [sortBy, setSortBy] = useState("dateAdded");
  const [sortDirection, setSortDirection] = useState(false);
  const [editAsset, setEditAsset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  let height = window.innerHeight;

  useEffect(() => {
    dispatch(getAll());
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (leadsError) GetToast(addToast, "error", leadsError);
  }, [leadsError]);

  const deleteOne = () => {
    multyChoise[deleteGuest._id] = true;
    deleteFunc();
    setDeleteGuest(null);
  };
  const initDeleteOne = () => {
    setDeleteGuest(null);
  };

  // const add_del = (item) => {
  //   if (multyChoise[item._id] !== undefined) {
  //     delete multyChoise[item._id];
  //     multyChoiseCounter--;
  //   } else {
  //     multyChoise[item._id] = `+972${item.phone}`;
  //     multyChoiseCounter++;
  //   }
  //   setSelected(multyChoiseCounter);
  //   setClosenessChosenFlag(!closenessChosenFlag);
  // };
  const deleteFunc = () => {
    dispatch(deleteLead(Object.keys(multyChoise))).then(
      (data) => {
        setMultiSelect(false);
        if (!data.payload.error)
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        setDeleteGuests(false);
        multyChoise = {};
        multyChoiseCounter = 0;
        setSelected(0);
        setEditAsset(null);
      },
      (error) => {
        GetToast(addToast, "error", error);
        setDeleteGuests(false);
        setMultiSelect(false);
      }
    );
  };

  const setDone = async (id, done) => {
    dispatch(editStatus(id, { done })).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
      }
    });
  };

  // const handleClick = (e, data) => {
  //   if (e.type === "click") {
  //     if (multiSelect) add_del({ _id: data._id, phone: data.phone });
  //     else setEditAsset(data);
  //   } else if (e.type === "contextmenu") {
  //     e.preventDefault();
  //     console.log("Right click");
  //   }
  // };
  const phonesToList = () => Object.values(multyChoise).join(",");

  let guestsArray = useMemo(() => {
    let arr = [];
    let excel = [];
    let sortedArray = [];
    if (Object.keys(leads).length > 0) {
      // sortedArray.push({ ...value, dateAdded: parseDate(value.dateAdded) });
      for (const [key, value] of Object.entries(leads)) sortedArray.push(value);
      switch (sortBy) {
        case "dateAdded":
          if (sortDirection)
            sortedArray.sort(
              (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
            );
          else
            sortedArray.sort(
              (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
            );
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
          else sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
      }
      // let newSortedArray = [];
      // for (let j = 0; j < sortedArray.length; j++)
      //   newSortedArray.push({
      //     ...sortedArray[j],
      //     dateAdded: parseDate(sortedArray[j]["dateAdded"]),
      //   });
      for (let i = 0; i < sortedArray.length; i++) {
        arr.push(
          <ClientCard
            key={i}
            // onClick={() => setEditTodo(value)}
            style={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.11)",
            }}
          >
            <FlexRowBetween style={{ alignItems: "flex-start" }}>
              <FlexColumnBetween>
                <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                  <div
                    style={{
                      fontFamily: "hebM",
                      whiteSpace: "nowrap",
                      width: "min-content",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "7px 7px 7px 10px",
                      backgroundColor: addAlpha(
                        user.settings.colors.buttonsColor,
                        0.15
                      ),
                      marginTop: "-10px",
                      marginRight: "-10px",
                      borderRadius: "0 3px 0 5px",
                    }}
                  >
                    <RespFont pSize={"12px"} cSize={"14px"}>
                      {parseDate(sortedArray[i].dateAdded)}
                    </RespFont>
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div
                    style={{
                      fontFamily: "hebM",
                      whiteSpace: "nowrap",
                      width: "min-content",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {sortedArray[i].fullname}
                  </div>
                </FlexRowBetween>
                {/* <GenericLink
                  // href={`localhost:3000/asset?a=${window.btoa(data._id)}`}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  href={`https://nadlan.agency/asset?a=${window.btoa(
                    sortedArray[i]._id
                  )}`}
                  style={{
                    borderRadius: "2px",
                    color: user.settings.colors.buttonsFontColor,
                    background: user.settings.colors.buttonsColor,
                    padding: "12px 20px",
                    fontWeight: "700",
                  }}
                >
                  לפרטים
                </GenericLink> */}
              </FlexColumnBetween>
              <FlexColumnBetween
                style={{
                  width: "min-content",
                  height: "100%",
                  //   alignItems:"",
                  marginLeft: "0px",
                  padding: "10px",
                  backgroundColor: addAlpha(
                    user.settings.colors.buttonsColor,
                    0.065
                  ),
                }}
              >
                <a
                  style={{ filter: "brightness(80%)" }}
                  target="_blank"
                  //   style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
                  rel="noreferrer"
                  href={`https://wa.me/972${sortedArray[i].phone}/?text=`}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    //   size={widthS > 768 ? "35px" : "25px"}
                    size={"20px"}
                    svg={svgs.whatsapp}
                    viewBox={"0 0 24 24"}
                  />
                </a>
                {/* <a
                  style={{ marginTop: "25px", filter: "brightness(80%)" }}
                  target="_blank"
                  // style={{ marginLeft: widthS > 768 ? "15px" : "15px" }}
                  rel="noreferrer"
                  href={`tel:972${sortedArray[i].phone}`}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    //   size={widthS > 768 ? "35px" : "25px"}
                    size={"18px"}
                    svg={svgs.phone}
                    //   viewBox={"0 0 24 24"}
                  />
                </a> */}
                <div
                  style={{ marginTop: "25px", filter: "brightness(80%)" }}
                  onClick={() => setShowEditNote(sortedArray[i])}
                >
                  {zaxEdit(20, user.settings.colors.buttonsColor)}
                </div>
                <GenericLink
                  // href={`localhost:3000/asset?a=${window.btoa(data._id)}`}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  href={`https://nadlan.agency/asset?a=${window.btoa(
                    sortedArray[i].assetId
                  )}`}
                  style={{
                    borderRadius: "2px",
                    color: user.settings.colors.buttonsFontColor,
                    background: "none",
                    // padding: "6px 10px",
                    fontWeight: "700",
                    fontSize: "14px",
                    marginTop: "0px",
                  }}
                >
                  <RespFont
                    style={{
                      filter: "brightness(80%)",
                      color: user.settings.colors.buttonsColor,
                      textDecoration: "underline",
                      paddingTop: "15px",
                      paddingBottom: "-9px",
                      marginBottom: "-6px",
                      marginTop: "3.5px",
                    }}
                  >
                    לנכס
                  </RespFont>
                </GenericLink>
              </FlexColumnBetween>
              <FlexColumnBetween
                style={{
                  width: "min-content",
                  height: "100%",
                  //   alignItems:"",
                  marginLeft: "0px",
                  padding: "10px",
                  backgroundColor: addAlpha(
                    user.settings.colors.buttonsColor,
                    0.065
                  ),
                }}
              >
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={<Tooltip>{"סטטוס"}</Tooltip>}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{ marginBottom: "-5px" }}
                  >
                    <Switch
                      width={30}
                      height={14}
                      onColor={"#b1b1b1"}
                      offColor={"#b1b1b1"}
                      onHandleColor={user.settings.colors.buttonsColor}
                      boxShadow={"0 2px 4px silver, 0 -1px 4px silver"}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      checked={sortedArray[i].done}
                      onChange={() =>
                        setDone(sortedArray[i]._id, !sortedArray[i].done)
                      }
                      handleDiameter={18}
                    />
                  </div>
                </OverlayTrigger>
                <div
                  style={{ marginTop: "25px", filter: "brightness(80%)" }}
                  onClick={() => setDeleteGuest(sortedArray[i])}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    size={"20px"}
                    svg={svgs.delete}
                    viewBox={"0 0 297 297"}
                  />
                </div>
                <a
                  style={{ marginTop: "25px", filter: "brightness(80%)" }}
                  target="_blank"
                  // style={{ marginLeft: widthS > 768 ? "15px" : "15px" }}
                  rel="noreferrer"
                  href={`tel:972${sortedArray[i].phone}`}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    //   size={widthS > 768 ? "35px" : "25px"}
                    size={"18px"}
                    svg={svgs.phone}
                    //   viewBox={"0 0 24 24"}
                  />
                </a>
              </FlexColumnBetween>
            </FlexRowBetween>
          </ClientCard>
        );

        // prepare to excel
        // excel.push({
        //   "שם מלא": newSortedArray[i].name,
        //   "מספר פלאפון": newSortedArray[i].phone,
        //   "עיר מועדפת": newSortedArray[i].preferredCity,
        //   "שכונה מועדפת": newSortedArray[i].preferredNeighborhood,
        //   קטגוריה: newSortedArray[i].closeness,
        //   "מינימום מ״ר": newSortedArray[i].minSquareMeters,
        //   "מינימום חדרים": newSortedArray[i].minRooms,
        //   "מינימום מחיר": newSortedArray[i].minPrice,
        //   "מקסימום מ״ר": newSortedArray[i].maxSquareMeters,
        //   "מקסימום חדרים": newSortedArray[i].maxRooms,
        //   "מקסימום מחיר": newSortedArray[i].maxPrice,
        //   הערות: newSortedArray[i].notes,
        // });
      }
    }
    return [arr, excel];
  }, [leads, sortBy, sortDirection, multiSelect, selected]);

  const fileSelected = (event) => {
    readXlsxFile(event.target.files[0]).then((rows) => {
      dispatch(postCsv({ rows })).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
          dispatch(getAll());
        }
      });
    });
  };

  return (
    <div
      id="leadsListA-id"
      style={{
        width: "100%",
        margin: "0 auto",
        position: "relative",
        minHeight: "70vh",
        maxHeight: "100vh",
        // overflowY: "auto",
      }}
    >
      {/* <div
        class="fb-share-button"
        data-href="https://developers.facebook.com/docs/plugins/"
        data-layout="button_count"
        data-size="small"
      >
        <a
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
          class="fb-xfbml-parse-ignore"
        >
          Share
        </a>
      </div> */}
      <Section
        cSize={"38px"}
        pSize={"34px"}
        style={{ color: constants.fontColor, marginTop: "40px" }}
      >
        רשימת לידים
      </Section>
      <FlexRowBetween style={{ width: "95%", margin: "10px auto" }}>
        {/* מיון */}
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "60px",
            padding: widthS > 768 ? "5px" : "3px",
            fontWeight: "700",
            marginRight: "0px",
          }}
        >
          <RespFont
            pSize={"12px"}
            onClick={() => setSortBy("dateAdded")}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                sortBy === "dateAdded"
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: sortBy === "dateAdded" ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            תאריך הוספה
          </RespFont>
          <RespFont
            pSize={"12px"}
            onClick={() => setSortBy("done")}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                sortBy === "done"
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: sortBy === "done" ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            טופל?
          </RespFont>
        </FlexRowBetween>

        {/* כיוון */}
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "50px",
            padding: widthS > 768 ? "5px" : "3px",
            fontWeight: "700",
            marginLeft: "0px",
          }}
        >
          <RespFont
            onClick={() => setSortDirection(true)}
            style={{
              padding: widthS > 768 ? "6px 10px" : "0px 4px",
              cursor: "pointer",
              backgroundColor: sortDirection
                ? user.settings.colors.buttonsColor
                : "inherit",
              transition: "all 0.2s",
              color: sortDirection ? "#fff" : constants.fontColorZax,
              borderRadius: "50px",
              width: "max-content",
            }}
          >
            <img
              src={sortDirection ? upArrowW : upArrowB}
              style={{ width: widthS > 768 ? "23px" : "13px" }}
            />
          </RespFont>
          <RespFont
            onClick={() => setSortDirection(false)}
            style={{
              padding: widthS > 768 ? "6px 10px" : "0px 4px",
              cursor: "pointer",
              backgroundColor: !sortDirection
                ? user.settings.colors.buttonsColor
                : "inherit",
              transition: "all 0.2s",
              color: !sortDirection ? "#fff" : constants.fontColorZax,
              borderRadius: "50px",
              width: "max-content",
            }}
          >
            <img
              src={sortDirection ? downArrowB : downArrowW}
              style={{ width: widthS > 768 ? "23px" : "13px" }}
            />
          </RespFont>
        </FlexRowBetween>
      </FlexRowBetween>
      {/* {sortBy[0] === "m" && (
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "60px",
            padding: widthS > 768 ? "5px" : "3px",
            fontWeight: "700",
            marginRight: "2.5%",
            marginTop: "10px",
          }}
        >
          <RespFont
            pSize={"12px"}
            onClick={() => {
              let temp = sortBy.split("");
              if (sortBy[1] === "a") {
                temp.splice(1, 2, "in");
                //   temp[1] = "i";
                //   temp[2] = "n";
              }
              temp = temp.join("");
              setSortBy(temp);
            }}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                sortBy[1] === "i"
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: sortBy[1] === "i" ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            רף תחתון
          </RespFont>
          <RespFont
            pSize={"12px"}
            onClick={() => {
              let temp = sortBy.split("");
              if (sortBy[1] === "i") {
                temp.splice(1, 2, "ax");
                //   temp[1] = "a";
                //   temp[2] = "x";
              }
              temp = temp.join("");
              setSortBy(temp);
            }}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                sortBy[1] === "a"
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: sortBy[1] === "a" ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            רף עליון
          </RespFont>
        </FlexRowBetween>
      )} */}
      {/* <FlexRowBetween style={{ width: "95%", margin: "0px auto" }}>
        <>
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"ייצוא"}</Tooltip>}
          >
            <div
              onClick={() => setAskForDownload(true)}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                filter: "brightness(80%)",
                marginRight: "10px",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"28px"}
                svg={svgs.export}
                viewBox={"0 0 24 24"}
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"בחירה מרובה"}</Tooltip>}
          >
            <div
              onClick={() => {
                if (multiSelect) {
                  multyChoise = {};
                  multyChoiseCounter = 0;
                  setSelected(0);
                }
                setMultiSelect(!multiSelect);
              }}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                filter: "brightness(80%)",
                marginRight: "10px",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"28px"}
                svg={svgs.checklist}
                viewBox={"0 0 98.374 98.374"}
              />
            </div>
          </OverlayTrigger>
          {multiSelect && (
            <div
              className="animate__animated animate__fadeIn"
              style={{
                marginRight: "10px",
                fontSize: "20px",
                color: user.settings.colors.buttonsColor,
                filter: "brightness(80%)",
                fontWeight: "700",
              }}
            >
              {selected}
            </div>
          )}

          {dataError?.kind === "csv" && (
            <ExcelDownloder
              data={{ שגיאות: dataError.error }}
              filename={"שגיאות אקסל"}
              type={Type.Button} // or type={'button'}
              style={{
                border: "none",
                background: "transparent",
                width: "0px",
              }}
            >
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip>{"שגיאות"}</Tooltip>}
              >
                <img
                  src={errors}
                  style={{ width: "32px", marginRight: "15px" }}
                />
              </OverlayTrigger>
            </ExcelDownloder>
          )}
        </>
        {multiSelect ? (
          <FlexRowBetween
            style={{ justifyContent: "flex-end" }}
            className="animate__animated animate__fadeIn"
          >
            {Object.keys(multyChoise).length > 0 && (
              <a
                className="animate__animated animate__fadeInLeft"
                href={`sms://open?addresses=${phonesToList()}?&body=`}
              >
                <div
                  style={{
                    cursor: "pointer",
                    marginRight: "15px",
                    filter: "brightness(80%)",
                  }}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    size={"31px"}
                    svg={svgs.sms2}
                    viewBox={"0 0 68 68"}
                  />
                </div>
              </a>
            )}
            <div
              style={{
                cursor: "pointer",
                marginRight: "15px",
                filter: "brightness(80%)",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"20px"}
                svg={svgs.whatsapp}
                viewBox={"0 0 24 24"}
              />
            </div>
            <div
              onClick={() => {
                if (Object.keys(multyChoise).length > 0) setDeleteGuests(true);
                else alert("לא נבחרו ממתינים");
              }}
              style={{
                cursor: "pointer",
                marginRight: "15px",
                filter: "brightness(80%)",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"20px"}
                svg={svgs.delete}
                viewBox={"0 0 297 297"}
              />
            </div>
          </FlexRowBetween>
        ) : (
          <FlexRowBetween></FlexRowBetween>
        )}
      </FlexRowBetween> */}

      <AssetsHolder style={{ height: `${height - 180}px`, marginTop: "20px" }}>
        {guestsArray[0].length ? (
          guestsArray[0]
        ) : (
          <FlexColumnBetween style={{ justifyContent: "space-evenly" }}>
            <RespFont style={{ fontFamily: "hebB" }}>
              נראה שעדיין לא נרשמו, נחכה להתפתחויות...
            </RespFont>
            {noWaitings(
              widthS > 768 ? 432.88 : 216.44,
              widthS > 768 ? 341.445 : 170.7225,
              user.settings.colors.buttonsColor
            )}
          </FlexColumnBetween>
        )}
      </AssetsHolder>

      {leadsLoading && <OpacityLoading />}

      {deleteGuests && (
        <GenericModal
          init={setDeleteGuests}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"מחיקת רשומות"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeleteGuests}
              yes={deleteFunc}
            />
          }
        />
      )}

      {deleteGuest && (
        <GenericModal
          init={initDeleteOne}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"מחיקת ליד"}
              body={"האם את/ה בטוח/ה?"}
              no={initDeleteOne}
              yes={deleteOne}
            />
          }
        />
      )}

      {showEditNote && (
        <GenericModal
          init={setShowEditNote}
          div={<EditNotes data={showEditNote} init={setShowEditNote} />}
        />
      )}

      {askForDownload && guestsArray[1].length && (
        <GenericModal
          init={setAskForDownload}
          div={
            <AskForDownload
              color={user.settings.colors.buttonsColor}
              title={`נתונים`}
              filename={`נתונים`}
              body={`האם ברצונך להוריד אקסל נתונים?`}
              no={setAskForDownload}
              data={guestsArray[1]}
              init={setAskForDownload}
            />
          }
        />
      )}
    </div>
  );
};

export default Leads;
