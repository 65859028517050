import React from "react";
import { FlexRowBetween, Section } from "../../Style";
import GenericArticle from "./GenericArticle";

const Articles = (props) => {
  return (
    <div style={{ padding: "40px 0", paddingBottom: "50px", width: "100%" }}>
      <Section pSize={"36px"} cSize={"48px"}>
        כתבות החודש
      </Section>
      <FlexRowBetween style={{ justifyContent: "center", width: "100%" }}>
        <GenericArticle
          data={{
            title:
              "ההתמתנות בקצב התייקרות הדירות ממשיכה: עלו ב-14.6% במהלך 2022",
            pic: "https://storage.nadlancenter.co.il/articles_new/32168_tumb_750Xauto.jpg",
            link: "https://www.nadlancenter.co.il/article/7359",
          }}
        />
        <div style={{ marginLeft: "20px" }} />
        <GenericArticle
          data={{
            title:
              'תוכנית המתאר החדשה של רמת השרון: 110 אלף תושבים ומיליוני מ"ר משרדים',
            pic: "https://storage.nadlancenter.co.il/articles_new/31371_tumb_750Xauto.jpg",
            link: "https://www.nadlancenter.co.il/article/7319",
          }}
        />
        <div style={{ marginRight: "20px" }} />
        <GenericArticle
          data={{
            title:
              'הדרכון האירופי מתרחק: ממשלת פורטוגל מבטלת את השקעות הנדל"ן לקבלת אזרחות',
            pic: "https://storage.nadlancenter.co.il/articles_new/30505_tumb_750Xauto.jpg",
            link: "https://www.nadlancenter.co.il/article/7236",
          }}
        />
      </FlexRowBetween>
    </div>
  );
};

export default Articles;
