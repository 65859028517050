import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { FlexRowBetween, MainDiv, RespFont } from "../Style";
import {
  handleClickScroll,
  getImageUrl,
  GetToast,
} from "../common/modals/Toast";
import { DisplayNoneComputer, DisplayNonePhone } from "../common/Style";
import { editDetails } from "../../store/reducers/authReducer";
import { deletePhoto } from "../../store/reducers/photosReducer";
import Assets from "./assets/Assets";
import Clients from "./clients/Clients";
import BurgerMenuUserAgent from "../common/menus/BurgerMenuUserAgent";
import Todos from "./todos/Todos";
import EditDetails from "./EditDetails";
import Face from "../common/views/Face";
import FaceP from "../common/views/FaceP";
import AboutC from "../common/views/AboutC";
import AboutP from "../common/views/AboutP";
import ReferencesC from "../common/views/ReferencesC";
import QandA from "../common/views/QandA";
import ArticlesC from "../common/views/ArticlesC";
import Waitings from "../common/views/Waitings";
import WaitingList from "./waitings/WaitingList";
import UserFooter from "../common/others/footer/UserFooter";
import Leads from "./leads/Leads";
import GenericSvg from "../common/footer/coloredSvgs/GenericSvg";
import { svgs } from "../common/footer/coloredSvgs/svgs";
import Docs from "./docs/Docs";
import EditDetailsFace from "./EditDetailsFace";
import EditDetailsAboutMe from "./EditDetailsAboutMe";
import EditDetailsQuesAndAns from "./EditDetailsQuesAndAns";
import EditDetailsContacts from "./EditDetailsContacts";
import EditDetailsArticle from "./EditDetailsArticle";
import EditDetailsAssets from "./EditDetailsAssets";
import EditDetailsRefs from "./EditDetailsRefs";

const User = () => {
  const { user } = useSelector((state) => state.authReducer);
  const [editFace, setEditFace] = useState(false);
  const [editAbout, setEditAbout] = useState(false);
  const [editRefs, setEditRefs] = useState(false);
  const [editArticle, setEditArticle] = useState(false);
  const [editContacts, setEditContacts] = useState(false);
  const [editQuesAndAns, setEditQuesAndAns] = useState(false);
  const [editAssets, setEditAssets] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const width = window.screen.availWidth;
  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  let widthS = window.innerWidth; // 1728

  const deletePhotoOnly = async (key) => {
    await deletePhoto1(user[key]);
    dispatch(editDetails({ [key]: "" })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };

  let logoPic =
    user.logoPic !== ""
      ? `${getImageUrl()}${user._id}/${user.logoPic}`
      : `${getImageUrl()}sys/defaultAgentLogo.png`;
  return (
    <MainDiv
      className="animate__animated animate__fadeIn"
      style={{
        background: `#F6F6F6`,
        position: "relative",
      }}
    >
      {/* <NavBarMenuUserAgent
        logoPic={logoPic}
        user={{
          colors: user.settings.colors,
          facebook: user.facebook,
          instagram: user.instagram,
          color: user.controls,
          bio: user.bio,
        }}
      /> */}
      <BurgerMenuUserAgent
        logoPic={logoPic}
        user={{
          titles: user.settings.PagesTitle,
          colors: user.settings.colors,
        }}
      />
      <div id={"hhhj"}></div>
      {/* <DisplayNonePhone style={{ marginTop: "69px" }} /> */}
      <DisplayNoneComputer>
        <FaceP setEditFace={setEditFace} editFace={editFace} />
        {editFace && <EditDetailsFace />}
        {/* <FacePContent /> */}
      </DisplayNoneComputer>
      <DisplayNonePhone>
        <Face setEditFace={setEditFace} editFace={editFace} />
        {editFace && <EditDetailsFace />}
      </DisplayNonePhone>
      {/* <Bg /> */}
      <Assets setEditAssets={setEditAssets} editAssets={editAssets} />
      {editAssets && <EditDetailsAssets />}

      {user.settings.showPages.page3 && (
        <>
          <DisplayNoneComputer
            style={{ background: user.settings.pagesColors.page3 }}
          >
            <AboutP setEditAbout={setEditAbout} editAbout={editAbout} />
            {editAbout && (
              // <div style={{ width: "100%", backgroundColor: "#F6F6F6" }}>
              <EditDetailsAboutMe />
            )}
          </DisplayNoneComputer>
          <DisplayNonePhone>
            <AboutC setEditAbout={setEditAbout} editAbout={editAbout} />
            {editAbout && <EditDetailsAboutMe />}
          </DisplayNonePhone>
        </>
      )}
      {/* <DisplayNoneComputer>
        <AboutP />
      </DisplayNoneComputer> */}
      {/* {user.settings.showPages.page4 && (
        <>
          <DisplayNonePhone>
            <ReferencesC />
          </DisplayNonePhone>
        </>
      )} */}

      {user.settings.showPages.page4 && (
        <ReferencesC setEditRefs={setEditRefs} editRefs={editRefs} />
      )}
      {editRefs && <EditDetailsRefs />}
      {user.settings.showPages.page5 && (
        <>
          <QandA
            setEditQuesAndAns={setEditQuesAndAns}
            editQuesAndAns={editQuesAndAns}
          />
          {editQuesAndAns && <EditDetailsQuesAndAns />}
        </>
      )}

      {user.settings.showPages.page6 && (
        <>
          <ArticlesC
            setEditArticle1={setEditArticle}
            editArticle1={editArticle}
          />

          {editArticle && <EditDetailsArticle />}
        </>
      )}
      <Waitings setEditContacts={setEditContacts} editContacts={editContacts} />
      {editContacts && <EditDetailsContacts />}
      <WaitingList />
      <Docs />
      <Todos />
      <Clients />
      <Leads />
      <EditDetails />
      <UserFooter id={"hhhj"} />
      <div
        onClick={() => handleClickScroll("hhhj")}
        style={{
          width: "min-content",
          boxShadow:
            "0 2px 2px rgba(0, 0, 0, 0.11), 0 -2px 2px rgba(0, 0, 0, 0.11)",
          borderRadius: "50px",
          padding: "5px",
          fontWeight: "700",
          marginRight: "30px",
          cursor: "pointer",
          position: "fixed",
          bottom: "20px",
          right: "0px",
        }}
      >
        <div
          style={{
            padding: widthS > 768 ? "4px 4px" : "2px 2px",

            backgroundColor: user.settings.colors.buttonsColor,
            transition: "all 0.2s",
            borderRadius: "50px",
            width: "max-content",
          }}
        >
          <GenericSvg
            fill={user.settings.colors.buttonsFontColor}
            size={"28px"}
            svg={svgs.scrollTop}
            viewBox={"0 0 32 32"}
          />
        </div>
      </div>
    </MainDiv>
  );
};

export default User;
