import React from "react";
import {
  GenericButton,
  RespFont,
  Responsive,
  HomeInput,
  FlexRowBetween,
  DisplayNonePhone,
  DisplayNoneComputer,
} from "../../common/Style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { addPotential } from "../../../store/reducers/potentialsReducer";
import { CenteredFormPage1, MainRegister } from "./Style";
import handShake11 from "./assets/handShake22.png";
import city from "./assets/deal.jpg";
import p0 from "../../../assets/zaks/p0.svg";
import p1 from "../../../assets/zaks/p1.svg";
import p2 from "../../../assets/zaks/p2.svg";
import p3 from "../../../assets/zaks/p3.svg";
import p4 from "../../../assets/zaks/p4.svg";

const Left = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { potentialsLoading } = useSelector((state) => state.potentialsReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const body = {
      phone: data.phone,
      name: data.name,
    };
    dispatch(addPotential(body)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      } else GetToast(addToast, "error", res.payload.error);
    });
  };
  let widthS = window.innerWidth; // 1728
  return (
    <div
      id={"home"}
      style={{
        width: "120%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: widthS / 57.6,
        height:
          widthS > 768
            ? widthS / 2.95
            : widthS > 480
            ? widthS / 1.7
            : widthS > 370
            ? widthS / 2
            : widthS / 2.7,
        position: "relative",
      }}
    >
      <DisplayNonePhone style={{ width: "100%", height: "100%" }}>
        <img
          src={p1}
          alt={"p1"}
          style={{
            position: "absolute",
            top: widthS / -16,
            left: 0,
            zIndex: 0,
            width: widthS / 2.88,
          }}
        />
        <img
          src={p0}
          alt={"p0"}
          style={{
            zIndex: 1,
            width: widthS / 3.456,
            position: "absolute",
            top: "5px",
            left: widthS / 6.574,
          }}
        />

        <img
          src={p2}
          alt={"p2"}
          style={{
            position: "absolute",
            top: 0,
            left: widthS / 5.574,
            zIndex: 0,
            width: widthS / 11.52,
          }}
        />
        <img
          src={p3}
          alt={"p3"}
          style={{
            position: "absolute",
            top: widthS / 9,
            left: widthS / 7.68,
            zIndex: 1,
            width: widthS / 8.307,
          }}
        />
        <img
          src={p4}
          alt={"p4"}
          style={{
            position: "absolute",
            top: widthS / 20.64,
            left: widthS / 2.88,
            zIndex: 0,
            width: widthS / 10.1647,
          }}
        />
      </DisplayNonePhone>
      <DisplayNoneComputer>
        <img
          src={p1}
          alt={"p1"}
          style={{
            position: "absolute",
            top: "-120px",
            left: "20px",
            zIndex: 0,
            width: widthS / 0.9,
            WebkitMaskImage:
              "linear-gradient(to top,rgba(255, 255, 255, 0.1),rgb(255, 255, 255),rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255))",
          }}
        />
        <img
          src={p0}
          alt={"p0"}
          style={{
            zIndex: 1,
            width: widthS / 1.152,
            position: "absolute",
            top: "5px",
            left: widthS / 5,
            WebkitMaskImage:
              "linear-gradient(to top,rgba(255, 255, 255, 0.1),rgb(255, 255, 255),rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255))",
          }}
        />

        <img
          src={p2}
          alt={"p2"}
          style={{
            position: "absolute",
            top: 0,
            left: widthS / 3.574,
            zIndex: 0,
            width: widthS / 3.84,
          }}
        />
        <img
          src={p3}
          alt={"p3"}
          style={{
            position: "absolute",
            top: widthS / 3,
            left: widthS / 7.68,
            zIndex: 1,
            width: widthS / 2.769,
          }}
        />
        <img
          src={p4}
          alt={"p4"}
          style={{
            position: "absolute",
            top: widthS / 7.24,
            left: widthS / 1.38,
            zIndex: 0,
            width: widthS / 3.388,
          }}
        />
      </DisplayNoneComputer>
    </div>
  );
};

export default Left;
