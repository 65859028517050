import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const GenericCarousle = ({
  dir = "rtl",
  data,
  width = "100%",
  interval = false,
}) => {
  const [activeStep, setActiveStep] = React.useState(data.length - 1);
  const maxSteps = data.length;
  const [inter, setInter] = React.useState(1000);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setInter(20000);
    }, 1500);
  }, []);

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        interval={interval ? inter : 3000}
        axis={dir === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        direction={"decremental"}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        // style={{ border: "2px solid red", padding: "5px 0" }}
        //     springConfig={{duration: "0.5s",
        // easeFunction: "cubic-bezier(0.1, 0.35, 0.2, 1)",
        // delay: "0.5s"}}
      >
        {data.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? step : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        style={{
          background: "rgb(255, 255, 255, 0.1)",
          // border: "2px solid blue",
          // marginTop: "10px",
        }}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {/* הקודם */}
            {dir === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {dir === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            {/* הבא */}
          </Button>
        }
      />
    </Box>
  );
};

export default GenericCarousle;
