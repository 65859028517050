import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ClientRoutes from "./Routes";
import Login from "../components/unAuth/Login";
// import Login from '../components/unAuth/Login1';
import Register from "../components/unAuth/Register";
import Client from "../components/unAuth/agent/Client";
import Asset from "../components/unAuth/agent/Asset";
import Home from "../components/unAuth/home/Home";
import SuccessPage from "../components/common/views/SuccessPage";
import FailurePage from "../components/common/views/FailurePage";
import Doc from "../components/common/views/Doc";
import FreeSign from "../components/user/docs/FreeSign";
const UnAuthSwitch = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.home}
        component={(props) => <Home {...props} />}
      />
      <Route
        path={ClientRoutes.UnAuth.doc}
        component={(props) => <Doc {...props} />}
      />
      <Route
        path={ClientRoutes.UnAuth.freeSign}
        component={(props) => <FreeSign {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.login}
        component={(props) => <Login {...props} />}
      />
      {/* <Route exact={true} path={ClientRoutes.UnAuth.login} component={props => <Login {...props} />} /> */}
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.register}
        component={(props) => <Register {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.agents}
        component={(props) => <Client {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.asset}
        component={(props) => <Asset {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.successPage}
        component={(props) => <SuccessPage {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.User.failurePage}
        component={(props) => <FailurePage {...props} />}
      />
      <Redirect from="/" to={ClientRoutes.UnAuth.home} />
    </Switch>
  );
};

export default UnAuthSwitch;
