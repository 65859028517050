import React, { useState } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  RTextArea,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  GenericDiv,
  GalleryOptions,
  RespFont,
} from "../Style";
import ixW from "../../assets/nadlan/ixW.svg";
import ixB from "../../assets/nadlan/ixB.svg";
import viW from "../../assets/nadlan/viW.svg";
import viB from "../../assets/nadlan/viB.svg";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { addAlpha, CFV, GetToast } from "../common/modals/Toast";
import OpacityLoading from "../common/loader/OpacityLoading";
import Switcher from "../common/switcher/Switcher";
import GCalendar from "../common/calendar/Calendar";
import { GuestOptions, HomeInput, HomeTextArea } from "../common/Style";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import GenericSvg from "../common/footer/coloredSvgs/GenericSvg";
import YesNoModal from "../common/modals/YesNoModal";
import { svgs } from "../common/footer/coloredSvgs/svgs";
import GenericModal from "../common/modals/GenericModal";
import { editUserById } from "../../store/reducers/userReducer";
const { encode } = require("url-encode-decode");

const Edit = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { usersLoading } = useSelector((state) => state.userReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [deleteT, setDeleteT] = useState(false);
  const [dateEWasChanged, setDateEWasChanged] = useState(false);
  const [dateSWasChanged, setDateSWasChanged] = useState(false);
  const [paymentTag, setPaymentTag] = useState(props.data.paymentTag);
  const [approved, setApproved] = useState(props.data.approved);

  const [dateE, setDateE] = useState(new Date(props.data.purchaseEnd));
  const [dateS, setDateS] = useState(new Date(props.data.purchaseStart));
  const [originalDate, setOriginalDate] = useState(new Date(props.data.date));
  const [showCalendarE, setShowCalendarE] = useState(null);
  const [showCalendarS, setShowCalendarS] = useState(null);
  const handleSetDateE = (e) => {
    setDateEWasChanged(true);
    // toISOString()
    let date1 = e;
    date1.setHours(20);
    setDateE(date1);
  };
  const handleSetDateS = (e) => {
    setDateSWasChanged(true);
    let date1 = e;
    date1.setHours(20);
    setDateS(date1);
  };
  const onSubmit = async (data) => {
    const body = {
      //   fullname: data.fullname,
      purchaseStart: dateS,
      purchaseEnd: dateE,
      paymentTag,
      approved,
    };
    dispatch(
      editUserById(body, props.data._id, dateSWasChanged, dateEWasChanged)
    ).then((res) => {
      if (!res.payload?.error) props.init();
    });
  };

  //   const deletT = () => {
  //     dispatch(deleteTodo(props.data._id)).then((res) => {
  //       if (!res.payload?.error) {
  //         GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
  //         props.init();
  //       }
  //     });
  //   };
  let height = window.screen.availHeight;
  let widthS = window.innerWidth; // 1728

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "90%",
          //   margin: "10px auto",
        }}
      >
        <FlexRowBetween>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שם
            </RespFont>
            <HomeInput
              style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
              disabled={true}
              type="text"
              defaultValue={props.data.fullname}
              //   {...register("fullname", {
              //     required: true,
              //     minLength: 2,
              //     maxLength: 20,
              //   })}
            />
            {/* {CFV("fullname", "בין 2 ל 20 תווים", errors)} */}
          </div>
          <div style={{ width: "50%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מס' פלאפון
            </RespFont>
            <HomeInput
              style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
              disabled={true}
              type="text"
              defaultValue={props.data.phone}
              //   {...register("phone", {
              //     pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
              //   })}
            />
            {/* {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)} */}
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ marginTop: "20px" }}>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              תאריך התחלה
            </RespFont>
            <HomeInput
              onClick={() => setShowCalendarS(true)}
              value={dateS
                .toISOString()
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("/")}
              onChange={() => {}}
              type={"text"}
            />
          </div>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              תאריך סיום
            </RespFont>
            <HomeInput
              onClick={() => setShowCalendarE(true)}
              value={dateE
                .toISOString()
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("/")}
              onChange={() => {}}
              type={"text"}
            />
          </div>
        </FlexRowBetween>
        <FlexRowBetween
          style={{ justifyContent: "space-evenly", marginTop: "40px" }}
        >
          <div>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                // textAlign: "right",
              }}
            >
              חבילה:
            </RespFont>
            <FlexRowBetween
              style={{
                width: "min-content",
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "60px",
                padding: widthS > 768 ? "5px" : "3px",
                fontWeight: "700",
                // marginRight: "1%",
                // marginLeft: "0px",
              }}
            >
              <RespFont
                pSize={"12px"}
                cSize={"14px"}
                onClick={() => setPaymentTag(1)}
                style={{
                  padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                  cursor: "pointer",
                  width: "max-content",
                  backgroundColor:
                    paymentTag === 1
                      ? user.settings.colors.buttonsColor
                      : "inherit",
                  transition: "all 0.2s",
                  color: paymentTag === 1 ? "#fff" : constants.fontColorZax,
                  borderRadius: "60px",
                }}
              >
                בסיסי
              </RespFont>
              <RespFont
                pSize={"12px"}
                cSize={"14px"}
                onClick={() => setPaymentTag(2)}
                style={{
                  padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                  cursor: "pointer",
                  backgroundColor:
                    paymentTag === 2
                      ? user.settings.colors.buttonsColor
                      : "inherit",
                  transition: "all 0.2s",
                  color: paymentTag === 2 ? "#fff" : constants.fontColorZax,
                  borderRadius: "60px",
                }}
              >
                אישי
              </RespFont>
              <RespFont
                pSize={"12px"}
                cSize={"14px"}
                onClick={() => setPaymentTag(3)}
                style={{
                  padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                  cursor: "pointer",
                  backgroundColor:
                    paymentTag === 3
                      ? user.settings.colors.buttonsColor
                      : "inherit",
                  transition: "all 0.2s",
                  color: paymentTag === 3 ? "#fff" : constants.fontColorZax,
                  borderRadius: "60px",
                }}
              >
                vip
              </RespFont>
            </FlexRowBetween>
          </div>
          <div>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                // textAlign: "right",
              }}
            >
              מאושר?
            </RespFont>
            <FlexRowBetween
              style={{
                width: "min-content",
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "50px",
                padding: widthS > 768 ? "5px" : "3px",
                fontWeight: "700",
                // marginRight: "30px"
              }}
            >
              <RespFont
                pSize={"12px"}
                cSize={"14px"}
                onClick={() => setApproved(true)}
                style={{
                  padding: widthS > 768 ? "6px 10px" : "3px 6px",
                  cursor: "pointer",
                  backgroundColor: approved
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                  transition: "all 0.2s",
                  color: approved ? "#fff" : constants.fontColorZax,
                  borderRadius: "50px",
                  width: "max-content",
                }}
              >
                <img
                  src={approved ? viW : viB}
                  style={{ width: widthS > 768 ? "21px" : "13px" }}
                />
              </RespFont>
              <RespFont
                pSize={"12px"}
                cSize={"14px"}
                onClick={() => setApproved(false)}
                style={{
                  padding: widthS > 768 ? "6px 10px" : "3px 8px",
                  cursor: "pointer",
                  backgroundColor: !approved
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                  transition: "all 0.2s",
                  color: !approved ? "#fff" : constants.fontColorZax,
                  borderRadius: "50px",
                  width: "max-content",
                }}
              >
                <img
                  src={approved ? ixB : ixW}
                  style={{
                    width: widthS > 768 ? "14px" : "9px",
                    //   padding: "20px 15px",
                  }}
                />
              </RespFont>
            </FlexRowBetween>
          </div>
        </FlexRowBetween>
      </div>

      <GalleryOptions
        width={"21px"}
        padding={`0px 0`}
        style={{
          justifyContent: "space-between",
          background: "#fff",
          backgroundColor: user.settings.colors.buttonsColor,
        }}
      >
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip>{"שמירה"}</Tooltip>}
        >
          <button
            type="submit"
            form="form1"
            style={{
              outline: "none",
              width: "100%",
              background: "inherit",
              border: "none",
            }}
          >
            <FlexRowBetween
              style={{
                justifyContent: "space-around",
                boxShadow: `0 -1px 4px ${addAlpha(
                  user.settings.colors.buttonsColor,
                  0.44
                )}`,
                padding: "5px 0",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: user.settings.colors.buttonsColor,
                  // filter: "brightness(90%)",
                }}
              >
                שמירה
              </div>
              <div
                style={{
                  cursor: "pointer",
                  padding: "10px 0",
                  filter: "brightness(90%)",
                }}
              >
                <GenericSvg
                  fill={user.settings.colors.buttonsColor}
                  size={"20px"}
                  svg={svgs.save}
                  viewBox={"0 0 49 49"}
                />
              </div>
            </FlexRowBetween>
          </button>
        </OverlayTrigger>
        {/* <div style={{ cursor: "pointer", padding: "10px 0" }}><GenericSvg fill={user.settings.colors.buttonsColor} size={"20px"} svg={svgs.save} viewBox={"0 0 49 49"} /></div> */}
        <FlexRowBetween
          style={{
            justifyContent: "space-around",
            backgroundColor: user.settings.colors.buttonsColor,
          }}
        >
          {props.data.phone && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://wa.me/972${props.data.phone}/?text=`}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                // filter: "brightness(90%)",
              }}
            >
              <GenericSvg
                fill={"#ffffff"}
                size={"20px"}
                svg={svgs.whatsapp}
                viewBox={"0 0 24 24"}
              />
            </a>
          )}
          {/* <div style={{ cursor: "pointer", padding: "10px 0", filter: "brightness(90%)" }} onClick={deleteC}>
                        <GenericSvg fill={"#ffffff"} size={"20px"} svg={svgs.whatsapp} viewBox={"0 0 24 24"} /></div> */}
          {
            props.data.phone && (
              <a
                href={
                  "tel:" +
                  (props.data.phone[0] === "0"
                    ? props.data.phone
                    : "0" + props.data.phone + "")
                }
              >
                <GenericSvg fill={"#ffffff"} size={"20px"} svg={svgs.phone} />
              </a>
            )
            // <div style={{ cursor: "pointer", padding: "10px 0", filter: "brightness(90%)" }} onClick={deletT}>
            //     <GenericSvg fill={"#ffffff"} size={"20px"} svg={svgs.phone} /></div>
          }
          <div
            style={{
              cursor: "pointer",
              padding: "10px 0",
              // filter: "brightness(90%)",
            }}
            onClick={() => setDeleteT(true)}
          >
            <GenericSvg
              fill={"#ffffff"}
              size={"20px"}
              svg={svgs.delete}
              viewBox={"0 0 297 297"}
            />
          </div>
        </FlexRowBetween>
      </GalleryOptions>
      {showCalendarE && (
        <GenericModal
          init={setShowCalendarE}
          div={
            <GCalendar
              background={user.settings.colors.buttonsColor}
              setDate={handleSetDateE}
              date={dateE}
              init={setShowCalendarE}
            />
          }
        />
      )}
      {showCalendarS && (
        <GenericModal
          init={setShowCalendarS}
          div={
            <GCalendar
              background={user.settings.colors.buttonsColor}
              setDate={handleSetDateS}
              date={dateS}
              init={setShowCalendarS}
            />
          }
        />
      )}
      {/* {deleteT && (
        <GenericModal
          init={setDeleteT}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"מחיקת אירוע"}
              body={"האם את/ה בטוח/ה שברצונך למחוק אירוע זה?"}
              no={setDeleteT}
              yes={deletT}
            />
          }
        />
      )} */}
      {usersLoading && <OpacityLoading />}
    </PopupForm>
  );
};

export default Edit;
