import { createSlice } from "@reduxjs/toolkit";
import ServerRoutes from "../api/routes";
import { get, get2, post, post2 } from "../api/http";

const initialState = {
  users: [],
  user: null,
  usersError: null,
  usersLoading: false,
};

const _setUsers = (state, action) => {
  state.users = action.payload;
  state.usersLoading = false;
};

const _setUser = (state, action) => {
  state.user = action.payload;
  state.usersLoading = false;
};

const _addUser = (state, action) => {
  state.users = state.users.concat(action.payload);
  state.usersLoading = false;
};

const _editUser = (state, action) => {
  let editedTodos = { ...state.users };
  for (const [key, value] of Object.entries(action.payload.body))
    editedTodos[action.payload._id][key] = value;
  state.users = editedTodos;
};

const _actionStart = (state, action) => {
  state.usersLoading = true;
  state.usersError = null;
};

const _actionSuccess = (state, action) => {
  state.usersLoading = false;
  state.usersError = null;
};

const _actionFailed = (state, action) => {
  state.usersLoading = false;
  state.usersError = action.payload.error;
};

const _deleteUser = (state, action) => {
  state.users = state.users.filter((x) => x._id !== action.payload);
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: _setUsers,
    setUser: _setUser,
    addUser: _addUser,
    editUser: _editUser,
    deleteUser1: _deleteUser,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
  },
});

const { reducer, actions } = user;

const {
  setUsers,
  setUser,
  addUser,
  editUser,
  deleteUser1,
  actionStart,
  actionFailed,
  actionSuccess,
} = actions;

export default reducer;

export const getOne = (data) => {
  return async (dispatch) => {
    const result = await get(ServerRoutes.Client.getOne, data);
    dispatch(setUser(result));
  };
};

export const getAllUsers = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(
      ServerRoutes.Client.getUsers,
      {}
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else return dispatch(setUsers(data));
  };
};

export const getAvivClients = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(
      ServerRoutes.Client.getAvivClients,
      {}
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else return dispatch(setUsers(data));
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    dispatch(setUsers([]));
  };
};

export const editUserPD = (body, _id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Client.editPD + _id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else return dispatch(editUser({ _id, body }));
  };
};

export const editUserById = (body, _id, dateSWasChanged, dateEWasChanged) => {
  console.log("ggg");
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Client.editUser + _id,
      body
    );
    console.log(data);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else
      return dispatch(
        editUser({
          _id,
          body: {
            ...body,
            purchaseStart: dateSWasChanged
              ? body.purchaseStart.toISOString()
              : body.purchaseStart,
            purchaseEnd: dateEWasChanged
              ? body.purchaseEnd.toISOString()
              : body.purchaseEnd,
          },
        })
      );
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    await post(ServerRoutes.Client.delete, { id });
    dispatch(deleteUser1(id));
  };
};

export const sendWhatsapp = (id, msg) => {
  return async () => {
    await post(ServerRoutes.Client.whatsapp, { id, msg });
  };
};
