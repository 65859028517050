import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cart: {},
    amount: 1,
    internalId: 1
}

const _setServices = (state, action) => {
    state.cart = action.payload;
    state.amount = 0;
};

const _addService = (state, action) => {
    state.cart[state.internalId] = { ...action.payload, internalId: state.internalId };
    state.internalId++;
    state.amount += action.payload.amount;
};

const _deleteService = (state, action) => {
    let item = action.payload.id;
    state.amount -= state.cart[item].amount;
    const { [item]: _, ...newCart } = state.cart;
    state.cart = newCart;
};

const _editServiceAmount = (state, action) => {
    state.amount += action.payload.add;
    state.cart[action.payload.id]["amount"] += action.payload.add;
};

const _editService = (state, action) => {
    for (const [key, value] of Object.entries(action.payload.body)) {
        state.amount += value - state.cart[action.payload.id]["amount"];
        state.cart[action.payload.id]["amount"] = value;
    }
};


const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setServices: _setServices,
        addService: _addService,
        deleteService: _deleteService,
        editService: _editService,
        editServiceAmount: _editServiceAmount
    },
});

const { reducer, actions } = cart;

export const { setServices, addService, editService, deleteService, editServiceAmount } = actions;

export default reducer;

export const deleteService1 = (id) => {
    return async (dispatch) => {
        dispatch(deleteService({ id }));
    }
};

export const add = (data) => {
    return async (dispatch) => {
        dispatch(addService(data));
    }
};

export const edit = (id, body) => {
    return async (dispatch) => {
        dispatch(editService({ id, body }));
    }
};

export const editServiceAmount1 = (id, add) => {
    return async (dispatch) => {
        dispatch(editServiceAmount({ id, add }));
    }
};

export const cleanAll = () => {
    return async (dispatch) => {
        dispatch(setServices({}));
    }
};