import React, { useState, useEffect } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  RTextArea,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  GenericDiv,
  RespFont,
} from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  CFV,
  GetToast,
  parseDate,
  parseIsoDate,
} from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { addTodo } from "../../../store/reducers/todosReducer";
import GenericModal from "../../common/modals/GenericModal";
import GCalendar from "../../common/calendar/Calendar";
import { reminder } from "../../../store/reducers/dataReducer";
import { HomeInput, HomeTextArea } from "../../common/Style";

const Add = ({ init, date1 }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { todosLoading } = useSelector((state) => state.todosReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showCalendar, setShowCalendar] = useState(null);
  const [date, setDate] = useState(date1);

  useEffect(() => {
    let date = date1;
    date.setHours(20);
    setDate(date);
  }, []);

  const handleSetDate = (e) => {
    let date = e;
    date.setHours(20);
    setDate(date);
  };

  const onSubmit = async (data) => {
    let date1 = new Date();
    date1.setHours(20);
    let flag = true;
    if (parseIsoDate(date) === parseIsoDate(date1)) flag = false;
    let phone = data.phone;
    // if (phone[0] === "0") phone = phone.substring(1);
    const body = {
      title: data.title,
      hour: data.hour,
      address: data.address,
      date,
      description: data.description,
      phone,
    };
    dispatch(addTodo(body, flag)).then((res) => {
      if (!res.payload?.error) {
        if (phone.length) {
          let msg = `לקוח יקר, נקבעה פגישה עם ${
            user.fullname
          }\nבתאריך ${parseIsoDate(date)} בשעה ${
            data.hour
          }\nלבירור/ביטול יש לעדכן בקישור: https://wa.me/972${
            user.contactPhone !== "" ? user.contactPhone : user.phone
          }/?text=`;
          dispatch(reminder({ arr: [{ phone, msg }] })).then((res) => {
            if (!res.payload?.error)
              GetToast(
                addToast,
                "success",
                "האירוע נשמר ביומן ונשלחה הודעה ללקוח"
              );
            else
              GetToast(
                addToast,
                "warning",
                "האירוע נשמר ביומן אך לא נשלח ללקוח עקב שגיאה - אנא שלח ידנית ופנה לתמיכה",
                8000
              );
          });
        } else GetToast(addToast, "success", "האירוע נשמר ביומן");
        init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{
        overflow: "auto",
        maxHeight: `${0.8 * height}px`,
        zIndex: 6,
      }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "90%",
          margin: "0px auto",
        }}
      >
        <FlexRowBetween style={{ margin: "0px auto" }}>
          <div style={{ width: "55%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שם / כותרת
            </RespFont>
            <HomeInput
              type="text"
              {...register("title", {
                required: true,
                minLength: 2,
                maxLength: 20,
              })}
            />
            {CFV("title", "בין 2 ל 20 תווים", errors)}
          </div>
          <div style={{ width: "40%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              תאריך
            </RespFont>
            <HomeInput
              onClick={() => setShowCalendar(true)}
              value={parseIsoDate(date)}
              onChange={() => {}}
              type={"text"}
            />
            {CFV("null", "בין 2 ל 20 תווים", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "0px auto" }}>
          <div style={{ width: "30%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שעה
            </RespFont>
            <HomeInput
              type={"time"}
              style={{ paddingLeft: "5px" }}
              {...register("hour", {
                required: true,
              })}
            />
            {CFV("hour", "יש לבחור שעה", errors)}
          </div>
          <div style={{ width: "65%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מס' פלאפון
            </RespFont>
            <HomeInput
              type="text"
              {...register("phone", {
                pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
              })}
            />
            {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)}
          </div>
        </FlexRowBetween>
        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          כתובת
        </RespFont>
        <HomeInput
          defaultValue={"משרד"}
          type={"text"}
          {...register("address", {
            required: true,
            minLength: 2,
            maxLength: 50,
          })}
        />
        {CFV("address", "בין 2 ל 50 תווים", errors)}
        <RespFont
          style={{
            fontFamily: "hebB",
            margin: "10px auto",
            textAlign: "right",
          }}
        >
          תיאור
        </RespFont>
        <HomeTextArea
          color={constants.fontColorZax}
          type="text"
          rows="3"
          style={{ margin: "0px auto" }}
          {...register("description", {
            maxLength: 500,
          })}
        />
        {CFV("description", "עד 500 תווים", errors)}
        <GenericButton
          form="form1"
          background={user.settings.colors.buttonsColor}
          color={"#fff"}
          type="submit"
          style={{
            width: "100%",
            margin: "0px auto",
            padding: "10px",
            borderRadius: "5px",
            fontWeight: "bolder",
            fontSize: "16px",
            boxShadow: "none",
            marginTop: "20px",
          }}
        >
          הוספה
        </GenericButton>
      </div>
      {showCalendar && (
        <GenericModal
          init={setShowCalendar}
          div={
            <GCalendar
              background={user.settings.colors.buttonsColor}
              setDate={handleSetDate}
              date={date}
              init={setShowCalendar}
            />
          }
        />
      )}
      {todosLoading && <OpacityLoading />}
    </PopupForm>
  );
};

export default Add;
