import { createSlice } from "@reduxjs/toolkit";
import { post2, get2 } from "../api/http";
import ServerRoutes from "../api/routes";

const initialState = {
  waiting: {},
  waitingError: null,
  waitingLoading: false,
  getWaitingLoading: false,
};

const _addWaiting = (state, action) => {
  let editedWaiting = { ...state.waiting };
  editedWaiting[action.payload.id] = {
    ...action.payload.body,
    _id: action.payload.id,
    dateAdded: action.payload.dateAdded,
  };
  state.waiting = editedWaiting;
};

const _setWaiting = (state, action) => {
  state.waiting = action.payload;
};

const _editWaiting = (state, action) => {
  let editedWaiting = { ...state.waiting };
  console.log(action.payload.body);
  console.log(action.payload.id);
  for (const [key, value] of Object.entries(action.payload.body))
    editedWaiting[action.payload.id][key] = value;
  state.waiting = editedWaiting;
};

const _deleteWaiting = (state, action) => {
  let editedWaiting = { ...state.waiting };
  for (let i = 0; i < action.payload.length; i++)
    delete editedWaiting[action.payload[i]];
  state.waiting = editedWaiting;
};

const _actionStart = (state) => {
  state.waitingLoading = true;
  state.waitingError = null;
};

const _actionSuccess = (state) => {
  state.waitingLoading = false;
  state.waitingError = null;
};

const _actionFailed = (state, action) => {
  state.waitingLoading = false;
  state.waitingError = action.payload.error;
};

const _getWaitingStart = (state) => {
  state.getWaitingLoading = true;
  state.waitingError = null;
};

const _getWaitingSuccess = (state) => {
  state.getWaitingLoading = false;
  state.waitingError = null;
};

const _getWaitingFailed = (state, action) => {
  state.getWaitingLoading = false;
  state.waitingError = action.payload.error;
};

const _cleanWaitingState = (state) => {
  state.waiting = {};
  state.waitingError = null;
  state.waitingLoading = false;
  state.getWaitingLoading = false;
};

const waiting = createSlice({
  name: "waiting",
  initialState,
  reducers: {
    addWaiting1: _addWaiting,
    cleanWaitingState: _cleanWaitingState,
    editWaiting1: _editWaiting,
    deleteWaiting1: _deleteWaiting,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    setWaiting: _setWaiting,
    actionSuccess: _actionSuccess,
    getWaitingStart: _getWaitingStart,
    getWaitingSuccess: _getWaitingSuccess,
    getWaitingFailed: _getWaitingFailed,
  },
});

const { reducer, actions } = waiting;

const {
  actionStart,
  actionFailed,
  actionSuccess,
  addWaiting1,
  editWaiting1,
  deleteWaiting1,
  setWaiting,
  cleanWaitingState,
  getWaitingStart,
  getWaitingSuccess,
  getWaitingFailed,
} = actions;

export default reducer;

export const addWaiting = (body, flag) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(ServerRoutes.Waiting.add, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      if (flag) return dispatch(actionSuccess());
      dispatch(actionSuccess());
      return dispatch(
        addWaiting1({ id: data.id, dateAdded: data.dateAdded, body })
      );
    }
  };
};

export const editStatus = (id, body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Waiting.editStatus + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editWaiting1({ id, body }));
    }
  };
};

export const getTodayWaiting = (body) => {
  // by date
  return async (dispatch) => {
    dispatch(getWaitingStart());
    const { data, error, status } = await post2(
      ServerRoutes.Waiting.getTodayWaiting,
      body
    );
    if (status !== 200) return dispatch(getWaitingFailed({ error }));
    else {
      dispatch(getWaitingSuccess());
      return dispatch(setWaiting(data));
    }
  };
};

export const getAll = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Waiting.getAll);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setWaiting(data));
    }
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    return dispatch(cleanWaitingState());
  };
};

export const editWaiting = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Waiting.edit + id, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editWaiting1({ id, body }));
    }
  };
};

export const deleteWaiting = (waitingsToDelete) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Waiting.deleteMany, {
      waitingsToDelete,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteWaiting1(waitingsToDelete));
    }
  };
};
