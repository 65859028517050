import React, { useState } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  RTextArea,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  GenericDiv,
  GalleryOptions,
  RespFont,
} from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { editAsset } from "../../../store/reducers/assetsReducer";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  addAlpha,
  CFV,
  GetToast,
  parseDate,
  parseIsoDate,
} from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import Switcher from "../../common/switcher/Switcher";
import { deleteTodo, editTodo } from "../../../store/reducers/todosReducer";
import GenericModal from "../../common/modals/GenericModal";
import GCalendar from "../../common/calendar/Calendar";
import { GuestOptions, HomeInput, HomeTextArea } from "../../common/Style";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import { svgs } from "../../common/footer/coloredSvgs/svgs";
import whatsapp1 from "../../../assets/nadlan/whatsapp1.svg";
import YesNoModal from "../../common/modals/YesNoModal";
import { reminder } from "../../../store/reducers/dataReducer";
const { encode } = require("url-encode-decode");

const Edit = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { todosLoading } = useSelector((state) => state.todosReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [deleteT, setDeleteT] = useState(false);
  const [date, setDate] = useState(new Date(props.data.date));
  const [originalDate, setOriginalDate] = useState(new Date(props.data.date));
  const [showCalendar, setShowCalendar] = useState(null);
  const handleSetDate = (e) => {
    let date1 = e;
    date1.setHours(20);
    setDate(date1);
  };
  const onSubmit = async (data) => {
    let phone = data.phone;
    // if (phone[0] === "0") phone = phone.substring(1);
    const body = {
      title: data.title,
      hour: data.hour,
      address: data.address,
      date,
      description: data.description,
      phone,
    };
    dispatch(editTodo(body, props.data._id)).then((res) => {
      if (!res.payload?.error) {
        if (
          phone.length &&
          (parseIsoDate(originalDate) !== parseIsoDate(date) ||
            props.data.hour !== data.hour)
        ) {
          let msg = `לקוח יקר, חלו שינויים בפגישתך עם ${
            user.fullname
          }:\nתאריך ושעה לפני השינוי:\n${parseIsoDate(originalDate)}, ${
            props.data.hour
          }\nתאריך ושעה לאחר השינוי:\n${parseIsoDate(date)}, ${
            data.hour
          }\nלבירור/ביטול יש לעדכן בקישור: https://wa.me/972${
            user.contactPhone !== "" ? user.contactPhone : user.phone
          }/?text=`;
          dispatch(reminder({ arr: [{ phone, msg }] })).then((res) => {
            if (!res.payload?.error)
              GetToast(
                addToast,
                "success",
                "האירוע נשמר ביומן ונשלחה הודעה ללקוח"
              );
            else
              GetToast(
                addToast,
                "warning",
                "האירוע נשמר ביומן אך לא נשלח ללקוח עקב שגיאה - אנא שלח ידנית ופנה לתמיכה",
                8000
              );
          });
        } else GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init();
      }
    });
  };

  const deletT = () => {
    dispatch(deleteTodo(props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init();
      }
    });
  };
  let height = window.screen.availHeight;

  let msg = encode(
    `היי, בקשר לפגישה בתאריך` +
      ` ${parseDate(props.data.date)}` +
      `, בשעה` +
      ` ${props.data.hour} ,`
  );

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "90%",
          margin: "10px auto",
        }}
      >
        <FlexRowBetween style={{ margin: "10px auto" }}>
          <div style={{ width: "55%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שם / כותרת
            </RespFont>
            <HomeInput
              type="text"
              defaultValue={props.data.title}
              {...register("title", {
                required: true,
                minLength: 2,
                maxLength: 20,
              })}
            />
            {CFV("title", "בין 2 ל 20 תווים", errors)}
          </div>
          <div style={{ width: "40%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              תאריך
            </RespFont>
            <HomeInput
              onClick={() => setShowCalendar(true)}
              value={date
                .toISOString()
                .replace(/T.*/, "")
                .split("-")
                .reverse()
                .join("/")}
              onChange={() => {}}
              type={"text"}
            />
            {CFV("null", "בין 2 ל 20 תווים", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "10px auto" }}>
          <div style={{ width: "30%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שעה
            </RespFont>
            <HomeInput
              defaultValue={props.data.hour}
              style={{ paddingLeft: "5px" }}
              type={"time"}
              {...register("hour", {
                required: true,
              })}
            />
            {CFV("hour", "יש לבחור שעה", errors)}
          </div>
          <div style={{ width: "65%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מס' פלאפון
            </RespFont>
            <HomeInput
              type="text"
              defaultValue={props.data.phone}
              {...register("phone", {
                pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
              })}
            />
            {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)}
          </div>
        </FlexRowBetween>
        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          כתובת
        </RespFont>
        <HomeInput
          defaultValue={props.data.address}
          type={"text"}
          {...register("address", {
            required: true,
            minLength: 2,
            maxLength: 50,
          })}
        />
        {CFV("address", "בין 2 ל 50 תווים", errors)}
        <RespFont
          style={{
            fontFamily: "hebB",
            margin: "10px auto",
            textAlign: "right",
          }}
        >
          תיאור
        </RespFont>
        <HomeTextArea
          color={constants.fontColorZax}
          defaultValue={props.data.description}
          type="text"
          rows="3"
          style={{ margin: "0px auto" }}
          {...register("description", {
            maxLength: 500,
          })}
        />
        {CFV("description", "עד 500 תווים", errors)}
        {/* <GenericButton
        form="form1"
        background={user.settings.colors.buttonsColor}
        color={"#fff"}
        type="submit"
        style={{
          width: "100%",
          margin: "0px auto",
          padding: "10px",
          borderRadius: "5px",
          fontWeight: "bolder",
          fontSize: "16px",
          boxShadow: "none",
          marginTop: "20px",
        }}
      >
        הוספה
      </GenericButton> */}
      </div>

      <GalleryOptions
        width={"21px"}
        padding={`0px 0`}
        style={{
          justifyContent: "space-between",
          background: "#fff",
          backgroundColor: user.settings.colors.buttonsColor,
        }}
      >
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip>{"שמירה"}</Tooltip>}
        >
          <button
            type="submit"
            form="form1"
            style={{
              outline: "none",
              width: "100%",
              background: "inherit",
              border: "none",
            }}
          >
            <FlexRowBetween
              style={{
                justifyContent: "space-around",
                boxShadow: `0 -1px 4px ${addAlpha(
                  user.settings.colors.buttonsColor,
                  0.44
                )}`,
                padding: "5px 0",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: user.settings.colors.buttonsColor,
                  // filter: "brightness(90%)",
                }}
              >
                שמירה
              </div>
              <div
                style={{
                  cursor: "pointer",
                  padding: "10px 0",
                  filter: "brightness(90%)",
                }}
              >
                <GenericSvg
                  fill={user.settings.colors.buttonsColor}
                  size={"20px"}
                  svg={svgs.save}
                  viewBox={"0 0 49 49"}
                />
              </div>
            </FlexRowBetween>
          </button>
        </OverlayTrigger>
        {/* <div style={{ cursor: "pointer", padding: "10px 0" }}><GenericSvg fill={user.settings.colors.buttonsColor} size={"20px"} svg={svgs.save} viewBox={"0 0 49 49"} /></div> */}
        <FlexRowBetween
          style={{
            justifyContent: "space-around",
            backgroundColor: user.settings.colors.buttonsColor,
          }}
        >
          {props.data.phone && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://wa.me/972${props.data.phone}/?text=${msg}`}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                // filter: "brightness(90%)",
              }}
            >
              <GenericSvg
                fill={"#ffffff"}
                size={"20px"}
                svg={svgs.whatsapp}
                viewBox={"0 0 24 24"}
              />
            </a>
          )}
          {/* <div style={{ cursor: "pointer", padding: "10px 0", filter: "brightness(90%)" }} onClick={deleteC}>
                        <GenericSvg fill={"#ffffff"} size={"20px"} svg={svgs.whatsapp} viewBox={"0 0 24 24"} /></div> */}
          {
            props.data.phone && (
              <a
                href={
                  "tel:" +
                  (props.data.phone[0] === "0"
                    ? props.data.phone
                    : "0" + props.data.phone + "")
                }
              >
                <GenericSvg fill={"#ffffff"} size={"20px"} svg={svgs.phone} />
              </a>
            )
            // <div style={{ cursor: "pointer", padding: "10px 0", filter: "brightness(90%)" }} onClick={deletT}>
            //     <GenericSvg fill={"#ffffff"} size={"20px"} svg={svgs.phone} /></div>
          }
          <div
            style={{
              cursor: "pointer",
              padding: "10px 0",
              // filter: "brightness(90%)",
            }}
            onClick={() => setDeleteT(true)}
          >
            <GenericSvg
              fill={"#ffffff"}
              size={"20px"}
              svg={svgs.delete}
              viewBox={"0 0 297 297"}
            />
          </div>
        </FlexRowBetween>
      </GalleryOptions>
      {/* <GenericButton form="form1" background={"#fff"} color={constants.fontColor} type="submit"
                style={{
                    width: "60%", margin: "20px auto", padding: "10px", borderRadius: "5px", fontWeight: "bolder"
                    , fontSize: "16px", boxShadow: "none", marginTop: "0px"
                }}>שמירה</GenericButton> */}
      {showCalendar && (
        <GenericModal
          init={setShowCalendar}
          div={
            <GCalendar
              background={user.settings.colors.buttonsColor}
              setDate={handleSetDate}
              date={date}
              init={setShowCalendar}
            />
          }
        />
      )}
      {deleteT && (
        <GenericModal
          init={setDeleteT}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"מחיקת אירוע"}
              body={"האם את/ה בטוח/ה שברצונך למחוק אירוע זה?"}
              no={setDeleteT}
              yes={deletT}
            />
          }
        />
      )}
      {todosLoading && <OpacityLoading />}
    </PopupForm>
  );
};

export default Edit;
