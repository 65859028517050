import React, { useEffect } from "react";
import { CenteredForm, FlexRowBetween, MainDiv, RespFont } from "../Style";
import queryString from "query-string";
import { failureSvg, successSvg } from "../footer/coloredSvgs/svgs";

const FailurePage = (props) => {
  let widthS = window.innerWidth; // 1728

  let params = queryString.parse(props.location.search);
  const { paymentTag, commitment, uid, paymentCode } = params;
  return (
    <MainDiv
      style={{
        justifyContent: "center",
        background: `radial-gradient(circle, #ffffff 0%, #f2f2f2 100%)`,
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          fontFamily: "logo",
          fontWeight: "700",
          position: "absolute",
          top: "50px",
        }}
      >
        התשלום נכשל
      </RespFont>
      {failureSvg(
        widthS > 768 ? 432.88 : 216.44,
        widthS > 768 ? 341.445 : 170.7225,
        "rgba(222, 133, 86, 1)"
      )}
    </MainDiv>
  );
};

export default FailurePage;
