import React, { useState } from "react";
import {
  PopupForm,
  GenericButton,
  FlexRowBetween,
  RespFont,
} from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { addGuest } from "../../../store/reducers/guestsReducer";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { HomeInput, HomeTextArea } from "../../common/Style";

const Add = ({ init }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { guestsLoading } = useSelector((state) => state.guestsReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const isNumber = (value) => (parseInt(value) > 0 ? parseInt(value) : 0);
  const isNumber = (value) => (parseInt(value) > 0 ? parseInt(value) : null);
  const isGeneral = (value) => (value.length ? value : "כללי");
  const onSubmit = async (data) => {
    let phone = data.phone;
    // if (phone[0] === "0") phone = phone.substring(1);
    const body = {
      // minSquareMeters: isNumber(data.minSquareMeters),
      // minRooms: isNumber(data.minRooms),
      // minPrice: isNumber(data.minPrice),
      preferredNeighborhood: data.preferredNeighborhood,
      name: data.name,
      preferredCity: data.preferredCity,
      phone,
      notes: data.notes,
      closeness: data.closeness,
      minSquareMeters:
        isNumber(data.minSquareMeters) === null
          ? 1
          : isNumber(data.minSquareMeters),
      minRooms: isNumber(data.minRooms) === null ? 1 : isNumber(data.minRooms),
      minPrice: isNumber(data.minPrice) === null ? 1 : isNumber(data.minPrice),
      maxSquareMeters:
        isNumber(data.maxSquareMeters) === null
          ? 500000
          : isNumber(data.maxSquareMeters),
      maxRooms:
        isNumber(data.maxRooms) === null ? 5000 : isNumber(data.maxRooms),
      maxPrice:
        isNumber(data.maxPrice) === null ? 100000000 : isNumber(data.maxPrice),
    };
    dispatch(addGuest(body)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ maxHeight: `${0.8 * height}px`, overflow: "auto" }}
    >
      <div
        style={{
          // overflow: "auto",
          padding: "25px 0",
          width: "90%",
          margin: "10px auto",
        }}
      >
        <FlexRowBetween style={{ margin: "0px auto" }}>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שם מלא
            </RespFont>
            <HomeInput
              type="text"
              {...register("name", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("name", "בין 2 ל 50 תווים", errors)}
          </div>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מס' פלאפון
            </RespFont>
            <HomeInput
              type="text"
              {...register("phone", {
                required: true,
                pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
              })}
            />
            {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "0px auto" }}>
          <div style={{ width: "100%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              קטגוריה
            </RespFont>
            <HomeInput
              type="text"
              defaultValue={"כללי"}
              {...register("closeness", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("closeness", "בין 2 ל 50 תווים", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "0px auto" }}>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              עיר מועדפת
            </RespFont>
            <HomeInput
              pHcolor={"color: rgba(56, 52, 61, 0.4)"}
              type="text"
              placeholder="לא חובה"
              {...register("preferredCity", {
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("preferredCity", "בין 2 ל 50 תווים", errors)}
          </div>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שכ׳ מועדפת
            </RespFont>
            <HomeInput
              pHcolor={"color: rgba(56, 52, 61, 0.4)"}
              type="text"
              placeholder="לא חובה"
              {...register("preferredNeighborhood", {
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("preferredNeighborhood", "בין 2 ל 50 תווים", errors)}
          </div>
        </FlexRowBetween>
        <RespFont
          pSize={"16px"}
          cSize={"18px"}
          style={{
            fontFamily: "hebB",
            width: "30%",
            margin: "0 auto",
            marginTop: "5px",
            borderBottom: `2px solid ${user.settings.colors.buttonsColor}`,
          }}
        >
          רף תחתון
        </RespFont>
        <FlexRowBetween style={{ margin: "10px auto" }}>
          <div style={{ width: "27%", marginRight: "0px" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מ״ר
            </RespFont>
            <HomeInput
              type="number"
              {...register("minSquareMeters", {
                // required: true,
                minLength: 1,
                maxLength: 50000,
              })}
            />
            {CFV("minSquareMeters", "מספר 1 ל 50000", errors)}
          </div>
          <div style={{ width: "20%", marginRight: "0px" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              חדרים
            </RespFont>
            <HomeInput
              type="number"
              {...register("minRooms", {
                // required: true,
                minLength: 1,
                maxLength: 30,
              })}
            />
            {CFV("minRooms", "מספר 1 ל 30", errors)}
          </div>
          <div style={{ width: "45%", marginRight: "0px" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מחיר (₪)
            </RespFont>
            <HomeInput
              type="number"
              {...register("minPrice", {
                // required: true,
                min: 1,
                max: 100000000,
              })}
            />
            {CFV("minPrice", "מינימום 1", errors)}
          </div>
        </FlexRowBetween>
        <RespFont
          pSize={"16px"}
          cSize={"18px"}
          style={{
            fontFamily: "hebB",
            width: "30%",
            margin: "0 auto",
            marginTop: "5px",
            borderBottom: `2px solid ${user.settings.colors.buttonsColor}`,
          }}
        >
          רף עליון
        </RespFont>
        <FlexRowBetween style={{ margin: "10px auto" }}>
          <div style={{ width: "27%", marginRight: "0px" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מ״ר
            </RespFont>
            <HomeInput
              type="number"
              {...register("maxSquareMeters", {
                // required: true,
                min: 1,
                max: 50000,
              })}
            />
            {CFV("maxSquareMeters", "מספר 1 ל 50000", errors)}
          </div>
          <div style={{ width: "20%", marginRight: "0px" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              חדרים
            </RespFont>
            <HomeInput
              type="number"
              {...register("maxRooms", {
                // required: true,
                min: 1,
                max: 30,
              })}
            />
            {CFV("maxRooms", "מספר 1 ל 30", errors)}
          </div>
          <div style={{ width: "45%", marginRight: "0px" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מחיר (₪)
            </RespFont>
            <HomeInput
              type="number"
              {...register("maxPrice", {
                // required: true,
                min: 1,
                max: 100000000,
              })}
            />
            {CFV("maxPrice", "מינימום 1", errors)}
          </div>
        </FlexRowBetween>

        <RespFont
          style={{ fontFamily: "hebB", marginBottom: "5px", marginTop: "15px" }}
        >
          הערות
        </RespFont>
        <HomeTextArea
          type="text"
          rows="3"
          style={{ margin: "0px auto" }}
          {...register("notes", {
            minLength: 0,
            maxLength: 500,
          })}
        />
        {CFV("notes", "עד 500 תווים", errors)}

        <GenericButton
          form="form1"
          background={user.settings.colors.buttonsColor}
          color={"#fff"}
          type="submit"
          style={{
            width: "100%",
            margin: "0px auto",
            padding: "10px",
            borderRadius: "5px",
            fontWeight: "bolder",
            fontSize: "16px",
            boxShadow: "none",
            marginTop: "25px",
          }}
        >
          הוספה
        </GenericButton>
      </div>
      {/* <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{limit ? "כולל מגבלות" : "ללא מגבלות"}</Tooltip>}>
            <div style={{
                position: "absolute", right: "15px", top: "15px"
                // , transform: "rotate(90deg)" 
            }}>
                <Switcher checked={limit} onChange={setLimit} color={user.controls} />
            </div>
        </OverlayTrigger> */}
      {guestsLoading && <OpacityLoading />}
    </PopupForm>
  );
};

export default Add;
