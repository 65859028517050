import React, { useState } from "react";
import { FlexRowBetween, GenericDiv, RespFont, RTextArea } from "../Style";
import { PopupForm } from "../../Style";
import { addAlpha, GetToast } from "./Toast";
import { reminder } from "../../../store/reducers/dataReducer";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import GenericModal from "./GenericModal";
import YesNoModal from "./YesNoModal";
import OpacityLoading from "../loader/OpacityLoading";

const SendMessage = ({ color, init, phones }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { dataLoading } = useSelector((state) => state.dataReducer);
  const { user } = useSelector((state) => state.authReducer);

  const [send, setSend] = useState(false);
  const [content, setContent] = useState("");
  const sendMessages = () => {
    let arr = [];
    let phones1 = phones.split(",");
    for (let i = 0; i < phones1.length; i++)
      // if (user.smsBlackList[phones1[i]] === undefined)
      arr.push({
        phone: phones1[i],
        msg: content + `\nבברכה, ${user.settings.contacts.nameAndSubject}`,
      });
    dispatch(reminder({ arr })).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        init();
      }
    });
  };
  let height = window.screen.availHeight;
  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      cWidth={"70%"}
      pWidth={"90%"}
      color={`${addAlpha(color, 0.1)}`}
      style={{
        padding: "10px 20px",
        borderRadius: "5px",
        maxHeight: `${height * 0.8}px`,
        zIndex: "0",
        margin: "40px auto",
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          letterSpacing: "-0.5px",
          color: "#3A3C3F",
          fontWeight: "700",
          padding: `30px 0`,
          paddingBottom: `10px`,
          fontFamily: "openSansMedium",
        }}
      >
        {"שליחה הודעה"}
      </RespFont>
      <RTextArea
        onChange={(e) => setContent(e.target.value)}
        color={color}
        placeholder="תוכן ההודעה"
        type="text"
        rows="5"
        style={{ width: "80%", margin: "20px auto" }}
      />
      <FlexRowBetween
        style={{
          color: "white",
          fontWeight: "bold",
          width: "80%",
          margin: "20px auto",
        }}
      >
        <GenericDiv
          style={{ backgroundColor: color, padding: "7px 20px" }}
          onClick={() => setSend(true)}
        >
          שליחה
        </GenericDiv>
        <GenericDiv
          style={{ backgroundColor: color, padding: "7px 20px" }}
          onClick={() => init()}
        >
          ביטול
        </GenericDiv>
      </FlexRowBetween>
      {send && (
        <GenericModal
          init={setSend}
          div={
            <YesNoModal
              color={color}
              title={"אישור שליחה"}
              body={"האם את/ה בטוח/ה?"}
              no={setSend}
              yes={sendMessages}
            />
          }
        />
      )}
      {dataLoading && <OpacityLoading />}
    </PopupForm>
  );
};
export default SendMessage;
