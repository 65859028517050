import React, { useState } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  RTextArea,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  RespFont,
} from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { addArticle } from "../../../store/reducers/articleReducer";
import { HomeInput } from "../../common/Style";

const Add = ({ init }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { articleLoading } = useSelector((state) => state.articleReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const body = { title: data.title, link: data.link, pic: data.pic };
    dispatch(addArticle(body)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "80%",
          margin: "10px auto",
        }}
      >
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            כותרת
          </RespFont>
          <HomeInput
            name="title"
            type={"text"}
            {...register("title", {
              required: true,
              minLength: 2,
              maxLength: 200,
            })}
          />
          {CFV("title", "בין 2 ל 200 תווים", errors)}
        </div>
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            קישור
          </RespFont>
          <HomeInput
            name="link"
            type={"text"}
            {...register("link", {
              required: true,
              minLength: 2,
              maxLength: 500,
            })}
          />
          {CFV("link", "בין 2 ל 50 תווים", errors)}
        </div>
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            כתובת תמונה
          </RespFont>
          <HomeInput
            name="pic"
            type={"text"}
            {...register("pic", {
              required: true,
              minLength: 2,
              maxLength: 500,
            })}
          />
          {CFV("pic", "בין 2 ל 500 תווים", errors)}
        </div>
        <GenericButton
          form="form1"
          background={user.settings.TitlePagesColor.page6}
          color={"#fff"}
          type="submit"
          style={{
            width: "100%",
            margin: "0px auto",
            padding: "10px",
            borderRadius: "5px",
            fontWeight: "bolder",
            fontSize: "16px",
            boxShadow: "none",
            marginTop: "25px",
          }}
        >
          הוספה
        </GenericButton>
      </div>
      {articleLoading && <OpacityLoading />}
    </PopupForm>
  );
};

export default Add;
