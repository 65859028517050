import { createSlice } from '@reduxjs/toolkit';
import { post2, get2 } from '../api/http';
import ServerRoutes from '../api/routes';

const initialState = {
    qaa: {},
    qaaError: null,
    qaaLoading: false,
    getQaaLoading: false,
    qaaChange: false,
}

const _addQaa = (state, action) => {
    let editedQaa = { ...state.qaa }
    editedQaa[action.payload.id] = { ...action.payload.body, done: false, _id: action.payload.id, dateAdded: action.payload.dateAdded }
    state.qaa = editedQaa;
};

const _setQaa = (state, action) => {
    state.qaa = action.payload;
};

const _editQaa = (state, action) => {
    let editedQaa = { ...state.qaa }
    for (const [key, value] of Object.entries(action.payload.body))
        editedQaa[action.payload.id][key] = value;
    state.qaa = editedQaa;
    state.qaaChange = !state.qaaChange;
};

const _deleteQaa = (state, action) => {
    let editedQaa = { ...state.qaa }
    delete editedQaa[action.payload]
    state.qaa = editedQaa;
};

const _actionStart = (state,) => {
    state.qaaLoading = true;
    state.qaaError = null;
};

const _actionSuccess = (state) => {
    state.qaaLoading = false;
    state.qaaError = null;
};

const _actionFailed = (state, action) => {
    state.qaaLoading = false;
    state.qaaError = action.payload.error;
};

const _getQaaStart = (state,) => {
    state.getQaaLoading = true;
    state.qaaError = null;
};

const _getQaaSuccess = (state) => {
    state.getQaaLoading = false;
    state.qaaError = null;
};

const _getQaaFailed = (state, action) => {
    state.getQaaLoading = false;
    state.qaaError = action.payload.error;
};

const _cleanQaaState = (state) => {
    state.qaa = {};
    state.qaaError = null;
    state.qaaLoading = false
    state.getQaaLoading = false
};

const qaa = createSlice({
    name: 'qaa',
    initialState,
    reducers: {
        addQaa1: _addQaa,
        cleanQaaState: _cleanQaaState,
        editQaa1: _editQaa,
        deleteQaa1: _deleteQaa,
        actionStart: _actionStart,
        actionFailed: _actionFailed,
        setQaa: _setQaa,
        actionSuccess: _actionSuccess,
        getQaaStart: _getQaaStart,
        getQaaSuccess: _getQaaSuccess,
        getQaaFailed: _getQaaFailed,
    },
});

const { reducer, actions } = qaa;

const { actionStart, actionFailed, actionSuccess, addQaa1, editQaa1, deleteQaa1, setQaa, cleanQaaState
    , getQaaStart, getQaaSuccess, getQaaFailed } = actions;

export default reducer;

export const addQaa = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await post2(ServerRoutes.Qaa.add, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(addQaa1({ id: data.id, dateAdded: data.dateAdded, body }));
        }
    }
};

export const getAll = () => {
    return async (dispatch) => {
        dispatch(getQaaStart())
        const { data, error, status } = await get2(ServerRoutes.Qaa.getAll);
        if (status !== 200)
            return dispatch(getQaaFailed({ error }));
        else {
            dispatch(getQaaSuccess())
            return dispatch(setQaa(data));
        }
    }
};

export const setQaaReduxOnly = (data) => {
    return async (dispatch) => {
        return dispatch(setQaa(data))
    }
}

export const cleanAll = () => {
    return async (dispatch) => {
        return dispatch(cleanQaaState());
    }
};


export const editQaa = (body, id) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Qaa.edit + id, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editQaa1({ id, body }));
        }
    }
};

export const deleteQaa = (id) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Qaa.delete, { id });
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(deleteQaa1(id));
        }
    }
};