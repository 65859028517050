import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { constants, GenericButton, Section } from "../../Style";
import GenericSvg from "../footer/coloredSvgs/GenericSvg";
import { zaxSvgs } from "../footer/coloredSvgs/svgs";
import { FlexRowBetween, HomeInput, RespFont } from "../Style";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../modals/Toast";
import { addWaiting } from "../../../store/reducers/waitingReducer";

const WaitingListForm = (props) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.authReducer);
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const isNumber = (value) => (parseInt(value) > 0 ? parseInt(value) : null);
  const onSubmit = async (data) => {
    if (
      data.minSquareMeters > data.maxSquareMeters &&
      data.maxSquareMeters !== "" &&
      data.minSquareMeters !== ""
    )
      GetToast(
        addToast,
        "warning",
        "לא אפשרי שרף תחתון יהיה גבוה מרף עליון בשדה מ״ר",
        8000
      );
    else if (
      data.minRooms > data.maxRooms &&
      data.minRooms !== "" &&
      data.maxRooms !== ""
    )
      GetToast(
        addToast,
        "warning",
        "לא אפשרי שרף תחתון יהיה גבוה מרף עליון בשדה מספר חדרים",
        8000
      );
    else if (
      data.minPrice > data.maxPrice &&
      data.minPrice !== "" &&
      data.maxPrice !== ""
    )
      GetToast(
        addToast,
        "warning",
        "לא אפשרי שרף תחתון יהיה גבוה מרף עליון בשדה מחיר",
        8000
      );
    else {
      let phone = data.phone;
      // if (phone[0] === "0") phone = phone.substring(1);
      const body = {
        name: data.name,
        agent: user.fullname,
        paymentTag: user.paymentTag,
        minSquareMeters:
          isNumber(data.minSquareMeters) === null
            ? 1
            : isNumber(data.minSquareMeters),
        minRooms:
          isNumber(data.minRooms) === null ? 1 : isNumber(data.minRooms),
        minPrice:
          isNumber(data.minPrice) === null ? 1 : isNumber(data.minPrice),
        maxSquareMeters:
          isNumber(data.maxSquareMeters) === null
            ? 500000
            : isNumber(data.maxSquareMeters),
        maxRooms:
          isNumber(data.maxRooms) === null ? 5000 : isNumber(data.maxRooms),
        maxPrice:
          isNumber(data.maxPrice) === null
            ? 100000000
            : isNumber(data.maxPrice),
        phone,
        uid: user._id,
        preferredCity: data.preferredCity,
      };
      dispatch(addWaiting(body)).then((res) => {
        if (!res.payload?.error) {
          reset({
            name: "",
            minSquareMeters: "",
            minRooms: "",
            minPrice: "",
            maxSquareMeters: "",
            maxRooms: "",
            maxPrice: "",
            preferredCity: "",
            phone: "",
          });
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה", 8000);
        } else GetToast(addToast, "error", res.payload.error);
      });
    }
  };
  let widthS = window.innerWidth; // 1728

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        backgroundColor: "#fff",
        borderRadius: "10px",
        width: "85%",
        maxWidth: "400px",
        marginRight: widthS > 768 ? "7.5%" : "0",
      }}
    >
      <Section
        style={{ width: "70%", margin: "10px auto", marginTop: "20px" }}
        cSize={"28px"}
        pSize={"21px"}
      >
        {user.settings.waitingList.part1}
      </Section>

      <div style={{ width: "80%", margin: "10px auto" }}>
        <RespFont style={{ textAlign: "center" }}>
          {user.settings.waitingList.part2}
        </RespFont>
        <HomeInput
          color={constants.fontColorZax}
          pHcolor={"color: rgba(56, 52, 61, 0.4)"}
          placeholder="שם מלא"
          style={{
            marginTop: "20px",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "5px",
          }}
          {...register("name", {
            required: true,
            minLength: 2,
            maxLength: 50,
          })}
        />
        {CFV("name", "בין 2 ל 50 תווים", errors)}
        <HomeInput
          color={constants.fontColorZax}
          pHcolor={"color: rgba(56, 52, 61, 0.4)"}
          placeholder="מס׳ פלאפון"
          style={{
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "5px",
          }}
          {...register("phone", {
            required: true,
            pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
          })}
        />
        {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)}
        <HomeInput
          color={constants.fontColorZax}
          pHcolor={"color: rgba(56, 52, 61, 0.4)"}
          placeholder="עיר מועדפת - לא חובה"
          style={{
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "5px",
          }}
          {...register("preferredCity", {
            minLength: 2,
            maxLength: 50,
          })}
        />
        {CFV("preferredCity", "בין 2 ל 50 תווים", errors)}

        <FlexRowBetween
          style={{
            width: "105%",
            marginRight: "-5%",
            alignItems: "baseline",
          }}
        >
          <FlexRowBetween
            style={{
              width: "min-content",
              justifyContent: "flex-start",
              marginRight: "0px",
            }}
          >
            <div
              style={{
                width: "min-content",
                marginLeft: "7px",
              }}
            >
              <GenericSvg
                fill={user.settings.pagesColors.page7}
                size={"15px"}
                svg={zaxSvgs.expand}
                viewBox={"0 0 14 14"}
              />
            </div>
            <RespFont pSize={"12px"} style={{ fontFamily: "hebM" }}>
              מ״ר
            </RespFont>
          </FlexRowBetween>
          <div style={{ marginRight: "10px" }}>
            <HomeInput
              smaller={true}
              color={constants.fontColorZax}
              pHfontFamily={"hebM"}
              pHcolor={"rgba(56, 52, 61, 0.4)"}
              type="number"
              placeholder="מינימום"
              style={{
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                textAlign: "center",
                fontSize: "14px",
                // marginRight: "10px",
                width: widthS > 768 ? "120px" : "90px",
              }}
              {...register("minSquareMeters", {
                // required: true,
                min: 1,
                max: 500000,
              })}
            />
            {CFV("minSquareMeters", "מספר בין 1 ל 500000", errors)}
          </div>
          <div style={{ marginRight: "10px" }}>
            <HomeInput
              smaller={true}
              pHfontFamily={"hebM"}
              pHcolor={"rgba(56, 52, 61, 0.4)"}
              type="number"
              placeholder="מקסימום"
              style={{
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                textAlign: "center",
                fontSize: "14px",
                // marginRight: "10px",
                width: widthS > 768 ? "120px" : "90px",
              }}
              {...register("maxSquareMeters", {
                // required: true,
                min: 1,
                max: 500000,
              })}
            />
            {CFV("maxSquareMeters", "מספר בין 1 ל 500000", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween
          style={{ width: "105%", marginRight: "-5%", alignItems: "baseline" }}
        >
          <FlexRowBetween
            style={{
              width: "min-content",
              justifyContent: "flex-start",
              marginRight: "0px",
            }}
          >
            <div
              style={{
                width: "min-content",
                marginLeft: "7px",
              }}
            >
              <GenericSvg
                fill={user.settings.pagesColors.page7}
                size={"15px"}
                svg={zaxSvgs.bed}
                viewBox={"0 0 14 14"}
              />
            </div>
            <RespFont pSize={"12px"} style={{ fontFamily: "hebM" }}>
              חדרים
            </RespFont>
          </FlexRowBetween>
          <div style={{ marginRight: "10px" }}>
            <HomeInput
              smaller={true}
              color={constants.fontColorZax}
              pHfontFamily={"hebM"}
              pHcolor={"rgba(56, 52, 61, 0.4)"}
              type="number"
              placeholder="מינימום"
              style={{
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                textAlign: "center",
                fontSize: "14px",
                // marginRight: "10px",
                width: widthS > 768 ? "120px" : "90px",
              }}
              {...register("minRooms", {
                // required: true,
                min: 1,
                max: 5000,
              })}
            />
            {CFV("minRooms", "מספר בין 1 ל 5000", errors)}
          </div>
          <div style={{ marginRight: "10px" }}>
            <HomeInput
              smaller={true}
              color={constants.fontColorZax}
              pHfontFamily={"hebM"}
              pHcolor={"rgba(56, 52, 61, 0.4)"}
              type="number"
              placeholder="מקסימום"
              style={{
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                textAlign: "center",
                fontSize: "14px",
                width: widthS > 768 ? "120px" : "90px",
              }}
              {...register("maxRooms", {
                // required: true,
                min: 1,
                max: 5000,
              })}
            />
            {CFV("maxRooms", "מספר בין 1 ל 5000", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween
          style={{ width: "105%", marginRight: "-5%", alignItems: "baseline" }}
        >
          <FlexRowBetween
            style={{
              width: "min-content",
              justifyContent: "flex-start",
              marginRight: "0px",
            }}
          >
            <div
              style={{
                width: "min-content",
                marginLeft: "7px",
              }}
            >
              <GenericSvg
                fill={user.settings.pagesColors.page7}
                size={"15px"}
                svg={zaxSvgs.shekel}
                viewBox={"0 0 14 12"}
              />
            </div>
            <RespFont pSize={"12px"} style={{ fontFamily: "hebM" }}>
              מחיר
            </RespFont>
          </FlexRowBetween>
          <div style={{ marginRight: "10px" }}>
            <HomeInput
              smaller={true}
              color={constants.fontColorZax}
              pHfontFamily={"hebM"}
              pHcolor={"rgba(56, 52, 61, 0.4)"}
              type="number"
              placeholder="מינימום"
              style={{
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                textAlign: "center",
                fontSize: "14px",
                // marginRight: "10px",
                width: widthS > 768 ? "120px" : "90px",
              }}
              {...register("minPrice", {
                // required: true,
                min: 1,
                max: 100000000,
              })}
            />
            {CFV("minPrice", "מספר בין 1 ל 100000000", errors)}
          </div>
          <div style={{ marginRight: "10px" }}>
            <HomeInput
              smaller={true}
              color={constants.fontColorZax}
              pHfontFamily={"hebM"}
              pHcolor={"rgba(56, 52, 61, 0.4)"}
              type="number"
              placeholder="מקסימום"
              style={{
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                textAlign: "center",
                fontSize: "14px",
                // marginRight: "10px",
                width: widthS > 768 ? "120px" : "90px",
              }}
              {...register("maxPrice", {
                // required: true,
                min: 1,
                max: 100000000,
              })}
            />
            {CFV("maxPrice", "מספר בין 1 ל 100000000", errors)}
          </div>
        </FlexRowBetween>
        <GenericButton
          background={user.settings.pagesColors.page7}
          color={"white"}
          style={{
            width: "100%",
            marginTop: "20px",
            fontWeight: "700",
            fontFamily: "monsterB",
            padding: "10px",
            marginBottom: "25px",
          }}
          type="submit"
        >
          <RespFont>השארת פרטים</RespFont>
        </GenericButton>
      </div>
    </form>
  );
};

export default WaitingListForm;
