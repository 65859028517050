import React, { useState } from "react";
import {
  constants,
  FlexRowBetween,
  GenericDiv,
  GenericLink,
  RespDiv,
  RespFont,
  Section,
} from "../../Style";
import { Link } from "react-router-dom";

import favorite from "../../../assets/nadlan/favorite.svg";
import favoriteRed from "../../../assets/nadlan/favoriteRed.svg";
import { Responsive } from "../../common/Style";
import { useDispatch, useSelector } from "react-redux";
import { addAlpha, commafy } from "../modals/Toast";
import GenericSvg from "../footer/coloredSvgs/GenericSvg";
import { svgs, zaxSvgs } from "../footer/coloredSvgs/svgs";
import ClientRoutes from "../../../navigation/Routes";

const GenericAsset = ({ src, data, marC, setEditAsset }) => {
  const { user, authenticated } = useSelector((state) => state.authReducer);

  const [isFavorited, setIsFavorited] = useState(
    localStorage.getItem(`${user?.addressName}:${data?._id}`) === "true"
  );

  const toggleFavorite = () => {
    const newValue = !isFavorited;
    setIsFavorited(newValue);
    localStorage.setItem(`${user?.addressName}:${data?._id}`, newValue);
  };
  return (
    <Responsive
      width={true}
      margin={true}
      marginBottom={true}
      marBottomP={"5px"}
      widC={"20%"}
      widP={"90%"}
      marP={"0 auto"}
      marC={marC}
      style={{
        background: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.11)",
        borderRadius: "10px",
        height: "464px",
        minWidth: "300px",
        position: "relative",
        color: constants.fontColorZax,
        marginTop: "25px",
        position: "relative",
      }}
    >
      <img
        src={src}
        style={{
          height: "250px",
          width: "100%",
          borderRadius: "15px",
          padding: "5px",
        }}
      />
      <div
        style={{
          backgroundColor: addAlpha(user.settings.TitlePagesColor.page2, 0.1),
          width: "min-content",
          borderRadius: "3px",
          marginRight: "5%",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            color: user.settings.TitlePagesColor.page2,
            filter: "brightness(90%)",
            padding: "3px 9px",
            fontSize: "14px",
          }}
        >
          {data.saleOrRentOrSold === 1
            ? "למכירה"
            : data.saleOrRentOrSold === 2
            ? "להשכרה"
            : "נסגרו"}
        </div>
      </div>

      <Section
        pSize={"17px"}
        cSize={"20px"}
        style={{
          marginBottom: "0px",
          marginRight: "5%",
          textAlign: "right",
          marginTop: "10px",
        }}
      >
        {data.name}
      </Section>
      <FlexRowBetween
        style={{
          width: "90%",
          margin: "0 auto",
          justifyContent: "flex-start",
          color: "rgba(56, 52, 61, 0.7)",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            width: "min-content",
            filter: "brightness(80%)",
            marginLeft: "7px",
          }}
        >
          <GenericSvg
            fill={user.settings.TitlePagesColor.page2}
            size={"15px"}
            svg={zaxSvgs.location}
            viewBox={"0 0 16 19"}
          />
        </div>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {data.address},
        </div>
        <div style={{ marginRight: "5px", width: "max-content" }}>
          {data.city}
        </div>
      </FlexRowBetween>
      <FlexRowBetween
        style={{ width: "90%", margin: "0 auto", marginTop: "10px" }}
      >
        <FlexRowBetween
          style={{
            width: "min-content",
            justifyContent: "flex-start",
            marginRight: "0px",
          }}
        >
          <div
            style={{
              width: "min-content",
              filter: "brightness(80%)",
              marginLeft: "7px",
            }}
          >
            <GenericSvg
              fill={user.settings.TitlePagesColor.page2}
              size={"15px"}
              svg={zaxSvgs.bed}
              viewBox={"0 0 14 14"}
            />
          </div>
          <RespFont
            style={{
              fontWeight: "700",
              width: "max-content",
            }}
          >
            {data.rooms}
          </RespFont>
          <RespFont
            style={{
              fontWeight: "700",
              width: "max-content",
              marginRight: "3px",
            }}
          >
            חדרים
          </RespFont>
        </FlexRowBetween>
        <FlexRowBetween
          style={{
            width: "min-content",
            justifyContent: "flex-start",
            // marginRight: "0px",
          }}
        >
          <div
            style={{
              width: "min-content",
              filter: "brightness(80%)",
              marginLeft: "7px",
            }}
          >
            <GenericSvg
              fill={user.settings.TitlePagesColor.page2}
              size={"15px"}
              svg={zaxSvgs.bathroom}
              viewBox={"0 0 14 14"}
            />
          </div>
          {/* <img src={bathroom} style={{ width: "15px", marginLeft: "7px" }} /> */}
          <RespFont
            style={{
              fontWeight: "700",
              width: "max-content",
            }}
          >
            {data.bathroom}
          </RespFont>
          <RespFont
            style={{
              fontWeight: "700",
              width: "max-content",
              marginRight: "3px",
            }}
          >
            חדרי שירותים
          </RespFont>
        </FlexRowBetween>
        <FlexRowBetween
          style={{
            width: "min-content",
            justifyContent: "flex-start",
            marginLeft: "0px",
          }}
        >
          <div
            style={{
              width: "min-content",
              filter: "brightness(80%)",
              marginLeft: "7px",
            }}
          >
            <GenericSvg
              fill={user.settings.TitlePagesColor.page2}
              size={"15px"}
              svg={zaxSvgs.expand}
              viewBox={"0 0 14 14"}
            />
          </div>
          <RespFont
            style={{
              fontWeight: "700",
              width: "max-content",
            }}
          >
            {data.squareMeters}
          </RespFont>
          <RespFont
            style={{
              fontWeight: "700",
              width: "max-content",
              marginRight: "3px",
            }}
          >
            מ״ר
          </RespFont>
        </FlexRowBetween>
      </FlexRowBetween>
      <FlexRowBetween
        style={{ width: "90%", margin: "0 auto", marginTop: "20px" }}
      >
        <FlexRowBetween
          style={{
            width: "min-content",
            justifyContent: "flex-start",
            marginRight: "0px",
          }}
        >
          <GenericLink
            // href={`localhost:3000/asset?a=${window.btoa(data._id)}`}
            target={"_blank"}
            rel="noopener noreferrer"
            href={`https://nadlan.agency/asset?a=${data._id}`}
            style={{
              borderRadius: "2px",
              color: user.settings.colors.buttonsFontColor,
              background: user.settings.TitlePagesColor.page2,
              padding: "12px 20px",
              fontWeight: "700",
            }}
          >
            לפרטים
          </GenericLink>
          {authenticated && user && user.role === 1 && (
            <div
              onClick={() => setEditAsset(data)}
              style={{
                width: "min-content",
                cursor: "pointer",
                padding: "9px",
                borderRadius: "2px",
                marginRight: "7px",
                filter: "brightness(80%)",
                border: `2px solid ${user.settings.TitlePagesColor.page2}`,
              }}
            >
              <GenericSvg
                fill={user.settings.TitlePagesColor.page2}
                size={"22px"}
                svg={svgs.edit}
                viewBox={"0 0 55.25 55.25"}
              />
            </div>
          )}
          {!authenticated && (
            <div
              onClick={toggleFavorite}
              style={{
                width: "min-content",
                border: "0.830357px solid rgba(0, 0, 0, 0.11)",
                //   backdropFilter: "blur(12.4554px)",
                borderRadius: "50px",
                padding: "7px",
                fontWeight: "700",
                marginRight: "20px",
                cursor: "pointer",
              }}
            >
              <img
                src={isFavorited ? favoriteRed : favorite}
                style={{ width: "20px" }}
              />
            </div>
          )}
        </FlexRowBetween>
        <RespFont
          cSize={"18px"}
          pSize={"16px"}
          style={{
            fontFamily: "hebM",
            fontWeight: "700",
            color: user.settings.TitlePagesColor.page2,
          }}
        >
          {data.salePrice ? (
            <div>
              <div
                style={{
                  textDecoration: "line-through",
                  textDecorationColor: "#EB5757",
                  color: "rgba(56, 52, 61, 0.4)",
                }}
              >
                {commafy(data.price) + "₪"}
              </div>
              <div>{commafy(data.salePrice) + "₪"}</div>
            </div>
          ) : (
            <div>{commafy(data.price) + "₪"}</div>
          )}
        </RespFont>
      </FlexRowBetween>
      {/* <a
        target="_blank"
        rel="noreferrer"
        href={link}
        style={{
          textDecoration: "none",
          color: "#38343D",
        }}
      >
        <RespFont
          pSize={"13px"}
          cSize={"15px"}
          style={{
            // width: "90%",
            // textAlign: "left",
            // margin: "10px auto",
            position: "absolute",
            left: "20px",
            bottom: "20px",
            direction: "rtl",
          }}
        >
          לכתבה המלאה
          <img src={leftArrow} style={{ width: "10px", marginRight: "5px" }} />
        </RespFont>
      </a> */}
    </Responsive>
  );
};

export default GenericAsset;
