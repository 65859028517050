import React from "react";
import GenericAccordion from "../../common/others/GenericAccordion";
import { Section } from "../../Style";

const QandARoy = () => {
  const list = [
    {
      title: "האם אתר האינטרנט הוא רק שלי?",
      content:
        'כן, האתר הוא לחלוטין שלך, כל התוכן הוא שלך וכלל העיצוב הוא ע"פ הגדרתך. בנוסף, חשוב להבין שכלל האופי של העסק, ההתנהלות היומיומית וניהול העסק יהיו בהתאם להתנהלותך עם הכלים האוטומטים והטכנולוגיה האינסופית שמונגשת לתחום זה וליזמים.',
    },
    {
      title: "האם אקבל את כל הכלים הטכנולוגיים שמציעה המערכת?",
      content:
        "כמובן, כלל הכלים הטכנולוגים מונגשים בקלות ובנוחות לך, כמנהל האתר שלך. כמובן שיש שוני בין החבילות השונות.",
    },
    {
      title: "האם אוכל להעלות תמונות וסרטונים של הנכסים שבבעלותי?",
      content:
        "כמובן, תוכל לעלות את כלל התמונות/הסרטונים שיש בידך על מנת להנגיש את זה בצורה מקצועית ורצינית ללקחותיו וכך לסוכנים אחרים אשר אתה משתף איתם פעולה.",
    },
    {
      title: "האם יש אפשרות לקדם את האתר דרך רשת הפרסום?",
      content:
        "כמובן, נושא זה מוסבר בנפרד בדף החבילות. זוהי רשת פרסום ייחודית לפלטפורמה שלנו עם אוטומציות וAI שמתאים את פרסומיך בהתאם. עם זאת, המידע הרב שנמצא בידינו מאפשר לנו לפרסם את נכסיך במקומות הנכונים ובצורה מדויקת ומכוונות ולא לייצר ספאם אצל הלקוח/משתפי הפעולה.",
    },
  ];
  return (
    <div
      id={"quesAndAns-id"}
      style={{ backgroundColor: "#fff", width: "100%", padding: "50px 0" }}
    >
      <Section pSize={"36px"} cSize={"48px"}>
        שאלות נפוצות
      </Section>
      <div style={{ width: "80%", margin: "40px auto" }}>
        <GenericAccordion list={list} selectedColor={"rgb(222, 133, 86)"} />
      </div>
    </div>
  );
};

export default QandARoy;
