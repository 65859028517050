const ServerRoutes = {
  Client: {
    getUsers: "users/getAll",
    editUser: "users/edit/",
    reminder: "clients/reminder/",
  },
  Payments: {
    getAll: "payments/getAll/",
    createlink: "payments/createlink/",
    setRecord: "payments/setRecord/",
  },
  Auth: {
    editPic: "user/editPic",
    editPicPosition: "user/editPicPosition",
    editArray: "user/editArray",
    editMySelf: "user/editMySelf",
    getUpdatedTables: "user/getUpdatedTables",
    getUpdatedSms: "user/getUpdatedSms",
    logoutAll: "auth/logoutAll",
    logoutSpecific: "auth/logoutSpecific",
    login: "auth/login",
    checkAuth: "auth/checkAuth",
    register: "auth/register",
    resetPassword: "auth/resetPassword",
    requestCode: "auth/requestCode",
  },
  Photos: {
    upload: "photos/upload/",
    uploadAssetPhoto: "photos/uploadAssetPhoto/",
    uploadDocPhoto: "photos/uploadDocPhoto/",
    uploadDocPhotoPdf: "photos/uploadDocPhotoPdf/",
    uploadSignedDocPdf: "photos/uploadSignedDocPdf/",
    uploadReferencePhoto: "photos/uploadReferencePhoto/",
    uploadAssetPhotos: "photos/uploadAssetPhotos/",
    delete: "photos/delete/",
    removeBg: "photos/removeBg/",
  },
  Qaa: {
    delete: "qaa/delete",
    add: "qaa/add",
    getAll: "qaa/getAll",
    edit: "qaa/edit/",
  },
  Article: {
    delete: "article/delete",
    add: "article/add",
    getAll: "article/getAll",
    edit: "article/edit/",
  },
  References: {
    delete: "references/delete",
    add: "references/add",
    getAll: "references/getAll",
    edit: "references/edit/",
  },
  Assets: {
    delete: "assets/delete",
    add: "assets/add",
    getAll: "assets/getAll",
    edit: "assets/edit/",
    getAsset: "assets/getAsset",
  },
  Guests: {
    delete: "guests/delete",
    add: "guests/add",
    getAll: "guests/getAll",
    edit: "guests/edit/",
    postCsv: "guests/postCsv",
  },
  Details: {
    getById: "details/getById/",
  },
  Todos: {
    delete: "todos/delete",
    add: "todos/add",
    getAll: "todos/getAll",
    getTodayTodos: "todos/getTodayTodos",
    edit: "todos/edit/",
    editStatus: "todos/editStatus/",
  },
  Waiting: {
    deleteMany: "waitingList/deleteMany",
    delete: "waitingList/delete",
    add: "waitingList/add",
    getAll: "waitingList/getAll",
    getTodayTodos: "waitingList/getTodayTodos",
    edit: "waitingList/edit/",
    editStatus: "waitingList/editStatus/",
  },
  Potentials: {
    add: "potentials/add",
    addE4U: "potentials/addE4U",
    getAll: "potentials/getAll",
    editPotentialDetails: "potentials/editPotentialDetails/",
    editStatus: "potentials/editStatus/",
    delete: "potentials/delete",
  },
  Leads: {
    add: "leads/add",
    getAll: "leads/getAll",
    deleteMany: "leads/deleteMany",
    editLeadDetails: "leads/editDetails/",
    editStatus: "leads/editStatus/",
    delete: "leads/delete",
  },
  Docs: {
    add: "docs/add",
    getDoc: "docs/getDoc",
    getAll: "docs/getAll",
    deleteMany: "docs/deleteMany",
    editDocDetails: "docs/editDetails/",
    editStatus: "docs/editStatus/",
    editPic: "docs/editPic/",
    delete: "docs/delete",
  },
  Expenses: {
    delete: "expenses/delete",
    add: "expenses/add",
    getAll: "expenses/getAll",
    edit: "expenses/edit/",
    editCalculate: "todos/editCalculate/",
  },
  Tables: {
    getAll: "guests/getGuestsTables",
    setTables: "guests/getGuestsTables",
  },
  Messages: {
    getMessages: "messages/getMessages",
  },
};

export default ServerRoutes;
