import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MainAdmin from "../components/admin/MainAdmin";

import ClientRoutes from "./Routes";

const AdminSwitch = () => {
  return (
    <Switch>
      <Route
        path={ClientRoutes.Admin.main}
        component={(props) => <MainAdmin {...props} />}
      />
      {/* <Route path={ClientRoutes.Admin.potentials} component={props => <Potentials {...props} />} /> */}
      <Redirect from="/" to={ClientRoutes.Admin.main} />
    </Switch>
  );
};

export default AdminSwitch;
