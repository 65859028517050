import React, { useMemo, useState } from "react";
import { FlexRowBetween, Section } from "../../Style";
import { useSelector, useDispatch } from "react-redux";
import GenericArticle from "../../unAuth/articles/GenericArticle";
import Add from "../../user/article/Add";
import EditS from "../../user/article/EditS";
import GenericCarousle from "../../common/carousel/GenericCarousle";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import { svgs } from "../../common/footer/coloredSvgs/svgs";
import GenericModal from "../../common/modals/GenericModal";

const ArticlesC = (props) => {
  const dispatch = useDispatch();
  const [editArticle, setEditArticle] = useState(false);

  let widthS = window.innerWidth; // 1728
  let height = window.innerHeight;
  const [addArticle, setAddArticle] = useState(false);

  const { user, authenticated } = useSelector((state) => state.authReducer);
  const { article, articleError, articleLoading, articleChange } = useSelector(
    (state) => state.simpleArticleReducer
  );
  let articleArray = useMemo(() => {
    let arr = [];
    let arrP = [];

    let sortedArray = [];
    if (Object.keys(article).length > 0) {
      for (const [key, value] of Object.entries(article))
        sortedArray.push(value);

      let temp = [];
      for (let i = 0; i < sortedArray.length; i++) {
        arrP.push(
          <GenericArticle
            setEditArticle={setEditArticle}
            data={sortedArray[i]}
          />
        );
        temp.push(
          <GenericArticle
            setEditArticle={setEditArticle}
            data={sortedArray[i]}
          />
        );
        if ((i + 1) % 3 === 0) {
          arr.push(
            <FlexRowBetween style={{ justifyContent: "center" }}>
              {[...temp]}
            </FlexRowBetween>
          );
          temp = [];
        }
      }
      arr.push(
        <FlexRowBetween style={{ justifyContent: "center" }}>
          {[...temp]}
        </FlexRowBetween>
      );
    }
    return [arr, arrP];
  }, [articleChange, article]);

  return (
    <div
      style={{
        backgroundColor: user.settings.pagesColors.page6,
        width: "100%",
        position: "relative",
        minHeight: "500px",
      }}
      id={"articleA-id"}
    >
      {authenticated && user && user.role === 1 && (
        <FlexRowBetween
          onClick={() => setAddArticle(true)}
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "50px",
            padding: widthS > 768 ? "5px" : "3px",
            position: "absolute",
            fontWeight: "700",
            // marginRight: "30px",
            cursor: "pointer",
            top: "10px",
            right: "20px",
          }}
        >
          <div
            style={{
              padding: widthS > 768 ? "6px 6px" : "1.5px 3px",
              backgroundColor: user.settings.colors.buttonsColor,
              transition: "all 0.2s",
              borderRadius: "50px",
              width: "max-content",
            }}
          >
            <GenericSvg
              fill={"#fff"}
              size={widthS > 768 ? "23px" : "17px"}
              svg={svgs.plus}
              viewBox={"0 0 16 16"}
            />
          </div>
        </FlexRowBetween>
      )}
      <Section
        cSize={"38px"}
        pSize={"34px"}
        style={{
          color: user.settings.TitlePagesColor.page6,
          paddingTop: "40px",
        }}
      >
        {user.settings.PagesTitle.page6}
      </Section>
      {widthS > 768 ? (
        <div
          style={{
            height: `${height - 165}px`,
            overflow: "auto",
            padding: "40px 0",
            width: "100%",
          }}
        >
          {articleArray[0]}
        </div>
      ) : articleArray[1].length > 0 ? (
        <GenericCarousle data={articleArray[1]} />
      ) : null}
      {addArticle && (
        <GenericModal init={setAddArticle} div={<Add init={setAddArticle} />} />
      )}
      {editArticle && (
        <GenericModal
          init={setEditArticle}
          div={<EditS data={editArticle} init={setEditArticle} />}
        />
      )}
    </div>
  );
};

export default ArticlesC;
