import React, { useEffect, useState, useMemo } from "react";
import {
  getTodayTodos,
  cleanAll,
  editStatus,
  deleteTodo,
} from "../../../store/reducers/todosReducer";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  Questions,
  AssetsHolder,
  GenericDiv,
  FlexRowBetween,
  PageTitle,
  constants,
  Section,
  RespFont,
  ClientCard,
  FlexColumnBetween,
} from "../../Style";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import GenericModal from "../../common/modals/GenericModal";
import { addAlpha, GetToast, parseDate } from "../../common/modals/Toast";
import Switch from "react-switch";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import calendar from "../../../assets/nadlan/calendar.svg";
import GCalendar from "../../common/calendar/Calendar";
import Add from "./Add";
import Edit from "./Edit";
import GCal from "./GCal";
import OpacityLoading from "../../common/loader/OpacityLoading";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import { svgs, todosSvg } from "../../common/footer/coloredSvgs/svgs";
import { reminder } from "../../../store/reducers/dataReducer";
import YesNoModal from "../../common/modals/YesNoModal";
const { encode } = require("url-encode-decode");

const Todos = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [date, setDate] = useState(null);
  const [editTodo, setEditTodo] = useState(false);
  const [calOpen, setCalOpen] = useState(true);
  const [addTodo, setAddTodo] = useState(false);
  const [sendReminder, setSendReminder] = useState(false);

  const getTodosByDate = (date) => {
    dispatch(getTodayTodos({ date }));
  };

  const handleSetDate = (e) => {
    let date1 = new Date(e);
    date1.setHours(20);
    setDate(date1);
    getTodosByDate(date1);
  };

  const handleSetNoGetDate = (e) => {
    let date1 = new Date(e);
    date1.setHours(20);
    setDate(date1);
  };

  const handleChooseDate = () => {
    let date1 = date.setHours(20);
    getTodosByDate(date1);
    setShowCalendar(false);
  };
  const { todos, todosError, getTodosLoading } = useSelector(
    (state) => state.todosReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const [showCalendar, setShowCalendar] = useState(false);

  let height = window.innerHeight;
  useEffect(() => {
    if (todosError) GetToast(addToast, "error", todosError);
  }, [todosError]);

  useEffect(() => {
    let date = new Date();
    date.setHours(20);
    date.setMinutes(0);
    date.setMilliseconds(0);
    date.setSeconds(0);
    setDate(date);
    getTodosByDate(date);
  }, []);

  const setDone = async (id, done) => {
    dispatch(editStatus(id, { done })).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
      }
    });
  };
  let widthS = window.innerWidth; // 1728

  const ddd = () => {
    let arr = [];
    if (Object.keys(todos).length > 0) {
      for (const [key, value] of Object.entries(todos)) {
        let msg = `להזכירך נקבעה פגישה עם ${user.fullname}\nבתאריך ${parseDate(
          value.date
        )} בשעה ${value.hour}\nלבירור/ביטול יש לעדכן בקישור: https://wa.me/972${
          user.contactPhone !== "" ? user.contactPhone : user.phone
        }/?text=`;
        if (value.phone !== "") arr.push({ phone: value.phone, msg });
      }
      dispatch(reminder({ arr })).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
          //props.init()
        }
      });
    }
  };

  let todosArray = useMemo(() => {
    let fff = [];
    if (Object.keys(todos).length > 0)
      for (const [key, value] of Object.entries(todos))
        fff.push(
          <ClientCard
            key={key}
            onClick={() => setEditTodo(value)}
            style={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.11)",
            }}
          >
            <FlexRowBetween style={{ alignItems: "flex-start" }}>
              <FlexColumnBetween>
                <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                  <div
                    style={{
                      fontFamily: "hebM",
                      whiteSpace: "nowrap",
                      width: "min-content",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "7px 7px 7px 10px",
                      backgroundColor: addAlpha(
                        user.settings.colors.buttonsColor,
                        0.15
                      ),
                      marginTop: "-10px",
                      marginRight: "-10px",
                      borderRadius: "0 3px 0 5px",
                    }}
                  >
                    <RespFont pSize={"12px"} cSize={"14px"}>
                      {value.hour}
                    </RespFont>
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "5px" }}
                >
                  <div
                    style={{
                      fontFamily: "hebM",
                      whiteSpace: "nowrap",
                      width: "min-content",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value.title}
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "3px" }}
                >
                  <div
                    style={{
                      // fontFamily: "hebM",
                      whiteSpace: "nowrap",
                      width: "min-content",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value.address}
                  </div>
                </FlexRowBetween>
              </FlexColumnBetween>
              <FlexColumnBetween
                style={{
                  width: "min-content",
                  height: "100%",
                  //   alignItems:"",
                  marginLeft: "0px",
                  padding: "10px",
                  backgroundColor: addAlpha(
                    user.settings.colors.buttonsColor,
                    0.065
                  ),
                }}
              >
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={<Tooltip>{"סטטוס"}</Tooltip>}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{ marginBottom: "-5px" }}
                  >
                    <Switch
                      width={30}
                      height={14}
                      onColor={"#b1b1b1"}
                      offColor={"#b1b1b1"}
                      onHandleColor={user.settings.colors.buttonsColor}
                      boxShadow={"0 2px 4px silver, 0 -1px 4px silver"}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      checked={value.done}
                      onChange={() => setDone(value._id, !value.done)}
                      handleDiameter={18}
                    />
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={<Tooltip>{"הוספה ליומן"}</Tooltip>}
                >
                  {/* <div onClick={(e) => e.stopPropagation()}>
                    <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=My Event&details=Event description text&dates=20220305T103000/20220305T184500&location=New York City">
                      Add to Google Calendar
                    </a>
                  </div> */}
                  {/* <AddToCalendarButton
                    name="Test-Event"
                    startDate="2023-05-22"
                    options={["Apple", "Google", "Yahoo", "iCal"]}
                  ></AddToCalendarButton> */}
                  <div
                    style={{
                      marginTop: "15px",
                      filter: "brightness(80%)",
                      width: "min-content",
                      zIndex: "0",
                    }}
                  >
                    <GCal
                      mission={value.title}
                      date={value.date}
                      hour={value.hour}
                      div={
                        <GenericSvg
                          fill={user.settings.colors.buttonsColor}
                          size={"25px"}
                          svg={svgs.calendar}
                          viewBox={"0 0 64 64"}
                        />
                      }
                    />
                  </div>
                </OverlayTrigger>
              </FlexColumnBetween>
            </FlexRowBetween>
          </ClientCard>
        );
    return fff;
  }, [todos]);

  const onInitHandle = () => {
    handleChooseDate();
    setEditTodo(false);
  };

  return (
    <div
      id="todos-id"
      style={{
        width: "100%",
        margin: "0 auto",
        position: "relative",
        backgroundColor: "#fff",
      }}
    >
      <FlexRowBetween
        style={{
          justifyContent: "center",
          // marginBottom: "0px",
          marginTop: "40px",
        }}
      >
        <Section
          cSize={"38px"}
          pSize={"34px"}
          style={{
            color: constants.fontColor,
            // marginTop: "40px",
            // marginBottom: "30px",
            marginRight: "20px",
            marginLeft: "20px",
          }}
        >
          יומן
        </Section>
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "60px",
            padding: "5px",
            fontWeight: "700",
            marginRight: "0px",
            marginLeft: "0px",
            marginBottom: "20px",
          }}
        >
          <RespFont
            onClick={() => setCalOpen(true)}
            style={{
              padding: "7px 12px",
              cursor: "pointer",
              backgroundColor:
                calOpen === true
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: calOpen === true ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            פתוח
          </RespFont>
          <RespFont
            onClick={() => setCalOpen(false)}
            style={{
              padding: "7px 12px",
              cursor: "pointer",
              backgroundColor:
                calOpen === false
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: calOpen === false ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            סגור
          </RespFont>
        </FlexRowBetween>
      </FlexRowBetween>
      {calOpen && (
        <FlexRowBetween style={{ justifyContent: "center" }}>
          <GCalendar
            showButton={false}
            button={"בחירה"}
            background={user.settings.colors.buttonsColor}
            setDate={handleSetDate}
            date={date}
            init={handleChooseDate}
          />
        </FlexRowBetween>
      )}
      <FlexRowBetween style={{ width: "90%", margin: " 20px auto" }}>
        <FlexRowBetween
          style={{
            justifyContent: "flex-end",
            width: "min-content",
            margin: "0px",
          }}
        >
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"הוספה"}</Tooltip>}
          >
            <div
              onClick={() => setAddTodo(true)}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                filter: "brightness(90%)",
                // marginRight: "10px",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"28px"}
                svg={svgs.plus}
                viewBox={"0 0 16 16"}
              />
            </div>
          </OverlayTrigger>

          {/* <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"תזכורת לכולם"}</Tooltip>}
          >
            <div
              onClick={() => setSendReminder(true)}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                filter: "brightness(90%)",
                marginRight: "10px",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"28px"}
                svg={svgs.reminder}
                viewBox={"0 0 48 48"}
              />
            </div>
          </OverlayTrigger> */}
        </FlexRowBetween>
        {!calOpen && (
          <GenericDiv
            background={user.settings.colors.buttonsColor}
            style={{ width: "max-content", padding: "10px 15px" }}
            onClick={() => setShowCalendar(true)}
          >
            <RespFont>
              {date
                ? date
                    .toISOString()
                    .replace(/T.*/, "")
                    .split("-")
                    .reverse()
                    .join("/")
                : "בחירת תאריך"}
            </RespFont>
          </GenericDiv>
        )}
      </FlexRowBetween>
      <AssetsHolder
        style={{
          height: `${height - 250}px`,
          marginTop: "10px",
          paddingBottom: "55px",
        }}
      >
        {todosArray.length ? (
          todosArray
        ) : (
          <FlexColumnBetween style={{ justifyContent: "space-evenly" }}>
            <RespFont style={{ fontFamily: "hebB" }}>
              אין לוז לתאריך זה, התחילו להוסיף...
            </RespFont>
            {todosSvg(
              widthS > 768 ? 432.88 : 216.44,
              widthS > 768 ? 341.445 : 170.7225,
              user.settings.colors.buttonsColor
            )}
          </FlexColumnBetween>
        )}
      </AssetsHolder>
      {/* {
                ans && <GenericModal
                    init={setTodo}
                    div={<TitleAndContentWithFooter title={ans.ques} content={ans.ans} id={ans._id} init={setTodo} color={user.settings.colors.buttonsColor} onEdit={onEditHandle} />}
                />
            } */}
      {addTodo && (
        <GenericModal
          init={setAddTodo}
          div={<Add init={setAddTodo} date1={date} />}
        />
      )}
      {editTodo && (
        <GenericModal
          init={setEditTodo}
          div={<Edit data={editTodo} init={onInitHandle} />}
        />
      )}
      {sendReminder && (
        <GenericModal
          init={setSendReminder}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"שליחת תזכורות"}
              body={
                "האם את/ה בטוח/ה שברצונך לשלוח תזכורת לכלל הפגישות לתאריך נבחר זה?"
              }
              no={setSendReminder}
              yes={ddd}
            />
          }
        />
      )}
      {showCalendar && (
        <GenericModal
          init={setShowCalendar}
          div={
            <GCalendar
              button={"בחירה"}
              background={user.settings.colors.buttonsColor}
              setDate={handleSetNoGetDate}
              date={date}
              init={handleChooseDate}
            />
          }
        />
      )}
      {getTodosLoading && <OpacityLoading />}
    </div>
  );
};
export default Todos;
