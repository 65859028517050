import React from 'react';
import { CenteredForm, RespFont } from '../Style';
import { useSelector, useDispatch } from 'react-redux';
import ExitModal from './ExitModal';
import { FooterOptions, PopupForm } from '../../Style';
import { svgs } from '../footer/coloredSvgs/svgs';
import GenericSvg from '../footer/coloredSvgs/GenericSvg';
import { addAlpha, getImageUrl, GetToast } from './Toast';
import { deleteQaa } from '../../../store/reducers/qaaReducer';
import { deleteArticle } from '../../../store/reducers/articleReducer';
import OpacityLoading from '../loader/OpacityLoading';
import { useToasts } from 'react-toast-notifications';

const TitleAndContentWithFooter = ({ title, content, init, onEdit, color, isArticle = false, id = null, pWidth = "80%", cWidth = "30%", footer = true }) => {
    const { addToast } = useToasts();
    const { user } = useSelector(state => state.authReducer);
    const { qaaLoading } = useSelector(state => state.qaaReducer);
    const { articleLoading } = useSelector(state => state.articleReducer);
    const dispatch = useDispatch()

    const onDelete = () => {
        if (isArticle)
            dispatch(deleteArticle(id)).then((res) => {
                if (!res.payload?.error) {
                    GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
                    init(null)
                }
            })
        else dispatch(deleteQaa(id)).then((res) => {
            if (!res.payload?.error) {
                GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
                init(null)
            }
        })
    }
    let height = window.screen.availHeight;
    let arr = content.split('\n');
    arr.push('\n');
    let items = []
    const regex = /uuu |iii |ccc |b1 |b2 |a1 |a2 |a3 /ig;

    for (let i = 0; i < arr.length; i++) {
        let style = {}
        let textAlign = "right"
        if (arr[i].includes('***p')) {
            if (arr[i + 2].includes('ccc'))
                textAlign = "center"
            items.push(<div style={{ width: "100%", textAlign }}><img src={`${getImageUrl()}${user._id}/${id}/${arr[i].substring(3)}`} alt={arr[i]}
                style={{ maxWidth: `${parseInt(arr[++i])}%`, margin: "10px auto" }} /></div>)
            continue;
        }
        if (arr[i].includes('b1')) style = { ...style, fontWeight: "bold" }
        if (arr[i].includes('b2')) style = { ...style, fontFamily: "openSansMedium" }
        if (arr[i].includes('iii')) style = { ...style, fontStyle: "italic" }
        if (arr[i].includes('uuu')) style = { ...style, textDecoration: "underline" }
        if (arr[i].includes('ccc')) style = { ...style, textAlign: "center" }
        if (arr[i].includes('a3')) style = { ...style, fontSize: "20px" }
        else if (arr[i].includes('a2')) style = { ...style, fontSize: "18px" }
        else if (arr[i].includes('a1')) style = { ...style, fontSize: "16px" }
        items.push(<div key={i} style={{ ...style }}>{arr[i].replaceAll(regex, "")}</div>)
    }


    return (<PopupForm pWidth={"90%"} cWidth={"70%"} color={`${addAlpha(color, 0.1)}`} onClick={(e) => e.stopPropagation()} style={{ borderRadius: "5px", maxHeight: `${height * 0.8}px` }}>
        <ExitModal init={init} color={user.controls} />
        <RespFont pSize={"22px"} cSize={"22px"} style={{ textAlign: "center", letterSpacing: "-0.5px", color: "#3A3C3F", fontWeight: "700", padding: `30px 0`, paddingBottom: `10px`, fontFamily: "openSansMedium" }}>{title}</RespFont>
        <RespFont pSize={"14px"} cSize={"14px"} style={{
            fontFamily: "openSansRegular", paddingBottom: "30px", textAlign: isArticle ? "right" : "center",
            width: "90%", margin: "10px auto"
        }}>
            {items}
            {/* {content.split('\n').map((item, index) => {
                const regex = /uuu |iii |ccc |b1 |b2 |a1 |a2 |a3 /ig;
                let style = {}
                if (item.includes('***p'))
                    return <img src={`${getImageUrl()}${user._id}/${id}/${item.substring(3)}`} alt={item} style={{ maxWidth: "100%", margin: "10px auto" }} />;
                if (item.includes('b1')) style = { ...style, fontWeight: "bold" }
                if (item.includes('b2')) style = { ...style, fontFamily: "openSansMedium" }
                if (item.includes('iii')) style = { ...style, fontStyle: "italic" }
                if (item.includes('uuu')) style = { ...style, textDecoration: "underline" }
                if (item.includes('ccc')) style = { ...style, textAlign: "center" }
                if (item.includes('a3')) style = { ...style, fontSize: "20px" }
                else if (item.includes('a2')) style = { ...style, fontSize: "18px" }
                else if (item.includes('a1')) style = { ...style, fontSize: "16px" }
                return <div key={index} style={{ ...style }}>{item.replaceAll(regex, "")}</div>
            })} */}
        </RespFont>
        {footer && <FooterOptions padding={'10px 0'} style={{ backgroundColor: `${addAlpha(color, 0.1)}`, marginTop: "30px", justifyContent: "space-around" }}>
            <div onClick={onDelete}>
                <GenericSvg fill={color} size={"42px"} svg={svgs.delete} viewBox={"0 0 297 297"} />
            </div>
            <div onClick={onEdit}>
                <GenericSvg fill={color} size={"42px"} svg={svgs.edit} viewBox={"0 0 55.25 55.25"} />
            </div>
        </FooterOptions>}
        {qaaLoading && <OpacityLoading />}
        {articleLoading && <OpacityLoading />}
    </PopupForm>)
}
export default TitleAndContentWithFooter;