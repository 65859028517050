import { createSlice } from '@reduxjs/toolkit';
import { post2, get2 } from '../api/http';
import ServerRoutes from '../api/routes';

const initialState = {
    guests: {},
    guestsError: null,
    guestsLoading: false,
    getGuestsLoading: false,
    guestsChange: false,
}

const _addGuest = (state, action) => {
    let editedGuests = { ...state.guests }
    editedGuests[action.payload.id] = { ...action.payload.body, done: false, _id: action.payload.id, dateAdded: action.payload.dateAdded, photos: [] }
    state.guests = editedGuests;
};

const _setGuests = (state, action) => {
    state.guests = action.payload;
};

const _editGuest = (state, action) => {
    let editedGuests = { ...state.guests }
    for (const [key, value] of Object.entries(action.payload.body))
        editedGuests[action.payload.id][key] = value;
    state.guests = editedGuests;
    state.guestsChange = !state.guestsChange;
};

const _deleteGuest = (state, action) => {
    let editedGuests = { ...state.guests }
    for (let i = 0; i < action.payload.length; i++)
        delete editedGuests[action.payload[i]]
    state.guests = editedGuests;
};

const _actionStart = (state,) => {
    state.guestsLoading = true;
    state.guestsError = null;
};

const _actionSuccess = (state) => {
    state.guestsLoading = false;
    state.guestsError = null;
};

const _actionFailed = (state, action) => {
    state.guestsLoading = false;
    state.guestsError = action.payload.error;
};

const _getGuestsStart = (state,) => {
    state.getGuestsLoading = true;
    state.guestsError = null;
};

const _getGuestsSuccess = (state) => {
    state.getGuestsLoading = false;
    state.guestsError = null;
};

const _getGuestsFailed = (state, action) => {
    state.getGuestsLoading = false;
    state.guestsError = action.payload.error;
};

const _cleanGuestsState = (state) => {
    state.guests = {};
    state.guestsError = null;
    state.guestsLoading = false
    state.getGuestsLoading = false
};

const guests = createSlice({
    name: 'guests',
    initialState,
    reducers: {
        addGuest1: _addGuest,
        cleanGuestsState: _cleanGuestsState,
        editGuest1: _editGuest,
        deleteGuest1: _deleteGuest,
        actionStart: _actionStart,
        actionFailed: _actionFailed,
        setGuests: _setGuests,
        actionSuccess: _actionSuccess,
        getGuestsStart: _getGuestsStart,
        getGuestsSuccess: _getGuestsSuccess,
        getGuestsFailed: _getGuestsFailed,
    },
});

const { reducer, actions } = guests;

const { actionStart, actionFailed, actionSuccess, addGuest1, editGuest1, deleteGuest1, setGuests, cleanGuestsState
    , getGuestsStart, getGuestsSuccess, getGuestsFailed } = actions;

export default reducer;

export const addGuest = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await post2(ServerRoutes.Guests.add, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(addGuest1({ id: data.id, dateAdded: data.dateAdded, body }));
        }
    }
};

export const getAll = () => {
    return async (dispatch) => {
        dispatch(getGuestsStart())
        const { data, error, status } = await get2(ServerRoutes.Guests.getAll);
        if (status !== 200)
            return dispatch(getGuestsFailed({ error }));
        else {
            dispatch(getGuestsSuccess())
            return dispatch(setGuests(data));
        }
    }
};

export const setGuestsReduxOnly = (data) => {
    return async (dispatch) => {
        return dispatch(setGuests(data))
    }
}

export const cleanAll = () => {
    return async (dispatch) => {
        return dispatch(cleanGuestsState());
    }
};


export const editGuest = (body, id) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Guests.edit + id, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editGuest1({ id, body }));
        }
    }
};

export const editStatus = (id, body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Guests.editStatus + id, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editGuest1({ id, body }));
        }
    }
};

export const deleteGuest = (guestsToDelete) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Guests.delete, { guestsToDelete });
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(deleteGuest1(guestsToDelete));
        }
    }
};

// export const deleteGuest = (id) => {
//     return async (dispatch) => {
//         dispatch(actionStart())
//         const { error, status } = await post2(ServerRoutes.Guests.delete, { id });
//         if (status !== 200)
//             return dispatch(actionFailed({ error }));
//         else {
//             dispatch(actionSuccess())
//             return dispatch(deleteGuest1(id));
//         }
//     }
// };