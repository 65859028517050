import React from "react";
import { useSelector } from "react-redux";
import {
  DarkenBackgroundImage,
  DarkenBackgroundImagePhone,
  MainDiv,
  RespDiv,
  RespFont,
  Section,
} from "../../Style";
import { getImageUrl, handleClickScrollXY } from "../modals/Toast";
import CommonEdit from "../others/CommonEdit";
import { RespImgH, FlexRowColumnOpposite } from "../Style";

const FaceP = ({ setEditFace, editFace }) => {
  const { user, authenticated } = useSelector((state) => state.authReducer);
  let userPic =
    user.profilePic !== ""
      ? `${getImageUrl()}${user._id}/${user.profilePic}`
      : `${getImageUrl()}sys/defaultAgentPic.png`;
  let userBackgroundPic =
    user.backgroundPic !== ""
      ? `${getImageUrl()}${user._id}/${user.backgroundPic}`
      : `${getImageUrl()}sys/defaultBackgroundAgentPicPhone.png`;
  return (
    <MainDiv
      id="faceAgent-id"
      className="animate__animated animate__fadeIn"
      style={{
        position: "relative",
        backgroundImage: `url(${userBackgroundPic})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "min-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "-105px",
        WebkitMaskImage:
          "linear-gradient(to top,rgba(0, 0, 0, 0.7),white,white, white, white, white)",
      }}
    >
      <DarkenBackgroundImagePhone />
      <img
        src={userPic}
        style={{
          width: "90%",
          marginTop: "70px",
          zIndex: 1,
        }}
      />
      <RespDiv
        cSize={"40%"}
        pSize={"100%"}
        style={{
          direction: "rtl",
          textAlign: "center",
          position: "relative",
          color: user.settings.colors.mainContent,
          // background: `linear-gradient(to top,
          //     rgba(0, 0, 0, 0.4),
          //     rgba(0, 0, 0, 0.5),
          //     rgba(0, 0, 0, 0.6),
          //     rgba(0, 0, 0, 0.7),
          //     rgba(0, 0, 0, 0.7),
          //     rgba(0, 0, 0, 0.8),
          //     rgba(0, 0, 0, 0.9)
          //   )`,
          zIndex: 2,
          padding: "10px 10px",
        }}
      >
        {authenticated && (
          <CommonEdit
            bottom={"15px"}
            right={"15px"}
            left={null}
            shadow={
              "0 2px 2px rgba(255, 255, 255, 0.5), 0 -2px 2px rgba(255, 255, 255, 0.5)"
            }
            func={() => {
              if (!editFace) handleClickScrollXY(1200);
              setEditFace(!editFace);
            }}
            color={user.settings.colors.buttonsColor}
            style={{
              zIndex: 1,
            }}
          />
        )}
        <Section pSize={"34px"} cSize={"80px"} style={{ margin: "0px auto" }}>
          {user.settings.mainContent.part0}
        </Section>
        <Section pSize={"22px"} cSize={"33px"} style={{ marginBottom: "10px" }}>
          {user.settings.mainContent.part1}
        </Section>

        <RespFont pSize={"16px"} cSize={"28px"}>
          {user.settings.mainContent.part2}
        </RespFont>
        <Section pSize={"16px"} cSize={"28px"}>
          {user.settings.mainContent.part3}
        </Section>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://wa.me/972${
            user.contactPhone !== "" ? user.contactPhone : user.phone
          }/?text=היי, הגעתי דרך אתר הנדלן שלך, אשמח לפרטים`}
          style={{
            display: "flex",
            width: "80%",
            textDecoration: "none",
            margin: "0 auto",
          }}
        >
          <RespFont
            pSize={"18px"}
            cSize={"24px"}
            style={{
              background: user.settings.colors.buttonsColor,
              padding: "12px 10px",
              fontFamily: "hebM",
              width: "100%",
              borderRadius: "5px",
              color: user.settings.colors.buttonsFontColor,
              boxShadow: "0px 7.2174px 10.3947px rgba(245, 245, 245, 0.2)",
              marginBottom: "40px",
            }}
          >
            {user.settings.mainContent.part4}
          </RespFont>
        </a>
      </RespDiv>
    </MainDiv>
  );
};

export default FaceP;
