import React from 'react'
import styled from 'styled-components';
import spinner from './spinner.svg'
// import spinner from './barbershopLoading.gif'
const AnimationContainer = styled.div`
	height: 100vh;
	display: flex;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
	align-items: center;
	justify-content: center;
	background:${props => props.background ? `radial-gradient(circle, ${props.background} 0%, #f2f2f2 100%)` : "radial-gradient(circle, black 0%, #f2f2f2 100%)"};
`;

const FilledLoading = (props) => {
    return (<AnimationContainer background={props.background}>
        <img src={spinner} width="350" alt="spinner" />
    </AnimationContainer>)
}

export default FilledLoading