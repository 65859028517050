import { createSlice } from '@reduxjs/toolkit';
import ServerRoutes from '../api/routes';
import { get, post2 } from '../api/http';

const initialState = {
    guests: {},
    guest: null,
    flag: false,
    guestsError: null,
}

const _actionStart = (state) => {
    state.guestsLoading = true;
    state.guestsError = null;
};
const _actionSuccess = (state) => {
    state.guestsLoading = false;
    state.guestsError = null;
};
const _actionFailed = (state, action) => {
    state.guestsLoading = false;
    state.guestsError = action.payload.error;
};

const _setGuests = (state, action) => {
    state.guests = action.payload;
};

const _editArrived = (state, action) => {
    let editedGuests = { ...state.guests }
    const { table, _id, arrived } = action.payload;
    editedGuests = { ...editedGuests, [table]: { ...editedGuests[table], [_id]: { ...editedGuests[table][_id], arrived } } }
    state.guests = editedGuests;
};

const _editGuestTable = (state, action) => {
    const { items, destinationTable } = action.payload;
    console.log(items);
    let editedGuests = { ...state.guests }
    for (const [key, value] of Object.entries(items)) {
        let newGuest = { ...value, table: destinationTable }
        if (destinationTable !== value.table) { // במידה ושינו שולחן
            delete editedGuests[value.table][key];
            if (Object.keys(editedGuests[value.table]).length === 0)
                delete editedGuests[value.table]
        }
        if (destinationTable !== null) {
            if (editedGuests[destinationTable] === undefined)
                editedGuests = { ...editedGuests, [destinationTable]: { [key]: newGuest } }
            else
                editedGuests = { ...editedGuests, [destinationTable]: { ...editedGuests[destinationTable], [key]: newGuest } }
        }
    }
    state.guests = { ...editedGuests };
};



const table = createSlice({
    name: 'table',
    initialState,
    reducers: {
        setGuests: _setGuests,
        editArrived: _editArrived,
        editGuestTable: _editGuestTable,
        actionStart: _actionStart,
        actionFailed: _actionFailed,
        actionSuccess: _actionSuccess,
    },
});

const { reducer, actions } = table;

const { setGuests, editArrived, editGuestTable, actionStart, actionFailed, actionSuccess } = actions;

export default reducer;

export const getAll = () => {
    return async (dispatch) => {
        const result = await get(ServerRoutes.Tables.getAll, {});
        dispatch(setGuests(result));
    }
};

export const cleanAll = () => {
    return async (dispatch) => {
        dispatch(setGuests([]));
    }
};

export const editGuestById = (arrived, _id, table) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Guests.editById + _id, { arrived });
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editArrived({ _id, arrived, table }));
        }
    }
};

export const setGuestsTable = (items, table) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Guests.setGuestsTable, { items, table });
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editGuestTable({ items, destinationTable: table }));
        }
    }
};





