import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import {
  FlexRowBetween,
  FlexRowStart,
  GenericButton,
  GenericDiv,
  HomeInput,
  HomeTextArea,
  RespFont,
  RInput,
  SignatureInput,
} from "../Style";
import { saveAs } from "file-saver";
import * as htmlToImage from "html-to-image";
import successfulDeal from "../../../assets/gifs/successfulDeal.svg";
import error from "../../../assets/gifs/error.svg";
import SignatureCanvas from "react-signature-canvas";
import imageCompression from "browser-image-compression";
import { uploadDocPhoto } from "../../../store/reducers/photosReducer";
import { useDispatch, useSelector } from "react-redux";
import { getImageUrl, GetToast } from "../modals/Toast";
import { useToasts } from "react-toast-notifications";
import ColorCheckbox from "../modals/ColorCheckbox";
import { svgs } from "../footer/coloredSvgs/svgs";
import GenericSvg from "../footer/coloredSvgs/GenericSvg";
import OpacityLoading from "../loader/OpacityLoading";
import {
  cleanAll,
  unAuthSign,
  getDoc,
} from "../../../store/reducers/docsReducer";
import queryString from "query-string";
import GenericModal from "../modals/GenericModal";
import SellDoc from "./docs/SellDoc";
import BuyDoc from "./docs/BuyDoc";
import OtherDoc from "./docs/OtherDoc";

const Doc = (props) => {
  const dispatch = useDispatch();
  const { doc, docsError, docsLoading } = useSelector(
    (state) => state.docsReducer
  );
  let params = queryString.parse(props.location.search);
  const { uid, docId } = params;
  var FileSaver = require("file-saver");

  const { addToast } = useToasts();
  const search = useLocation().search;
  const [showClear, setShowClear] = useState(true);
  const [isSale, setIsSale] = useState(true);
  const [isVisible, setIsVisible] = useState("visible");
  const [isRent, setIsRent] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [imageWidth, setImageWidth] = useState(false);
  const [imageHeight, setImageHeight] = useState(false);
  const [sign, setSign] = useState();
  const [signStep, setSignStep] = useState(1);
  const getId = (num) => {
    let data = new URLSearchParams(search).get("a");
    data = window.atob(data);
    data = data.split(" ");
    return data[num];
  };
  const [isSigned, setIsSigned] = useState(false);
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  useEffect(() => {
    // let path = `${getImageUrl()}${uid}/${docId}/pic`;
    // let image = new Image();
    // image.src = path;
    // setImageWidth(image.width);
    // setImageHeight(image.height);
    dispatch(getDoc(docId));
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (docsError) GetToast(addToast, "error", docsError);
  }, [docsError]);

  const handleDone = () => {
    dispatch(unAuthSign(docId, { done: true })).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
      }
    });
    setLoading(false);
    setIsDone(true);
  };

  const handleError = () => {
    setLoading(false);
    setShowGif(true);
    setTimeout(() => {
      setShowGif(false);
    }, 6000);
  };

  // useEffect(() => {
  //   setUserId(getId(0));
  //   setDocId(getId(1));
  // }, []);
  const download = require("downloadjs");
  const fff = () => {
    setLoading(true);
    setShowClear(false);
    // htmlToImage
    //   .toBlob(document.getElementById("my-node"))
    //   .then(function (blob) {
    //     if (window.saveAs) {
    //       window.saveAs(blob, "my-node.png");
    //     } else {
    //       FileSaver.saveAs(blob, "my-node.png");
    //     }
    //   });

    htmlToImage
      .toPng(document.getElementById("my-node"), { backgroundColor: "#ffffff" })
      .then(
        async function (dataUrl) {
          download(dataUrl, `בלעדיות-${doc?.user.fullname}.png`);

          let image = dataURLtoFile(dataUrl, "doc.png");
          if (image.size > 1000000) {
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            try {
              const compressedFile = await imageCompression(image, options);
              image = new File([compressedFile], "image.jpeg", {
                type: compressedFile.type,
              });
            } catch (error) {
              console.log(error);
            }
          }
          const formData = new FormData();
          formData.append("image", image);
          dispatch(uploadDocPhoto(formData, uid + "-" + docId)).then((data) => {
            if (image.name === data) handleDone();
            else handleError();
          });

          setShowClear(true);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const clear = () => {
    sign.clear();
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (!isSigned) {
      GetToast(addToast, "warning", "יש לחתום המקום המסומן", 8000);
      setSignStep(2);
    } else fff();
  };
  let widthS = window.innerWidth; // 1728

  const handlePrint = () => {
    setLoading(true);
    setShowClear(false);
    setIsVisible("hidden");
    setTimeout(function () {
      setLoading(false);
      window.print();
    }, 1000);
    setTimeout(function () {
      setShowClear(true);
      setIsVisible("visible");
    }, 3000);
  };

  return (
    <>
      {isDone || doc?.done ? (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: "18px", fontFamily: "hebM" }}>
            המסמך נשלח בהצלחה!!
          </div>
          <img
            src={successfulDeal}
            style={{
              width: widthS > 768 ? "350px" : "250px",
              marginTop: "10px",
            }}
          />
        </div>
      ) : (
        <div style={{ margin: "0", width: "100%" }}>
          <RespFont
            id="my-node"
            style={{ width: "100%", backgroundColor: "white", padding: "20px" }}
          >
            <form onSubmit={onSubmit} id="form1">
              {/* <OtherDoc src={`${getImageUrl()}${uid}/${docId}/pic`} /> */}
              {/* <div
                style={{
                  width: "50%",
                  height: "auto",
                  maxWidth: "800px",
                  margin: "10px auto",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={`${getImageUrl()}${uid}/${docId}/pic`}
                />
              </div> */}
              {doc?.docKind == 1 ? (
                <SellDoc
                  doc={doc}
                  isRent={isRent}
                  isSale={isSale}
                  israelId={doc?.user.israelId}
                  fullname={doc?.user.fullname}
                  company={doc?.user.company}
                />
              ) : doc?.docKind == 2 ? (
                <BuyDoc
                  doc={doc}
                  isRent={isRent}
                  isSale={isSale}
                  israelId={doc?.user.israelId}
                  fullname={doc?.user.fullname}
                  company={doc?.user.company}
                />
              ) : (
                <OtherDoc
                  src={`${getImageUrl()}${uid}/docs/${docId}/${doc?.pic}`}
                />
              )}
              <RespFont
                style={{
                  borderBottom: "1px solid black",
                  width: "min-content",
                  margin: "10px auto",
                }}
              >
                חתימה:
              </RespFont>
              <FlexRowStart
                style={{
                  justifyContent: "center",
                  margin: "10px auto",
                  alignItems: "flex-end",
                }}
              >
                <SignatureCanvas
                  ref={(data) => setSign(data)}
                  penColor="blue"
                  onEnd={() => {
                    setIsSigned(true);
                    setSignStep(1);
                  }}
                  canvasProps={{
                    width: 300,
                    height: 100,
                    className: signStep === 2 ? "sigCanvasEmpty" : "sigCanvas",
                    border: "1px solid red",
                  }}
                />
                {showClear && (
                  <div
                    style={{
                      cursor: "pointer",
                      // padding: "0px 0",
                      paddingRight: "5px",
                      // filter: "brightness(90%)",
                    }}
                    onClick={clear}
                  >
                    <GenericSvg
                      fill={"#000"}
                      size={"20px"}
                      svg={svgs.delete}
                      viewBox={"0 0 297 297"}
                    />
                  </div>
                )}
              </FlexRowStart>
              <FlexRowStart style={{ marginTop: "40px" }}>
                <RespFont
                  pSize={"12px"}
                  cSize={"14px"}
                  style={{ color: "gray" }}
                >
                  כל המידע המוצג כאן ניתן כשירות לגולשים בלבד ואינו מהוה ייעוץ
                  משפטי או תחליף לו, כל הסתמכות על ההסכמים, התכנים והמידע שבהם
                  הינה באחריות המשתמש בלבד.
                </RespFont>
              </FlexRowStart>
            </form>
          </RespFont>
          <FlexRowStart
            style={{ justifyContent: "center", fontWeight: "700" }}
          ></FlexRowStart>
          {/* {doc?.docKind != 3 && ( */}
          <GenericButton
            hoverBackground={"rgb(223, 223, 223)"}
            background={"#fda14b"}
            type="submit"
            form="form1"
            style={{
              padding: "10px 30px",
              fontSize: "14px",
              margin: "10px auto",
              visibility: isVisible,
            }}
          >
            סיום
          </GenericButton>
          {/* )} */}
          {/* <GenericDiv
            hoverBackground={"rgb(223, 223, 223)"}
            background={"#fda14b"}
            onClick={handlePrint}
            style={{
              padding: "10px 30px",
              fontSize: "14px",
              margin: "10px auto",
              visibility: isVisible,
            }}
          >
            הדפסה{" "}
          </GenericDiv> */}
          {/* <FlexRowStart onClick={fff}>שלח והורד</FlexRowStart> */}
          {docsLoading || (loading && <OpacityLoading />)}

          {showGif && (
            <GenericModal
              init={() => {}}
              div={
                <div>
                  <div style={{ fontSize: "18px", fontFamily: "hebM" }}>
                    המסמך לא נשלח, יש לנסות שוב או לפנות לבעל האתר
                  </div>
                  <img
                    src={error}
                    style={{
                      width: widthS > 768 ? "350px" : "250px",
                      marginTop: "20px",
                    }}
                  />
                </div>
              }
            />
          )}
        </div>
      )}
    </>
  );
};
export default Doc;
