import React from "react";
import {
  FlexRowBetween,
  GenericDiv,
  GenericLink,
  OptionsFirst,
  RespFont,
  Section,
  Ser,
} from "../../Style";
import w1 from "../../../assets/zaks/mockup.png";
import whiteStar from "../../../assets/zaks/whiteStar.svg";
const StepsC = () => {
  return (
    <FlexRowBetween id={"steps-id"} style={{ margin: "100px auto" }}>
      <div
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Section pSize={"36px"} cSize={"48px"}>
          למה אנחנו?
        </Section>
        <FlexRowBetween style={{ justifyContent: "center" }}>
          <img
            src={whiteStar}
            style={{
              width: "45px",
              padding: "10px",
              backgroundColor: "rgb(231, 124, 64)",
              borderRadius: "50%",
              marginLeft: "10px",
            }}
          />
          <RespFont
            style={{
              fontWeight: "700",
              textAlign: "center",
              fontFamily: "hebM",
            }}
            pSize={"20px"}
            cSize={"24px"}
          >
            אתר אישי מותאם אישית
          </RespFont>
        </FlexRowBetween>

        <RespFont
          style={{
            textAlign: "center",
            width: "50%",
            fontFamily: "heb",
            color: "rgba(56, 52, 61, 0.7)",
            letterSpacing: "0.2px",
            marginTop: "10px",
          }}
        >
          אתר אישי המותאם אליכם הוא הפריט המרכזי שמציג אותך כחברת נדלן/סוכן
          נדלן, מדגיש את הייחודיות והמקצועיות שלך וכן את ניסיונך ומומחיותך
          בתחום. באמצעות עיצוב מותאם אישית, אפשר להעביר את האישיות והסגנון שלכם
          בצורה מקצועית. האתר יציג תמונות, פרטים מקיפים ותיאור מפורט על נכסי
          הנדל"ן שלכם, ובכך יוכלו הלקוחות להתרכז בחווית הגלישה באתר,להשאיר פניות
          מותאמות לבקשתם,לקבל עדכונים בזמן אמת ולהכיר אתכם טוב יותר.
        </RespFont>

        <FlexRowBetween style={{ justifyContent: "center", marginTop: "30px" }}>
          <img
            src={whiteStar}
            style={{
              width: "45px",
              padding: "10px",
              backgroundColor: "rgb(231, 124, 64)",
              borderRadius: "50%",
              marginLeft: "10px",
            }}
          />
          <RespFont
            style={{
              fontWeight: "700",
              textAlign: "center",
              fontFamily: "hebM",
            }}
            pSize={"20px"}
            cSize={"24px"}
          >
            מעקב והתמקדות בלקוחות
          </RespFont>
        </FlexRowBetween>

        <RespFont
          style={{
            textAlign: "center",
            width: "50%",
            fontFamily: "heb",
            color: "rgba(56, 52, 61, 0.7)",
            letterSpacing: "0.2px",
            marginTop: "10px",
          }}
        >
          היתרון שלכם מגיע מהיכולת למעקב אחרי הלקוחות ולהתמקד בהם באופן פרטני.
          בעזרת ממשק הניהול שלכם, תקבלו מידע חשוב ומותאם אישית על הלקוחות
          והצרכים שלהם. זה יעזור לכם ליצור יחסים אישיים וחזקים עם הלקוחות שלכם,
          ולהתמקד בסיפוק צרכיהם הייחודיים. האפשרות לקבל התראות מיידיות ומידע
          עדכני על הלקוחות שלכם תעניק לכם יתרון נוסף ותהפוך את עבודתכם ליעילה
          ומקצועית יותר.
        </RespFont>

        <FlexRowBetween style={{ justifyContent: "center", marginTop: "30px" }}>
          <img
            src={whiteStar}
            style={{
              width: "45px",
              padding: "10px",
              backgroundColor: "rgb(231, 124, 64)",
              borderRadius: "50%",
              marginLeft: "10px",
            }}
          />
          <RespFont
            style={{
              fontWeight: "700",
              textAlign: "center",
              fontFamily: "hebM",
            }}
            pSize={"20px"}
            cSize={"24px"}
          >
            תהליכים אוטומטיים ויעילים
          </RespFont>
        </FlexRowBetween>

        <RespFont
          style={{
            textAlign: "center",
            width: "50%",
            fontFamily: "heb",
            color: "rgba(56, 52, 61, 0.7)",
            letterSpacing: "0.2px",
            marginTop: "10px",
          }}
        >
          כחלק ממשק הניהול שלך, תוכל להשתמש במגוון כלים אוטומטיים ותהליכים
          מתקדמים, תוכל לחסוך זמן ומאמץ בתהליכי העבודה,ניהול יומן, ניהול לידים
          ועוד. הפלטפורמה מציעה חתימות דיגיטליות, מענה אוטומטי וכלים נוספים
          שיעזרו להם להתמקד בלקוחות ובשרות מקצועי. בזכות תהליכים ממוקדים
          ואוטומטיים, סוכני הנדל"ן יכולים להעניק שירות מהיר ויעיל יותר, ובכך
          להפיק תוצאות טובות יותר ללקוחות שלהם.
        </RespFont>

        <GenericLink
          target={"_blank"}
          rel="noopener noreferrer"
          href={`https://nadlan.agency/agents/adam-levi`}
          style={{
            backgroundColor: "#DE8556",
            fontFamily: "hebM",
            padding: "8px 64px",
            borderRadius: "5px",
            color: "#fff",
            fontSize: "20px",
            marginTop: "30px",
          }}
        >
          לאתר סוכן לדוגמא
        </GenericLink>
      </div>
      <div style={{ width: "60%" }}>
        <img src={w1} style={{ width: "100%" }} />
      </div>
    </FlexRowBetween>
  );
};

export default StepsC;
