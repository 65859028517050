import React, { useState, useMemo } from "react";
import {
  constants,
  FlexRowBetween,
  GenericButton,
  GenericDiv,
  RespFont,
} from "../Style";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../common/modals/Toast";
import { useForm } from "react-hook-form";
import { editDetails } from "../../store/reducers/authReducer";
import OpacityLoading from "../common/loader/OpacityLoading";
import { FlexRowStart, HomeInput, HomeTextArea } from "../common/Style";
import delete1 from "../../assets/nadlan/delete1.svg";

import ColorCheckbox from "../common/others/ColorCheckbox";
import EditPics from "./images/EditPics";
import GenericModal from "../common/modals/GenericModal";
import { deletePhoto } from "../../store/reducers/photosReducer";
import SketchColorPaletteG from "../common/sketchColor/SketchColorPaletteG";

const EditDetailsAboutMe = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [kind, setKind] = useState(false);
  const { user, editDetailsLoading, settingsFlag } = useSelector(
    (state) => state.authReducer
  );
  const [tempQues, setTempQues] = useState(user?.settings?.questions);
  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoOnly = async (key) => {
    await deletePhoto1(user[key]);
    dispatch(editDetails({ [key]: "" })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };
  const getd = (color, nName, pageToChange, setDisplay, kKKind, palette) => (
    <div
      style={{
        textAlign: "center",
        marginTop: widthS > 768 ? "-7px" : "0px",
        position: "relative",
        width: "100%",
        maxWidth: "300px",
        margin: "10px 20px",
      }}
    >
      {palette}
      <RespFont
        style={{
          fontFamily: "hebM",
          width: "max-content",
          textAlign: "center",
          margin: "auto",
        }}
      >
        {nName}
      </RespFont>
      <div
        onClick={() => {
          setDisplayT(false);
          setDisplayP(false);
          setDisplayM(false);
          setChoosenColor(color);
          setPageToChange(pageToChange);
          if (name === nName && kKKind === kKind) {
            setName("null");
            setKKind("null");
            setDisplay(false);
          } else {
            setName(nName);
            setKKind(kKKind);
            setDisplay(true);
          }
          // setName(nName);
          // setDisplay(true);
        }}
        style={{
          border: "1px solid silver",
          padding: "3px",
          borderRadius: "3px",
          width: "min-content",
          margin: "0 auto",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            width: widthS > 768 ? "70px" : "30px",
            height: "30px",
            backgroundColor: color,
            borderRadius: "3px",
            position: "relative",
          }}
        />
      </div>
    </div>
  );
  const [displayT, setDisplayT] = useState(false);
  const [displayP, setDisplayP] = useState(false);
  const [displayM, setDisplayM] = useState(false);
  const [isLengthWasChanged, seIsLengthWasChanged] = useState(false);
  const [name, setName] = useState("null");
  const [kKind, setKKind] = useState("null");
  const [choosenColor, setChoosenColor] = useState(false);
  const [pageToChange, setPageToChange] = useState(false);
  const [showPage3, setShowPage3] = useState(user.settings.showPages.page3);
  // const [showAllFeatures, setShowAllFeatures] = useState(false);
  let widthS = window.innerWidth; // 1728
  let inputsArray = useMemo(() => {
    let inputs = [];
    if (user.settings.about.part4.length > 0) {
      for (let i = 0; i < user.settings.about.part4.length; i++) {
        inputs.push(
          <div
            style={{
              width: "100%",
              maxWidth: "300px",
              margin: "10px 20px",
            }}
          >
            <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
              {`מאפיין ${i + 1}`}
            </RespFont>
            <HomeInput
              type="text"
              style={{ fontFamily: "hebM" }}
              defaultValue={user.settings.about.part4[i]["key"]}
              {...register(`about_part4_key_${i}`, {
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV(`about_part4_key_${i}`, "בין 2 ל 50 אותיות", errors)}
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              תוכן
            </RespFont>
            <HomeTextArea
              defaultValue={user.settings.about.part4[i]["value"]}
              type="text"
              rows="5"
              {...register(`about_part4_value_${i}`, {
                minLength: 2,
                maxLength: 500,
              })}
            />
            {CFV(`about_part4_value_${i}`, "בין 2 ל 500 אותיות", errors)}
          </div>
        );
      }
    }
    return widthS > 768 ? inputs : inputs;
  }, [settingsFlag, isLengthWasChanged]);

  const onSubmit = async (data) => {
    const body = {
      settings: {
        ...user.settings,
        about: {
          part0: data.about_part0,
          part1: data.about_part1,
          part2: data.about_part2,
          part3: data.about_part3,
          part4: [
            { key: data.about_part4_key_0, value: data.about_part4_value_0 },
            { key: data.about_part4_key_1, value: data.about_part4_value_1 },
            { key: data.about_part4_key_2, value: data.about_part4_value_2 },
            { key: data.about_part4_key_3, value: data.about_part4_value_3 },
            { key: data.about_part4_key_4, value: data.about_part4_value_4 },
            { key: data.about_part4_key_5, value: data.about_part4_value_5 },
            { key: data.about_part4_key_6, value: data.about_part4_value_6 },
          ],
        },
        showPages: {
          ...user.settings.showPages,
          page3: showPage3, // מי אני
        },
        pagesColors: {
          ...user.settings.pagesColors,
          page3: user.settings.pagesColors.page3, // מי אני
        },
        TitlePagesColor: {
          ...user.settings.TitlePagesColor,
          page3: user.settings.TitlePagesColor.page3, // מי אני
        },
      },
    };
    dispatch(editDetails(body)).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      else GetToast(addToast, "error", res.payload.error);
    });
  };

  return (
    <form
      id="editDetails-id"
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "90%",
        margin: "10px auto",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          margin: "0px 0px",
          height: "100%",
          overflow: "auto",
          paddingBottom: "30px",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כותרת
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.about.part0}
            {...register("about_part0", {
              required: true,
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`about_part0`, "בין 1 ל 50 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כותרת משנית
          </RespFont>
          <HomeTextArea
            defaultValue={user.settings.about.part1}
            type="text"
            rows="5"
            {...register("about_part1", {
              required: true,
              minLength: 1,
              maxLength: 500,
            })}
          />
          {CFV(`about_part1`, "בין 1 ל 500 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            תיאור
          </RespFont>
          <HomeTextArea
            defaultValue={user.settings.about.part2}
            type="text"
            rows="5"
            {...register("about_part2", {
              required: true,
              minLength: 0,
              maxLength: 500,
            })}
          />
          {CFV(`about_part2`, "בין 1 ל 500 תווים", errors)}
        </div>

        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כותרת נקודות
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.about.part3}
            {...register("about_part3", {
              required: true,
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`about_part3`, "בין 1 ל 50 תווים", errors)}
        </div>
        {/* <FlexRowBetween /> */}
        {inputsArray}
        <br />
        <FlexRowStart>
          <FlexRowBetween
            style={{
              width: "min-content",
              marginRight: "0px",
              marginLeft: "0px",
            }}
          >
            <ColorCheckbox
              checked={showPage3}
              color={user.settings.colors.buttonsColor}
              handleCHange={() => setShowPage3(!showPage3)}
            />
            <RespFont style={{ width: "max-content" }}>הצגת העמוד</RespFont>
          </FlexRowBetween>
          {getd(
            user.settings.pagesColors.page3,
            "רקע העמוד",
            "page3",
            setDisplayP,
            "p",
            <SketchColorPaletteG
              wtc={"pages"}
              name={name}
              change={pageToChange}
              display={displayP}
              onClose={setDisplayP}
              cc={choosenColor}
            />
          )}
          {getd(
            user.settings.TitlePagesColor.page3,
            "צבע הכותרת",
            "page3",
            setDisplayT,
            "t",
            <SketchColorPaletteG
              wtc={"titles"}
              name={name}
              change={pageToChange}
              display={displayT}
              onClose={setDisplayT}
              cc={choosenColor}
            />
          )}

          <FlexRowStart
            style={{
              width: "min-content",
              width: "100%",
              maxWidth: "300px",
              margin: "10px 20px",
            }}
          >
            {user.aboutPic !== "" && (
              <div
                onClick={() => deletePhotoOnly("aboutPic")}
                style={{
                  backgroundColor: constants.fontColorZax,
                  borderRadius: "3px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                <img
                  src={delete1}
                  alt="delete"
                  style={{
                    width: widthS > 768 ? "32px" : "30px",
                    padding: "7px",
                  }}
                />
              </div>
            )}
            <GenericDiv
              background={user.settings.colors.buttonsColor}
              color={user.settings.colors.buttonsFontColor}
              style={{ borderRadius: "3px" }}
              onClick={() => {
                if (user.aboutPic !== "")
                  GetToast(
                    addToast,
                    "warning",
                    "קודם יש למחוק את התמונה הקיימת ורק לאחר מכן להעלות חדשה"
                  );
                else setKind("aboutPic");
              }}
            >
              תמונה
            </GenericDiv>
          </FlexRowStart>
        </FlexRowStart>

        {/* <FlexRowBetween
          style={{
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <SketchColorPaletteG
              wtc={"pages"}
              name={name}
              change={pageToChange}
              display={displayP}
              onClose={setDisplayP}
              cc={choosenColor}
            />
          </div>
        </FlexRowBetween> */}
        {/* <FlexRowBetween
          style={{
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <SketchColorPaletteG
              wtc={"titles"}
              name={name}
              change={pageToChange}
              display={displayT}
              onClose={setDisplayT}
              cc={choosenColor}
            />
          </div>
        </FlexRowBetween> */}

        <GenericButton
          background={user.settings.colors.buttonsColor}
          color={user.settings.colors.buttonsFontColor}
          style={{
            width: widthS > 768 ? "50%" : "80%",
            margin: "0 auto",
            marginTop: "20px",
            fontWeight: "700",
            fontFamily: "monsterB",
            padding: "10px",
            marginBottom: "25px",
          }}
          type="submit"
        >
          <RespFont>שמירה</RespFont>
        </GenericButton>
        {editDetailsLoading && (
          <div style={{ marginRight: "-20px" }}>
            <OpacityLoading />
          </div>
        )}
        {kind && (
          <GenericModal
            init={setKind}
            div={<EditPics kind={kind} init={setKind} />}
          />
        )}
      </div>
    </form>
  );
};

export default EditDetailsAboutMe;
