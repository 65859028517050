import React from "react";
import { CompPriceDiv, GenericDiv, RespFont } from "../../../Style";
import vi from "../../../../assets/zaks/vi.svg";
import fire from "../../../../assets/nadlan/fire.svg";

const GenericDeal = ({ title, price, extra, setReg, reg }) => {
  return (
    <CompPriceDiv className="App animate__animated animate__fadeIn">
      <div>
        <RespFont
          pSize={"24px"}
          cSize={"30px"}
          style={{
            margin: "10px auto",
            color: "#38343D",
            fontFamily: "hebB",
          }}
        >
          {title}
        </RespFont>
        <RespFont
          pSize={"40px"}
          cSize={"48px"}
          style={{
            fontFamily: "monsterB",
            color: "#DE8556",
            marginBottom: "30px",
          }}
        >
          {price}
          {/* <div
            style={{
              color: "rgba(222, 133, 86, 0.7)",
              fontSize: "14px",
              fontFamily: "monsterB",
              marginTop: "-10px",
            }}
          >
            לחודש
          </div> */}
          <div
            style={{
              color: "rgba(222, 133, 86, 0.8)",
              fontSize: "14px",
              fontFamily: "monsterB",
              // marginTop: "-10px",
            }}
          >
            (לא כולל מע״מ)
          </div>
        </RespFont>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <RespFont>
            <img
              src={vi}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "8px 6px",
                backgroundColor: "#2DC071",
                marginLeft: "10px",
              }}
            />
            אתר אינטרנט מלא
          </RespFont>
          <RespFont style={{ marginTop: "18px" }}>
            <img
              src={vi}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "8px 6px",
                backgroundColor: "#2DC071",
                marginLeft: "10px",
              }}
            />
            עיצוב אישי
          </RespFont>
          <RespFont style={{ marginTop: "18px" }}>
            <img
              src={fire}
              style={{
                width: "38px",
                // borderRadius: "50%",
                // padding: "8px 6px",
                // backgroundColor: "#2DC071",
                marginLeft: "5px",
                marginRight: "-6px",
              }}
            />
            חיתום דיגיטלי
          </RespFont>
          <RespFont style={{ marginTop: "18px" }}>
            <img
              src={vi}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "8px 6px",
                backgroundColor: "#2DC071",
                marginLeft: "10px",
              }}
            />
            ממשק ניהול מלא
          </RespFont>
          <RespFont style={{ marginTop: "18px" }}>
            <img
              src={vi}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "8px 6px",
                backgroundColor: "#2DC071",
                marginLeft: "10px",
              }}
            />
            דפי נחיתה לנכסים
          </RespFont>
          <RespFont style={{ marginTop: "18px" }}>
            <img
              src={vi}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "8px 6px",
                backgroundColor: "#2DC071",
                marginLeft: "10px",
              }}
            />
            יומן משימות/פגישות כולל תזכורות
          </RespFont>
          <RespFont style={{ marginTop: "18px" }}>
            <img
              src={vi}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "8px 6px",
                backgroundColor: "#2DC071",
                marginLeft: "10px",
              }}
            />
            ניהול לקוחות
          </RespFont>
          {extra}
        </div>
      </div>
      <GenericDiv
        onClick={() => setReg(reg)}
        style={{
          backgroundColor: "#DE8556",
          width: "80%",
          color: "#fff",
          fontFamily: "monsterM",
          margin: "20px auto",
          padding: "10px",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        לקניה
      </GenericDiv>
    </CompPriceDiv>
  );
};

export default GenericDeal;
