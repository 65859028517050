import React, { useState } from "react";
import { AssetPic, FlexRowBetween, GenericDiv, PopupForm } from "../../Style";
import { availHeight, getImageUrl, GetToast } from "../../common/modals/Toast";
import UploadAssetsPhotos from "../images/UploadAssetsPhotos";
import Carousel from "../../common/carousel/Carousel";
import GenericModal from "../../common/modals/GenericModal";
import YesNoModal from "../../common/modals/YesNoModal";
import { editAsset } from "../../../store/reducers/assetsReducer";
import { editArticle } from "../../../store/reducers/articleReducer";
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { deletePhoto } from "../../../store/reducers/photosReducer";
import OpacityLoading from "../../common/loader/OpacityLoading";
import UploadArticlesPhotos from "../images/UploadArticlesPhotos";

const ArticlesPhotos = ({ photos, uid, color, assetId, init, isAsset = false }) => {
    const { addToast } = useToasts();
    const dispatch = useDispatch()
    const [uploadPic, setUploadPic] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deletePic, setDeletePic] = useState(false);
    const [i, setI] = useState(0);
    const deletePhoto1 = async (id) => dispatch(deletePhoto(id))
    const deletePhotoFunc = async () => {
        setLoading(true)
        await deletePhoto1(assetId + '/' + photos[i])
        let newPhotos = photos.filter(x => x !== photos[i])
        dispatch(editArticle({ photos: newPhotos }, assetId)).then((res) => {
            if (!res.payload?.error) {
                GetToast(addToast, "success", "הפעולה בוצעה")
                init()
            }
            setLoading(false)
        })
    }

    return (<>
        {uploadPic ? <UploadArticlesPhotos isAsset={isAsset} photos={photos} assetId={assetId} color={color} init={init} /> :
            deletePic ? <GenericModal
                init={setDeletePic}
                div={<YesNoModal
                    color={color}
                    title={"מחיקת תמונה"}
                    body={"האם את/ה בטוח/ה?"}
                    no={setDeletePic}
                    yes={deletePhotoFunc}
                />}
            /> :
                <PopupForm onClick={(e) => e.stopPropagation()}>
                    <Carousel setI={setI} i={i} photos={photos} color={color} path={`${getImageUrl()}${uid}/${assetId}/`}
                        setUploadPic={setUploadPic} setDeletePic={setDeletePic} />
                    {loading && <OpacityLoading />}
                </PopupForm>
        }
    </>)
}

export default ArticlesPhotos;