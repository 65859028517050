import React, { useState } from "react";
import ClientRoutes from "../../navigation/Routes";
// import background from '../../assets/nadlan/mainPageBackground.jpeg';
import background from "./home/assets/cityWhite.jpg";
import { Link } from "react-router-dom";
import eye from "../../assets/nadlan/eyeBlack.svg";

import {
  MainPageBackgroundImage,
  MainPageContent,
  WelcomePageContentTitle,
  WelcomePageContentSubTitle,
  MainPageButtons,
  BrightBackgroundImage,
  constants,
  EnteranceCompHolder,
  GenericButton,
  PlaceHolderImage,
  FlexRowBetween,
  GenericDiv,
  RespFont,
  PopupForm,
} from "../Style";
import { availHeight, CFV, GetToast } from "../common/modals/Toast";
import { HomeInput } from "../common/Style";
import { useForm } from "react-hook-form";
import { login } from "../../store/reducers/authReducer";
import { useToasts } from "react-toast-notifications";
import { sha512 } from "js-sha512";
import { useDispatch } from "react-redux";
import ResetPassword from "./ResetPassword";
import GenericModal from "../common/modals/GenericModal";

const Login = () => {
  let widthS = window.innerWidth; // 1728

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPass, setShowPass] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const onSubmitLogin = async (data) => {
    const body = {
      phone: data.phoneL,
      password: sha512(data.passwordL),
    };
    dispatch(login(body)).then((res) => {
      if (res.payload?.error) {
        GetToast(addToast, "error", res.payload.error);
      }
    });
  };

  return (
    <MainPageBackgroundImage
      pic={background}
      style={{
        // borderBottom: `10px solid ${constants.red}`,
        // borderBottom: `10px solid #f5ae07`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: availHeight()
      }}
    >
      <BrightBackgroundImage />
      {/* <MainPageContent style={{ height: availHeight(0.7), justifyContent: "flex-end" }}> */}
      {/* <EnteranceCompHolder style={{ backdropFilter: "blur(12.4554px)" }}> */}
      {/* <WelcomePageContentTitle style={{ fontFamily: "openSansMedium", zIndex: 2, lineHeight: "44px" }}>התחברות</WelcomePageContentTitle> */}
      {/* <WelcomePageContentTitle style={{ fontFamily: "openSansMedium", zIndex: 2, lineHeight: "44px" }}>למהפכת הנדל״ן</WelcomePageContentTitle>
                <WelcomePageContentSubTitle style={{ fontFamily: "openSansRegular", zIndex: 2, marginTop: "10px" }}>שדרגו עוד היום את הדרך בה אתם ונכסיכם מוצגים ללקוחות</WelcomePageContentSubTitle>
                <MainPageButtons as={Link} to={ClientRoutes.UnAuth.register}
                 background={"#f5ae07"} 
                style={{ zIndex: 2, marginTop: "20px", fontSize: "16px" }}>הרשמה</MainPageButtons>
                <MainPageButtons as={Link} to={ClientRoutes.UnAuth.login} color={constants.fontColor} background={"#f2f2f2"} style={{ zIndex: 2, marginTop: "10px", fontSize: "16px" }}>כניסה</MainPageButtons> */}
      {resetPassword ? (
        <ResetPassword init={setResetPassword} />
      ) : (
        <PopupForm
          onSubmit={handleSubmit(onSubmitLogin)}
          style={{
            // width: widthS > 768 ? "100%" : "80%",
            // margin: "0 auto",
            maxWidth: "400px",
            background: "rgba(255,255,255,0.85)",
          }}
        >
          <div style={{ width: "80%", margin: "0 auto", marginTop: "25px" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מס׳ פלאפון
            </RespFont>
            <HomeInput
              // style={{ textAlign: "center" }}
              pHcolor={"silver"}
              {...register("phoneL", {
                required: true,
                pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
              })}
            />
            {CFV("phoneL", "פורמט מספר פלאפון לא חוקי", errors)}
          </div>
          <div style={{ width: "80%", margin: "0 auto", position: "relative" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                // marginTop: "10px",
                textAlign: "right",
              }}
            >
              סיסמא
            </RespFont>
            <HomeInput
              type={showPass ? "text" : "password"}
              {...register("passwordL", {
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
            />
            {CFV("passwordL", "סיסמא בין 2 ל 30 תווים", errors)}
            <PlaceHolderImage
              onClick={() => setShowPass(!showPass)}
              fontSize={`20px`}
              style={{ left: `5px`, cursor: "pointer" }}
              top={`52px`}
              src={eye}
              alt="accept"
            />
          </div>
          <div style={{ width: "80%", margin: "15px auto" }}>
            <FlexRowBetween style={{ marginBottom: "30px", marginTop: "10px" }}>
              <GenericButton
                background={"#DE8556"}
                style={{
                  padding: "10px",
                  fontWeight: "700",
                  width: "100%",
                  fontSize: "16px",
                  // boxShadow: "0px 1px 2px white",
                }}
                type="submit"
              >
                התחברות
              </GenericButton>
              <GenericDiv
                style={{
                  padding: "10px",
                  width: "min-content",
                  fontSize: "16px",
                  marginRight: "10px",
                }}
                background={"gray"}
                onClick={() => setResetPassword(true)}
              >
                שכחתי סיסמא
              </GenericDiv>
            </FlexRowBetween>
          </div>
        </PopupForm>
      )}
      {/* </EnteranceCompHolder> */}
      {/* </MainPageContent> */}
      {/* {resetPassword && (
        <GenericModal
          init={setResetPassword}
          div={<ResetPassword init={setResetPassword} />}
        />
      )} */}
    </MainPageBackgroundImage>
  );
};
export default Login;
