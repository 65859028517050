import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ClientRoutes from "../../navigation/Routes";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { sha512 } from "js-sha512";
import { register1 } from "../../store/reducers/authReducer";
import { CFV, GetToast } from "../common/modals/Toast";
import eye from "../../assets/nadlan/eyeBlack.svg";
import vi from "../../assets/nadlan/vi.svg";
import {
  constants,
  InputBox,
  PlaceHolderImage,
  GenericButton,
  RespFont,
  PopupForm,
  FlexRowBetween,
} from "../Style";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { CenteredFormPage2 } from "./home/Style";
import { HomeInput } from "../common/Style";
import OpacityLoading from "../common/loader/OpacityLoading";
import { useHistory } from "react-router-dom";

// import PSPDFKit from 'pspdfkit';
const nA = (
  <div>
    <div>שם שיופיע בכתובת הקישור שיישלח ללקוחותיך לדוגמא:</div>
    <div>itay-shetrit</div>
    <div>ניתן לכתוב אותיות באנגלית ואת הסימנים הבאים:</div>
    <div>- . _</div>
  </div>
);
const Register = (props) => {
  let history = useHistory();

  const [tash, setTash] = useState(1);
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    let newWindow = window.open();
    const body = {
      phone: data.phone,
      mail: data.mail,
      contactPhone: data.phone,
      password: sha512(data.password),
      israelId: data.israelId,
      company: data.company,
      fullname: data.fullname,
      addressName: data.addressName,
      paymentTag: props.paymentTag,
      commitment: props.commitment,
      tash: data.tash,
    };
    setLoading(true);
    dispatch(register1(body)).then((res) => {
      setLoading(false);
      if (!res.payload?.error) {
        const link = `${res.payload.data}`;
        newWindow.location = link;
        GetToast(addToast, "success", "נרשמת בהצלחה!!");
        if (res.payload.status === 200) props.init();
        // history.push(ClientRoutes.UnAuth.login);
      } else {
        newWindow.close();
        GetToast(addToast, "error", res.payload.error);
      }
    });
  };
  let height = window.screen.availHeight;
  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      // id="form1Reg"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "90%",
          margin: "10px auto",
        }}
      >
        <FlexRowBetween style={{ margin: "0 auto" }}>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שם מלא
            </RespFont>
            <HomeInput
              {...register("fullname", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("fullname", "בין 2 ל 50 תווים", errors)}
          </div>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שם חברה
            </RespFont>
            <HomeInput
              {...register("company", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("company", "בין 2 ל 50 תווים", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "0 auto" }}>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מס׳ נייד אישי
            </RespFont>
            <HomeInput
              {...register("phone", {
                required: true,
                pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
              })}
            />
            {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)}
          </div>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מס׳ נייד עסקי
            </RespFont>
            <HomeInput
              {...register("contactPhone", {
                required: true,
                pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
              })}
            />
            {CFV("contactPhone", "פורמט מספר פלאפון לא חוקי", errors)}
          </div>
        </FlexRowBetween>
        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          שם העסק{" "}
          <span style={{ fontFamily: "heb", fontSize: "12px" }}>
            שיופיע בכתובת האתר, לדוגמא: itay-remax
          </span>
        </RespFont>
        <HomeInput
          {...register("addressName", {
            required: true,
            pattern: /^[a-z-._]{2,30}$/,
          })}
          pHcolor={"silver"}
          placeholder="itay-remax"
        />
        {CFV(
          "addressName",
          "בין 2 ל 30 אותיות באנגלית והתווים הבאים: - . _",
          errors
        )}
        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          מייל לקבלת חשבונית
        </RespFont>
        <HomeInput
          {...register("mail", {
            required: true,
            pattern: /\S+@\S+\.\S+/,
          })}
          pHcolor={"silver"}
        />
        {CFV("mail", "פורמט מייל לא חוקי", errors)}
        <FlexRowBetween>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              ת.ז
            </RespFont>
            <HomeInput
              {...register("israelId", {
                required: true,
                pattern: /^[0-9]{9}$/,
              })}
            />
            {CFV("israelId", "9 ספרות", errors)}
          </div>

          <div style={{ position: "relative", width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                // marginTop: "10px",
                textAlign: "right",
              }}
            >
              סיסמא
            </RespFont>
            <HomeInput
              type={showPass ? "text" : "password"}
              {...register("password", {
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
            />
            {CFV("password", "סיסמא בין 2 ל 30 תווים", errors)}
            <PlaceHolderImage
              onClick={() => setShowPass(!showPass)}
              fontSize={`20px`}
              top={`49px`}
              style={{ left: `5px`, cursor: "pointer" }}
              src={eye}
              alt="accept"
            />
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ alignItems: "flex-start" }}>
          <div style={{ width: "20%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              תשלומים
            </RespFont>
            <HomeInput
              type="number"
              defaultValue={tash}
              {...register("tash", {
                required: true,
                min: 1,
                max:
                  props.commitment === 1 ? 12 : props.commitment === 2 ? 6 : 3,
              })}
            />
            {CFV(
              "tash",
              `1-${
                props.commitment === 1 ? 12 : props.commitment === 2 ? 6 : 3
              }`,
              errors
            )}
          </div>

          <div style={{ position: "relative", width: "75%" }}>
            <RespFont
              style={{
                textAlign: "right",
              }}
            >
              מספר תשלומים מקסימלי בהתאם למספר חודשי ההתחייבות. ביטול טרם סיום
              החבילה יגרור חיוב יחסי בהתאם לחבילה המתאימה
            </RespFont>
          </div>
        </FlexRowBetween>
        <GenericButton
          // form="form1Reg"
          background={"#DE8556"}
          color={"#fff"}
          type="submit"
          style={{
            width: "100%",
            margin: "0px auto",
            padding: "10px",
            borderRadius: "5px",
            fontWeight: "bolder",
            fontSize: "16px",
            boxShadow: "none",
            marginTop: "40px",
          }}
        >
          שליחה
        </GenericButton>
      </div>
      {loading && <OpacityLoading />}
    </PopupForm>
  );
};

export default Register;
