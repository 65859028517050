import React from "react";
import { DisplayNonePhone, FlexRowBetween, Service } from "../../common/Style";
import { Link } from "react-router-dom";
import ClientRoutes from "../../../navigation/Routes";
import "./style.css";
import ZaxLogo from "../logo/ZaxLogo";
const NavBarMenu = () => {
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <DisplayNonePhone>
      <FlexRowBetween
        style={{
          position: "fixed",
          top: "0",
          backgroundColor: "rgba(228, 226, 226)",
          // fontWeight: "700",
          zIndex: 20,
          padding: "5px 50px",
          boxShadow: "0 2px 4px silver",
          fontFamily: "heb",
        }}
      >
        <div onClick={() => handleClickScroll("right-id")}>
          <ZaxLogo />
        </div>
        {/* <div
          onClick={() => handleClickScroll("contact-id")}
          className="scale11"
          style={{ cursor: "pointer", marginTop: "5px", marginBottom: "-5px" }}
        >
          <div>
            <img src={v0} alt={"v0"} style={{ width: "65px" }} />
          </div>
          <div style={{ height: "min-content" }}>
            <img src={v1} alt={"v1"} style={{ width: "65px" }} />
          </div>
          <img
            src={v2}
            alt={"v2"}
            style={{ width: "65px", marginTop: "-11px" }}
          />
        </div> */}

        <FlexRowBetween
          style={{
            width: "50%",
            cursor: "pointer",
            justifyContent: "space-evenly",
            color: "rgba(56, 52, 61, 1)",
          }}
        >
          <Service onClick={() => handleClickScroll("services-id")}>
            שירותים
          </Service>
          <Service onClick={() => handleClickScroll("steps-id")}>
            למה אנחנו?
          </Service>
          <Service onClick={() => handleClickScroll("priceListC-id")}>
            חבילות
          </Service>
          <Service onClick={() => handleClickScroll("quesAndAns-id")}>
            שאלות נפוצות
          </Service>
          <Service onClick={() => handleClickScroll("contact-id")}>
            צרו קשר
          </Service>
        </FlexRowBetween>

        <Service>
          <Link
            style={{
              color: "rgba(246, 246, 246, 1)",
              textDecoration: "none",
              backgroundColor: "rgba(222, 133, 86, 1)",
              borderRadius: "5px",
              padding: "12px 28px",
              fontWeight: "600",
            }}
            to={ClientRoutes.UnAuth.login}
          >
            כניסה
          </Link>
        </Service>
      </FlexRowBetween>
    </DisplayNonePhone>
  );
};

export default NavBarMenu;
