import React, { useEffect } from "react";
import { FlexRowBetween, GalleryOptions } from "../../Style";
import GenericSvg from "../footer/coloredSvgs/GenericSvg";
import { svgs } from "../footer/coloredSvgs/svgs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { addAlpha, availHeight, handleClickScroll } from "../modals/Toast";

const Carousel = ({
  scroll = true,
  photos,
  path,
  i,
  setI,
  color,
  setDeletePic,
  setUploadPic,
  authenticated = true,
  showOptions = true,
  isAgent = true,
}) => {
  useEffect(() => {
    if (scroll)
      setTimeout(function () {
        handleClickScroll("bla");
      }, 1000);
  }, [i]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
        maxHeight: availHeight(0.6),
      }}
    >
      {isAgent ? (
        <>
          {photos.length ? (
            <div
              style={{ width: "100%", maxHeight: "95%", textAlign: "center" }}
            >
              <img
                src={path + photos[i]}
                alt={photos[i]}
                style={{
                  width: "auto",
                  margin: "0 auto",
                  height: "auto",
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                height: availHeight(0.2),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              אין תמונות
            </div>
          )}
        </>
      ) : null}
      <GalleryOptions
        width={"21px"}
        padding={`10px 0`}
        style={{
          justifyContent: "space-between",
          marginTop: isAgent ? "0px" : "15px",
        }}
      >
        {photos.length > 1 && (
          <FlexRowBetween
            style={{
              justifyContent: "space-around",
              boxShadow: `0 -1px 4px ${addAlpha(color, 0.44)}`,
              backgroundColor: "#f2f2f2",
            }}
            onClick={() => {
              if (i < photos.length - 1) setI(i + 1);
              else setI(0);
            }}
          >
            <div style={{ padding: "8px 0", transform: "rotate(180deg)" }}>
              <GenericSvg
                fill={color}
                size={"20px"}
                svg={svgs.leftArrow}
                viewBox={"0 0 558.957 558.957"}
              />
            </div>
          </FlexRowBetween>
        )}

        {/* <FlexRowBetween style={{ justifyContent: "space-around", boxShadow: `0 -1px 4px ${addAlpha(color, 0.44)}` }}>
                <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{"שמירה"}</Tooltip>}>
                    <div style={{ padding: "8px 0" }}>
                        <GenericSvg fill={color} size={"20px"} svg={svgs.plus} viewBox={"0 0 16 16"} />
                    </div>
                </OverlayTrigger>
            </FlexRowBetween> */}

        {showOptions && authenticated && (
          <FlexRowBetween
            onClick={() => setUploadPic(true)}
            style={{
              justifyContent: "space-around",
              boxShadow: `0 -1px 4px ${addAlpha(color, 0.44)}`,
              backgroundColor: color,
              position: photos.length === 0 && "absolute",
              bottom: photos.length === 0 && "0px",
            }}
          >
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"הוספה"}</Tooltip>}
            >
              <div style={{ padding: "8px 0" }}>
                <GenericSvg
                  fill={"white"}
                  size={"20px"}
                  svg={svgs.plus}
                  viewBox={"0 0 16 16"}
                />
              </div>
            </OverlayTrigger>
          </FlexRowBetween>
        )}

        {authenticated && photos.length && showOptions && (
          <FlexRowBetween
            onClick={() => setDeletePic(true)}
            style={{
              justifyContent: "space-around",
              boxShadow: `0 -1px 4px ${addAlpha(color, 0.44)}`,
              backgroundColor: "#f2f2f2",
            }}
          >
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip>{"מחיקה"}</Tooltip>}
            >
              <div style={{ padding: "8px 0" }}>
                <GenericSvg
                  fill={color}
                  size={"20px"}
                  svg={svgs.delete}
                  viewBox={"0 0 297 297"}
                />
              </div>
            </OverlayTrigger>
          </FlexRowBetween>
        )}

        {/* <FlexRowBetween style={{ justifyContent: "space-around", boxShadow: `0 -1px 4px ${addAlpha(color, 0.44)}` }}>
                <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{"שמירה"}</Tooltip>}>
                    <div style={{ padding: "8px 0" }}>
                        <GenericSvg fill={color} size={"20px"} svg={svgs.leftArrow} viewBox={"0 0 558.957 558.957"} />
                    </div>
                </OverlayTrigger>
            </FlexRowBetween> */}

        {photos.length > 1 && (
          <FlexRowBetween
            id={"bla"}
            style={{
              justifyContent: "space-around",
              boxShadow: `0 -1px 4px ${addAlpha(color, 0.44)}`,
              backgroundColor: color,
            }}
            onClick={() => {
              if (i > 0) setI(i - 1);
              else setI(photos.length - 1);
            }}
          >
            <div style={{ padding: "8px 0" }}>
              <GenericSvg
                fill={"white"}
                size={"20px"}
                svg={svgs.leftArrow}
                viewBox={"0 0 558.957 558.957"}
              />
            </div>
          </FlexRowBetween>
        )}
      </GalleryOptions>
      {/* <FlexRowBetween style={{ width: "70%", margin: "0 auto", minHeight: "10%" }}>
            <img src={leftArrow} alt={'leftArrow'} style={{ transform: "rotate(180deg)", width: "20px" }} onClick={() => {
                if (i < photos.length - 1)
                    setI(i + 1)
                else setI(0)
            }} />
            <img src={leftArrow} alt={'leftArrow'} style={{ width: "20px" }} onClick={() => {
                if (i > 0)
                    setI(i - 1)
                else setI(photos.length - 1)
            }} />
        </FlexRowBetween> */}
      {!isAgent ? (
        <>
          {photos.length ? (
            <div
              style={{ width: "100%", maxHeight: "95%", textAlign: "center" }}
            >
              <img
                src={path + photos[i]}
                alt={photos[i]}
                style={{
                  width: "auto",
                  margin: "0 auto",
                  height: "auto",
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                height: availHeight(0.2),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              אין תמונות
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default Carousel;
