import React, { useMemo, useEffect, useState, useRef } from "react";
import {
  getAll,
  cleanAll,
  deleteGuest,
} from "../../../store/reducers/guestsReducer";
import { postCsv } from "../../../store/reducers/dataReducer";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  addAlpha,
  availHeight,
  getImageUrl,
  GetToast,
} from "../../common/modals/Toast";
import {
  ClientCard,
  AssetPic,
  AssetsHolder,
  constants,
  FlexRowBetween,
  GenericDiv,
  ClosenessTitle2,
  PageTitle,
  DropOption,
  Section,
  RespFont,
  FlexColumnBetween,
  PlaceHolderImage,
} from "../../Style";
import sort1 from "../../../assets/nadlan/sort3.svg";
import clear from "../../../assets/nadlan/clear.svg";
import downArrowB from "../../../assets/nadlan/downArrowB.svg";
import downArrowW from "../../../assets/nadlan/downArrowW.svg";
import upArrowB from "../../../assets/nadlan/upArrowB.svg";
import upArrowW from "../../../assets/nadlan/upArrowW.svg";
import GenericModal from "../../common/modals/GenericModal";
import Edit from "./Edit";
import Add from "./Add";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import {
  clientsSvg,
  svgs,
  zaxEdit,
} from "../../common/footer/coloredSvgs/svgs";
import sort from "../../../assets/nadlan/sort.svg";
import errors from "../../../assets/nadlan/errors1.svg";
import Switcher from "../../common/switcher/Switcher";
import YesNoModal from "../../common/modals/YesNoModal";
import OpacityLoading from "../../common/loader/OpacityLoading";
import AskForDownload from "../../common/modals/AskForDownload.js";

import readXlsxFile from "read-excel-file";
import { useExcelDownloder } from "react-xls";
import SendMessage from "../../common/modals/SendMessage";
import {
  DisplayNonePhone,
  HomeInput,
  FlexRowStart,
  DisplayNoneComputer,
} from "../../common/Style";

let dic = {
  closeness: "קטגוריה",
  preferredCity: "עיר מועדפת",
  preferredNeighborhood: "שכונה מועדפת",
  minSquareMeters: "מ״ר מינימלי",
  minRooms: "מס׳ חדרים מינימלי",
  minPrice: "מחיר מינימלי",
  maxSquareMeters: "מ״ר מקסימלי",
  maxRooms: "מס׳ חדרים מקסימלי",
  maxPrice: "מחיר מקסימלי",
};
let multyChoise = {};
let multyChoiseCounter = 0;
const Clients = (props) => {
  const { ExcelDownloder, Type } = useExcelDownloder();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { guests, guestsError, guestsChange, guestsLoading, getGuestsLoading } =
    useSelector((state) => state.guestsReducer);
  const { dataError } = useSelector((state) => state.dataReducer);
  const [send, setSend] = useState(false);
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef();

  const { user } = useSelector((state) => state.authReducer);
  const [addAsset, setAddAsset] = useState(false);
  const [askForDownload, setAskForDownload] = useState(false);
  const [deleteGuests, setDeleteGuests] = useState(false);

  const [selected, setSelected] = useState(0);
  const [closenessChosenFlag, setClosenessChosenFlag] = useState(false);
  const [showDropOwc, setShowDropOwc] = useState(false);
  const [deleteGuest1, setDeleteGuest] = useState(false);

  const [sortBy, setSortBy] = useState("closeness");
  const [sortDirection, setSortDirection] = useState(false);
  const [editAsset, setEditAsset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  let widthS = window.innerWidth; // 1728

  let height = window.innerHeight;

  const handleSetSend = () => {
    setMultiSelect(false);
    multyChoise = {};
    multyChoiseCounter = 0;
    setSelected(0);
    setSend(false);
  };

  useEffect(() => {
    dispatch(getAll());
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (guestsError) GetToast(addToast, "error", guestsError);
  }, [guestsError]);

  const add_del = (item) => {
    if (multyChoise[item._id] !== undefined) {
      delete multyChoise[item._id];
      multyChoiseCounter--;
    } else {
      // multyChoise[item._id] = `+972${item.phone}`;
      multyChoise[item._id] = item.phone;
      multyChoiseCounter++;
    }
    setSelected(multyChoiseCounter);
    setClosenessChosenFlag(!closenessChosenFlag);
  };
  const deleteFunc = () => {
    dispatch(deleteGuest(Object.keys(multyChoise))).then(
      (data) => {
        setMultiSelect(false);
        if (!data.payload.error)
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        setDeleteGuests(false);
        multyChoise = {};
        multyChoiseCounter = 0;
        setSelected(0);
        setEditAsset(null);
      },
      (error) => {
        GetToast(addToast, "error", error);
        setDeleteGuests(false);
        setMultiSelect(false);
      }
    );
  };
  const deleteOne = () => {
    multyChoise[deleteGuest1._id] = true;
    deleteFunc();
    setDeleteGuest(null);
  };
  const initDeleteOne = () => {
    setDeleteGuest(null);
  };
  // const deleteGuest1 = (arr = null) => {
  //     dispatch(deleteGuest(arr !== null ? arr : guestToDelete)).then((data) => {
  //         setDeleteMode(false)
  //         setMultiSelect(false)
  //         if (!data.payload.error)
  //             GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!")
  //         setDeleteGuest(null);
  //         multyChoise = {};
  //         multyChoiseCounter = 0;
  //         setSelected(0)
  //         setEdit(null);
  //     }, error => {
  //         GetToast(addToast, "error", error)
  //         setDeleteMode(false)
  //         setMultiSelect(false)
  //     })
  // }

  const handleClick = (e, data) => {
    if (e.type === "click") {
      if (multiSelect) add_del({ _id: data._id, phone: data.phone });
      // else setEditAsset(data);
    } else if (e.type === "contextmenu") {
      e.preventDefault();
      console.log("Right click");
    }
  };
  const phonesToList = () => Object.values(multyChoise).join(",");

  let guestsArray = useMemo(() => {
    let arr = [];
    let excel = [];
    let sortedArray = [];
    const addBookmark = (category) => {
      arr.push(
        <div style={{ width: "100%" }}>
          <ClosenessTitle2
            color={user.settings.colors.buttonsColor}
            id={category}
            key={category}
          >
            {category}
          </ClosenessTitle2>
        </div>
      );
    };
    if (Object.keys(guests).length > 0) {
      for (const [key, value] of Object.entries(guests))
        if (value.name.includes(searchText) || value.phone.includes(searchText))
          sortedArray.push(value);
      switch (sortBy) {
        case "price":
          if (sortDirection)
            sortedArray.sort(
              (a, b) =>
                (a["salePrice"] === null ? a["price"] : a["salePrice"]) -
                (b["salePrice"] === null ? b["price"] : b["salePrice"])
            );
          else
            sortedArray.sort(
              (a, b) =>
                (b["salePrice"] === null ? b["price"] : b["salePrice"]) -
                (a["salePrice"] === null ? a["price"] : a["salePrice"])
            );
          break;
        // case "rent":
        //     if (sortDirection) sortedArray.sort((a, b) => Number(b[sortBy]) - Number(a[sortBy]));
        //     else sortedArray.sort((a, b) => Number(a[sortBy]) - Number(b[sortBy]));
        //     break;
        case "closeness":
        case "preferredNeighborhood":
        case "preferredCity":
          if (sortDirection)
            sortedArray.sort((a, b) => b[sortBy].localeCompare(a[sortBy]));
          else sortedArray.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
          else sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
      }
      if (sortedArray.length)
        addBookmark(
          sortBy === "preferredNeighborhood" && sortedArray[0][sortBy] === ""
            ? "אין שכונה מועדפת"
            : sortBy === "preferredCity" && sortedArray[0][sortBy] === ""
            ? "אין עיר מועדפת"
            : sortedArray[0][sortBy]
        );

      // addBookmark(sortedArray[0][sortBy]);
      for (let i = 0; i < sortedArray.length; i++) {
        if (i !== 0 && sortedArray[i][sortBy] !== sortedArray[i - 1][sortBy])
          addBookmark(
            sortBy === "preferredNeighborhood" && sortedArray[i][sortBy] === ""
              ? "אין שכונה מועדפת"
              : sortBy === "preferredCity" && sortedArray[i][sortBy] === ""
              ? "אין עיר מועדפת"
              : sortedArray[i][sortBy]
          );
        // addBookmark(sortedArray[i][sortBy]);

        arr.push(
          <ClientCard
            key={i + 1}
            onClick={(e) => handleClick(e, sortedArray[i])}
            onContextMenu={(e) => handleClick(e, sortedArray[i])}
            //  onClick={() => setEditAsset(sortedArray[i])}
            style={{
              backgroundColor:
                multyChoise[sortedArray[i]._id] !== undefined
                  ? `${addAlpha(user.settings.colors.buttonsColor, 0.15)}`
                  : "rgba(255,255,255,0.5)",
              color: constants.fontColor,
              borderRadius: "5px",
              padding: "5px 8px",
              position: "relative",
            }}
          >
            <FlexRowBetween style={{ alignItems: "flex-start" }}>
              <FlexColumnBetween>
                <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                  <div style={{ fontFamily: "hebM" }}>
                    {sortedArray[i].name}
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div>
                    {sortedArray[i].preferredCity !== ""
                      ? sortedArray[i].preferredCity
                      : "אין עיר מועדפת"}
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div>
                    {sortedArray[i].preferredNeighborhood !== ""
                      ? sortedArray[i].preferredNeighborhood
                      : "אין שכונה מועדפת"}
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div>
                    {sortedArray[i].minPrice + "-" + sortedArray[i].maxPrice}₪
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div>
                    {sortedArray[i].minRooms +
                      "-" +
                      sortedArray[i].maxRooms +
                      " חדרים"}
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div>
                    {sortedArray[i].minSquareMeters +
                      "-" +
                      sortedArray[i].maxSquareMeters +
                      " מ״ר"}
                  </div>
                </FlexRowBetween>
              </FlexColumnBetween>
              <FlexColumnBetween
                style={{
                  width: "max-content",
                  height: "100%",
                  //   alignItems:"",
                  marginLeft: "0px",
                  padding: "10px",
                  alignItems: "flex-start",
                }}
              >
                {/* {(user?.smsBlackList).split(",").includes([sortedArray[i].phone]) && (
                  <OverlayTrigger
                    placement={"top"}
                    overlay={<Tooltip>{"חסום להודעות"}</Tooltip>}
                  >
                    <div>
                      <img src={blackList} style={{ width: "25px" }} />
                    </div>
                  </OverlayTrigger>
                )} */}
              </FlexColumnBetween>
              <FlexColumnBetween
                style={{
                  width: "min-content",
                  height: "100%",
                  //   alignItems:"",
                  marginLeft: "0px",
                  padding: "10px",
                  backgroundColor: addAlpha(
                    user.settings.colors.buttonsColor,
                    0.065
                  ),
                }}
              >
                <a
                  style={{ filter: "brightness(80%)" }}
                  target="_blank"
                  rel="noreferrer"
                  href={`https://wa.me/972${sortedArray[i].phone}/?text=`}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    size={"20px"}
                    svg={svgs.whatsapp}
                    viewBox={"0 0 24 24"}
                  />
                </a>
                <a
                  style={{ marginTop: "25px", filter: "brightness(80%)" }}
                  target="_blank"
                  // style={{ marginLeft: widthS > 768 ? "15px" : "15px" }}
                  rel="noreferrer"
                  href={`tel:972${sortedArray[i].phone}`}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    //   size={widthS > 768 ? "35px" : "25px"}
                    size={"18px"}
                    svg={svgs.phone}
                    //   viewBox={"0 0 24 24"}
                  />
                </a>
                <div
                  style={{ marginTop: "25px", filter: "brightness(80%)" }}
                  onClick={() => setEditAsset(sortedArray[i])}
                >
                  {zaxEdit(20, user.settings.colors.buttonsColor)}
                  {/* <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    //   size={widthS > 768 ? "35px" : "25px"}
                    size={"20px"}
                    svg={svgs.whatsapp}
                    viewBox={"0 0 24 24"}
                  /> */}
                </div>
                <div
                  style={{ marginTop: "25px", filter: "brightness(80%)" }}
                  onClick={() => setDeleteGuest(sortedArray[i])}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    size={"20px"}
                    svg={svgs.delete}
                    viewBox={"0 0 297 297"}
                  />
                </div>
              </FlexColumnBetween>
            </FlexRowBetween>
          </ClientCard>
        );

        // prepare to excel
        excel.push({
          "שם מלא": sortedArray[i].name,
          "מספר פלאפון": sortedArray[i].phone,
          "עיר מועדפת": sortedArray[i].preferredCity,
          "שכונה מועדפת": sortedArray[i].preferredNeighborhood,
          קטגוריה: sortedArray[i].closeness,
          "מינימום מ״ר": sortedArray[i].minSquareMeters,
          "מינימום חדרים": sortedArray[i].minRooms,
          "מינימום מחיר": sortedArray[i].minPrice,
          "מקסימום מ״ר": sortedArray[i].maxSquareMeters,
          "מקסימום חדרים": sortedArray[i].maxRooms,
          "מקסימום מחיר": sortedArray[i].maxPrice,
          הערות: sortedArray[i].notes,
        });
      }
    }
    return [arr, excel];
  }, [
    guestsChange,
    guests,
    sortBy,
    sortDirection,
    multiSelect,
    selected,
    searchText,
  ]);

  const fileSelected = (event) => {
    readXlsxFile(event.target.files[0]).then((rows) => {
      dispatch(postCsv({ rows })).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
          dispatch(getAll());
        }
      });
    });
  };

  return (
    <div
      id="clients-id"
      style={{ width: "100%", margin: "0 auto", position: "relative" }}
    >
      <Section
        cSize={"38px"}
        pSize={"34px"}
        style={{ color: constants.fontColor, marginTop: "40px" }}
      >
        לקוחות
      </Section>
      <FlexRowBetween
        style={{ width: "95%", margin: "10px auto", alignItems: "baseline" }}
      >
        <FlexRowStart>
          {/* מיון */}
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "60px",
              padding: widthS > 768 ? "5px" : "3px",
              fontWeight: "700",
              marginRight: "0px",
              marginLeft: "0px",
            }}
          >
            <RespFont
              pSize={"12px"}
              onClick={() => setSortBy("preferredCity")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                width: "max-content",
                backgroundColor:
                  sortBy === "preferredCity"
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color:
                  sortBy === "preferredCity" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              עיר
            </RespFont>
            <RespFont
              pSize={"12px"}
              onClick={() => setSortBy("preferredNeighborhood")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                width: "max-content",
                backgroundColor:
                  sortBy === "preferredNeighborhood"
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color:
                  sortBy === "preferredNeighborhood"
                    ? "#fff"
                    : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              שכונה
            </RespFont>
            <RespFont
              pSize={"12px"}
              onClick={() => setSortBy("minRooms")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                width: "max-content",
                backgroundColor: sortBy.toLowerCase().includes("rooms")
                  ? user.settings.colors.buttonsColor
                  : "inherit",
                transition: "all 0.2s",
                color: sortBy.toLowerCase().includes("rooms")
                  ? "#fff"
                  : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              חדרים
            </RespFont>
            <RespFont
              pSize={"12px"}
              onClick={() => setSortBy("minPrice")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                backgroundColor: sortBy.toLowerCase().includes("price")
                  ? user.settings.colors.buttonsColor
                  : "inherit",
                transition: "all 0.2s",
                color: sortBy.toLowerCase().includes("price")
                  ? "#fff"
                  : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              מחיר
            </RespFont>
            <RespFont
              pSize={"12px"}
              onClick={() => setSortBy("minSquareMeters")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                backgroundColor: sortBy.toLowerCase().includes("squaremeters")
                  ? user.settings.colors.buttonsColor
                  : "inherit",
                transition: "all 0.2s",
                color: sortBy.toLowerCase().includes("squaremeters")
                  ? "#fff"
                  : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              מ״ר
            </RespFont>
            <RespFont
              pSize={"12px"}
              onClick={() => setSortBy("closeness")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                width: "max-content",
                backgroundColor:
                  sortBy === "closeness"
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color: sortBy === "closeness" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              קטגוריה
            </RespFont>
          </FlexRowBetween>
          <DisplayNonePhone
            style={{
              position: "relative",
              marginRight: "30px",
              width: "200px",
            }}
          >
            <PlaceHolderImage
              onClick={() => {
                setSearchText("");
                inputRef.current.focus();
              }}
              fontSize={`23px`}
              top={`22px`}
              src={clear}
              style={{
                cursor: "pointer",
                left: "4px",
                padding: "4px",
                borderRadius: "2px",
              }}
            />
            <HomeInput
              ref={inputRef}
              value={searchText}
              style={{ direction: "rtl" }}
              pHcolor={"grey"}
              placeholder={"חיפוש שם/נייד"}
              type="text"
              required={true}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </DisplayNonePhone>
        </FlexRowStart>
        {/* <div>ss</div> */}
        {/* כיוון */}
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "50px",
            padding: widthS > 768 ? "5px" : "3px",
            fontWeight: "700",
            marginLeft: "0px",
          }}
        >
          <RespFont
            onClick={() => setSortDirection(true)}
            style={{
              padding: widthS > 768 ? "6px 10px" : "0px 4px",
              cursor: "pointer",
              backgroundColor: sortDirection
                ? user.settings.colors.buttonsColor
                : "inherit",
              transition: "all 0.2s",
              color: sortDirection ? "#fff" : constants.fontColorZax,
              borderRadius: "50px",
              width: "max-content",
            }}
          >
            <img
              src={sortDirection ? upArrowW : upArrowB}
              style={{ width: widthS > 768 ? "23px" : "13px" }}
            />
          </RespFont>
          <RespFont
            onClick={() => setSortDirection(false)}
            style={{
              padding: widthS > 768 ? "6px 10px" : "0px 4px",
              cursor: "pointer",
              backgroundColor: !sortDirection
                ? user.settings.colors.buttonsColor
                : "inherit",
              transition: "all 0.2s",
              color: !sortDirection ? "#fff" : constants.fontColorZax,
              borderRadius: "50px",
              width: "max-content",
            }}
          >
            <img
              src={sortDirection ? downArrowB : downArrowW}
              style={{ width: widthS > 768 ? "23px" : "13px" }}
            />
          </RespFont>
        </FlexRowBetween>
      </FlexRowBetween>
      {sortBy[0] === "m" && (
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "60px",
            padding: widthS > 768 ? "5px" : "3px",
            fontWeight: "700",
            marginRight: "2.5%",
            marginTop: "10px",
          }}
        >
          <RespFont
            pSize={"12px"}
            onClick={() => {
              let temp = sortBy.split("");
              if (sortBy[1] === "a") {
                temp.splice(1, 2, "in");
                //   temp[1] = "i";
                //   temp[2] = "n";
              }
              temp = temp.join("");
              setSortBy(temp);
            }}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                sortBy[1] === "i"
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: sortBy[1] === "i" ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            רף תחתון
          </RespFont>
          <RespFont
            pSize={"12px"}
            onClick={() => {
              let temp = sortBy.split("");
              if (sortBy[1] === "i") {
                temp.splice(1, 2, "ax");
                //   temp[1] = "a";
                //   temp[2] = "x";
              }
              temp = temp.join("");
              setSortBy(temp);
            }}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                sortBy[1] === "a"
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: sortBy[1] === "a" ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            רף עליון
          </RespFont>
        </FlexRowBetween>
      )}
      <FlexRowBetween style={{ width: "95%", margin: "10px auto" }}>
        <>
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"הוספה"}</Tooltip>}
          >
            <div
              onClick={() => setAddAsset(true)}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                filter: "brightness(90%)",
                marginRight: "0px",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"28px"}
                svg={svgs.plus}
                viewBox={"0 0 16 16"}
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"ייצוא"}</Tooltip>}
          >
            <div
              onClick={() => setAskForDownload(true)}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                filter: "brightness(90%)",
                marginRight: "10px",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"28px"}
                svg={svgs.import}
                viewBox={"0 0 24 24"}
              />
            </div>
          </OverlayTrigger>

          <a
            href="https://secret-mqr-upload.s3.eu-central-1.amazonaws.com/nadlan/sys/%D7%AA%D7%91%D7%A0%D7%99%D7%AA.xlsx"
            style={{ textDecoration: "none" }}
            target="_blank"
            download
          >
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"תבנית"}</Tooltip>}
            >
              <div
                style={{
                  cursor: "pointer",
                  padding: "10px 0",
                  filter: "brightness(90%)",
                  marginRight: "10px",
                }}
              >
                <GenericSvg
                  fill={user.settings.colors.buttonsColor}
                  size={"28px"}
                  svg={svgs.excel}
                  viewBox={"0 0 110.037 110.037"}
                />
              </div>
            </OverlayTrigger>
          </a>

          <label htmlFor="file-upload">
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"ייבוא"}</Tooltip>}
            >
              <div
                style={{
                  cursor: "pointer",
                  padding: "10px 0",
                  filter: "brightness(90%)",
                  marginRight: "10px",
                }}
              >
                <GenericSvg
                  fill={user.settings.colors.buttonsColor}
                  size={"28px"}
                  svg={svgs.export}
                  viewBox={"0 0 24 24"}
                />
              </div>
            </OverlayTrigger>
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={fileSelected}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"בחירה מרובה"}</Tooltip>}
          >
            <div
              onClick={() => {
                if (multiSelect) {
                  multyChoise = {};
                  multyChoiseCounter = 0;
                  setSelected(0);
                }
                setMultiSelect(!multiSelect);
              }}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                filter: "brightness(90%)",
                marginRight: "10px",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"28px"}
                svg={svgs.checklist}
                viewBox={"0 0 98.374 98.374"}
              />
            </div>
          </OverlayTrigger>
          {multiSelect && (
            <div
              className="animate__animated animate__fadeIn"
              style={{
                marginRight: "10px",
                fontSize: "20px",
                color: user.settings.colors.buttonsColor,
                filter: "brightness(80%)",
                fontWeight: "700",
              }}
            >
              {selected}
            </div>
          )}

          {dataError?.kind === "csv" && (
            <ExcelDownloder
              data={{ שגיאות: dataError.error }}
              filename={"שגיאות אקסל"}
              type={Type.Button} // or type={'button'}
              style={{
                border: "none",
                background: "transparent",
                width: "0px",
              }}
            >
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip>{"שגיאות"}</Tooltip>}
              >
                <img
                  src={errors}
                  style={{ width: "32px", marginRight: "15px" }}
                />
              </OverlayTrigger>
            </ExcelDownloder>
          )}
        </>
        {multiSelect ? (
          <FlexRowBetween
            style={{ justifyContent: "flex-end" }}
            className="animate__animated animate__fadeIn"
          >
            {Object.keys(multyChoise).length > 0 && (
              <div
                className="animate__animated animate__fadeInLeft"
                onClick={() => setSend(true)}
                style={{
                  cursor: "pointer",
                  marginRight: "15px",
                  filter: "brightness(90%)",
                }}
              >
                <GenericSvg
                  fill={user.settings.colors.buttonsColor}
                  size={"31px"}
                  svg={svgs.sms2}
                  viewBox={"0 0 68 68"}
                />
              </div>
            )}

            {/* {(Object.keys(multyChoise).length > 0) && <ahref={`sms://open?addresses=${phonesToList()}?&body=`}>
                            <div style={{ cursor: "pointer", marginRight: "15px", filter: "brightness(90%)" }}><GenericSvg fill={user.settings.colors.buttonsColor} size={"31px"} svg={svgs.sms2} viewBox={"0 0 68 68"} /></div></a>} */}
            {/* <div style={{ cursor: "pointer", marginRight: "15px", filter: "brightness(90%)" }}><GenericSvg fill={user.settings.colors.buttonsColor} size={"20px"} svg={svgs.whatsapp} viewBox={"0 0 24 24"} /></div> */}
            <div
              onClick={() => {
                if (Object.keys(multyChoise).length > 0) setDeleteGuests(true);
                else alert("לא נבחרו לקוחות");
              }}
              style={{
                cursor: "pointer",
                marginRight: "15px",
                filter: "brightness(90%)",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"20px"}
                svg={svgs.delete}
                viewBox={"0 0 297 297"}
              />
            </div>
          </FlexRowBetween>
        ) : (
          <FlexRowBetween></FlexRowBetween>
        )}
      </FlexRowBetween>

      <DisplayNoneComputer
        style={{
          position: "relative",
          marginRight: "3.5%",
          width: "200px",
        }}
      >
        <PlaceHolderImage
          onClick={() => {
            setSearchText("");
            inputRef.current.focus();
          }}
          fontSize={`23px`}
          top={`20px`}
          src={clear}
          style={{
            cursor: "pointer",
            left: "4px",
            padding: "4px",
            borderRadius: "2px",
          }}
        />
        <HomeInput
          ref={inputRef}
          value={searchText}
          style={{ direction: "rtl" }}
          type="text"
          required={true}
          pHcolor={"grey"}
          placeholder={"חיפוש שם/נייד"}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </DisplayNoneComputer>
      <AssetsHolder style={{ height: `${height - 180}px` }}>
        {guestsArray[0].length ? (
          guestsArray[0]
        ) : (
          <FlexColumnBetween style={{ justifyContent: "space-evenly" }}>
            <RespFont style={{ fontFamily: "hebB" }}>
              אין לך לקוחות, התחילו להוסיף...
            </RespFont>
            {clientsSvg(
              widthS > 768 ? 432.88 : 216.44,
              widthS > 768 ? 341.445 : 170.7225,
              user.settings.colors.buttonsColor
            )}
          </FlexColumnBetween>
        )}
      </AssetsHolder>

      {send && (
        <GenericModal
          init={setSend}
          div={
            <SendMessage
              init={handleSetSend}
              phones={phonesToList()}
              color={user.settings.colors.buttonsColor}
            />
          }
        />
      )}
      {addAsset && (
        <GenericModal init={setAddAsset} div={<Add init={setAddAsset} />} />
      )}
      {(getGuestsLoading || guestsLoading) && <OpacityLoading />}
      {editAsset && (
        <GenericModal
          init={setEditAsset}
          div={<Edit data={editAsset} init={setEditAsset} />}
        />
      )}

      {deleteGuest1 && (
        <GenericModal
          init={initDeleteOne}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"מחיקת רשומה"}
              body={"האם את/ה בטוח/ה?"}
              no={initDeleteOne}
              yes={deleteOne}
            />
          }
        />
      )}

      {deleteGuests && (
        <GenericModal
          init={setDeleteGuests}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"מחיקת לקוחות"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeleteGuests}
              yes={deleteFunc}
            />
          }
        />
      )}

      {askForDownload && guestsArray[1].length && (
        <GenericModal
          init={setAskForDownload}
          div={
            <AskForDownload
              color={user.settings.colors.buttonsColor}
              title={`נתונים`}
              filename={`נתונים`}
              body={`האם ברצונך להוריד אקסל נתונים?`}
              no={setAskForDownload}
              data={guestsArray[1]}
              init={setAskForDownload}
            />
          }
        />
      )}
    </div>
  );
};

export default Clients;
