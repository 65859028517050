import React from "react";
import { DisplayNonePhone, FlexRowColumn } from "../../common/Style";
import { OptionsFirst, RespFont, Section, Ser } from "../../Style";
import s1 from "../../../assets/zaks/s1.svg";
import s2 from "../../../assets/zaks/s2.svg";
import s3 from "../../../assets/zaks/s3.svg";
import s4 from "../../../assets/zaks/s4.svg";
import s5 from "../../../assets/zaks/s5.svg";
import s6 from "../../../assets/zaks/s6.svg";

const Services = () => {
  return (
    <div
      id={"services-id"}
      style={{
        fontFamily: "heb",
        paddingTop: "40px",
        backgroundColor: "#fff",
      }}
    >
      <Section pSize={"36px"} cSize={"48px"}>
        מה אנחנו מציעים?
      </Section>
      <FlexRowColumn>
        <Ser style={{ paddingTop: "0px" }}>
          <img src={s6} style={{ width: "140px" }} />
          <RespFont
            cSize={"22px"}
            pSize={"20px"}
            style={{
              fontWeight: "600",
              margin: "15px auto",
              marginTop: "-7px",
            }}
          >
            אתר אישי + ממשק ניהול
          </RespFont>
          <RespFont>
            הגיע הזמן שכל נכסיך והידע שלך יהיו מרוכזים באתר בלעדי משלך, תוכל
            לשלוט בקלות על התוכן, הצבעים, התמונות ועוד..
          </RespFont>
        </Ser>
        <Ser style={{ marginRight: "10px", marginLeft: "10px" }}>
          <img src={s1} style={{ width: "100px" }} />
          <RespFont
            cSize={"22px"}
            pSize={"20px"}
            style={{
              fontWeight: "600",
              margin: "15px auto",
            }}
          >
            דף נחיתה לכל נכס{" "}
          </RespFont>
          <RespFont>
            תוכל לרכז את כל הנכסים שלך למכירה או השכרה במקום אחד- כולל פירוט,
            תמונות ,סרטונים וכפתור ליצירת קשר (לידים)
          </RespFont>
        </Ser>
        <Ser>
          <img src={s5} style={{ width: "100px" }} />
          <RespFont
            cSize={"22px"}
            pSize={"20px"}
            style={{
              fontWeight: "600",
              margin: "15px auto",
            }}
          >
            התראות בזמן אמת ללקוחותיך
          </RespFont>
          <RespFont>
            הלקוח הקיים/הפוטנציאלי יכול להגדיר את הנכס שהוא מחפש וכאשר תוסיף נכס
            העונה למבוקשו - מיד יקבל הודעה על כך עם קישור לנכס
          </RespFont>
        </Ser>
      </FlexRowColumn>

      <FlexRowColumn>
        <Ser>
          <img src={s4} style={{ width: "90px" }} />
          <RespFont
            cSize={"22px"}
            pSize={"20px"}
            style={{
              fontWeight: "600",
              margin: "15px auto",
            }}
          >
            חדשות נדלן/מאמרים
          </RespFont>
          <RespFont>
            הגיע הזמן שתנגיש ללקוחותיך ולכלל האוכלוסיה את הידע שלך במקום אחד
            מרוכז, תוכל לכתוב, לנהל ולהוסיף כתבות בלחיצת כפתור
          </RespFont>
        </Ser>
        <Ser style={{ marginRight: "10px", marginLeft: "10px" }}>
          <img src={s3} style={{ width: "100px" }} />
          <RespFont
            cSize={"22px"}
            pSize={"20px"}
            style={{
              fontWeight: "600",
              margin: "15px auto",
            }}
          >
            חתימות דיגטליות
          </RespFont>
          <RespFont>
            אתם יכולים להשתמש בשירות שלנו גם כדי להחתים לקוחות בצורה דיגיטלית על
            מסמכים (למשל בלעדיות או כל חוזה או הסכם אחר)
          </RespFont>
        </Ser>
        <Ser>
          <img src={s2} style={{ width: "100px" }} />
          <RespFont
            cSize={"22px"}
            pSize={"20px"}
            style={{
              fontWeight: "600",
              margin: "15px auto",
            }}
          >
            המזכירה האישית שלך
          </RespFont>
          <RespFont>
            ניהול יומן משימות יומי/שבועי/חודשי, ניהול לקוחות על פי
            קבוצות/איזורים/מחירים, זימון פגישות ושליחת עדכונים אוטומטיים עם כל
            שינוי
          </RespFont>
        </Ser>
      </FlexRowColumn>
    </div>
  );
};

export default Services;
