import React from "react";
import { CenteredForm, RespFont } from "../Style";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import { GetToast } from "./Toast";
import { useToasts } from "react-toast-notifications";
import copy from "../../../assets/nadlan/copy.svg";
import instagram from "../../../assets/nadlan/instagram.svg";
import {
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";

import "./style.css";
import { PopupForm } from "../../Style";
import { GetToast } from "../modals/Toast";
const ShareLink = (props) => {
  const { addToast } = useToasts();

  return (
    <PopupForm onClick={(e) => e.stopPropagation()}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
          padding: "30px 10px",
          //   paddingTop: "10px",
        }}
      >
        <CopyToClipboard
          text={encodeURI(props.link)}
          onCopy={() => GetToast(addToast, "info", "הקישור הועתק בהצלחה!")}
        >
          <div
            style={{
              padding: "10px 10px",
              boxShadow: "0 2px 4px silver",
              borderRadius: "5px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>העתקת קישור</div>
            <img
              alt={"copy"}
              src={copy}
              style={{ width: "30px", marginRight: "15px" }}
            />
          </div>
        </CopyToClipboard>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
          padding: "40px 10px",
          paddingTop: "10px",
        }}
      >
        <div className="Demo__some-network">
          <TwitterShareButton
            url={encodeURI(props.link)}
            className="Demo__some-network__share-button"
          >
            <TwitterIcon size={40} borderRadius={5} />
          </TwitterShareButton>
        </div>
        <div className="Demo__some-network">
          <WhatsappShareButton
            url={encodeURI(props.link)}
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={40} borderRadius={5} />
          </WhatsappShareButton>
        </div>
        <div className="Demo__some-network">
          <FacebookShareButton
            url={encodeURI(props.link)}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={40} borderRadius={5} />
          </FacebookShareButton>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
          padding: "40px 10px",
          paddingTop: "10px",
        }}
      >
        <div className="Demo__some-network">
          <EmailShareButton
            url={encodeURI(props.link)}
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={40} borderRadius={5} />
          </EmailShareButton>
        </div>
        <div className="Demo__some-network">
          <LinkedinShareButton
            url={encodeURI(props.link)}
            className="Demo__some-network__share-button"
          >
            <LinkedinIcon size={40} borderRadius={5} />
          </LinkedinShareButton>
        </div>
        <div className="Demo__some-network">
          <TelegramShareButton
            url={encodeURI(props.link)}
            className="Demo__some-network__share-button"
          >
            <TelegramIcon size={40} borderRadius={5} />
          </TelegramShareButton>
        </div>
      </div>
    </PopupForm>
  );
};
export default ShareLink;
