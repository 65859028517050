import React from "react";
import {
  DisplayNoneComputer,
  FlexRowBetween,
  FlexRowColumn,
  RespFont,
} from "../Style";
import { useSelector } from "react-redux";
import WaitingListForm from "./WaitingListForm";
import { constants, Section } from "../../Style";
import GenericSvg from "../footer/coloredSvgs/GenericSvg";
import { svgs, zaxSvgs } from "../footer/coloredSvgs/svgs";
import CommonEdit from "../others/CommonEdit";
import { handleClickScrollXY } from "../modals/Toast";

const Waitings = ({ setEditContacts, editContacts }) => {
  const { user, authenticated } = useSelector((state) => state.authReducer);
  const cP = user.contactPhone !== "" ? user.contactPhone : user.phone;
  let widthS = window.innerWidth; // 1728

  return (
    <FlexRowColumn
      id="waiting-list-client-id"
      style={{
        backgroundColor: user.settings.pagesColors.page7,
        justifyContent: "space-evenly",
        padding: "100px 0",
        position: "relative",
      }}
    >
      {authenticated && (
        <CommonEdit
          bottom={"30px"}
          left={null}
          right={"30px"}
          func={() => {
            if (!editContacts) handleClickScrollXY(900);
            setEditContacts(!editContacts);
          }}
          color={"rgba(255,255,255,0.99)"}
          pen={user.settings.pagesColors.page7}
        />
      )}
      <WaitingListForm />
      <DisplayNoneComputer style={{ marginTop: "25px" }} />

      <div style={{ color: "white" }}>
        <Section cSize={"26px"} pSize={"19px"}>
          {user.settings.contacts.nameAndSubject}
        </Section>
        {user.settings.contacts.officeAddress !== "" && (
          <FlexRowBetween
            style={{
              width: "max-content",
              marginRight: "0px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                padding: "11px 11px",
                borderRadius: "50%",
                backgroundColor: "#fff",
              }}
            >
              <GenericSvg
                fill={user.settings.pagesColors.page7}
                size={"25px"}
                svg={zaxSvgs.location}
                viewBox={"0 0 16 19"}
              />
            </div>
            <RespFont>{user.settings.contacts.officeAddress}</RespFont>
          </FlexRowBetween>
        )}
        <FlexRowBetween
          style={{
            width: "max-content",
            marginRight: "0px",
            marginTop: "20px",
          }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={`tel:${cP}`}
            style={{
              marginLeft: "10px",
              padding: "11px 11px",
              borderRadius: "50%",
              backgroundColor: "#fff",
            }}
          >
            <GenericSvg
              fill={user.settings.pagesColors.page7}
              size={widthS > 768 ? "25px" : "20px"}
              svg={zaxSvgs.mobilePhone}
              viewBox={"0 0 21 33"}
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`tel:${cP}`}
            style={{
              textDecoration: "none",
              color: "#fff",
            }}
          >
            <RespFont>{cP}</RespFont>
          </a>
        </FlexRowBetween>
        {user.settings.contacts.email !== "" && (
          <FlexRowBetween
            style={{
              width: "max-content",
              marginRight: "0px",
              marginTop: "20px",
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={`mailto:${user.settings.contacts.email}`}
              style={{
                marginLeft: "10px",
                padding: "11px 11px",
                borderRadius: "50%",
                backgroundColor: "#fff",
              }}
            >
              <GenericSvg
                fill={user.settings.pagesColors.page7}
                size={widthS > 768 ? "25px" : "20px"}
                svg={zaxSvgs.email}
                viewBox={"0 0 25 21"}
              />
            </a>
            <RespFont>{user.settings.contacts.email}</RespFont>
          </FlexRowBetween>
        )}
        <FlexRowBetween
          style={{
            width: "max-content",
            marginRight: "5px",
            marginTop: "25px",
          }}
        >
          {user.settings.contacts.facebook !== "" && (
            <a
              target="_blank"
              style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
              rel="noreferrer"
              href={user.settings.contacts.facebook}
            >
              <GenericSvg
                fill={"#fff"}
                size={widthS > 768 ? "35.5px" : "25.5px"}
                svg={zaxSvgs.facebook}
                viewBox={"0 0 56 57"}
              />
            </a>
          )}
          {user.settings.contacts.instagram !== "" && (
            <a
              target="_blank"
              style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
              rel="noreferrer"
              href={user.settings.contacts.instagram}
            >
              <GenericSvg
                fill={"#fff"}
                size={widthS > 768 ? "35px" : "25px"}
                svg={zaxSvgs.instagram}
                viewBox={"0 0 60 60"}
              />
            </a>
          )}

          <a
            target="_blank"
            style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
            rel="noreferrer"
            href={`https://wa.me/972${cP}/?text=`}
          >
            <GenericSvg
              fill={"#fff"}
              size={widthS > 768 ? "35px" : "25px"}
              svg={svgs.whatsapp}
              viewBox={"0 0 24 24"}
            />
          </a>
          {user.settings.contacts.officeWaze !== "" && (
            <a
              target="_blank"
              style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
              rel="noreferrer"
              href={user.settings.contacts.officeWaze}
            >
              <GenericSvg
                fill={"#fff"}
                size={widthS > 768 ? "35px" : "25px"}
                svg={zaxSvgs.waze}
                viewBox={"0 0 61 55"}
              />
            </a>
          )}
          {user.settings.contacts.twitter !== "" && (
            <a
              target="_blank"
              style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
              rel="noreferrer"
              href={user.settings.contacts.twitter}
            >
              <GenericSvg
                fill={"#fff"}
                size={widthS > 768 ? "35px" : "25px"}
                svg={zaxSvgs.twitter}
                viewBox={"0 0 58 48"}
              />
            </a>
          )}
          {user.settings.contacts.linkedin !== "" && (
            <a
              target="_blank"
              style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
              rel="noreferrer"
              href={user.settings.contacts.linkedin}
            >
              <GenericSvg
                fill={"#fff"}
                size={widthS > 768 ? "35px" : "25px"}
                svg={zaxSvgs.linkedin}
                viewBox={"0 0 56 56"}
              />
            </a>
          )}
        </FlexRowBetween>
      </div>
    </FlexRowColumn>
  );
};

export default Waitings;
