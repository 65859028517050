import React, { useEffect } from "react";
import { PopupForm } from "../../Style";
import queryString from "query-string";
import { getImageUrl } from "../../common/modals/Toast";

const PDF = (props) => {
  console.log("ddd");
  let params = queryString.parse(props.location.search);
  const { uid, docId, fullname } = params;
  // console.log(`${getImageUrl()}${uid}/docs/${docId}/doc.png`);
  useEffect(() => {
    handlePrint();
  }, []);
  const handlePrint = () => {
    document.title = fullname + " בלעדיות";
    setTimeout(function () {
      window.print();
    }, 1000);
    setTimeout(function () {
      window.close();
    }, 3000);
  };
  return (
    <img
      src={`${getImageUrl()}${uid}/docs/${docId}/doc.png`}
      style={{ width: "100%", height: "auto" }}
    />
  );
};

export default PDF;
