import React, { useState } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  RespFont,
} from "../../Style";
import { useForm } from "react-hook-form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";
import { addAsset } from "../../../store/reducers/assetsReducer";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import Switcher from "../../common/switcher/Switcher";
import {
  FlexRowStart,
  HomeInput,
  HomeTextArea,
  RTextArea,
} from "../../common/Style";

const Add = ({ init }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { assetsLoading } = useSelector((state) => state.assetsReducer);
  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm();
  const [saleOrRentOrSold, setSaleOrRentOrSold] = useState(1); // 1 for sale, 2 for rent, 3 for sold
  const [kind, setKind] = useState(1); // 1 - prati, 2 - dira, 3 - migrash, 4 - aher

  const onSubmit = async (data) => {
    const body = {
      name: data.name,
      city: data.city,
      address: data.address,
      squareMeters: data.squareMeters,
      bathroom: data.bathroom,
      phone:
        data.phone !== undefined
          ? data.phone
          : user.contactPhone !== ""
          ? user.contactPhone
          : user.phone,
      rooms: data.rooms,
      waze: data.waze,
      description: data.description,
      price: data.price,
      saleOrRentOrSold,
      kind,
    };
    dispatch(addAsset(body)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "90%",
          margin: "10px auto",
        }}
      >
        <FlexRowBetween style={{ margin: "0px auto" }}>
          <div style={{ width: "60%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שם / כותרת
            </RespFont>
            <HomeInput
              type="text"
              {...register("name", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("name", "בין 2 ל 50 תווים", errors)}
          </div>
          <div style={{ width: "35%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מחיר (₪)
            </RespFont>
            <HomeInput
              type="number"
              name="price"
              {...register("price", {
                required: true,
                min: 1,
              })}
            />
            {CFV("price", "מינימום 1", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "0px auto" }}>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              עיר
            </RespFont>
            <HomeInput
              type={"text"}
              {...register("city", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("city", "בין 2 ל 50 תווים", errors)}
          </div>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              כתובת
            </RespFont>
            <HomeInput
              type={"text"}
              {...register("address", {
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
            />
            {CFV("address", "בין 2 ל 30 תווים", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "0px auto" }}>
          <div style={{ width: "20%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מ״ר
            </RespFont>
            <HomeInput
              type="number"
              {...register("squareMeters", {
                required: true,
                min: 2,
                max: 500000,
              })}
            />
            {CFV("squareMeters", "מספר 2 ל 50", errors)}
          </div>
          <div style={{ width: "20%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              חדרים
            </RespFont>
            <HomeInput
              type="number"
              {...register("rooms", {
                required: true,
                min: 0,
                max: 50,
              })}
            />
            {CFV("rooms", "מספר 0 ל 50", errors)}
          </div>
          <div style={{ width: "20%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שירותים
            </RespFont>
            <HomeInput
              type="number"
              {...register("bathroom", {
                required: true,
                min: 0,
                max: 50,
              })}
            />
            {CFV("bathroom", "מספר 0 ל 50", errors)}
          </div>
        </FlexRowBetween>
        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          נייד ליצירת קשר עבור נכס זה
        </RespFont>

        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip>{"שינוי מתאפשר רק עבור חברה - פנו למנהל המערכת"}</Tooltip>
          }
        >
          <HomeInput
            disabled={!user.isCompany}
            defaultValue={
              user.contactPhone !== "" ? user.contactPhone : user.phone
            }
            {...register("phone", {
              // required: true,
              pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
            })}
          />
        </OverlayTrigger>
        {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)}
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            ניווט
          </RespFont>
          <HomeInput
            name="waze"
            type={"text"}
            // pattern="https://waze.com/ul/[a-zA-Z0-9]{10}"
            {...register("waze", {
              minLength: 2,
              maxLength: 50,
            })}
          />
          {CFV("waze", "בין 2 ל 50 תווים", errors)}
        </div>
        <FlexRowStart style={{ marginBottom: "20px" }}>
          <RespFont style={{ fontFamily: "hebM" }}>סטטוס:</RespFont>
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "60px",
              padding: "5px",
              fontWeight: "700",
              marginRight: "20px",
            }}
          >
            <RespFont
              onClick={() => setSaleOrRentOrSold(1)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  saleOrRentOrSold === 1
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color: saleOrRentOrSold === 1 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              למכירה
            </RespFont>
            <RespFont
              onClick={() => setSaleOrRentOrSold(2)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  saleOrRentOrSold === 2
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color: saleOrRentOrSold === 2 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              להשכרה
            </RespFont>
            <RespFont
              onClick={() => setSaleOrRentOrSold(3)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  saleOrRentOrSold === 3
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color: saleOrRentOrSold === 3 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              נסגרו
            </RespFont>
          </FlexRowBetween>
        </FlexRowStart>
        <FlexRowStart style={{ marginBottom: "20px" }}>
          <RespFont style={{ fontFamily: "hebM" }}>סוג הנכס:</RespFont>
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "60px",
              padding: "5px",
              fontWeight: "700",
              marginRight: "20px",
            }}
          >
            <RespFont
              onClick={() => setKind(1)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  kind === 1 ? user.settings.colors.buttonsColor : "inherit",
                transition: "all 0.2s",
                color: kind === 1 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              פרטי
            </RespFont>
            <RespFont
              onClick={() => setKind(2)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  kind === 2 ? user.settings.colors.buttonsColor : "inherit",
                transition: "all 0.2s",
                color: kind === 2 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              דירה
            </RespFont>
            <RespFont
              onClick={() => setKind(3)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  kind === 3 ? user.settings.colors.buttonsColor : "inherit",
                transition: "all 0.2s",
                color: kind === 3 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              מגרש
            </RespFont>
            <RespFont
              onClick={() => setKind(4)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  kind === 4 ? user.settings.colors.buttonsColor : "inherit",
                transition: "all 0.2s",
                color: kind === 4 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              אחר
            </RespFont>
          </FlexRowBetween>
        </FlexRowStart>

        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          תיאור הנכס
        </RespFont>
        <HomeTextArea
          color={constants.fontColorZax}
          name="description"
          type="text"
          rows="3"
          style={{ margin: "0px auto" }}
          {...register("description", {
            required: true,
            minLength: 0,
            maxLength: 500,
          })}
        />
        {CFV("description", "עד 500 תווים", errors)}
        <GenericButton
          form="form1"
          background={user.settings.colors.buttonsColor}
          color={"#fff"}
          type="submit"
          style={{
            width: "100%",
            margin: "0px auto",
            padding: "10px",
            borderRadius: "5px",
            fontWeight: "bolder",
            fontSize: "16px",
            boxShadow: "none",
            marginTop: "25px",
          }}
        >
          הוספה
        </GenericButton>
      </div>
      {assetsLoading && <OpacityLoading />}
    </PopupForm>
  );
};

export default Add;
