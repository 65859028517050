import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { uploadDocPhotoPdf } from "../../../store/reducers/photosReducer";
import { GetToast } from "../../common/modals/Toast";
import "../style.css";
import imageCompression from "browser-image-compression";
import { GenericButton, PopupForm } from "../../Style";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { editDoc, editDocPic } from "../../../store/reducers/docsReducer";
import { editArticle } from "../../../store/reducers/articleReducer";
import ExitModal from "../../common/modals/ExitModal";
const UploadDocsPhotos = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.authReducer);

  async function postImage(image) {
    image = new File([image], props.fullname + ".pdf", {
      type: image.type,
    });
    const formData = new FormData();
    formData.append("image", image);
    setLoading(true);

    dispatch(uploadDocPhotoPdf(formData, user._id + "-" + props.id)).then(
      (data) => {
        if (image.name === data)
          dispatch(editDocPic({ pic: data }, props.id)).then((res) => {
            if (!res.payload?.error) {
              GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
              props.handleInit();
            }
          });
        else
          GetToast(
            addToast,
            "error",
            "הפעולה כשלה, נסה/י שנית או פנה/י לתמיכה"
          );
      }
    );
  }

  const submit = async (event) => {
    event.preventDefault();
    await postImage(file);
  };

  const fileSelected = async (event) => {
    let imageFile = event.target.files[0];
    if (imageFile.size > 1000000) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        setLoading(true);
        const compressedFile = await imageCompression(imageFile, options);
        setLoading(false);
        imageFile = new File([compressedFile], imageFile.name, {
          type: compressedFile.type,
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    setFile(imageFile);
  };

  return (
    <PopupForm onClick={(e) => e.stopPropagation()}>
      {/* <GenericSvg fill={user.controls} size={"20px"} svg={svgs.facebook} viewBox={"0 0 24 24"} /> */}
      {/* <PlaceHolderImage src={exitModalLogo} alt="exitModalLogo" style={{ top: "8px", left: "8px", transform: "translate(0,0)", width: "32px", cursor: "pointer" }} onClick={() => props.init(null)} /> */}
      <ExitModal init={props.init} color={user.settings.colors.buttonsColor} />
      <div style={{ marginTop: "5vh", fontSize: "1.1rem" }}>העלאת מסמך</div>
      <label
        htmlFor="file-upload"
        className="custom-file-upload"
        style={{ marginBottom: "5vh" }}
      >
        בחירת מסמך
      </label>
      <input
        id="file-upload"
        type="file"
        onChange={fileSelected}
        accept="application/pdf"
      />
      {file !== null && (
        <GenericButton
          hoverBackground={"rgb(223, 223, 223)"}
          background={props.color}
          style={{
            borderRadius: "0px",
            width: "100%",
            padding: "13px",
            fontSize: "16px",
          }}
          onClick={(e) => submit(e)}
        >
          שמירה
        </GenericButton>
      )}
      {loading && <OpacityLoading />}
    </PopupForm>
  );
};

export default UploadDocsPhotos;
