import React, { useEffect, useState, useMemo } from "react";
import { getAll, cleanAll } from "../../../store/reducers/qaaReducer";
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import TitleAndContentWithFooter from "../../common/modals/TitleAndContentWithFooter";
import { Questions, AssetsHolder, GenericDiv, PageTitle } from "../../Style";
import GenericModal from "../../common/modals/GenericModal";
import { GetToast } from "../../common/modals/Toast";
import plus from '../../../assets/nadlan/plus.svg'
import Edit from "./Edit";
import Add from "./Add";
import GenericAccordion from "../../common/others/GenericAccordion";

const Qaa = () => {
    const dispatch = useDispatch()
    const { addToast } = useToasts();
    const { qaa, qaaError, getQaaLoading } = useSelector(state => state.qaaReducer);
    const { user } = useSelector(state => state.authReducer);
    const [ans, setAns] = useState(null)
    const [editQaa, setEditQaa] = useState(false);
    const [addQaa, setAddQaa] = useState(false)

    let height = window.innerHeight;

    useEffect(() => {
        dispatch(getAll())
        return () => {
            dispatch(cleanAll());
        }
    }, []);

    const onEditHandle = () => {
        setEditQaa(ans)
    }

    const onInitHandle = () => {
        setEditQaa(false)
        setAns(null)
    }

    let qaaArray = useMemo(() => {
        let fff = []
        if (Object.keys(qaa).length > 0)
            for (const [key, value] of Object.entries(qaa))
                fff.push(<Questions key={key} onClick={() => setAns(value)}>{value.ques}</Questions>)
        return fff
    }, [qaa])

    // let qaaArray = useMemo(() => {
    //     let fff = []
    //     if (Object.keys(qaa).length > 0)
    //         for (const [key, value] of Object.entries(qaa))
    //             fff.push({'title': value.ques, 'content': value.ans})
    //     return fff
    // }, [qaa])

    

    return (
        <div id="qaaA-id" style={{ width: "100%", margin: "0 auto", position: "relative" }}>
            <PageTitle pWidth={"158px"} cWidth={"220px"} borderBottom={user.controls}>שאלות ותשובות</PageTitle>
            <GenericDiv background={user.controls} onClick={() => setAddQaa(true)}
                style={{ width: "min-content", padding: "5px 5px", marginRight: "5%" }}>
                <img src={plus} alt="plus" style={{ width: "16px" }} /></GenericDiv>
            <AssetsHolder style={{ height: `${(height - 150)}px`, marginTop: "10px", paddingBottom: "55px" }}>
                {qaaArray}
            </AssetsHolder>
            {
                ans && <GenericModal
                    init={setAns}
                    div={<TitleAndContentWithFooter title={ans.ques} content={ans.ans} id={ans._id} init={setAns} color={user.controls} onEdit={onEditHandle} />}
                />
            }

            {addQaa && <GenericModal init={setAddQaa} div={<Add init={setAddQaa} />} />}
            {editQaa && <GenericModal init={setEditQaa} div={<Edit data={editQaa} init={onInitHandle} />} />}
        </div>
    )
    // return(<div id={"quesAndAns-id"} style={{width:"80%", margin:"80px auto"}}>
    //     <GenericAccordion list={qaaArray} />
    //     </div>)
}
export default Qaa;