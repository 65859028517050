import styled from "styled-components";

const media = {
  inv: "@media only screen and (max-width:500px)",
  phone: "@media only screen and (max-width:480px)",
  phoneAndTablet: "@media only screen and (max-width:768px)",
  tablet: "@media only screen and (min-width:481px) and (max-width:768px)",
  bigTablet: "@media only screen and (min-width:769px) and (max-width:1024px)",
  comp: "@media only screen and (min-width:1025px)",
};

export const CartStyle = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: orange;
  box-shadow: 0 1px 3px silver;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CartAmount = styled(CartStyle)`
  position: fixed;
  bottom: 60px;
  right: 15px;
  height: 20px;
  width: 20px;
  font-size: 15px;
  text-align: center;
  background: #f2f2f2;
  color: orange;
  box-shadow: 0 -1px 2px #f2f2f2, 0 -1px 2px orange;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
`;

export const ServiceOption = styled.div`
  width: 96%;
  margin: 10px auto;
  position: relative;
  cursor: pointer;
  font-family: openSansRegular;
  border-radius: 5px;
  text-align: center;
  padding: 8px 0;
  background: ${(props) =>
    props.include ? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.4)"};
  color: ${(props) => (props.include ? "#3A3C3F" : "white")};
  text-decoration: ${(props) => (props.include ? "none" : "line-through")};
  text-decoration-thickness: 1px;
`;

export const MainRegister = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px;
  ${media.phoneAndTablet} {
    flex-direction: column;
  }
  div {
    margin: 12px auto;
    width: 40%;
    ${media.phoneAndTablet} {
      width: 90% !important;
    }
    button {
      width: 88%;
      ${media.phoneAndTablet} {
        width: 100% !important;
      }
    }
  }
  input {
    width: 40%;
    ${media.phoneAndTablet} {
      width: 45%;
      margin: 0 !important;
    }
  }
`;

export const CenteredFormPage1 = styled.form`
  width: 80%;
  background: rgba(255, 255, 255, 0.5);
  font-family: openSansRegular;
  border-radius: 5px;
  ${media.bigTablet} {
    width: 50%;
  }
  ${media.tablet} {
    width: 60%;
    border-radius: 20px;
  }
  ${media.phone} {
    width: 80%;
    border-radius: 20px;
  }
  flex-direction: column;
  box-shadow: 5px 20px 50px silver;
  display: flex;
  position: relative;
  transition: all 0.2s;
`;

export const CenteredFormPage2 = styled(CenteredFormPage1)`
  width: 30%;
  background: white;
  border-radius: 5px;
  ${media.phoneAndTablet} {
    width: 85%;
    border-radius: 10px;
  }
  flex-direction: column;
  box-shadow: 5px 20px 50px silver;
  display: flex;
  position: relative;
  transition: all 0.2s;
`;
