import React, { useState } from "react";
import whatsapp from "../../../assets/nadlan/whatsapp.svg";
import { RespFont } from "../../Style";

const Contact = () => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://wa.me/972557112993/?text=${"היי, "}`}
      style={{
        position: "fixed",
        bottom: "50px",
        left: "20px",
        zIndex: "99999",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        background: "rgba(255,255,255,0.9)",
        borderRadius: "50px",
        padding: "10px",
        boxShadow: "0 1px 2px gray",
        textDecoration: "none",
      }}
    >
      <img alt="whatsapp" src={whatsapp} style={{ width: "24px" }} />
    </a>
  );
};

export default Contact;
