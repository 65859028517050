import React, { useEffect, useState, useMemo, useRef } from "react";
import { getAllPayments } from "../../store/reducers/paymentsReducer";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../../store/reducers/userReducer";
import {
  constants,
  FlexRowBetween,
  PlaceHolderImage,
  RespFont,
  TextWithPic,
} from "../Style";
import { useToasts } from "react-toast-notifications";
import { GuestRowStyle, GuestsHolder, HomeInput } from "../common/Style";
import clear from "../../assets/nadlan/clear.svg";
import downArrowB from "../../assets/nadlan/downArrowB.svg";
import downArrowW from "../../assets/nadlan/downArrowW.svg";
import upArrowB from "../../assets/nadlan/upArrowB.svg";
import logout1 from "../../assets/nadlan/logout.svg";
import upArrowW from "../../assets/nadlan/upArrowW.svg";
import { addAlpha, GetToast, parseDate } from "../common/modals/Toast";
import GenericModal from "../common/modals/GenericModal";
import Edit from "./Edit";
import OpacityLoading from "../common/loader/OpacityLoading";
import LogOutAll from "../common/LogOut/LogOutAll";

const MainAdmin = () => {
  const [sortBy, setSortBy] = useState("purchaseEnd");
  const [logout, setLogout] = useState(false);

  const [editUser, setEditUser] = useState(null);
  const [page, setPage] = useState(true); // true for users and false for transatctions
  const { user, authenticated } = useSelector((state) => state.authReducer);
  const [sortDirection, setSortDirection] = useState(false);
  let widthS = window.innerWidth; // 1728
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef();

  const { addToast } = useToasts();
  const { users, usersError, usersLoading } = useSelector(
    (state) => state.userReducer
  );
  const { payments, paymentsError, paymentsLoading } = useSelector(
    (state) => state.paymentsReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers()).then((data) => {
      if (!data.payload.error) dispatch(getAllPayments());
    });
    return () => {
      //   dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (usersError) GetToast(addToast, "error", usersError);
  }, [usersError]);

  useEffect(() => {
    if (paymentsError) GetToast(addToast, "error", paymentsError);
  }, [paymentsError]);

  let usersArray = useMemo(() => {
    let arr = [];
    let arrP = [];

    let sortedArray = [];
    if (Object.keys(users).length > 0 && Object.keys(payments).length > 0) {
      for (const [key, value] of Object.entries(users))
        if (
          value.fullname.includes(searchText) ||
          value._id.includes(searchText) ||
          searchText === ""
        )
          sortedArray.push(value);
      switch (sortBy) {
        case "purchaseEnd":
        case "purchaseStart":
          if (sortDirection)
            sortedArray.sort(
              (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
            );
          else
            sortedArray.sort(
              (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
            );
          break;
        case "paymentTag":
          if (sortDirection)
            sortedArray.sort((a, b) => Number(b[sortBy]) - Number(a[sortBy]));
          else
            sortedArray.sort((a, b) => Number(a[sortBy]) - Number(b[sortBy]));
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
          else sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
      }

      for (let i = 0; i < sortedArray.length; i++) {
        arrP.push(
          <GuestRowStyle onClick={() => setEditUser(sortedArray[i])}>
            <RespFont pSize={"12px"} cSize={"14px"} style={{ width: "100%" }}>
              <FlexRowBetween
                style={{
                  padding: `10px 7px`,
                  width: "100%",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                <div>{sortedArray[i].fullname}</div>
                {sortBy === "purchaseEnd" ? (
                  <div>{parseDate(sortedArray[i]?.purchaseEnd)}</div>
                ) : sortBy === "purchaseStart" ? (
                  <div>{parseDate(sortedArray[i]?.purchaseStart)}</div>
                ) : sortBy === "paymentTag" ? (
                  <div>
                    {sortedArray[i].paymentTag === 1
                      ? "בסיסי"
                      : sortedArray[i].paymentTag === 2
                      ? "אישי"
                      : "vip"}
                  </div>
                ) : (
                  <div>{sortedArray[i].approved ? "כן" : "לא"}</div>
                )}
                {/* <div>{sortedArray[i].fullname}</div> */}
              </FlexRowBetween>
            </RespFont>
          </GuestRowStyle>
        );
      }
    }
    return arrP;
  }, [users, sortBy, sortDirection, searchText, payments]);

  let paymentsArray = useMemo(() => {
    let arr = [];
    let arrP = [];

    let sortedArray = [];
    if (Object.keys(payments).length > 0) {
      for (const [key, value] of Object.entries(payments))
        if (value.transactionNumber.includes(searchText) || searchText === "")
          sortedArray.push(value);
      // switch (sortBy) {
      //   case "purchaseEnd":
      //   case "purchaseStart":
      //     if (sortDirection)
      //       sortedArray.sort(
      //         (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
      //       );
      //     else
      //       sortedArray.sort(
      //         (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
      //       );
      //     break;
      //   case "paymentTag":
      //     if (sortDirection)
      //       sortedArray.sort((a, b) => Number(b[sortBy]) - Number(a[sortBy]));
      //     else
      //       sortedArray.sort((a, b) => Number(a[sortBy]) - Number(b[sortBy]));
      //     break;
      //   default:
      //     if (sortDirection) sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
      //     else sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
      // }

      for (let i = 0; i < sortedArray.length; i++) {
        arrP.push(
          <GuestRowStyle>
            <RespFont pSize={"12px"} cSize={"14px"} style={{ width: "100%" }}>
              <FlexRowBetween
                style={{
                  padding: `10px 7px`,
                  width: "100%",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                <div>{users[sortedArray[i]?.uid]["fullname"]}</div>
                <div>
                  <div
                    style={{
                      padding: "2px 4px",
                      marginLeft: "-7px",
                      backgroundColor: addAlpha(
                        user.settings.colors.buttonsColor,
                        0.1
                      ),
                      marginTop: "-10px",
                      marginRight: "-10px",
                      borderRadius: "5px 0px 5px 0px",
                      marginBottom: "3px",
                    }}
                  >
                    {parseDate(sortedArray[i].dateAdded)}
                  </div>
                  <div style={{ marginBottom: "-5px" }}>
                    {sortedArray[i].transactionNumber}
                  </div>
                </div>

                {/* <div>{sortedArray[i].fullname}</div> */}
              </FlexRowBetween>
            </RespFont>
          </GuestRowStyle>
        );
      }
    }
    return arrP;
  }, [payments, sortBy, sortDirection, searchText]);

  return (
    <div>
      <TextWithPic onClick={() => setLogout(true)}>
        <RespFont style={{ width: "max-content" }}>התנתקות</RespFont>
        <img
          src={logout1}
          alt={"logout1"}
          style={{ width: "20px", marginRight: "10px" }}
        />
      </TextWithPic>
      <FlexRowBetween
        style={{
          justifyContent: "space-evenly",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div
          onClick={() => setPage(true)}
          style={{
            padding: "7px 12px",
            cursor: "pointer",
            backgroundColor: !page
              ? addAlpha(user.settings.colors.buttonsColor, 0.5)
              : user.settings.colors.buttonsColor,
            color: "white",
            borderRadius: "3px",
            boxShadow: "0 1px 2px silver",
          }}
        >
          משתמשים
        </div>
        <div
          onClick={() => setPage(false)}
          style={{
            padding: "7px 12px",
            cursor: "pointer",
            backgroundColor: page
              ? addAlpha(user.settings.colors.buttonsColor, 0.5)
              : user.settings.colors.buttonsColor,
            color: "white",
            borderRadius: "3px",
            boxShadow: "0 1px 2px silver",
            marginRight: "20px",
          }}
        >
          עסקאות
        </div>
      </FlexRowBetween>
      {page && (
        <FlexRowBetween style={{ justifyContent: "flex-start" }}>
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "60px",
              padding: widthS > 768 ? "5px" : "3px",
              fontWeight: "700",
              marginRight: "1%",
              marginLeft: "0px",
            }}
          >
            <RespFont
              pSize={"12px"}
              cSize={"14px"}
              onClick={() => setSortBy("purchaseEnd")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                width: "max-content",
                backgroundColor:
                  sortBy === "purchaseEnd"
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color:
                  sortBy === "purchaseEnd" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              סיום
            </RespFont>
            <RespFont
              pSize={"12px"}
              cSize={"14px"}
              onClick={() => setSortBy("purchaseStart")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                backgroundColor:
                  sortBy === "purchaseStart"
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color:
                  sortBy === "purchaseStart" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              התחלה
            </RespFont>
            <RespFont
              pSize={"12px"}
              cSize={"14px"}
              onClick={() => setSortBy("paymentTag")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                backgroundColor:
                  sortBy === "paymentTag"
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color:
                  sortBy === "paymentTag" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              חבילה
            </RespFont>
            <RespFont
              pSize={"12px"}
              cSize={"14px"}
              onClick={() => setSortBy("approved")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                width: "max-content",
                backgroundColor:
                  sortBy === "approved"
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color: sortBy === "approved" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              מאושר?
            </RespFont>
          </FlexRowBetween>
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "50px",
              padding: widthS > 768 ? "5px" : "3px",
              fontWeight: "700",
              marginRight: "30px",
            }}
          >
            <RespFont
              pSize={"12px"}
              cSize={"14px"}
              onClick={() => setSortDirection(true)}
              style={{
                padding: widthS > 768 ? "6px 10px" : "3px 5px",
                cursor: "pointer",
                backgroundColor: sortDirection
                  ? user.settings.colors.buttonsColor
                  : "inherit",
                transition: "all 0.2s",
                color: sortDirection ? "#fff" : constants.fontColorZax,
                borderRadius: "50px",
                width: "max-content",
              }}
            >
              <img
                src={sortDirection ? upArrowW : upArrowB}
                style={{ width: widthS > 768 ? "21px" : "13px" }}
              />
            </RespFont>
            <RespFont
              pSize={"12px"}
              cSize={"14px"}
              onClick={() => setSortDirection(false)}
              style={{
                padding: widthS > 768 ? "6px 10px" : "3px 5px",
                cursor: "pointer",
                backgroundColor: !sortDirection
                  ? user.settings.colors.buttonsColor
                  : "inherit",
                transition: "all 0.2s",
                color: !sortDirection ? "#fff" : constants.fontColorZax,
                borderRadius: "50px",
                width: "max-content",
              }}
            >
              <img
                src={sortDirection ? downArrowB : downArrowW}
                style={{ width: widthS > 768 ? "21px" : "13px" }}
              />
            </RespFont>
          </FlexRowBetween>
        </FlexRowBetween>
      )}
      <div
        style={{
          position: "relative",
          width: "98%",
          margin: "10px auto",
          maxWidth: "300px",
        }}
      >
        <PlaceHolderImage
          onClick={() => {
            setSearchText("");
            inputRef.current.focus();
          }}
          fontSize={`30px`}
          top={`50%`}
          src={clear}
          alt="clear"
          // style={{ cursor: "pointer", left:"7px" }}
          style={{
            cursor: "pointer",
            border: "1px solid silver",
            padding: "5px",
            borderRadius: "2px",
            left: "7px",
          }}
        />
        <HomeInput
          value={searchText}
          ref={inputRef}
          type="text"
          name="value"
          style={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          // let tempPhone = phone.replaceAll(/[^\d]/g, "");
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <GuestsHolder>
        {page ? (
          usersLoading ? (
            <OpacityLoading />
          ) : (
            usersArray
          )
        ) : paymentsLoading ? (
          <OpacityLoading />
        ) : (
          paymentsArray
        )}
      </GuestsHolder>

      {editUser && (
        <GenericModal
          init={setEditUser}
          div={<Edit data={editUser} init={setEditUser} />}
        />
      )}

      {logout && (
        <GenericModal init={setLogout} div={<LogOutAll init={setLogout} />} />
      )}
    </div>
  );
};

export default MainAdmin;
