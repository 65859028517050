import { createSlice } from "@reduxjs/toolkit";
import ServerRoutes from "../api/routes";
import { post2 } from "../api/http";

const initialState = {
  data: [],
  dataError: null,
  dataLoading: false,
  changeData: false,
};

const _actionStart = (state) => {
  state.dataLoading = true;
  state.dataError = null;
};
const _actionSuccess = (state) => {
  state.dataLoading = false;
  state.dataError = null;
};
const _actionFailed = (state, action) => {
  state.dataLoading = false;
  state.dataError = action.payload;
};

const _setData = (state, action) => {
  let tempData = action.payload;
  state.data = [...tempData];
  state.changeData = !state.changeData;
};

const _cleanData = (state, action) => {
  state.data = [];
  state.dataError = null;
  state.dataLoading = false;
};

const data = createSlice({
  name: "data",
  initialState,
  reducers: {
    setData: _setData,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
    cleanData: _cleanData,
  },
});

const { reducer, actions } = data;

export const { setData, actionStart, actionFailed, actionSuccess, cleanData } =
  actions;

export default reducer;

export const cleanAllData = () => {
  return async (dispatch) => {
    dispatch(cleanData());
  };
};

export const print = (body) => {
  return async (dispatch) => {
    dispatch(setData([]));
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Guests.print,
      body
    );
    if (status !== 200) {
      dispatch(setData([]));
      return dispatch(actionFailed({ error }));
    } else {
      dispatch(actionSuccess());
      return dispatch(setData(data));
    }
  };
};

export const reminder = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Client.reminder, body);
    if (status !== 200)
      return dispatch(actionFailed({ error, kind: "reminder" }));
    else {
      return dispatch(actionSuccess());
    }
  };
};

export const findDuplicates = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Guests.findDuplicates,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setData(data));
    }
  };
};

export const sms = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(ServerRoutes.Guests.sms, body);
    if (status !== 200) return dispatch(actionFailed({ error, kind: "sms" }));
    else {
      return dispatch(actionSuccess(data));
    }
  };
};

export const postCsv = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Guests.postCsv, body);
    if (status !== 200) return dispatch(actionFailed({ error, kind: "csv" }));
    else {
      return dispatch(actionSuccess());
    }
  };
};
