import React, { useEffect, useState, useMemo } from "react";
import { getAll, cleanAll } from "../../../store/reducers/articleReducer";
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import TitleAndContentWithFooter from "../../common/modals/TitleAndContentWithFooter";
import { Questions, AssetsHolder, GenericDiv, FlexRowBetween, Scale, PageTitle } from "../../Style";
import GenericModal from "../../common/modals/GenericModal";
import { GetToast } from "../../common/modals/Toast";
import plus from '../../../assets/nadlan/plus.svg'
import Edit from "./Edit";
import Add from "./Add";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import { svgs } from "../../common/footer/coloredSvgs/svgs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AssetsPhotos from "../assets/AssetsPhotos";
import OpacityLoading from "../../common/loader/OpacityLoading";
import ArticlesPhotos from "../assets/ArticlesPhotos";

const Articles = () => {
    const dispatch = useDispatch()
    const { addToast } = useToasts();
    const { article, articleError, getArticleLoading } = useSelector(state => state.articleReducer);
    const { user } = useSelector(state => state.authReducer);
    const [ans, setAns] = useState(null)
    const [editArticle, setEditArticle] = useState(false);
    const [addArticle, setAddArticle] = useState(false)
    const [showPhotos, setShowPhotos] = useState(false);

    let height = window.innerHeight;

    useEffect(() => {
        dispatch(getAll())
        return () => {
            dispatch(cleanAll());
        }
    }, []);

    const onEditHandle = () => {
        setEditArticle(ans)
    }

    const onInitHandle = () => {
        setEditArticle(false)
        setAns(null)
    }

    let articleArray = useMemo(() => {
        let fff = []
        if (Object.keys(article).length > 0)
            for (const [key, value] of Object.entries(article))
                fff.push(<Questions style={{ justifyContent: "space-between" }} key={key} onClick={() => setAns(value)}>
                    <div>{value.ques}</div>
                    <OverlayTrigger placement={'top'} overlay={<Tooltip>{"גלריה"}</Tooltip>}>
                        <div
                            onClick={(e) => { e.stopPropagation(); setShowPhotos({ photos: value.photos, assetId: value._id }) }}
                            style={{ marginRight: "20px", filter: "brightness(90%)", cursor: "pointer" }}>
                            <GenericSvg fill={user.controls} size={"30px"} svg={svgs.gallery} viewBox={"0 0 232.322 232.322"} />
                        </div>
                    </OverlayTrigger>
                </Questions>)
        return fff
    }, [article])

    return (
        <div id="articleA-id" style={{ width: "100%", margin: "0 auto", position: "relative" }}>
            <PageTitle borderBottom={user.controls}>כתבות</PageTitle>
            <FlexRowBetween style={{ width: "90%", margin: "10px auto", justifyContent: "flex-start" }}>
                <GenericDiv background={user.controls} onClick={() => setAddArticle(true)}
                    style={{ width: "min-content", padding: "5px 5px" }}>
                    <img src={plus} alt="plus" style={{ width: "16px" }} /></GenericDiv>
            </FlexRowBetween>

            <AssetsHolder style={{ height: `${(height - 150)}px`, marginTop: "10px", paddingBottom: "55px" }}>
                {articleArray}
            </AssetsHolder>
            {
                ans && <GenericModal
                    init={setAns}
                    div={<TitleAndContentWithFooter pWidth={"90%"} cWidth={"70%"} style={{ width: "80%" }} isArticle={true} title={ans.ques} content={ans.ans} id={ans._id} init={setAns} color={user.controls} onEdit={onEditHandle} />}
                />
            }
            {getArticleLoading && <OpacityLoading />}
            {showPhotos && <GenericModal init={setShowPhotos} div={<ArticlesPhotos assetId={showPhotos.assetId}
                color={user.controls} photos={showPhotos.photos} init={setShowPhotos} uid={user._id} />} />}
            {addArticle && <GenericModal init={setAddArticle} div={<Add init={setAddArticle} />} />}
            {editArticle && <GenericModal init={setEditArticle} div={<Edit data={editArticle} init={onInitHandle} />} />}
        </div>
    )
}
export default Articles;