import React, { useEffect, useState } from "react";
import { PopupForm, TextWithPic } from "../../Style";
import queryString from "query-string";
import { getImageUrl } from "../../common/modals/Toast";
import { RespFont } from "../../common/Style";
import UploadSignedDocs from "../images/UploadSignedDocs";
import GenericModal from "../../common/modals/GenericModal";
import successfulDeal from "../../../assets/gifs/successfulDeal.svg";
import upload from "../../../assets/nadlan/upload.svg";
import signature from "../../../assets/nadlan/signature.svg";
import video from "../../../assets/nadlan/video.svg";
import download from "../../../assets/nadlan/download.svg";

const FreeSign = (props) => {
  const [showUpload, setShowUpload] = useState(false);
  let params = queryString.parse(props.location.search);
  const { uid, docId, fullname, pic, done } = params;
  const [tempDone, setTempDone] = useState(done);
  let widthS = window.innerWidth; // 1728
  return (
    <div>
      {tempDone == true ? (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: "18px", fontFamily: "hebM" }}>
            המסמך נשלח בהצלחה!!
          </div>
          <img
            src={successfulDeal}
            style={{
              width: widthS > 768 ? "350px" : "250px",
              marginTop: "10px",
            }}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
            height: "100%",
            minHeight: "100vh",
          }}
        >
          <a
            href={widthS > 768 ? "https://youtu.be/ahKb70hcQlQ" : ""}
            target={"_blank"}
            rel="noopener noreferrer"
            style={{ color: "#3a3c3f", textDecoration: "none" }}
          >
            <TextWithPic
              style={{
                flexDirection: "column",
                width: "90%",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              <img
                src={video}
                alt={"video"}
                style={{ width: "40px", marginBottom: "10px" }}
              />
              <RespFont>יש ללחוץ כאן ולפעול לפי הסרטון</RespFont>
            </TextWithPic>
          </a>
          <a
            href={`${getImageUrl()}${uid}/docs/${docId}/${pic}`}
            target={"_blank"}
            rel="noopener noreferrer"
            style={{ color: "#3a3c3f", textDecoration: "none" }}
          >
            <TextWithPic
              style={{
                flexDirection: "column",
                width: "90%",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              <img
                src={download}
                alt={"download"}
                style={{ width: "40px", marginBottom: "10px" }}
              />
              <RespFont>הורדת המסמך</RespFont>
            </TextWithPic>
          </a>
          <a
            href={`https://mozilla.github.io/pdf.js/web/viewer.html`}
            target={"_blank"}
            rel="noopener noreferrer"
            style={{ color: "#3a3c3f", textDecoration: "none" }}
          >
            <TextWithPic
              style={{
                flexDirection: "column",
                width: "90%",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              <img
                src={signature}
                alt={"signature"}
                style={{ width: "40px", marginBottom: "10px" }}
              />
              <RespFont>בחירת המסמך שהורדנו, חתימה, הורדת המסמך החתום</RespFont>
            </TextWithPic>
          </a>
          <TextWithPic
            style={{
              flexDirection: "column",
              width: "90%",
              margin: "0 auto",
              textAlign: "center",
            }}
            onClick={() => setShowUpload(true)}
          >
            <img
              src={upload}
              alt={"upload"}
              style={{ width: "40px", marginBottom: "10px" }}
            />
            <RespFont>העלאת המסמך החתום</RespFont>
          </TextWithPic>

          {showUpload && (
            <GenericModal
              init={setShowUpload}
              div={
                <UploadSignedDocs
                  uid={uid}
                  docId={docId}
                  fullname={fullname + "-חתום"}
                  init={setShowUpload}
                  setTempDone={setTempDone}
                />
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FreeSign;
