import { createSlice } from "@reduxjs/toolkit";
import ServerRoutes from "../api/routes";
import { get, post, get2, post2 } from "../api/http";

const initialState = {
  user: null,
  settingsFlag: false, // change every edit
  authenticated: false,
  authError: null,
  authLoading: false,
  editDetailsLoading: false,
};

const _actionStart = (state, action) => {
  state.authLoading = true;
  state.authError = null;
};
const _actionSuccess = (state, action) => {
  state.authLoading = false;
  state.authError = null;
};
const _actionFailed = (state, action) => {
  state.authLoading = false;
  state.authError = action.payload.error;
};

const _editDetailsActionStart = (state, action) => {
  state.editDetailsLoading = true;
  state.authError = null;
};
const _editDetailsActionSuccess = (state, action) => {
  state.editDetailsLoading = false;
  state.authError = null;
};
const _editDetailsActionFailed = (state, action) => {
  state.editDetailsLoading = false;
  state.authError = action.payload.error;
};

// const _actionFailed = (state, action) => {
//     state.loading = false;
//     state.error = action.payload;
// };

const _setUser = (state, action) => {
  state.user = action.payload.user;
  state.authenticated = action.payload.authenticated;
  state.authLoading = false;
};

const _editSomeUserColor = (state, action) => {
  state.user.settings.colors[action.payload.key] = action.payload.value;
  state.settingsFlag = !state.settingsFlag;
};

const _editSomePageColor = (state, action) => {
  state.user.settings.pagesColors[action.payload.key] = action.payload.value;
  state.settingsFlag = !state.settingsFlag;
};

const _editSomeTitlePageColor = (state, action) => {
  state.user.settings.TitlePagesColor[action.payload.key] =
    action.payload.value;
  state.settingsFlag = !state.settingsFlag;
};

const _editUserDetails = (state, action) => {
  for (const [key, value] of Object.entries(action.payload))
    state.user[key] = value;
  state.settingsFlag = !state.settingsFlag;
};

const _stopLoading = (state) => {
  state.authLoading = false;
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: _setUser,
    editUserDetails: _editUserDetails,
    actionStart: _actionStart,
    stopLoading: _stopLoading,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
    editDetailsActionStart: _editDetailsActionStart,
    editDetailsActionSuccess: _editDetailsActionSuccess,
    editDetailsActionFailed: _editDetailsActionFailed,
    editSomeUserColor1: _editSomeUserColor,
    editSomePageColor1: _editSomePageColor,
    editSomeTitlePageColor1: _editSomeTitlePageColor,
  },
});

const { reducer, actions } = auth;

const {
  setUser,
  editUserDetails,
  actionStart,
  actionFailed,
  actionSuccess,
  stopLoading,
  editDetailsActionStart,
  editDetailsActionSuccess,
  editDetailsActionFailed,
  editSomeUserColor1,
  editSomePageColor1,
  editSomeTitlePageColor1,
} = actions;

export default reducer;

export const setUserReduxOnly = (user) => {
  return async (dispatch) => {
    return dispatch(setUser({ user, authenticated: false }));
  };
};

export const login = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(ServerRoutes.Auth.login, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      localStorage.setItem("bs", data.token);
      return dispatch(setUser({ user: data.user, authenticated: true }));
    }
  };
};

export const editDetails = (body) => {
  return async (dispatch) => {
    dispatch(editDetailsActionStart());
    const { error, status } = await post2(ServerRoutes.Auth.editMySelf, body);
    if (status !== 200) return dispatch(editDetailsActionFailed({ error }));
    else {
      dispatch(editDetailsActionSuccess());
      return dispatch(editUserDetails(body));
    }
  };
};

export const editSomePageColor = (body) => {
  return async (dispatch) => {
    dispatch(actionSuccess());
    return dispatch(editSomePageColor1(body));
  };
};

export const editSomeTitlePageColor = (body) => {
  return async (dispatch) => {
    dispatch(actionSuccess());
    return dispatch(editSomeTitlePageColor1(body));
  };
};

export const editSomeUserColor = (body) => {
  return async (dispatch) => {
    dispatch(actionSuccess());
    return dispatch(editSomeUserColor1(body));
  };
};

export const editDetailsLocalOnly = (body) => {
  return async (dispatch) => {
    dispatch(actionSuccess());
    return dispatch(editUserDetails(body));
  };
};

export const register1 = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Auth.register,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess(data));
    }
  };
};

export const resetPassword = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Auth.resetPassword,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
    }
  };
};

export const requestCode = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Auth.requestCode, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
    }
  };
};

export const checkAuth = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, status } = await get2(ServerRoutes.Auth.checkAuth, {});
    if (status !== 200) return dispatch(stopLoading());
    else return dispatch(setUser({ user: data, authenticated: true }));
  };
};

export const logoutAll = () => {
  return async (dispatch) => {
    await post(ServerRoutes.Auth.logoutAll, {});
    localStorage.removeItem("bs");
    return dispatch(setUser({ user: null, authenticated: false }));
  };
};

export const logoutSpecific = () => {
  return async (dispatch) => {
    const { error, status } = await post2(ServerRoutes.Auth.logoutSpecific, {});
    if (status !== 200) dispatch(actionFailed(error));
    else {
      localStorage.removeItem("bs");
      return dispatch(setUser({ user: null, authenticated: false }));
    }
  };
};
