import React, { useEffect } from "react";
import { DisplayNoneComputer } from "../../common/Style";
import { HashLink } from "react-router-hash-link";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PersonIcon from "@material-ui/icons/Person";
import InfoIcon from "@mui/icons-material/Info";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import HomeIcon from "@material-ui/icons/Home";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import ClientRoutes from "../../../navigation/Routes";
import l1 from "../../../assets/zaks/l1.svg";
import { FlexRowBetween } from "../../Style";
import ZaxLogo from "../logo/ZaxLogo";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "rgba(255,255,255,0.1)",
    width: "min-content",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
    fontFamily: "logo",
    letterSpacing: "1px",
    color: "#3A3C3F",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const BurgerMenu = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      setOpen(false);
    }
  };

  return (
    <DisplayNoneComputer>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <div
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <img
              src={l1}
              style={{
                width: "45px",
                backgroundColor: "rgb(222, 133, 86)",
                padding: "10px",
                borderRadius: "50%",
                boxShadow: "-1px 1px 15px rgba(9, 20, 49, 0.11)",
                marginTop: "15px",
                marginRight: "15px",
              }}
            />
          </div>
        </AppBar>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
        </main>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <div onClick={() => handleClickScroll("services-id")}>
              <ListItem
                button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  direction: "rtl",
                }}
              >
                <ListItemText primary={"שירותים"} style={{ display: "flex" }} />
                <ListItemIcon style={{ display: "flow-root" }}>
                  <MenuBookIcon />
                </ListItemIcon>
              </ListItem>
            </div>
            <div onClick={() => handleClickScroll("steps")}>
              <ListItem
                button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  direction: "rtl",
                }}
              >
                <ListItemText
                  primary={"למה אנחנו?"}
                  style={{ display: "flex" }}
                />
                <ListItemIcon style={{ display: "flow-root" }}>
                  <InfoIcon />
                </ListItemIcon>
              </ListItem>
            </div>
            <div onClick={() => handleClickScroll("priceList-id")}>
              <ListItem
                button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  direction: "rtl",
                }}
              >
                <ListItemText primary={"חבילות"} style={{ display: "flex" }} />
                <ListItemIcon style={{ display: "flow-root" }}>
                  <AttachMoneyIcon />
                </ListItemIcon>
              </ListItem>
            </div>
            <div onClick={() => handleClickScroll("quesAndAns-id")}>
              <ListItem
                button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  direction: "rtl",
                }}
              >
                <ListItemText
                  primary={"שאלות נפוצות"}
                  style={{ display: "flex" }}
                />
                <ListItemIcon style={{ display: "flow-root" }}>
                  <QuestionAnswerIcon />
                </ListItemIcon>
              </ListItem>
            </div>
            <div onClick={() => handleClickScroll("contact-id")}>
              <ListItem
                button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  direction: "rtl",
                }}
              >
                <ListItemText
                  primary={"יצירת קשר"}
                  style={{ display: "flex" }}
                />
                <ListItemIcon style={{ display: "flow-root" }}>
                  <ConnectWithoutContactOutlinedIcon />
                </ListItemIcon>
              </ListItem>
            </div>
            <Link
              to={ClientRoutes.UnAuth.login}
              style={{ color: "#3A3C3F", textDecoration: "none" }}
            >
              <ListItem
                button
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  direction: "rtl",
                  backgroundColor: "#fcd887",
                }}
              >
                <ListItemText primary={"כניסה"} style={{ display: "flex" }} />
                <ListItemIcon style={{ display: "flow-root" }}>
                  <PersonIcon />
                </ListItemIcon>
              </ListItem>
            </Link>
          </List>
        </Drawer>
      </div>
    </DisplayNoneComputer>
  );
};

export default BurgerMenu;
