import React, { useState } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  RTextArea,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  RespFont,
} from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { editAsset } from "../../../store/reducers/assetsReducer";
import delete1 from "../../../assets/nadlan/delete1.svg";

import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import Switcher from "../../common/switcher/Switcher";
import {
  deleteReference,
  editReference,
} from "../../../store/reducers/referenceReducer";
import { CopyToClipboard } from "react-copy-to-clipboard";

import alignCenter from "../../../assets/nadlan/alignCenter.svg";
import textItalic from "../../../assets/nadlan/textItalic.svg";
import { HomeInput, HomeTextArea } from "../../common/Style";

const Edit = (props) => {
  console.log(props.data);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { referenceLoading } = useSelector((state) => state.referenceReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let widthS = window.innerWidth; // 1728

  const onSubmit = async (data) => {
    const body = {
      fullname: data.fullname,
      city: data.city,
      rank: data.rank,
      description: data.description,
    };
    dispatch(editReference(body, props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init();
      }
    });
  };
  const deleteA = () => {
    dispatch(deleteReference(props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  return (
    <PopupForm
      cWidth={"70%"}
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "80%",
          margin: "10px auto",
        }}
      >
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            שם מלא
          </RespFont>
          <HomeInput
            style={{ paddingRight: "10px" }}
            defaultValue={props.data.fullname}
            type={"text"}
            {...register("fullname", {
              required: true,
              minLength: 2,
              maxLength: 200,
            })}
          />
          {CFV("fullname", "בין 2 ל 200 תווים", errors)}
        </div>
        <div style={{ marginTop: "20px" }}>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            עיר
          </RespFont>
          <HomeInput
            style={{ paddingRight: "10px" }}
            defaultValue={props.data.city}
            type={"text"}
            {...register("city", {
              required: true,
              minLength: 2,
              maxLength: 200,
            })}
          />
          {CFV("city", "בין 2 ל 200 תווים", errors)}
        </div>
        <div style={{ marginTop: "20px" }}>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            ציון
          </RespFont>
          <HomeInput
            defaultValue={props.data.rank}
            type={"number"}
            {...register("rank", {
              required: true,
              minLength: 1,
              maxLength: 5,
            })}
          />
          {CFV("rank", "מספר 1 ל 5", errors)}
        </div>
        <div style={{ marginTop: "20px" }}>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            תיאור
          </RespFont>
          <HomeTextArea
            defaultValue={props.data.description}
            type="text"
            rows="5"
            {...register("description", {
              required: true,
              minLength: 1,
              maxLength: 500,
            })}
          />
          {CFV("description", "בין 1 ל 500 תווים", errors)}
        </div>
        <FlexRowBetween style={{ margin: "0px auto", marginTop: "40px" }}>
          <GenericButton
            form="form1"
            background={user.settings.colors.buttonsColor}
            color={"#fff"}
            type="submit"
            style={{
              width: "70%",

              padding: "10px",
              borderRadius: "5px",
              fontWeight: "bolder",
              fontSize: "16px",
              boxShadow: "none",
            }}
          >
            שמירה
          </GenericButton>
          <div
            onClick={deleteA}
            style={{
              backgroundColor: constants.fontColorZax,
              borderRadius: "3px",
              cursor: "pointer",
              marginLeft: "0px",
            }}
          >
            <img
              src={delete1}
              alt="delete"
              style={{ width: widthS > 768 ? "40px" : "38px", padding: "9px" }}
            />
          </div>
        </FlexRowBetween>
      </div>
      {referenceLoading && <OpacityLoading />}
    </PopupForm>
  );
};

export default Edit;
