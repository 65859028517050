import React, { useState } from "react";
import { AssetPic, FlexRowBetween, GenericDiv, PopupForm } from "../../Style";
import { getImageUrl, GetToast } from "../../common/modals/Toast";
import UploadAssetsPhotos from "../../user/images/UploadAssetsPhotos";
import Carousel from "../../common/carousel/Carousel";
import GenericModal from "../../common/modals/GenericModal";
import YesNoModal from "../../common/modals/YesNoModal";
import { editAsset } from "../../../store/reducers/assetsReducer";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { deletePhoto } from "../../../store/reducers/photosReducer";
import OpacityLoading from "../../common/loader/OpacityLoading";

const AssetsPhotos = ({
  photos,
  uid,
  color,
  assetId,
  init,
  authenticated = true,
}) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [uploadPic, setUploadPic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deletePic, setDeletePic] = useState(false);
  const [i, setI] = useState(0);
  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoFunc = async () => {
    setLoading(true);
    await deletePhoto1(assetId + "/" + photos[i]);
    let newPhotos = photos.filter((x) => x !== photos[i]);
    console.log(newPhotos);
    dispatch(editAsset({ photos: newPhotos }, assetId)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה");
        init();
      }
      setLoading(false);
    });
  };

  return (
    <>
      {authenticated && uploadPic ? (
        <UploadAssetsPhotos
          photos={photos}
          assetId={assetId}
          color={color}
          init={init}
        />
      ) : authenticated && deletePic ? (
        <GenericModal
          init={setDeletePic}
          div={
            <YesNoModal
              color={color}
              title={"מחיקת תמונה"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeletePic}
              yes={deletePhotoFunc}
            />
          }
        />
      ) : (
        <PopupForm onClick={(e) => e.stopPropagation()}>
          {authenticated && (
            <FlexRowBetween style={{ padding: "20px" }}>
              <GenericDiv background={color} onClick={() => setUploadPic(true)}>
                הוספת תמונה
              </GenericDiv>
              <GenericDiv background={color} onClick={() => setDeletePic(true)}>
                מחיקת תמונה
              </GenericDiv>
            </FlexRowBetween>
          )}
          {photos?.length && (
            <Carousel
              color={color}
              authenticated={authenticated}
              setI={setI}
              i={i}
              height={"350px"}
              photos={photos}
              path={`${getImageUrl()}${uid}/${assetId}/`}
            />
          )}
          {loading && <OpacityLoading />}
        </PopupForm>
      )}
      {/* {loading && <OpacityLoading />} */}
    </>
  );
};

export default AssetsPhotos;
