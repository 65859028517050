import React, { useMemo } from "react";
import { constants, FlexRowBetween, RespFont, Section } from "../../Style";
import whiteStar from "../../../assets/zaks/whiteStar.svg";
import { useSelector } from "react-redux";
import { getImageUrl, handleClickScrollXY } from "../modals/Toast";
import CommonEdit from "../others/CommonEdit";
const AboutC = ({ setEditAbout, editAbout }) => {
  const { user, settingsFlag, authenticated } = useSelector(
    (state) => state.authReducer
  );

  let agentValues = useMemo(() => {
    const values = user.settings.about.part4;
    let fff = [];
    for (let i = 0; i < values.length; i++)
      if (values[i]["key"] !== "")
        fff.push(
          <FlexRowBetween
            style={{
              width: "60%",
              margin: "10px auto",
              justifyContent: "flex-start",
            }}
          >
            <img
              src={whiteStar}
              style={{
                width: "45px",
                padding: "10px",
                backgroundColor: user.settings.TitlePagesColor.page3,
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            />
            <RespFont style={{ textAlign: "right" }}>
              <b>{`${values[i]["key"]} - `}</b>
              {values[i]["value"]}
            </RespFont>
          </FlexRowBetween>
        );
    return fff;
  }, [settingsFlag]);

  let aboutPic =
    user.aboutPic !== ""
      ? `${getImageUrl()}${user._id}/${user.aboutPic}`
      : `${getImageUrl()}sys/defaultAboutUsPic.png`;
  return (
    <FlexRowBetween
      id={"steps"}
      style={{
        position: "relative",
        padding: "50px 0",
        backgroundColor: user.settings.pagesColors.page3,
      }}
    >
      {authenticated && (
        <CommonEdit
          bottom={"30px"}
          left={null}
          style={{
            right: "20px",
          }}
          func={() => {
            if (!editAbout) handleClickScrollXY(700);
            setEditAbout(!editAbout);
          }}
          color={user.settings.TitlePagesColor.page3}
        />
      )}
      <div
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
        id={"aboutMe-id"}
      >
        <Section
          pSize={"36px"}
          cSize={"48px"}
          style={{
            color: user.settings.TitlePagesColor.page3,
            width: "60%",
            textAlign: "right",
            marginBottom: "0px",
          }}
        >
          {user.settings.about.part0}
        </Section>
        <RespFont
          pSize={"18px"}
          cSize={"22px"}
          style={{
            fontFamily: "monsterB",
            color: constants.fontColorZax,
            width: "60%",
            textAlign: "right",
          }}
        >
          {user.settings.about.part1}
        </RespFont>
        <RespFont
          pSize={"14px"}
          cSize={"16px"}
          style={{
            fontFamily: "monster",
            marginTop: "10px",
            fontWeight: "100",
            color: constants.fontColorZax,
            width: "60%",
            textAlign: "right",
          }}
        >
          {user.settings.about.part2}
        </RespFont>
        <Section
          pSize={"26px"}
          cSize={"38px"}
          style={{
            color: user.settings.TitlePagesColor.page3,
            width: "60%",
            textAlign: "right",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {user.settings.about.part3}
        </Section>

        {agentValues}
      </div>
      <div style={{ width: "40%" }}>
        <img
          src={aboutPic}
          style={{
            width: "auto",
            maxWidth: "1000px",
            maxHeight: "800px",
            margin: "0 auto",
            objectFit: "contain",
          }}
        />
      </div>
    </FlexRowBetween>
  );
};

export default AboutC;
