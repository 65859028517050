import React from "react";
import {
  DisplayNoneComputer,
  DisplayNonePhone,
  FlexRowColumn,
} from "../../common/Style";
import { FlexRowBetween, RespFont, Section, Ser } from "../../Style";
import cs1 from "../../../assets/zaks/cs1.svg";
import cs2 from "../../../assets/zaks/cs2.svg";
import cs3 from "../../../assets/zaks/cs3.svg";
import cs4 from "../../../assets/zaks/cs4.svg";
import cs5 from "../../../assets/zaks/cs5.svg";
import cs6 from "../../../assets/zaks/cs6.svg";
// real_estate_house
const ComingSoon = () => {
  let widthS = window.innerWidth; // 1728

  return (
    <div
      // id={"services-id"}
      style={{
        fontFamily: "heb",
        paddingTop: "40px",
        backgroundColor: "#fff",
      }}
    >
      <FlexRowBetween>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.06)",
            width: "100%",
            marginRight: "5%",
            marginLeft: "2%",
            // margin: "5px auto",
            height: "4px",
            borderRadius: "1px",
          }}
        />
        <Section
          pSize={"36px"}
          cSize={"48px"}
          style={{
            marginBottom: "0",
            color: "rgba(56, 52, 61, 0.4)",
            fontWeight: "100",
          }}
        >
          בקרוב
        </Section>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.06)",
            width: "100%",
            marginRight: "2%",
            marginLeft: "5%",
            height: "4px",
            borderRadius: "1px",
          }}
        />
      </FlexRowBetween>
      <DisplayNonePhone>
        <FlexRowColumn
          style={{ width: "90%", margin: "20px auto", paddingBottom: "30px" }}
        >
          <FlexRowBetween
            style={{ justifyContent: "center", width: "min-content" }}
          >
            <Ser
              style={{
                height: "150px",
                justifyContent: "space-between",
                width: "max-content",
              }}
            >
              <img src={cs4} alt={"cs4"} style={{ width: "50px" }} />
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                הודעות וניהול
              </RespFont>
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                לקוחות בוואטצאפ
              </RespFont>
            </Ser>
            <Ser
              style={{
                height: "150px",
                justifyContent: "space-between",
                marginRight: widthS > 768 ? "15px" : "15px",
                width: "max-content",
              }}
            >
              <img src={cs3} alt={"cs3"} style={{ width: "50px" }} />
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                שיווק אוטומטי
              </RespFont>
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                בפייסבוק ע״פ תזמון
              </RespFont>
            </Ser>
            <Ser
              style={{
                height: "150px",
                justifyContent: "space-between",
                marginRight: widthS > 768 ? "15px" : "15px",
                // marginRight: widthS > 768 ? "7.5px" : "0",
                width: "max-content",
              }}
            >
              <img src={cs2} alt={"cs2"} style={{ width: "50px" }} />
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                עסקאות אחרונות
              </RespFont>
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                שנעשו ודווחו
              </RespFont>
            </Ser>
            <Ser
              style={{
                height: "150px",
                justifyContent: "space-between",
                marginRight: widthS > 768 ? "15px" : "15px",
                width: "max-content",
              }}
            >
              <img src={cs6} alt={"cs6"} style={{ width: "50px" }} />
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                מאגר נכסים
              </RespFont>
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                מתעדכן
              </RespFont>
            </Ser>
            {/* <Ser
              style={{
                height: "150px",
                justifyContent: "space-between",
                marginRight: widthS > 768 ? "15px" : "15px",
                width: "max-content",
              }}
            >
              <img src={cs5} alt={"cs5"} style={{ width: "50px" }} />
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                חיתום
              </RespFont>
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                דיגיטלי
              </RespFont>
            </Ser> */}
            <Ser
              style={{
                height: "150px",
                justifyContent: "space-between",
                marginRight: widthS > 768 ? "15px" : "15px",
                width: "max-content",
              }}
            >
              <img src={cs1} alt={"cs1"} style={{ width: "50px" }} />
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                חבילות קידום ושיווק
              </RespFont>
              <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
                נכסים ע"י רשת הפרסום שלנו
              </RespFont>
            </Ser>
          </FlexRowBetween>
        </FlexRowColumn>
      </DisplayNonePhone>
      <DisplayNoneComputer>
        <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
          <div
            style={{
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "10px",
              textAlign: "center",
              padding: "10px",
            }}
          >
            <img
              src={cs6}
              alt={"cs6"}
              style={{ width: "30px", marginBottom: "10px" }}
            />
            <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
              מאגר נכסים מתעדכן
            </RespFont>
          </div>
          <div
            style={{
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "10px",
              textAlign: "center",
              padding: "10px",
            }}
          >
            <img
              src={cs3}
              alt={"cs3"}
              style={{ width: "30px", marginBottom: "10px" }}
            />
            <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
              שיווק אוטומטי בפייסבוק ע״פ תזמון
            </RespFont>
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
          <div
            style={{
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "10px",
              textAlign: "center",
              padding: "10px",
              marginLeft: "15px",
            }}
          >
            <img
              src={cs2}
              alt={"cs2"}
              style={{ width: "30px", marginBottom: "10px" }}
            />
            <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
              עסקאות אחרונות שנעשו ודווחו
            </RespFont>
          </div>
          <div
            style={{
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "10px",
              textAlign: "center",
              padding: "10px",
              marginRight: "15px",
            }}
          >
            <img
              src={cs4}
              alt={"cs4"}
              style={{ width: "30px", marginBottom: "10px" }}
            />
            <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
              הודעות וניהול לקוחות בוואטצאפ
            </RespFont>
          </div>
        </FlexRowBetween>
        <div
          style={{
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "10px",
            textAlign: "center",
            padding: "10px",
            width: "90%",
            margin: "20px auto",
          }}
        >
          <img
            src={cs1}
            alt={"cs1"}
            style={{ width: "30px", marginBottom: "10px" }}
          />
          <RespFont pSize={"12px"} style={{ fontWeight: "600" }}>
            חבילות קידום ושיווק נכסים ע"י רשת הפרסום שלנו
          </RespFont>
        </div>
        {/* </FlexRowBetween> */}
        <div style={{ height: "20px" }}></div>
      </DisplayNoneComputer>
    </div>
  );
};

export default ComingSoon;
