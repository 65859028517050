import React, { useState } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  GalleryOptions,
  RespFont,
  GenericDiv,
} from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { editAsset, deleteAsset } from "../../../store/reducers/assetsReducer";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import gallery from "../../../assets/nadlan/galleryWhite.svg";
import {
  addAlpha,
  CFV,
  getImageUrl,
  GetToast,
  parseIsoDate,
} from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import Switcher from "../../common/switcher/Switcher";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import { svgs } from "../../common/footer/coloredSvgs/svgs";
import delete1 from "../../../assets/nadlan/delete1.svg";
import galleryWhite from "../../../assets/nadlan/galleryWhite.svg";
import whitePdf from "../../../assets/nadlan/whitePdf.svg";
import {
  FlexRowStart,
  HomeInput,
  HomeTextArea,
  RTextArea,
} from "../../common/Style";
import { editWaiting } from "../../../store/reducers/waitingReducer";
import {
  editDocDetails,
  editDocPic,
} from "../../../store/reducers/docsReducer";
import GenericModal from "../../common/modals/GenericModal";
import GCalendar from "../../common/calendar/Calendar";
import ColorCheckbox from "../../common/others/ColorCheckbox";
import UploadArticlesPhotos from "../images/UploadArticlesPhotos";
import { deletePhoto } from "../../../store/reducers/photosReducer";
import UploadDocsPhotos from "../images/UploadDocsPhotos";

const Edit = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [editPic, setEditPic] = useState(false);

  const [isSale, setIsSale] = useState(
    props.data.dealKind === true ? true : false
  );
  const [isRent, setIsRent] = useState(
    props.data.dealKind === false ? true : false
  );
  const [docKind, setDocKind] = useState(props.data.docKind); // 1 - sell, 2 - buy, 3 - other

  const { user } = useSelector((state) => state.authReducer);
  const { assetsLoading } = useSelector((state) => state.assetsReducer);
  const [showStartCalendar, setShowStartCalendar] = useState(null);
  const [showEndCalendar, setShowEndCalendar] = useState(null);
  const [startDate, setStartDate] = useState(new Date(props.data.startDate));
  const [endDate, setEndDate] = useState(new Date(props.data.endDate));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [saleOrRentOrSold, setSaleOrRentOrSold] = useState(
    props.data.saleOrRentOrSold
  ); // 1 for sale, 2 for rent, 3 for sold
  let widthS = window.innerWidth; // 1728

  const onSubmit = async (data) => {
    const body = {
      fullname: data.fullname,
      phone: data.phone,
      details: data.details,
      notes: docKind === 3 ? props.data.notes : data.notes,
      gush: docKind === 3 ? props.data.gush : parseInt(data.gush),
      helka: docKind === 3 ? props.data.helka : parseInt(data.helka),
      tatHelka: docKind === 3 ? props.data.tatHelka : parseInt(data.tatHelka),
      dealKind: docKind === 3 ? props.data.dealKind : isSale,
      fees: docKind === 3 ? props.data.fees : data.fees,
      assetOwner: docKind === 3 ? props.data.assetOwner : data.assetOwner,
      assetKind: docKind === 3 ? props.data.assetKind : data.assetKind,
      assetAddress: docKind === 3 ? props.data.assetAddress : data.assetAddress,
      assetPrice: docKind === 3 ? props.data.assetPrice : data.assetPrice,
      startDate: props.data.startDate,
      endDate: props.data.endDate,
    };
    dispatch(editDocDetails(body, props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init(false);
      }
    });
  };

  const handleInit = () => {
    setEditPic(false);
    props.init(false);
  };

  const deleteA = () => {
    dispatch(deleteAsset(props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  const handleSetIsSale = () => {
    if (!isSale) {
      setIsSale(true);
      setIsRent(false);
    }
  };

  const handleSetIsRent = () => {
    if (!isRent) setIsRent(true);
    setIsSale(false);
  };

  const handleSetStartDate = (e) => {
    let date = e;
    date.setHours(20);
    setStartDate(date);
  };

  const handleSetEndDate = (e) => {
    let date = e;
    date.setHours(20);
    setEndDate(date);
  };
  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoOnly = async () => {
    await deletePhoto1(`docs/${props.data._id}/${props.data.pic}`);
    dispatch(editDocPic({ pic: "" }, props.data._id)).then(
      (res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה הצליחה!!");
          props.init(false);
        }
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "90%",
          margin: "10px auto",
        }}
      >
        <FlexRowBetween
          onClick={() =>
            GetToast(
              addToast,
              "warning",
              "לא ניתן לשינוי, יש ליצור חדש במידת הצורך",
              8000
            )
          }
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "60px",
            padding: widthS > 768 ? "5px" : "3px",
            fontWeight: "700",
            margin: "10px auto",
          }}
        >
          <RespFont
            pSize={"12px"}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                docKind === 1 ? user.settings.colors.buttonsColor : "inherit",
              transition: "all 0.2s",
              color: docKind === 1 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            מוכר/משכיר
          </RespFont>
          <RespFont
            pSize={"12px"}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                docKind === 2 ? user.settings.colors.buttonsColor : "inherit",
              transition: "all 0.2s",
              color: docKind === 2 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            קונה/שוכר
          </RespFont>
          <RespFont
            pSize={"12px"}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                docKind === 3 ? user.settings.colors.buttonsColor : "inherit",
              transition: "all 0.2s",
              color: docKind === 3 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            אחר
          </RespFont>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "0px auto", marginTop: "20px" }}>
          <div style={{ width: "55%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שם מלא
            </RespFont>
            <HomeInput
              type="text"
              defaultValue={props.data.fullname}
              {...register("fullname", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("fullname", "בין 2 ל 50 תווים", errors)}
          </div>
          <div style={{ width: "40%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מס׳ פלאפון
            </RespFont>
            <HomeInput
              defaultValue={props.data.phone}
              {...register("phone", {
                required: true,
                pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
              })}
            />
            {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)}
          </div>
        </FlexRowBetween>
        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          פרטים לשימוש עצמי
        </RespFont>
        <HomeTextArea
          color={constants.fontColorZax}
          defaultValue={props.data.details}
          name="details"
          type="text"
          rows="3"
          style={{ margin: "0px auto" }}
          {...register("details", {
            required: false,
            minLength: 0,
            maxLength: 500,
          })}
        />

        {docKind !== 3 ? (
          <>
            <RespFont
              style={{ borderBottom: "2px solid gray", margin: "10px auto" }}
            >
              תיאור הנכס
            </RespFont>

            <FlexRowStart style={{ marginTop: "20px" }}>
              <span style={{ fontFamily: "hebM" }}>סוג העסקה:</span>
              <FlexRowStart
                style={{
                  width: "max-content",
                  justifyContent: "flex-start",
                  marginRight: "15px",
                  marginLeft: "0",
                  marginTop: "0px",
                }}
              >
                <ColorCheckbox
                  checked={isSale}
                  color={"blue"}
                  handleCHange={handleSetIsSale}
                />
                <RespFont style={{ marginRight: "2px", width: "max-content" }}>
                  {docKind === 1 ? "מכירה" : "קניה"}
                </RespFont>
              </FlexRowStart>
              <FlexRowStart
                style={{
                  width: "max-content",
                  justifyContent: "flex-start",
                  marginRight: "15px",
                  marginTop: "0px",
                  //   marginLeft: "0",
                }}
              >
                <ColorCheckbox
                  checked={isRent}
                  color={"blue"}
                  handleCHange={handleSetIsRent}
                />
                <RespFont style={{ marginRight: "2px", width: "max-content" }}>
                  השכרה
                </RespFont>
              </FlexRowStart>
            </FlexRowStart>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
                marginTop: "20px",
              }}
            >
              דמי תיווך
            </RespFont>
            <HomeInput
              defaultValue={props.data.fees}
              type="text"
              {...register("fees", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("fees", "בין 2 ל 50 תווים", errors)}
            <FlexRowBetween style={{ margin: "0px auto", marginTop: "20px" }}>
              <div style={{ width: "30%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  גוש
                </RespFont>
                <HomeInput
                  type="number"
                  defaultValue={props.data.gush}
                  {...register("gush", {
                    min: 0,
                    max: 10000000,
                  })}
                />
                {CFV("gush", "עד 8 ספרות", errors)}
              </div>
              <div style={{ width: "30%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  חלקה
                </RespFont>
                <HomeInput
                  type="number"
                  defaultValue={props.data.helka}
                  {...register("helka", {
                    min: 0,
                    max: 10000000,
                  })}
                />
                {CFV("helka", "עד 8 ספרות", errors)}
              </div>
              <div style={{ width: "30%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  תת חלקה
                </RespFont>
                <HomeInput
                  type="number"
                  defaultValue={props.data.tatHelka}
                  {...register("tatHelka", {
                    min: 0,
                    max: 10000000,
                  })}
                />
                {CFV("tatHelka", "עד 8 ספרות", errors)}
              </div>
            </FlexRowBetween>
            <FlexRowBetween style={{ margin: "0px auto" }}>
              <div style={{ width: "45%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  סוג הנכס
                </RespFont>
                <HomeInput
                  type="text"
                  defaultValue={props.data.assetKind}
                  {...register("assetKind", {
                    required: true,
                    minLength: 2,
                    maxLength: 50,
                  })}
                />
                {CFV("assetKind", "בין 2 ל 50 תווים", errors)}
              </div>
              <div style={{ width: "50%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  שם הבעלים
                </RespFont>
                <HomeInput
                  defaultValue={props.data.assetOwner}
                  {...register("assetOwner", {
                    required: true,
                    minLength: 2,
                    maxLength: 50,
                  })}
                />
                {CFV("assetOwner", "בין 2 ל 50 תווים", errors)}
              </div>
            </FlexRowBetween>
            <FlexRowBetween style={{ margin: "0px auto" }}>
              <div style={{ width: "55%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  כתובת הנכס
                </RespFont>
                <HomeInput
                  type="text"
                  defaultValue={props.data.assetAddress}
                  {...register("assetAddress", {
                    required: true,
                    minLength: 2,
                    maxLength: 50,
                  })}
                />
                {CFV("assetAddress", "בין 2 ל 50 תווים", errors)}
              </div>
              <div style={{ width: "40%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  מחיר מבוקש
                </RespFont>
                <HomeInput
                  type="number"
                  defaultValue={props.data.assetPrice}
                  {...register("assetPrice", {
                    required: true,
                    min: 0,
                    max: 1000000000,
                  })}
                />
                {CFV("assetPrice", "עד 9 ספרות", errors)}
              </div>
            </FlexRowBetween>
            <FlexRowBetween>
              <div style={{ width: "40%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  תאריך התחלה
                </RespFont>
                <HomeInput
                  onClick={() => setShowStartCalendar(true)}
                  value={parseIsoDate(startDate)}
                  onChange={() => {}}
                  type={"text"}
                />
                {CFV("null", "בין 2 ל 20 תווים", errors)}
              </div>
              <div style={{ width: "40%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  תאריך סיום
                </RespFont>
                <HomeInput
                  onClick={() => setShowEndCalendar(true)}
                  value={parseIsoDate(endDate)}
                  onChange={() => {}}
                  type={"text"}
                />
                {CFV("null", "בין 2 ל 20 תווים", errors)}
              </div>
            </FlexRowBetween>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              הערות
            </RespFont>
            <HomeTextArea
              color={constants.fontColorZax}
              defaultValue={props.data.notes}
              name="notes"
              type="text"
              rows="3"
              style={{ margin: "0px auto" }}
              {...register("notes", {
                required: false,
                minLength: 0,
                maxLength: 500,
              })}
            />
          </>
        ) : (
          <GenericDiv
            style={{ marginTop: "20px" }}
            background={user.settings.colors.buttonsColor}
            onClick={(e) => {
              if (props.data.pic === "") {
                setEditPic(true);
              } else deletePhotoOnly();
            }}
          >
            <img
              src={whitePdf}
              alt={"whitePdf"}
              style={{ width: "20px", marginLeft: "10px" }}
            />
            <div>{props.data.pic === "" ? "העלאת מסמך" : "מחיקת מסמך"}</div>
          </GenericDiv>
        )}
        <FlexRowBetween style={{ margin: "0px auto", marginTop: "20px" }}>
          <GenericButton
            form="form1"
            background={user.settings.colors.buttonsColor}
            color={"#fff"}
            type="submit"
            style={{
              width: widthS > 768 ? "70%" : "60%",
              marginRight: "0px",
              padding: "10px",
              borderRadius: "5px",
              fontWeight: "bolder",
              fontSize: "16px",
              boxShadow: "none",
            }}
          >
            שמירה
          </GenericButton>

          <FlexRowBetween style={{ width: "min-content", marginLeft: "0px" }}>
            <div
              onClick={deleteA}
              style={{
                backgroundColor: constants.fontColorZax,
                borderRadius: "3px",
                cursor: "pointer",
                marginLeft: "0px",
              }}
            >
              <img
                src={delete1}
                alt="delete"
                style={{
                  width: widthS > 768 ? "40px" : "38px",
                  padding: "9px",
                }}
              />
            </div>
          </FlexRowBetween>
        </FlexRowBetween>
      </div>
      {assetsLoading && <OpacityLoading />}
      {showStartCalendar && (
        <GenericModal
          init={setShowStartCalendar}
          div={
            <GCalendar
              background={user.settings.colors.buttonsColor}
              setDate={handleSetStartDate}
              date={startDate}
              init={setShowStartCalendar}
            />
          }
        />
      )}
      {showEndCalendar && (
        <GenericModal
          init={setShowEndCalendar}
          div={
            <GCalendar
              background={user.settings.colors.buttonsColor}
              setDate={handleSetEndDate}
              date={endDate}
              init={setShowEndCalendar}
            />
          }
        />
      )}
      {editPic && (
        <GenericModal
          init={setEditPic}
          div={
            <UploadDocsPhotos
              handleInit={handleInit}
              fullname={props.data.fullname}
              id={props.data._id}
              color={user.settings.colors.buttonsColor}
              init={setEditPic}
            />
          }
        />
      )}
    </PopupForm>
  );
};

export default Edit;
