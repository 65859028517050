import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ClientRoutes from "../../navigation/Routes";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import { sha512 } from "js-sha512";
import { requestCode, resetPassword } from "../../store/reducers/authReducer";
import { CFV, GetToast } from "../common/modals/Toast";
import eye from "../../assets/nadlan/eye.svg";
import {
  constants,
  InputBox,
  PlaceHolderImage,
  GenericButton,
  GenericDiv,
  PopupForm,
  RespFont,
} from "../Style";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { CenteredFormPage2 } from "./home/Style";
import { HomeInput } from "../common/Style";
const ResetPassword = (props) => {
  const [tempPhone, setTempPhone] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showExtra, setShowExtra] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (data.password !== data.passwordV) alert("הסיסמאות אינן תואמות");
    else {
      const body = {
        phone: tempPhone,
        code: data.code,
        password: sha512(data.password),
      };
      const phoneRegex = /^[0]{1}[5]{1}[0-9]{8}$/;
      if (tempPhone.match(phoneRegex) !== null)
        dispatch(resetPassword(body)).then((res) => {
          if (!res.payload?.error) {
            GetToast(addToast, "success", "הסיסמא שונתה בהצלחה!!");
            props.init();
          } else GetToast(addToast, "error", res.payload.error);
        });
      else GetToast(addToast, "warning", "פורמט מספר פלאפון לא חוקי", 8000);
    }
  };

  const handleRequestCode = () => {
    const phoneRegex = /^[0]{1}[5]{1}[0-9]{8}$/;
    if (tempPhone.match(phoneRegex) !== null)
      dispatch(requestCode({ phone: tempPhone })).then((res) => {
        if (!res.payload?.error) {
          GetToast(
            addToast,
            "success",
            "קיבלת קוד לנייד, נא להקיש בשדה המתאים"
          );
          setShowExtra(true);
        } else GetToast(addToast, "error", res.payload.error);
      });
    else GetToast(addToast, "warning", "פורמט מספר פלאפון לא חוקי", 8000);
  };

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      style={{ overflow: "auto", margin: "50px auto", zIndex: 10 }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "90%",
          margin: "10px auto",
        }}
      >
        <div style={{ width: "80%", margin: "0 auto", marginTop: "25px" }}>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            מס׳ פלאפון
          </RespFont>
          <HomeInput
            onChange={(e) => setTempPhone(e.target.value)}
            pHcolor={"silver"}
            // {...register("phone", {
            //   required: true,
            //   pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
            // })}
          />
          {/* {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)} */}
        </div>

        {showExtra ? (
          <div style={{ width: "80%", margin: "0 auto", marginTop: "25px" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              קוד אימות שנשלח לנייד
            </RespFont>
            <HomeInput
              {...register("code", {
                required: true,
                pattern: /^[0-9]{6}$/,
              })}
            />
            {CFV("code", "6 ספרות בלבד", errors)}

            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                // marginTop: "10px",
                textAlign: "right",
              }}
            >
              סיסמא חדשה
            </RespFont>
            <div style={{ position: "relative", width: "100%" }}>
              <HomeInput
                type={showPass ? "text" : "password"}
                {...register("password", {
                  required: true,
                  minLength: 2,
                  maxLength: 30,
                })}
              />
              {CFV("password", "סיסמא בין 2 ל 30 תווים", errors)}
              <PlaceHolderImage
                onClick={() => setShowPass(!showPass)}
                fontSize={`20px`}
                top={`22px`}
                style={{ left: `5px`, cursor: "pointer" }}
                src={eye}
                alt="accept"
              />
            </div>

            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                // marginTop: "10px",
                textAlign: "right",
              }}
            >
              סיסמא חדשה
            </RespFont>
            <div style={{ position: "relative", width: "100%" }}>
              <HomeInput
                type={showPass ? "text" : "password"}
                {...register("passwordV", {
                  required: true,
                  minLength: 2,
                  maxLength: 30,
                })}
              />
              {CFV("passwordV", "סיסמא בין 2 ל 30 תווים", errors)}
              <PlaceHolderImage
                onClick={() => setShowPass(!showPass)}
                fontSize={`20px`}
                top={`22px`}
                style={{ left: `5px`, cursor: "pointer" }}
                src={eye}
                alt="accept"
              />
            </div>
            <GenericButton
              background={"#DE8556"}
              color={"#fff"}
              type="submit"
              style={{
                width: "100%",
                margin: "20px auto",
                padding: "10px",
                borderRadius: "5px",
                fontWeight: "bolder",
                fontSize: "16px",
                boxShadow: "none",
                marginTop: "40px",
              }}
            >
              שליחה
            </GenericButton>
          </div>
        ) : (
          <GenericDiv
            onClick={handleRequestCode}
            background={"#DE8556"}
            color={"#fff"}
            style={{
              width: "80%",
              margin: "20px auto",
              padding: "10px",
              borderRadius: "5px",
              fontWeight: "bolder",
              fontSize: "16px",
              boxShadow: "none",
              marginTop: "40px",
            }}
          >
            קבלת קוד לנייד
          </GenericDiv>
        )}
      </div>
    </PopupForm>
  );
};

export default ResetPassword;
