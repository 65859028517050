import authReducer from "./authReducer";
import userReducer from "./userReducer";
import guestsReducer from "./guestsReducer";
import tablesReducer from "./tablesReducer";
import messagesReducer from "./messagesReducer";
import invitationReducer from "./invitationReducer";
import photosReducer from "./photosReducer";
import waitingReducer from "./waitingReducer";
import dataReducer from "./dataReducer";
import todosReducer from "./todosReducer";
import assetsReducer from "./assetsReducer";
import detailsReducer from "./detailsReducer";
import expensesReducer from "./expensesReducer";
import cartReducer from "./cartReducer";
import potentialsReducer from "./potentialsReducer";
import leadsReducer from "./leadsReducer";
import docsReducer from "./docsReducer";
import qaaReducer from "./qaaReducer";
import articleReducer from "./articleReducer";
import simpleArticleReducer from "./simpleArticleReducer";
import referenceReducer from "./referenceReducer";
import paymentsReducer from "./paymentsReducer";

export const reducers = {
  authReducer,
  userReducer,
  referenceReducer,
  paymentsReducer,
  simpleArticleReducer,
  articleReducer,
  qaaReducer,
  docsReducer,
  leadsReducer,
  potentialsReducer,
  cartReducer,
  expensesReducer,
  detailsReducer,
  assetsReducer,
  todosReducer,
  dataReducer,
  waitingReducer,
  photosReducer,
  guestsReducer,
  messagesReducer,
  tablesReducer,
  invitationReducer,
};
