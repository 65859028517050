import React from "react";
import ColorCheckbox from "../../others/ColorCheckbox";
import {
  FlexRowStart,
  HomeTextArea,
  RespFont,
  SignatureInput,
} from "../../Style";

const SellDoc = ({ doc, isRent, isSale, israelId, fullname, company }) => {
  return (
    <>
      <RespFont
        pSize={"16px"}
        cSize={"18px"}
        style={{
          textAlign: "center",
          fontWeight: "700",
          margin: "20px auto",
          fontFamily: "hebM",
        }}
      >
        הזמנת שירותי תיווך בבלעדיות
      </RespFont>
      <div style={{ textAlign: "center", margin: "20px auto" }}>
        (על פי חוק המתווכים במקרקעין)
      </div>
      <FlexRowStart>
        אנו פונים ל״
        <div
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
          }}
        >
          {company}
        </div>
        ״ או באמצעות נציגם, שם: ״
        <div
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
          }}
        >
          {fullname}
        </div>
        ״
      </FlexRowStart>
      <FlexRowStart>
        ת.ז <SignatureInput value={israelId} disabled={true} />
      </FlexRowStart>
      <FlexRowStart>
        (להלן ביחד ולחוד: "<span style={{ fontFamily: "hebM" }}>המתווך</span>")
        ו/או עובדיו ו/או מי מטעמו.
      </FlexRowStart>
      <FlexRowStart>
        לקבלת שירותי תיווך עבור הנכס/ים הרשומים להלן ונדווח לכם על כל מו"מ או
        הצעה בקשר לעסקה לגביהן
      </FlexRowStart>
      <FlexRowStart>
        <span style={{ fontFamily: "hebM", marginLeft: "3px" }}>
          1) פרטי הלקוח:
        </span>{" "}
        שם: <SignatureInput required={true} /> ת.ז.{" "}
        <SignatureInput required={true} />
      </FlexRowStart>
      <FlexRowStart style={{ paddingRight: "10px" }}>
        <span style={{ fontFamily: "hebM", marginLeft: "3px" }}>
          פרטי הלקוח:
        </span>{" "}
        שם: <SignatureInput /> ת.ז. <SignatureInput />
      </FlexRowStart>
      <FlexRowStart>
        <span style={{ fontFamily: "hebM" }}>כתובת:</span> <SignatureInput />{" "}
        טלפון: <SignatureInput /> נייד: <SignatureInput required={true} />
      </FlexRowStart>
      <FlexRowStart>
        <span style={{ fontFamily: "hebM" }}>2) סוג העסקה:</span>
        <FlexRowStart
          style={{
            width: "max-content",
            justifyContent: "flex-start",
            marginRight: "15px",
            marginLeft: "0",
            marginTop: "0px",
          }}
        >
          <ColorCheckbox
            checked={isSale}
            color={"blue"}
            handleCHange={() => {}}
          />
          <RespFont style={{ marginRight: "2px" }}>מכירה</RespFont>
        </FlexRowStart>
        <FlexRowStart
          style={{
            width: "max-content",
            justifyContent: "flex-start",
            marginRight: "15px",
            marginTop: "0px",
          }}
        >
          <ColorCheckbox
            checked={isRent}
            color={"blue"}
            handleCHange={() => {}}
          />
          <RespFont style={{ marginRight: "2px" }}>השכרה</RespFont>
        </FlexRowStart>
      </FlexRowStart>

      <FlexRowStart
        style={{ justifyContent: "flex-start", fontFamily: "hebM" }}
      >
        ותיאור הנכס: גוש{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginLeft: "20px",
            marginRight: "4px",
            marginTop: "-2px",
          }}
        >
          {doc?.gush !== null ? doc?.gush : "______"}
        </span>{" "}
        חלקה{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginLeft: "20px",
            marginRight: "4px",
            marginTop: "-2px",
          }}
        >
          {doc?.helka !== null ? doc?.helka : "______"}
        </span>{" "}
        תת חלקה{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginRight: "4px",
            marginTop: "-2px",
          }}
        >
          {doc?.tatHelka !== null ? doc?.tatHelka : "______"}
        </span>
      </FlexRowStart>
      <FlexRowStart style={{ fontFamily: "hebM" }}>
        3) הצהרה: אני הח"מ מצהיר בזה כי אני בעל הזכויות בנכס ו/או מוסמך מטעמו של
        בעל הזכויות בנכס למכור/להשכיר את הנכס.
      </FlexRowStart>
      <FlexRowStart style={{ fontFamily: "hebM" }}>
        סוג הנכס:{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.assetKind}
        </span>{" "}
        כתובת:{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.assetAddress}
        </span>
      </FlexRowStart>
      <FlexRowStart style={{ fontFamily: "hebM" }}>
        שם הבעלים:{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.assetOwner}
        </span>{" "}
        מחיר מבוקש:{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.assetPrice}
        </span>
      </FlexRowStart>

      <HomeTextArea
        type="text"
        rows="3"
        disabled={true}
        value={doc?.notes}
        style={{
          border: "1px solid silver",
          maxWidth: "700px",
          margin: "10px auto",
        }}
        placeholder={"הערות"}
        pHcolor={"silver"}
      />

      <FlexRowStart>
        <span style={{ fontFamily: "hebM", marginLeft: "3px" }}>
          4) דמי התיווך:
        </span>{" "}
        ישולמו מיד לאחר התקשרות הצדדים שבאמצעותכם בהסכם מחייב כלשהו:{" "}
      </FlexRowStart>
      <FlexRowStart
        style={{
          borderBottom: "1px solid black",
          width: "max-content",
          marginRight: "0px",
          height: "21px",
        }}
      >
        {doc?.fees}
      </FlexRowStart>
      <FlexRowStart>
        (לסכומים כאמור יתווסף מע"מ לפי שיעורו כחוק) אם אמכור את המושכר תוך תקופת
        השכירות או אחריה הנני מתחייב/ת לשלם לכם שכר נוסף בשיעור %2 מהסכום הכולל
        של העסקה.
      </FlexRowStart>
      <FlexRowStart>
        <span style={{ fontFamily: "hebM", marginLeft: "3px" }}>
          5) בלעדיות:
        </span>{" "}
        שתחל בתאריך{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.startDate.replace(/T.*/, "").split("-").reverse().join("/")}{" "}
        </span>
        עד לתאריך{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.endDate.replace(/T.*/, "").split("-").reverse().join("/")}
        </span>
      </FlexRowStart>
      <FlexRowStart>(להלן תקופת בלעדיות)</FlexRowStart>
    </>
  );
};
export default SellDoc;
