import React from "react";
import { useSelector } from "react-redux";
import GenericSvg from "../footer/coloredSvgs/GenericSvg";
import { svgs } from "../footer/coloredSvgs/svgs";

const CommonEdit = ({
  func,
  bottom = "20px",
  top = null,
  left = "20px",
  right = "30px",
  shadow = "0 2px 2px rgba(0, 0, 0, 0.11), 0 -2px 2px rgba(0, 0, 0, 0.11)",
  color = false,
  pen = false,
}) => {
  const { user } = useSelector((state) => state.authReducer);
  let widthS = window.innerWidth; // 1728

  return (
    <div
      onClick={() => func()}
      style={{
        width: "min-content",
        boxShadow: shadow,
        borderRadius: "50px",
        padding: widthS > 768 ? "5px" : "3px",
        fontWeight: "700",
        right: right,
        cursor: "pointer",
        position: "absolute",
        bottom: bottom,
        left: left,
        top: top,
      }}
    >
      <div
        style={{
          padding: widthS > 768 ? "10px 10px" : "4px 7px",
          backgroundColor: color ? color : user.settings.colors.buttonsColor,
          transition: "all 0.2s",
          borderRadius: "50px",
          width: "max-content",
        }}
      >
        <GenericSvg
          fill={pen ? pen : user.settings.colors.buttonsFontColor}
          size={widthS > 768 ? "20px" : "14px"}
          svg={svgs.edit1}
          viewBox={"0 0 32 32"}
        />
      </div>
    </div>
  );
};

export default CommonEdit;
