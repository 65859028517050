import React from "react";
import { useSelector } from "react-redux";
import ScrollToTopSvg from "../../footer/coloredSvgs/ScrollToTopSvg";
import { addAlpha, getImageUrl } from "../../modals/Toast";
import {
  DisplayNoneComputer,
  DisplayNonePhone,
  FlexRowBetween,
  FlexRowStart,
  RespFont,
} from "../../Style";

const UserFooterVertical = (props) => {
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { user } = useSelector((state) => state.authReducer);
  let logoPic =
    user?.logoPic !== ""
      ? `${getImageUrl()}${user?._id}/${user?.logoPic}`
      : `${getImageUrl()}sys/defaultAgentLogo.png`;
  return (
    <FlexRowBetween
      onClick={() => handleClickScroll(props.id)}
      style={{
        justifyContent: "space-around",
        padding: "10px 10px",
        backgroundColor: user?.settings.colors.compNav,
        zIndex: 1,
      }}
    >
      <div>
        <FlexRowBetween
          style={{
            fontFamily: "ralewayLight",
            justifyContent: "center",
            fontStyle: "italic",
          }}
        >
          <RespFont style={{ color: user?.settings.colors.compNavFont }}>
            כל הזכויות שמורות ל{user?.settings.contacts.footer}
          </RespFont>
        </FlexRowBetween>
        <FlexRowBetween
          style={{
            fontWeight: "100",
            justifyContent: "center",
            fontSize: "12px",
            fontStyle: "oblique",
            marginTop: "10px",
          }}
        >
          <a
            onClick={(e) => e.stopPropagation()}
            style={{
              textDecoration: "none",
              color: user?.settings.colors.compNavFont,
            }}
            target="_blank"
            rel="noreferrer"
            href={`https://nadlan.agency`}
          >
            <RespFont pSize={"12px"} cSize={"14px"}>
              אתר זה נבנה באמצעות פלטפורמת הנדלן מבית Be Simple
            </RespFont>
          </a>
        </FlexRowBetween>
      </div>
      <img src={logoPic} alt="logo" style={{ width: "80px" }} />
    </FlexRowBetween>
    // <div
    //   style={{
    //     padding: "10px 0",
    //     backgroundColor: user?.settings.colors.compNav,
    //   }}
    // >
    //   <DisplayNonePhone>
    //     <FlexRowBetween style={{ margin: "0" }}>
    //       <FlexRowStart
    //         onClick={() => handleClickScroll(props.id)}
    //         style={{ width: "auto", marginRight: "0" }}
    //       >
    //         <RespFont
    //           style={{
    //             color: user?.settings.colors.compNavFont,
    //             padding: "0 20px",
    //           }}
    //         >
    //           כל הזכויות שמורות ל{user?.settings.contacts.footer}
    //         </RespFont>
    //         <div
    //           style={{
    //             backgroundColor: addAlpha(
    //               user?.settings.colors.compNavFont,
    //               0.5
    //             ),
    //             width: "2px",
    //             height: "50px",
    //             marginLeft: "10px",
    //           }}
    //         />
    //         <img src={logoPic} style={{ width: "70px", marginRight: "10px" }} />
    //       </FlexRowStart>

    //       <RespFont
    //         style={{
    //           color: user?.settings.colors.compNavFont,
    //           marginTop: "10px",
    //           padding: "0 20px",
    //           width: "max-content",
    //           // textAlign: "center",
    //         }}
    //       >
    //         <a
    //           style={{
    //             textDecoration: "none",
    //             color: user?.settings.colors.compNavFont,
    //             textAlign: "center",
    //           }}
    //           target="_blank"
    //           rel="noreferrer"
    //           href={`https://nadlan.agency`}
    //         >
    //           אתר זה נבנה באמצעות פלטפורמת הנדלן מבית Be Simple
    //         </a>
    //       </RespFont>
    //     </FlexRowBetween>
    //   </DisplayNonePhone>
    //   <DisplayNoneComputer>
    //     <FlexRowBetween onClick={() => handleClickScroll(props.id)}>
    //       <RespFont
    //         style={{
    //           color: user?.settings.colors.compNavFont,
    //           padding: "0 20px",
    //         }}
    //       >
    //         כל הזכויות שמורות ל{user?.settings.contacts.footer}
    //       </RespFont>

    //       <div
    //         style={{
    //           backgroundColor: addAlpha(user?.settings.colors.compNavFont, 0.5),
    //           width: "2px",
    //           height: "50px",
    //           marginLeft: "10px",
    //         }}
    //       />
    //       <img
    //         src={logoPic}
    //         style={{ width: "70px", margin: "0px auto", marginLeft: "10px" }}
    //       />
    //     </FlexRowBetween>
    //     <RespFont
    //       style={{
    //         color: user?.settings.colors.compNavFont,
    //         marginTop: "10px",
    //         padding: "0 20px",
    //         textAlign: "center",
    //       }}
    //     >
    //       <a
    //         style={{
    //           textDecoration: "none",
    //           color: user?.settings.colors.compNavFont,
    //           textAlign: "center",
    //         }}
    //         target="_blank"
    //         rel="noreferrer"
    //         href={`https://nadlan.agency`}
    //       >
    //         אתר זה נבנה באמצעות פלטפורמת הנדלן מבית
    //       </a>
    //     </RespFont>
    //     <RespFont
    //       style={{
    //         color: user?.settings.colors.compNavFont,
    //         padding: "0 20px",
    //         textAlign: "center",
    //       }}
    //     >
    //       <a
    //         style={{
    //           textDecoration: "none",
    //           color: user?.settings.colors.compNavFont,
    //           textAlign: "center",
    //         }}
    //         target="_blank"
    //         rel="noreferrer"
    //         href={`https://nadlan.agency`}
    //       >
    //         Be Simple
    //       </a>
    //     </RespFont>
    //   </DisplayNoneComputer>
    // </div>
  );
};

export default UserFooterVertical;
