import React, { useMemo, useEffect, useState } from "react";
import {
  getAll,
  cleanAll,
  deleteWaiting,
} from "../../../store/reducers/waitingReducer";
import { postCsv } from "../../../store/reducers/dataReducer";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  addAlpha,
  availHeight,
  getImageUrl,
  GetToast,
  parseDate,
} from "../../common/modals/Toast";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  ClientCard,
  AssetPic,
  AssetsHolder,
  constants,
  FlexRowBetween,
  GenericDiv,
  ClosenessTitle2,
  PageTitle,
  DropOption,
  Section,
  RespFont,
  FlexColumnBetween,
} from "../../Style";
import sort from "../../../assets/nadlan/sort.svg";
import GenericModal from "../../common/modals/GenericModal";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import {
  noWaitings,
  svgs,
  zaxEdit,
} from "../../common/footer/coloredSvgs/svgs";
import errors from "../../../assets/nadlan/errors1.svg";
import downArrowB from "../../../assets/nadlan/downArrowB.svg";
import downArrowW from "../../../assets/nadlan/downArrowW.svg";
import upArrowB from "../../../assets/nadlan/upArrowB.svg";
import upArrowW from "../../../assets/nadlan/upArrowW.svg";

import YesNoModal from "../../common/modals/YesNoModal";
import OpacityLoading from "../../common/loader/OpacityLoading";
import AskForDownload from "../../common/modals/AskForDownload.js.js";

import readXlsxFile from "read-excel-file";
import { useExcelDownloder } from "react-xls";
import EditNotes from "./EditNotes";
import SendMessage from "../../common/modals/SendMessage";
let dic = {
  minSquareMeters: "מ״ר מינימלי",
  minRooms: "מס׳ חדרים מינימלי",
  minPrice: "מחיר מינימלי",
  maxSquareMeters: "מ״ר מקסימלי",
  maxRooms: "מס׳ חדרים מקסימלי",
  maxPrice: "מחיר מקסימלי",
  preferredCity: "עיר מועדפת",
};
let multyChoise = {};
let multyChoiseCounter = 0;
const WaitingList = (props) => {
  let widthS = window.innerWidth; // 1728
  const [send, setSend] = useState(false);

  const { ExcelDownloder, Type } = useExcelDownloder();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { waiting, waitingError, waitingLoading } = useSelector(
    (state) => state.waitingReducer
  );
  const { dataError } = useSelector((state) => state.dataReducer);

  const { user } = useSelector((state) => state.authReducer);
  const [askForDownload, setAskForDownload] = useState(false);
  const [deleteGuests, setDeleteGuests] = useState(false);
  const [deleteGuest, setDeleteGuest] = useState(false);
  const [showEditNote, setShowEditNote] = useState(false);
  const [selected, setSelected] = useState(0);
  const [closenessChosenFlag, setClosenessChosenFlag] = useState(false);
  const [showDropOwc, setShowDropOwc] = useState(false);

  const [sortBy, setSortBy] = useState("dateAdded");
  const [sortDirection, setSortDirection] = useState(false);
  const [editAsset, setEditAsset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  let height = window.innerHeight;

  useEffect(() => {
    dispatch(getAll());
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (waitingError) GetToast(addToast, "error", waitingError);
  }, [waitingError]);

  const deleteOne = () => {
    multyChoise[deleteGuest._id] = true;
    deleteFunc();
    setDeleteGuest(null);
  };
  const initDeleteOne = () => {
    setDeleteGuest(null);
  };

  const add_del = (item) => {
    if (multyChoise[item._id] !== undefined) {
      delete multyChoise[item._id];
      multyChoiseCounter--;
    } else {
      multyChoise[item._id] = `+972${item.phone}`;
      multyChoiseCounter++;
    }
    setSelected(multyChoiseCounter);
    setClosenessChosenFlag(!closenessChosenFlag);
  };
  const deleteFunc = () => {
    dispatch(deleteWaiting(Object.keys(multyChoise))).then(
      (data) => {
        setMultiSelect(false);
        if (!data.payload.error)
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        setDeleteGuests(false);
        multyChoise = {};
        multyChoiseCounter = 0;
        setSelected(0);
        setEditAsset(null);
      },
      (error) => {
        GetToast(addToast, "error", error);
        setDeleteGuests(false);
        setMultiSelect(false);
      }
    );
  };

  // const deleteGuest1 = (arr = null) => {
  //     dispatch(deleteGuest(arr !== null ? arr : guestToDelete)).then((data) => {
  //         setDeleteMode(false)
  //         setMultiSelect(false)
  //         if (!data.payload.error)
  //             GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!")
  //         setDeleteGuest(null);
  //         multyChoise = {};
  //         multyChoiseCounter = 0;
  //         setSelected(0)
  //         setEdit(null);
  //     }, error => {
  //         GetToast(addToast, "error", error)
  //         setDeleteMode(false)
  //         setMultiSelect(false)
  //     })
  // }

  const handleClick = (e, data) => {
    if (e.type === "click") {
      if (multiSelect) add_del({ _id: data._id, phone: data.phone });
      else setEditAsset(data);
    } else if (e.type === "contextmenu") {
      e.preventDefault();
      console.log("Right click");
    }
  };

  const handleSetSend = () => {
    setMultiSelect(false);
    multyChoise = {};
    multyChoiseCounter = 0;
    setSelected(0);
    setSend(false);
  };

  const phonesToList = () => Object.values(multyChoise).join(",");

  let guestsArray = useMemo(() => {
    let arr = [];
    let excel = [];
    let sortedArray = [];
    const addBookmark = (category) => {
      arr.push(
        <div style={{ width: "100%" }}>
          <ClosenessTitle2
            color={user.settings.colors.buttonsColor}
            id={category}
            key={category}
          >
            {category}
          </ClosenessTitle2>
        </div>
      );
    };
    if (Object.keys(waiting).length > 0) {
      // sortedArray.push({ ...value, dateAdded: parseDate(value.dateAdded) });
      for (const [key, value] of Object.entries(waiting))
        sortedArray.push(value);
      switch (sortBy) {
        case "dateAdded":
          if (sortDirection)
            sortedArray.sort(
              (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
            );
          else
            sortedArray.sort(
              (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
            );
          break;
        case "price":
          if (sortDirection)
            sortedArray.sort(
              (a, b) =>
                (a["salePrice"] === null ? a["price"] : a["salePrice"]) -
                (b["salePrice"] === null ? b["price"] : b["salePrice"])
            );
          else
            sortedArray.sort(
              (a, b) =>
                (b["salePrice"] === null ? b["price"] : b["salePrice"]) -
                (a["salePrice"] === null ? a["price"] : a["salePrice"])
            );
          break;
        // case "rent":
        //     if (sortDirection) sortedArray.sort((a, b) => Number(b[sortBy]) - Number(a[sortBy]));
        //     else sortedArray.sort((a, b) => Number(a[sortBy]) - Number(b[sortBy]));
        //     break;
        case "preferredCity":
          if (sortDirection)
            sortedArray.sort((a, b) => b[sortBy].localeCompare(a[sortBy]));
          else sortedArray.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
          else sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
      }
      let newSortedArray = [];
      for (let j = 0; j < sortedArray.length; j++)
        newSortedArray.push({
          ...sortedArray[j],
          dateAdded: parseDate(sortedArray[j]["dateAdded"]),
        });

      if (newSortedArray.length)
        addBookmark(
          sortBy === "dateAdded"
            ? parseDate(newSortedArray[0][sortBy])
            : sortBy === "preferredCity" && newSortedArray[0][sortBy] === ""
            ? "אין עיר מועדפת"
            : newSortedArray[0][sortBy]
        );
      for (let i = 0; i < newSortedArray.length; i++) {
        if (
          i !== 0 &&
          newSortedArray[i][sortBy] !== newSortedArray[i - 1][sortBy]
        )
          addBookmark(
            sortBy === "dateAdded"
              ? parseDate(newSortedArray[i][sortBy])
              : sortBy === "preferredCity" && newSortedArray[i][sortBy] === ""
              ? "אין עיר מועדפת"
              : newSortedArray[i][sortBy]
          );

        arr.push(
          <ClientCard
            key={i + 1}
            onClick={(e) => handleClick(e, newSortedArray[i])}
            onContextMenu={(e) => handleClick(e, newSortedArray[i])}
            style={{
              background:
                multyChoise[newSortedArray[i]._id] !== undefined
                  ? `${addAlpha(user.settings.colors.buttonsColor, 0.15)}`
                  : "rgba(255,255,255,0.5)",
            }}
          >
            <FlexRowBetween style={{ alignItems: "flex-start" }}>
              <FlexColumnBetween>
                <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                  <div style={{ fontFamily: "hebM" }}>
                    {newSortedArray[i].name}
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div>
                    {newSortedArray[i].preferredCity !== ""
                      ? newSortedArray[i].preferredCity
                      : "אין עיר מועדפת"}
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div>
                    {newSortedArray[i].minPrice +
                      "-" +
                      newSortedArray[i].maxPrice}
                    ₪
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div>
                    {newSortedArray[i].minRooms +
                      "-" +
                      newSortedArray[i].maxRooms +
                      " חדרים"}
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div>
                    {newSortedArray[i].minSquareMeters +
                      "-" +
                      newSortedArray[i].maxSquareMeters +
                      " מ״ר"}
                  </div>
                </FlexRowBetween>
              </FlexColumnBetween>
              <FlexColumnBetween
                style={{
                  width: "min-content",
                  height: "100%",
                  //   alignItems:"",
                  marginLeft: "0px",
                  padding: "10px",
                  backgroundColor: addAlpha(
                    user.settings.colors.buttonsColor,
                    0.065
                  ),
                }}
              >
                <a
                  style={{ filter: "brightness(80%)" }}
                  target="_blank"
                  //   style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
                  rel="noreferrer"
                  href={`https://wa.me/972${newSortedArray[i].phone}/?text=`}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    //   size={widthS > 768 ? "35px" : "25px"}
                    size={"20px"}
                    svg={svgs.whatsapp}
                    viewBox={"0 0 24 24"}
                  />
                </a>
                <a
                  style={{ marginTop: "15px", filter: "brightness(80%)" }}
                  target="_blank"
                  // style={{ marginLeft: widthS > 768 ? "15px" : "15px" }}
                  rel="noreferrer"
                  href={`tel:972${newSortedArray[i].phone}`}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    //   size={widthS > 768 ? "35px" : "25px"}
                    size={"18px"}
                    svg={svgs.phone}
                    //   viewBox={"0 0 24 24"}
                  />
                </a>
                <div
                  style={{ marginTop: "15px", filter: "brightness(80%)" }}
                  onClick={() => setShowEditNote(newSortedArray[i])}
                >
                  {zaxEdit(20, user.settings.colors.buttonsColor)}
                  {/* <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    //   size={widthS > 768 ? "35px" : "25px"}
                    size={"20px"}
                    svg={svgs.whatsapp}
                    viewBox={"0 0 24 24"}
                  /> */}
                </div>
                <div
                  style={{ marginTop: "15px", filter: "brightness(80%)" }}
                  onClick={() => setDeleteGuest(newSortedArray[i])}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    size={"20px"}
                    svg={svgs.delete}
                    viewBox={"0 0 297 297"}
                  />
                </div>
              </FlexColumnBetween>
            </FlexRowBetween>
          </ClientCard>
        );

        // prepare to excel
        excel.push({
          "שם מלא": newSortedArray[i].name,
          "מספר פלאפון": newSortedArray[i].phone,
          "עיר מועדפת": newSortedArray[i].preferredCity,
          "שכונה מועדפת": newSortedArray[i].preferredNeighborhood,
          קטגוריה: newSortedArray[i].closeness,
          "מינימום מ״ר": newSortedArray[i].minSquareMeters,
          "מינימום חדרים": newSortedArray[i].minRooms,
          "מינימום מחיר": newSortedArray[i].minPrice,
          "מקסימום מ״ר": newSortedArray[i].maxSquareMeters,
          "מקסימום חדרים": newSortedArray[i].maxRooms,
          "מקסימום מחיר": newSortedArray[i].maxPrice,
          הערות: newSortedArray[i].notes,
        });
      }
    }
    return [arr, excel];
  }, [waiting, sortBy, sortDirection, multiSelect, selected]);

  const fileSelected = (event) => {
    readXlsxFile(event.target.files[0]).then((rows) => {
      dispatch(postCsv({ rows })).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
          dispatch(getAll());
        }
      });
    });
  };

  return (
    <div
      id="waitingListA-id"
      style={{
        width: "100%",
        margin: "0 auto",
        position: "relative",
        minHeight: "70vh",
        maxHeight: "100vh",
        marginBottom: "30px",
        // overflowY: "auto",
      }}
    >
      {/* <div
        class="fb-share-button"
        data-href="https://developers.facebook.com/docs/plugins/"
        data-layout="button_count"
        data-size="small"
      >
        <a
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
          class="fb-xfbml-parse-ignore"
        >
          Share
        </a>
      </div> */}
      <Section
        cSize={"38px"}
        pSize={"34px"}
        style={{ color: constants.fontColor, marginTop: "40px" }}
      >
        רשימת המתנה
      </Section>
      <FlexRowBetween style={{ width: "95%", margin: "10px auto" }}>
        {/* מיון */}
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "60px",
            padding: widthS > 768 ? "5px" : "3px",
            fontWeight: "700",
            marginRight: "0px",
          }}
        >
          <RespFont
            pSize={"12px"}
            onClick={() => setSortBy("preferredCity")}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                sortBy === "preferredCity"
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color:
                sortBy === "preferredCity" ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            עיר
          </RespFont>
          <RespFont
            pSize={"12px"}
            onClick={() => setSortBy("minRooms")}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor: sortBy.toLowerCase().includes("rooms")
                ? user.settings.colors.buttonsColor
                : "inherit",
              transition: "all 0.2s",
              color: sortBy.toLowerCase().includes("rooms")
                ? "#fff"
                : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            חדרים
          </RespFont>
          <RespFont
            pSize={"12px"}
            onClick={() => setSortBy("minPrice")}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              backgroundColor: sortBy.toLowerCase().includes("price")
                ? user.settings.colors.buttonsColor
                : "inherit",
              transition: "all 0.2s",
              color: sortBy.toLowerCase().includes("price")
                ? "#fff"
                : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            מחיר
          </RespFont>
          <RespFont
            pSize={"12px"}
            onClick={() => setSortBy("minSquareMeters")}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              backgroundColor: sortBy.toLowerCase().includes("squaremeters")
                ? user.settings.colors.buttonsColor
                : "inherit",
              transition: "all 0.2s",
              color: sortBy.toLowerCase().includes("squaremeters")
                ? "#fff"
                : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            מ״ר
          </RespFont>
          <RespFont
            pSize={"12px"}
            onClick={() => setSortBy("dateAdded")}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                sortBy === "dateAdded"
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: sortBy === "dateAdded" ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            תאריך
          </RespFont>
        </FlexRowBetween>

        {/* כיוון */}
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "50px",
            padding: widthS > 768 ? "5px" : "3px",
            fontWeight: "700",
            marginLeft: "0px",
          }}
        >
          <RespFont
            onClick={() => setSortDirection(true)}
            style={{
              padding: widthS > 768 ? "6px 10px" : "0px 4px",
              cursor: "pointer",
              backgroundColor: sortDirection
                ? user.settings.colors.buttonsColor
                : "inherit",
              transition: "all 0.2s",
              color: sortDirection ? "#fff" : constants.fontColorZax,
              borderRadius: "50px",
              width: "max-content",
            }}
          >
            <img
              src={sortDirection ? upArrowW : upArrowB}
              style={{ width: widthS > 768 ? "23px" : "13px" }}
            />
          </RespFont>
          <RespFont
            onClick={() => setSortDirection(false)}
            style={{
              padding: widthS > 768 ? "6px 10px" : "0px 4px",
              cursor: "pointer",
              backgroundColor: !sortDirection
                ? user.settings.colors.buttonsColor
                : "inherit",
              transition: "all 0.2s",
              color: !sortDirection ? "#fff" : constants.fontColorZax,
              borderRadius: "50px",
              width: "max-content",
            }}
          >
            <img
              src={sortDirection ? downArrowB : downArrowW}
              style={{ width: widthS > 768 ? "23px" : "13px" }}
            />
          </RespFont>
        </FlexRowBetween>
      </FlexRowBetween>
      {sortBy[0] === "m" && (
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "60px",
            padding: widthS > 768 ? "5px" : "3px",
            fontWeight: "700",
            marginRight: "2.5%",
            marginTop: "10px",
          }}
        >
          <RespFont
            pSize={"12px"}
            onClick={() => {
              let temp = sortBy.split("");
              if (sortBy[1] === "a") {
                temp.splice(1, 2, "in");
                //   temp[1] = "i";
                //   temp[2] = "n";
              }
              temp = temp.join("");
              setSortBy(temp);
            }}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                sortBy[1] === "i"
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: sortBy[1] === "i" ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            רף תחתון
          </RespFont>
          <RespFont
            pSize={"12px"}
            onClick={() => {
              let temp = sortBy.split("");
              if (sortBy[1] === "i") {
                temp.splice(1, 2, "ax");
                //   temp[1] = "a";
                //   temp[2] = "x";
              }
              temp = temp.join("");
              setSortBy(temp);
            }}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                sortBy[1] === "a"
                  ? user.settings.colors.buttonsColor
                  : "inherit",
              transition: "all 0.2s",
              color: sortBy[1] === "a" ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            רף עליון
          </RespFont>
        </FlexRowBetween>
      )}
      <FlexRowBetween style={{ width: "95%", margin: "0px auto" }}>
        <>
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"ייצוא"}</Tooltip>}
          >
            <div
              onClick={() => setAskForDownload(true)}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                filter: "brightness(80%)",
                marginRight: "10px",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"28px"}
                svg={svgs.export}
                viewBox={"0 0 24 24"}
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{"בחירה מרובה"}</Tooltip>}
          >
            <div
              onClick={() => {
                if (multiSelect) {
                  multyChoise = {};
                  multyChoiseCounter = 0;
                  setSelected(0);
                }
                setMultiSelect(!multiSelect);
              }}
              style={{
                cursor: "pointer",
                padding: "10px 0",
                filter: "brightness(80%)",
                marginRight: "10px",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"28px"}
                svg={svgs.checklist}
                viewBox={"0 0 98.374 98.374"}
              />
            </div>
          </OverlayTrigger>
          {multiSelect && (
            <div
              className="animate__animated animate__fadeIn"
              style={{
                marginRight: "10px",
                fontSize: "20px",
                color: user.settings.colors.buttonsColor,
                filter: "brightness(80%)",
                fontWeight: "700",
              }}
            >
              {selected}
            </div>
          )}

          {dataError?.kind === "csv" && (
            <ExcelDownloder
              data={{ שגיאות: dataError.error }}
              filename={"שגיאות אקסל"}
              type={Type.Button} // or type={'button'}
              style={{
                border: "none",
                background: "transparent",
                width: "0px",
              }}
            >
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip>{"שגיאות"}</Tooltip>}
              >
                <img
                  src={errors}
                  style={{ width: "32px", marginRight: "15px" }}
                />
              </OverlayTrigger>
            </ExcelDownloder>
          )}
        </>
        {multiSelect ? (
          <FlexRowBetween
            style={{ justifyContent: "flex-end" }}
            className="animate__animated animate__fadeIn"
          >
            {Object.keys(multyChoise).length > 0 && (
              <div
                className="animate__animated animate__fadeInLeft"
                onClick={() => setSend(true)}
                style={{
                  cursor: "pointer",
                  marginRight: "15px",
                  filter: "brightness(90%)",
                }}
              >
                <GenericSvg
                  fill={user.settings.colors.buttonsColor}
                  size={"31px"}
                  svg={svgs.sms2}
                  viewBox={"0 0 68 68"}
                />
              </div>
            )}
            <div
              onClick={() => {
                if (Object.keys(multyChoise).length > 0) setDeleteGuests(true);
                else alert("לא נבחרו ממתינים");
              }}
              style={{
                cursor: "pointer",
                marginRight: "15px",
                filter: "brightness(80%)",
              }}
            >
              <GenericSvg
                fill={user.settings.colors.buttonsColor}
                size={"20px"}
                svg={svgs.delete}
                viewBox={"0 0 297 297"}
              />
            </div>
          </FlexRowBetween>
        ) : (
          <FlexRowBetween></FlexRowBetween>
        )}
      </FlexRowBetween>

      <AssetsHolder style={{ height: `${height - 180}px` }}>
        {guestsArray[0].length ? (
          guestsArray[0]
        ) : (
          <FlexColumnBetween style={{ justifyContent: "space-evenly" }}>
            <RespFont style={{ fontFamily: "hebB" }}>
              נראה שעדיין לא נרשמו, נחכה להתפתחויות...
            </RespFont>
            {noWaitings(
              widthS > 768 ? 432.88 : 216.44,
              widthS > 768 ? 341.445 : 170.7225,
              user.settings.colors.buttonsColor
            )}
          </FlexColumnBetween>
        )}
      </AssetsHolder>

      {waitingLoading && <OpacityLoading />}

      {deleteGuests && (
        <GenericModal
          init={setDeleteGuests}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"מחיקת רשומות"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeleteGuests}
              yes={deleteFunc}
            />
          }
        />
      )}

      {deleteGuest && (
        <GenericModal
          init={initDeleteOne}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"מחיקת רשומה"}
              body={"האם את/ה בטוח/ה?"}
              no={initDeleteOne}
              yes={deleteOne}
            />
          }
        />
      )}

      {showEditNote && (
        <GenericModal
          init={setShowEditNote}
          div={<EditNotes data={showEditNote} init={setShowEditNote} />}
        />
      )}

      {send && (
        <GenericModal
          init={setSend}
          div={
            <SendMessage
              init={handleSetSend}
              phones={phonesToList()}
              color={user.settings.colors.buttonsColor}
            />
          }
        />
      )}

      {askForDownload && guestsArray[1].length && (
        <GenericModal
          init={setAskForDownload}
          div={
            <AskForDownload
              color={user.settings.colors.buttonsColor}
              title={`נתונים`}
              filename={`נתונים`}
              body={`האם ברצונך להוריד אקסל נתונים?`}
              no={setAskForDownload}
              data={guestsArray[1]}
              init={setAskForDownload}
            />
          }
        />
      )}
    </div>
  );
};

export default WaitingList;
