import { createSlice } from '@reduxjs/toolkit';
import { post2, get2 } from '../api/http';
import ServerRoutes from '../api/routes';

const initialState = {
    article: {},
    articleError: null,
    articleLoading: false,
    getArticleLoading: false,
    articleChange: false,
}

const _addArticle = (state, action) => {
    let editedArticle = { ...state.article }
    editedArticle[action.payload.id] = { ...action.payload.body, _id: action.payload.id, dateAdded: action.payload.dateAdded, photos: [] }
    state.article = editedArticle;
};

const _setArticle = (state, action) => {
    state.article = action.payload;
};

const _editArticle = (state, action) => {
    let editedArticle = { ...state.article }
    for (const [key, value] of Object.entries(action.payload.body))
        editedArticle[action.payload.id][key] = value;
    state.article = editedArticle;
    state.articleChange = !state.articleChange;
};

const _deleteArticle = (state, action) => {
    let editedArticle = { ...state.article }
    delete editedArticle[action.payload]
    state.article = editedArticle;
};

const _actionStart = (state,) => {
    state.articleLoading = true;
    state.articleError = null;
};

const _actionSuccess = (state) => {
    state.articleLoading = false;
    state.articleError = null;
};

const _actionFailed = (state, action) => {
    state.articleLoading = false;
    state.articleError = action.payload.error;
};

const _getArticleStart = (state,) => {
    state.getArticleLoading = true;
    state.articleError = null;
};

const _getArticleSuccess = (state) => {
    state.getArticleLoading = false;
    state.articleError = null;
};

const _getArticleFailed = (state, action) => {
    state.getArticleLoading = false;
    state.articleError = action.payload.error;
};

const _cleanArticleState = (state) => {
    state.article = {};
    state.articleError = null;
    state.articleLoading = false
    state.getArticleLoading = false
};

const article = createSlice({
    name: 'article',
    initialState,
    reducers: {
        addArticle1: _addArticle,
        cleanArticleState: _cleanArticleState,
        editArticle1: _editArticle,
        deleteArticle1: _deleteArticle,
        actionStart: _actionStart,
        actionFailed: _actionFailed,
        setArticle: _setArticle,
        actionSuccess: _actionSuccess,
        getArticleStart: _getArticleStart,
        getArticleSuccess: _getArticleSuccess,
        getArticleFailed: _getArticleFailed,
    },
});

const { reducer, actions } = article;

const { actionStart, actionFailed, actionSuccess, addArticle1, editArticle1, deleteArticle1, setArticle, cleanArticleState
    , getArticleStart, getArticleSuccess, getArticleFailed } = actions;

export default reducer;

export const addArticle = (body) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { data, error, status } = await post2(ServerRoutes.Article.add, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(addArticle1({ id: data.id, dateAdded: data.dateAdded, body }));
        }
    }
};

export const getAll = () => {
    return async (dispatch) => {
        dispatch(getArticleStart())
        const { data, error, status } = await get2(ServerRoutes.Article.getAll);
        if (status !== 200)
            return dispatch(getArticleFailed({ error }));
        else {
            dispatch(getArticleSuccess())
            return dispatch(setArticle(data));
        }
    }
};

export const setArticleReduxOnly = (data) => {
    return async (dispatch) => {
        return dispatch(setArticle(data))
    }
}

export const cleanAll = () => {
    return async (dispatch) => {
        return dispatch(cleanArticleState());
    }
};


export const editArticle = (body, id) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Article.edit + id, body);
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(editArticle1({ id, body }));
        }
    }
};

export const deleteArticle = (id) => {
    return async (dispatch) => {
        dispatch(actionStart())
        const { error, status } = await post2(ServerRoutes.Article.delete, { id });
        if (status !== 200)
            return dispatch(actionFailed({ error }));
        else {
            dispatch(actionSuccess())
            return dispatch(deleteArticle1(id));
        }
    }
};