import React from "react";
import ICalendarLink from "react-icalendar-link";

const GCal = ({ mission, date, hour, div, description = "" }) => {
  let hour1 = hour.split(":")[0];
  hour1 = parseInt(hour1);
  hour1 += 2;
  let hour2 = hour1 + ":" + hour.split(":")[1];
  let date1 = date.toString().split("T")[0];
  const event = {
    title: mission,
    description: description,
    startTime: date1 + `T${hour2}:00+00:00`,
    endTime: "",
  };
  return (
    <ICalendarLink id="todo" event={event}>
      {div}
    </ICalendarLink>
  );
};
export default GCal;
