import { createSlice } from "@reduxjs/toolkit";
import ServerRoutes from "../api/routes";
import { uploadImage, post, uploadDocFile } from "../api/http";

const initialState = {
  photosError: null,
  photosLoading: false,
};

const _actionStart = (state) => {
  state.photosLoading = true;
  state.photosError = null;
};

const _actionSuccess = (state) => {
  state.photosLoading = false;
  state.photosError = null;
};

const _actionFailed = (state, action) => {
  state.photosLoading = false;
  state.photosError = action.payload.error;
};

const photos = createSlice({
  name: "photos",
  initialState,
  reducers: {
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
  },
});

const { reducer, actions } = photos;

const { actionStart, actionFailed, actionSuccess } = actions;

export default reducer;

export const uploadPhoto = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await uploadImage(
      ServerRoutes.Photos.upload + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return data.data;
    }
  };
};

export const uploadAssetPhoto = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await uploadImage(
      ServerRoutes.Photos.uploadAssetPhoto + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return data.data;
    }
  };
};

export const uploadDocPhoto = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await uploadDocFile(
      ServerRoutes.Photos.uploadDocPhoto + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return data.data;
    }
  };
};

export const uploadSignedDocPdf = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await uploadDocFile(
      ServerRoutes.Photos.uploadSignedDocPdf + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return data.data;
    }
  };
};

export const uploadDocPhotoPdf = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await uploadDocFile(
      ServerRoutes.Photos.uploadDocPhotoPdf + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return data.data;
    }
  };
};

export const deletePhoto = (key) => {
  return async () => {
    await post(ServerRoutes.Photos.delete, { key });
  };
};

export const removeBg = (key) => {
  return async () => {
    await post(ServerRoutes.Photos.removeBg, { key });
  };
};
