import React from "react";
import { useSelector } from "react-redux";
import GenericCarousle from "../../common/carousel/GenericCarousle";
import { Section } from "../../Style";
import GenericArticle from "./GenericArticle";

const ArticlesP = (props) => {
  const { user } = useSelector((state) => state.authReducer);

  return (
    <div
      style={{
        backgroundColor: user ? user.settings.pagesColors.page6 : "inherit",
      }}
    >
      <Section
        cSize={"38px"}
        pSize={"34px"}
        // style={{ color: user.settings.TitlePagesColor.page6 }}
      >
        כתבות החודש
      </Section>
      <GenericCarousle
        data={[
          <GenericArticle
            data={{
              title:
                "ההתמתנות בקצב התייקרות הדירות ממשיכה: עלו ב-14.6% במהלך 2022",
              pic: "https://storage.nadlancenter.co.il/articles_new/32168_tumb_750Xauto.jpg",
              link: "https://www.nadlancenter.co.il/article/7359",
            }}
          />,
          <GenericArticle
            data={{
              title:
                'תוכנית המתאר החדשה של רמת השרון: 110 אלף תושבים ומיליוני מ"ר משרדים',
              pic: "https://storage.nadlancenter.co.il/articles_new/31371_tumb_750Xauto.jpg",
              link: "https://www.nadlancenter.co.il/article/7319",
            }}
          />,
          <GenericArticle
            data={{
              title:
                'הדרכון האירופי מתרחק: ממשלת פורטוגל מבטלת את השקעות הנדל"ן לקבלת אזרחות',
              pic: "https://storage.nadlancenter.co.il/articles_new/30505_tumb_750Xauto.jpg",
              link: "https://www.nadlancenter.co.il/article/7236",
            }}
          />,
        ]}
      />
    </div>
  );
};

export default ArticlesP;
