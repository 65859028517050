import React, { useEffect } from "react";
import { MainDiv } from "../../Style";
import { useToasts } from "react-toast-notifications";
import {
  getImageUrl,
  GetToast,
  handleClickScroll,
} from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanDetails,
  getDetails,
} from "../../../store/reducers/detailsReducer";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBarMenuUser from "../../common/menus/NavBarMenuUser";
import BurgerMenuUser from "../../common/menus/BurgerMenuUser";
import { DisplayNoneComputer, DisplayNonePhone } from "../../common/Style";
import FacePContent from "../../common/views/FacePContent";
import FaceP from "../../common/views/FaceP";
import Face from "../../common/views/Face";
import AboutP from "../../common/views/AboutP";
import AboutC from "../../common/views/AboutC";
import QandA from "../../common/views/QandA";
import Waitings from "../../common/views/Waitings";
import Assets from "./Assets";
import UserFooter from "../../common/others/footer/UserFooter";
import ArticlesC from "./ArticlesC";
import ReferencesC from "./ReferencesC";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import { svgs } from "../../common/footer/coloredSvgs/svgs";
const Client = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { details, detailsLoading, detailsError } = useSelector(
    (state) => state.detailsReducer
  );
  let widthS = window.innerWidth; // 1728

  const { user } = useSelector((state) => state.authReducer);
  // const { qaa } = useSelector((state) => state.qaaReducer);
  const { article } = useSelector((state) => state.articleReducer);
  const { assets } = useSelector((state) => state.assetsReducer);
  const width = window.screen.availWidth;

  useEffect(() => {
    if (detailsError) GetToast(addToast, "error", detailsError);
  }, [detailsError]);

  const search = useLocation().search;
  useEffect(() => {
    let name = new URLSearchParams(search).get("a");
    const url = window.location.href.split("/");
    dispatch(getDetails(url[url.length - 1]));
    // dispatch(getDetails(name))
    return () => {
      dispatch(cleanDetails());
    };
  }, []);
  let json = {
    "itay-shetrit": ["Itay Shetrit", "6324d30a3d98db1bd9e7a486"],
    "gal-argaman": ["גל ארגמן", "6324d30a3d98db1bd9e7a486"],
  };
  let arr = window.location.href.split("/");
  let path = arr[2];
  let isAgent = arr[1] == "agents";
  let pic = "https://mqr.life/bbb.png";
  let title = "Be Simple Real Estate";
  if (isAgent) {
    let imgPath =
      "https://secret-mqr-upload.s3.eu-central-1.amazonaws.com/nadlan/";
    pic = imgPath + json[path][1] + "/profilePic";
    let mTitle = document.getElementById("mTitle");
    let mDescription = document.getElementById("mDescription");
    let mImage = document.getElementById("mImage");
    let mUrl = document.getElementById("mUrl");
    title = json[path][0];
    mTitle.content = json[path][0];
    mImage.content = pic;
    mUrl.content = window.location.href;
  }
  let logoPic =
    user?.logoPic !== ""
      ? `${getImageUrl()}${user?._id}/${user?.logoPic}`
      : `${getImageUrl()}sys/defaultAgentLogo.png`;
  return (
    <>
      {user ? (
        <MainDiv
          className="animate__animated animate__fadeIn"
          style={{
            background: `#F6F6F6`,
            position: "relative",
          }}
        >
          {/* <NavBarMenuUser
            logoPic={logoPic}
            assets={assets.length > 0}
            user={user}
          /> */}
          <BurgerMenuUser
            logoPic={logoPic}
            assets={assets.length > 0}
            user={user}
          />
          <div id={"hhhj"}></div>
          {/* <DisplayNonePhone style={{ marginTop: "69px" }} /> */}
          <DisplayNoneComputer>
            <FaceP />
            {/* <FacePContent /> */}
          </DisplayNoneComputer>
          <DisplayNonePhone>
            <Face />
          </DisplayNonePhone>
          <Assets />
          {user.settings.showPages.page3 && (
            <>
              <DisplayNoneComputer>
                <AboutP />
              </DisplayNoneComputer>
              <DisplayNonePhone>
                <AboutC />
              </DisplayNonePhone>
            </>
          )}
          {user.settings.showPages.page4 && <ReferencesC />}
          {user.settings.showPages.page5 && (
            <>
              <QandA />
            </>
          )}
          {user.settings.showPages.page6 && <ArticlesC />}
          <Waitings />
          <UserFooter id={"hhhj"} />
          <div
            onClick={() => handleClickScroll("hhhj")}
            style={{
              width: "min-content",
              boxShadow:
                "0 2px 2px rgba(0, 0, 0, 0.11), 0 -2px 2px rgba(0, 0, 0, 0.11)",
              borderRadius: "50px",
              padding: "5px",
              fontWeight: "700",
              marginRight: "30px",
              cursor: "pointer",
              position: "fixed",
              bottom: "20px",
              right: "0px",
            }}
          >
            <div
              style={{
                padding: widthS > 768 ? "4px 4px" : "2px 2px",

                backgroundColor: user.settings.colors.buttonsColor,
                transition: "all 0.2s",
                borderRadius: "50px",
                width: "max-content",
              }}
            >
              <GenericSvg
                fill={user?.settings.colors.buttonsFontColor}
                size={"28px"}
                svg={svgs.scrollTop}
                viewBox={"0 0 32 32"}
              />
            </div>
          </div>
          {/* <WaitingList /> */}
          {/* <Helmet>
            <title>My Page Title</title>
            <meta name="og:title" content={(json[path])[0]} />
            <meta name="og:image" content={pic} />
            <meta name="og:url" content={window.location.href} />
        </Helmet> */}
          {/* {about && <GenericModal init={setAbout} div={<TitleAndContent color={user.controls} title={"קצת עלי"} content={user.bio} init={setAbout} />} />} */}
          {/* {waitingList && <GenericModal init={setWaitingList} div={<Add init={setWaitingList} />} />} */}

          {detailsLoading && <OpacityLoading />}
        </MainDiv>
      ) : (
        <OpacityLoading />
      )}
    </>
  );
};

export default Client;
