import React from "react";

const OtherDoc = ({ src }) => {
  console.log("eeeee ");
  // let img = new Image();
  // img.src = src;
  // console.log(src);
  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <center>
        <iframe
          src={
            "https://secret-mqr-upload.s3.eu-central-1.amazonaws.com/nadlan/64873cdc5777cf4152dec2c8/docs/64b451b4c3a18e0f2aba4e28/jjfjfjf.pdf"
          }
          width="800"
          height="1100"
        ></iframe>
      </center>
      <object
        data={src}
        type="application/pdf"
        width="100%"
        height="1000"
      ></object>
    </div>
    // <div
    //   style={{
    //     width: img.height > 683 ? img.width * (683 / img.height) : img.width,
    //     height: img.height > 683 ? 683 : img.height,
    //     margin: "10px auto",
    //   }}
    // >
    //   <img style={{ width: "100%" }} src={src} />
    // </div>
  );
};

export default OtherDoc;
