import React, { useEffect, useState, useMemo } from "react";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import { noImages, svgs, zaxSvgs } from "../../common/footer/coloredSvgs/svgs";
import {
  addAlpha,
  commafy,
  getImageUrl,
  GetToast,
} from "../../common/modals/Toast";
import {
  FlexRowBetween,
  GenericButton,
  GenericLink,
  RespFont,
  Section,
} from "../../Style";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import SwipeableViews from "react-swipeable-views";
import { Gallery } from "react-grid-gallery";
import { useSelector, useDispatch } from "react-redux";
import { cleanAssets, getAsset } from "../../../store/reducers/assetsReducer";
import { useLocation } from "react-router-dom";
import GenericModal from "../../common/modals/GenericModal";
import bbb from "../../../assets/logo/bbb.png";
import waze from "../../../assets/nadlan/wazeSvg.svg";
import favorite from "../../../assets/nadlan/favorite.svg";
import favoriteRed from "../../../assets/nadlan/favoriteRed.svg";
import share from "../../../assets/nadlan/share.svg";
import whatsapp from "../../../assets/nadlan/whatsappLogo.svg";
import phoneLogo from "../../../assets/nadlan/phoneLogoWhite.svg";
import Carousel from "../../common/carousel/Carousel";
import ShareLink from "../../common/others/ShareLink";
import UserFooterVertical from "../../common/others/footer/UserFooterVertical";
import { autoPlay } from "react-swipeable-views-utils";
import AssetsPhotos from "./AssetsPhotos";
import {
  DisplayNoneComputer,
  FlexRowColumnPhoneReverse,
  HomeInput,
} from "../../common/Style";
import { CenteredFormPage1, MainRegister } from "../home/Style";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { addLead } from "../../../store/reducers/leadsReducer";
import GenericCarousle from "../../common/carousel/GenericCarousle";
import GenericAssetPic from "../articles/GenericAssetPic";

const Asset = () => {
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  const { addToast } = useToasts();
  const [assetId, setAssetId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selfInv, setSelfInv] = useState(false);
  let widthS = window.innerWidth; // 1728
  const [showPhotos, setShowPhotos] = useState(false);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const regex = /^[0]{0,1}[5]{1}[0-9]{8}$/g;
    let tempPhone = phone.replaceAll(/[^\d]/g, "");
    const validatePhone = tempPhone.match(regex);
    const validateName = name.length >= 2 && name.length <= 50;
    if (validateName && validatePhone !== null) {
      const body = {
        phone,
        fullname: name,
        assetId,
        uid: user?._id,
      };
      dispatch(addLead(body)).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        } else GetToast(addToast, "error", res.payload.error);
      });
    } else
      GetToast(
        addToast,
        "warning",
        "שם בין 2 ל 50 תווים, מספר טלפון ללא סימנים ורווחים, נא להקליד ידנית ספרה ספרה",
        8000
      );
  };
  const { asset } = useSelector((state) => state.assetsReducer);
  const { user } = useSelector((state) => state.authReducer);

  // const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  // const dir = "rtl";
  const search = useLocation().search;

  const dispatch = useDispatch();
  const isNumber = (value) => (parseInt(value) > 0 ? parseInt(value) : null);
  const getId = (num) => {
    let data = new URLSearchParams(search).get("a");
    // data = window.atob(data);
    data = data.split(" ");
    return data[num];
  };

  const [isFavorited, setIsFavorited] = useState(
    localStorage.getItem(`${user?.addressName}:${asset?._id}`) === "true"
  );
  useEffect(() => {
    setAssetId(getId(0));
    dispatch(getAsset(getId(0))).then((res) => {
      setLoading(false);
      setIsFavorited(
        localStorage.getItem(`${res.user?.addressName}:${res.asset?._id}`) ===
          "true"
      );
    });
    return () => {
      dispatch(cleanAssets());
    };
  }, []);
  function getImgSize(imgSrc) {
    var newImg = new Image();
    newImg.src = imgSrc; // this must be done AFTER setting onload
    return [newImg.width, newImg.height];
  }

  const toggleFavorite = () => {
    const newValue = !isFavorited;
    setIsFavorited(newValue);
    localStorage.setItem(`${user?.addressName}:${asset?._id}`, newValue);
  };

  let assetsArray = useMemo(() => {
    let pics = [];
    for (let i = 0; i < asset?.photos?.length; i++) {
      pics.push({
        original: `${getImageUrl()}${user?._id}/${assetId}/${asset?.photos[i]}`,
        thumbnail: `${getImageUrl()}${user?._id}/${assetId}/${
          asset?.photos[i]
        }`,
      });
      // pics.push(
      //   <GenericAssetPic
      //     pic={`${getImageUrl()}${user?._id}/${assetId}/${asset?.photos[i]}`}
      //   />
      // );
      // pics.push(
      //   <div
      //     style={{
      //       height: widthS > 768 ? "500px" : "300px",
      //       width: widthS > 768 ? "500px" : "300px",
      //       display: "flex",
      //       justifyContent: "center",
      //       alignItems: "center",
      //     }}
      //   >
      //     <img
      //       src={`${getImageUrl()}${user?._id}/${assetId}/` + asset?.photos[i]}
      //       style={{
      //         width: widthS > 768 ? "500px" : "300px",
      //         height: widthS > 768 ? "500px" : "300px",
      //         objectFit: "cover",
      //       }}
      //     />
      //   </div>
      // );
    }
    // console.log(pics);
    return pics;
  }, [asset, assetId, user]);

  return user === null ? (
    <GenericModal div={<img src={user === null ? bbb : user.logoPic} />} />
  ) : (
    <div
      style={{
        width: "100%",
        position: "relative",
        margin: "0 auto",
        background: "white",
      }}
    >
      {/* <div style={{ height: "0px" }}></div> */}
      <DisplayNoneComputer id={"hhhj"} style={{ height: "10px" }} />
      <FlexRowColumnPhoneReverse style={{ justifyContent: "space-evenly" }}>
        <div
          style={{
            width: "100%",
            marginTop: "0px",
            maxWidth: "500px",
          }}
        >
          <div
            style={{
              backgroundColor: addAlpha(
                user?.settings.colors.buttonsColor,
                0.1
              ),
              width: "min-content",
              borderRadius: "3px",
              marginRight: "5%",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                color: user?.settings.colors.buttonsColor,
                filter: "brightness(90%)",
                padding: "3px 9px",
                fontSize: "14px",
              }}
            >
              {asset?.saleOrRentOrSold === 1
                ? "למכירה"
                : asset?.saleOrRentOrSold === 2
                ? "להשכרה"
                : "נסגרו"}
            </div>
          </div>
          <Section
            pSize={"17px"}
            cSize={"20px"}
            style={{
              marginBottom: "0px",
              marginRight: "5%",
              textAlign: "right",
              marginTop: "10px",
            }}
          >
            {asset?.name}
          </Section>
          <FlexRowBetween
            style={{
              width: "90%",
              margin: "0 auto",
              justifyContent: "flex-start",
              color: "rgba(56, 52, 61, 0.7)",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                width: "min-content",
                filter: "brightness(80%)",
                marginLeft: "7px",
              }}
            >
              <GenericSvg
                fill={user?.settings.colors.buttonsColor}
                size={"15px"}
                svg={zaxSvgs.location}
                viewBox={"0 0 16 19"}
              />
            </div>
            <div>{asset?.address},</div>
            <div style={{ marginRight: "5px" }}>{asset?.city}</div>
          </FlexRowBetween>
          <FlexRowBetween
            style={{ width: "90%", margin: "0 auto", marginTop: "10px" }}
          >
            <FlexRowBetween
              style={{
                width: "min-content",
                justifyContent: "flex-start",
                marginRight: "0px",
              }}
            >
              <div
                style={{
                  width: "min-content",
                  filter: "brightness(80%)",
                  marginLeft: "7px",
                }}
              >
                <GenericSvg
                  fill={user?.settings.colors.buttonsColor}
                  size={"15px"}
                  svg={zaxSvgs.bed}
                  viewBox={"0 0 14 14"}
                />
              </div>
              <RespFont
                style={{
                  fontWeight: "700",
                  width: "max-content",
                }}
              >
                {asset?.rooms}
              </RespFont>
              <RespFont
                style={{
                  fontWeight: "700",
                  width: "max-content",
                  marginRight: "3px",
                }}
              >
                חדרים
              </RespFont>
            </FlexRowBetween>
            <FlexRowBetween
              style={{
                width: "min-content",
                justifyContent: "flex-start",
                // marginRight: "0px",
              }}
            >
              <div
                style={{
                  width: "min-content",
                  filter: "brightness(80%)",
                  marginLeft: "7px",
                }}
              >
                <GenericSvg
                  fill={user?.settings.colors.buttonsColor}
                  size={"15px"}
                  svg={zaxSvgs.bathroom}
                  viewBox={"0 0 14 14"}
                />
              </div>
              {/* <img src={bathroom} style={{ width: "15px", marginLeft: "7px" }} /> */}
              <RespFont
                style={{
                  fontWeight: "700",
                  width: "max-content",
                }}
              >
                {asset?.bathroom}
              </RespFont>
              <RespFont
                style={{
                  fontWeight: "700",
                  width: "max-content",
                  marginRight: "3px",
                }}
              >
                חדרי שירותים
              </RespFont>
            </FlexRowBetween>
            <FlexRowBetween
              style={{
                width: "min-content",
                justifyContent: "flex-start",
                marginLeft: "0px",
              }}
            >
              <div
                style={{
                  width: "min-content",
                  filter: "brightness(80%)",
                  marginLeft: "7px",
                }}
              >
                <GenericSvg
                  fill={user?.settings.colors.buttonsColor}
                  size={"15px"}
                  svg={zaxSvgs.expand}
                  viewBox={"0 0 14 14"}
                />
              </div>
              <RespFont
                style={{
                  fontWeight: "700",
                  width: "max-content",
                }}
              >
                {asset?.squareMeters}
              </RespFont>
              <RespFont
                style={{
                  fontWeight: "700",
                  width: "max-content",
                  marginRight: "3px",
                }}
              >
                מ״ר
              </RespFont>
            </FlexRowBetween>
          </FlexRowBetween>
          <FlexRowBetween
            style={{ width: "90%", margin: "0 auto", marginTop: "20px" }}
          >
            <FlexRowBetween
              style={{
                width: "min-content",
                justifyContent: "flex-start",
                marginRight: "0px",
              }}
            >
              <div
                onClick={() => setSelfInv(true)}
                style={{
                  width: "min-content",
                  border: "0.830357px solid rgba(0, 0, 0, 0.11)",
                  //   backdropFilter: "blur(12.4554px)",
                  borderRadius: "50px",
                  padding: "7px",
                  fontWeight: "700",
                  //   marginRight: "30px",
                  cursor: "pointer",
                }}
              >
                <img src={share} style={{ width: "20px" }} />
              </div>
              <div
                onClick={toggleFavorite}
                style={{
                  width: "min-content",
                  border: "0.830357px solid rgba(0, 0, 0, 0.11)",
                  borderRadius: "50px",
                  padding: "7px",
                  fontWeight: "700",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={isFavorited ? favoriteRed : favorite}
                  style={{ width: "20px" }}
                />
              </div>
            </FlexRowBetween>
            <RespFont
              cSize={"18px"}
              pSize={"16px"}
              style={{
                fontFamily: "hebM",
                fontWeight: "700",
                color: user?.settings.colors.buttonsColor,
              }}
            >
              {asset?.salePrice ? (
                <div>
                  <div
                    style={{
                      textDecoration: "line-through",
                      textDecorationColor: "#EB5757",
                      color: "rgba(56, 52, 61, 0.4)",
                    }}
                  >
                    {commafy(asset?.price) + "₪"}
                  </div>
                  <div>{commafy(asset?.salePrice) + "₪"}</div>
                </div>
              ) : (
                <div>{commafy(asset?.price) + "₪"}</div>
              )}
            </RespFont>
          </FlexRowBetween>

          <div
            style={{
              width: "90%",
              margin: "10px auto",
              height: "2px",
              background: "rgba(0, 0, 0, 0.11)",
            }}
          />
          <RespFont
            style={{
              width: "90%",
              margin: "20px auto",
              paddingBottom: "10px",
            }}
          >
            {asset?.description?.split("??").map((x) => (
              <div>{x}</div>
            ))}
          </RespFont>
          <CenteredFormPage1
            onSubmit={handleSubmit(onSubmit)}
            style={{
              margin: "50px auto",
              backgroundColor: "#f6f6f6",
              maxWidth: "500px",
              padding: "10px",
            }}
          >
            <FlexRowBetween style={{ width: "90%", margin: "12px auto" }}>
              <HomeInput
                pHcolor={"silver"}
                // fontFamily={"openSansRegular"}
                onChange={(e) => setName(e.target.value)}
                placeholder="שם מלא"
                style={{ width: "50%" }}
              />
              <HomeInput
                pHcolor={"silver"}
                // fontFamily={"openSansRegular"}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="מס׳ פלאפון"
                style={{ width: "45%" }}
              />
            </FlexRowBetween>
            <div>
              <GenericButton
                // hoverBackground={"#5c3e94"}
                style={{
                  padding: "10px",
                  width: "90%",
                  margin: "10px auto",
                  marginBottom: "15px",
                  fontWeight: "700",
                  background: `linear-gradient(135deg, ${
                    user?.settings.colors.buttonsColor
                  }, ${addAlpha(user?.settings.colors.buttonsColor, 0.5)})`,
                }}
                type="submit"
              >
                השארת פרטים
              </GenericButton>
            </div>
            {/* <MainRegister style={{ flexDirection: "column" }}>
            <FlexRowBetween style={{ width: "100%", margin: "12px auto" }}>
              <HomeInput
                pHcolor={"silver"}
                // fontFamily={"openSansRegular"}
                onChange={(e) => setName(e.target.value)}
                placeholder="שם מלא"
                style={{ marginRight: "5.5%" }}
              />
              <HomeInput
                pHcolor={"silver"}
                // fontFamily={"openSansRegular"}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="מס׳ פלאפון"
                style={{ marginLeft: "7.3%" }}
              />
            </FlexRowBetween>
            <div>
              <GenericButton
                // hoverBackground={"#5c3e94"}
                style={{
                  padding: "10px",
                  fontWeight: "700",
                  background: `linear-gradient(135deg, ${
                    user?.settings.colors.buttonsColor
                  }, ${addAlpha(user?.settings.colors.buttonsColor, 0.5)})`,
                }}
                type="submit"
              >
                השארת פרטים
              </GenericButton>
            </div>
          </MainRegister> */}
          </CenteredFormPage1>
        </div>
        <FlexRowBetween
          // id={"fitImage"}
          style={{
            width: "min-content",
            justifyContent: "center",
            margin: 0,
            marginTop: "10px",
            height: "100%",
          }}
        >
          {assetsArray.length > 0 ? (
            assetsArray.length == 1 ? (
              <div>dd</div>
            ) : (
              <ImageGallery
                items={assetsArray}
                showPlayButton={false}
                showThumbnails={false}
              />
            )
          ) : (
            <div
              style={{
                justifyContent: "space-evenly",
                height: "100%",
                width: "90%",
                maxWidth: "500px",
                margin: "10px auto",
              }}
            >
              <RespFont style={{ fontFamily: "hebB", textAlign: "center" }}>
                נכס זה עתה התווסף למערכת, תמונות יועלו בקרוב
              </RespFont>
              {noImages(
                widthS > 768 ? 432.88 : 216.44,
                widthS > 768 ? 341.445 : 170.7225,
                user.settings.colors.buttonsColor
              )}
            </div>
          )}
          {/* <GenericCarousle
            interval={true}
            data={assetsArray.length > 0 ? assetsArray : []}
          /> */}
          {/* <AssetsPhotos
            authenticated={false}
            isAsset={true}
            assetId={asset?._id}
            color={user?.settings.colors.buttonsColor}
            photos={asset?.photos}
            init={setShowPhotos}
            uid={user?._id}
          /> */}
        </FlexRowBetween>
      </FlexRowColumnPhoneReverse>
      <div style={{ width: "100%", background: `#F6F6F6` }}>
        <div
          style={{
            //   color: user.settings.colors.buttonsColor,
            width: "90%",
            margin: "10px auto",
          }}
        >
          <Section
            cSize={"26px"}
            pSize={"19px"}
            style={{
              borderBottom: `2px solid ${addAlpha(
                user?.settings.colors.buttonsColor,
                0.25
              )}`,
              width: "80%",
              margin: "20px auto",
              paddingTop: "30px",
            }}
          >
            {user?.settings.contacts.nameAndSubject}
          </Section>
          {user?.settings.contacts.officeAddress !== "" && (
            <FlexRowBetween
              style={{
                width: "max-content",
                marginRight: "0px",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  marginLeft: "10px",
                  padding: "11px 11px",
                  borderRadius: "50%",
                  border: `1px solid ${user?.settings.colors.buttonsColor}`,
                  // backgroundColor: "#fff",
                }}
              >
                <GenericSvg
                  fill={user?.settings.pagesColors.page7}
                  size={widthS > 768 ? "22px" : "20px"}
                  svg={zaxSvgs.location}
                  viewBox={"0 0 16 19"}
                />
              </div>
              <RespFont>{user?.settings.contacts.officeAddress}</RespFont>
            </FlexRowBetween>
          )}
          {/* http://localhost:3000/asset?a=NjQ4ZDk5ZTVlYWQ1NDkxMjUwZTExMjNi */}
          <a
            target="_blank"
            rel="noreferrer"
            href={`tel:${asset?.phone}`}
            style={{ textDecoration: "none" }}
          >
            <FlexRowBetween
              style={{
                width: "max-content",
                marginRight: "0px",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  marginLeft: "10px",
                  padding: "11px 11px",
                  borderRadius: "50%",
                  border: `1px solid ${user?.settings.colors.buttonsColor}`,
                }}
              >
                <GenericSvg
                  fill={user?.settings.pagesColors.page7}
                  size={widthS > 768 ? "22px" : "20px"}
                  svg={zaxSvgs.mobilePhone}
                  viewBox={"0 0 21 33"}
                />
              </div>
              <RespFont style={{ color: "#3a3c3f" }}>{asset?.phone}</RespFont>
            </FlexRowBetween>
          </a>
          {user?.settings.contacts.email !== "" && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`mailto:${user?.settings.contacts.email}`}
              style={{ textDecoration: "none" }}
            >
              <FlexRowBetween
                style={{
                  width: "max-content",
                  marginRight: "0px",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    padding: "11px 11px",
                    borderRadius: "50%",
                    border: `1px solid ${user?.settings.colors.buttonsColor}`,
                  }}
                >
                  <GenericSvg
                    fill={user?.settings.pagesColors.page7}
                    size={widthS > 768 ? "22px" : "20px"}
                    svg={zaxSvgs.email}
                    viewBox={"0 0 25 21"}
                  />
                </div>
                <RespFont style={{ color: "#3a3c3f" }}>
                  {user?.settings.contacts.email}
                </RespFont>
              </FlexRowBetween>
            </a>
          )}
          <FlexRowBetween
            style={{
              width: "max-content",
              marginRight: "5px",
              marginTop: "25px",
            }}
          >
            {user?.settings.contacts.facebook !== "" && (
              <a
                target="_blank"
                style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
                rel="noreferrer"
                href={user?.settings.contacts.facebook}
              >
                <GenericSvg
                  fill={user?.settings.colors.buttonsColor}
                  size={widthS > 768 ? "35.5px" : "25.5px"}
                  svg={zaxSvgs.facebook}
                  viewBox={"0 0 56 57"}
                />
              </a>
            )}
            {user?.settings.contacts.instagram !== "" && (
              <a
                target="_blank"
                style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
                rel="noreferrer"
                href={user?.settings.contacts.instagram}
              >
                <GenericSvg
                  fill={user?.settings.colors.buttonsColor}
                  size={widthS > 768 ? "35px" : "25px"}
                  svg={zaxSvgs.instagram}
                  viewBox={"0 0 60 60"}
                />
              </a>
            )}

            <a
              target="_blank"
              style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
              rel="noreferrer"
              href={`https://wa.me/972${asset?.phone}/?text=`}
            >
              <GenericSvg
                fill={user?.settings.colors.buttonsColor}
                size={widthS > 768 ? "35px" : "25px"}
                svg={svgs.whatsapp}
                viewBox={"0 0 24 24"}
              />
            </a>
            {user?.settings.contacts.officeWaze !== "" && (
              <a
                target="_blank"
                style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
                rel="noreferrer"
                href={user?.settings.contacts.officeWaze}
              >
                <GenericSvg
                  fill={user?.settings.colors.buttonsColor}
                  size={widthS > 768 ? "35px" : "25px"}
                  svg={zaxSvgs.waze}
                  viewBox={"0 0 61 55"}
                />
              </a>
            )}
            {user?.settings.contacts.twitter !== "" && (
              <a
                target="_blank"
                style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
                rel="noreferrer"
                href={user?.settings.contacts.twitter}
              >
                <GenericSvg
                  fill={user?.settings.colors.buttonsColor}
                  size={widthS > 768 ? "35px" : "25px"}
                  svg={zaxSvgs.twitter}
                  viewBox={"0 0 58 48"}
                />
              </a>
            )}
            {user?.settings.contacts.linkedin !== "" && (
              <a
                target="_blank"
                style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
                rel="noreferrer"
                href={user?.settings.contacts.linkedin}
              >
                <GenericSvg
                  fill={user?.settings.colors.buttonsColor}
                  size={widthS > 768 ? "35px" : "25px"}
                  svg={zaxSvgs.linkedin}
                  viewBox={"0 0 56 56"}
                />
              </a>
            )}
          </FlexRowBetween>
          <GenericLink
            background={"inherit"}
            target={"_blank"}
            rel="noopener noreferrer"
            href={`https://nadlan.agency/agents/${user?.addressName}`}
            style={{
              color: user?.settings.colors.buttonsColor,
              border: `1px solid ${addAlpha(
                user?.settings.colors.buttonsColor,
                0.5
              )}`,
              padding: "9px 20px",
              width: "min-content",
              margin: "50px auto",
              fontWeight: "700",
            }}
          >
            לאתר הסוכן
          </GenericLink>
        </div>
        <UserFooterVertical id={"hhhj"} />
      </div>
      {selfInv && (
        <GenericModal
          init={setSelfInv}
          div={<ShareLink link={window.location.href} color={user?.color} />}
        />
      )}
      {showPhotos && (
        <GenericModal
          init={setShowPhotos}
          div={
            <AssetsPhotos
              authenticated={false}
              isAsset={true}
              assetId={asset?._id}
              color={user?.settings.colors.buttonsColor}
              photos={asset?.photos}
              init={setShowPhotos}
              uid={user?._id}
            />
          }
        />
      )}
    </div>
  );
};

export default Asset;
