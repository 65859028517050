import React, { useState } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  GalleryOptions,
  RespFont,
} from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { editAsset, deleteAsset } from "../../../store/reducers/assetsReducer";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { addAlpha, CFV, GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import Switcher from "../../common/switcher/Switcher";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import { svgs } from "../../common/footer/coloredSvgs/svgs";
import delete1 from "../../../assets/nadlan/delete1.svg";
import galleryWhite from "../../../assets/nadlan/galleryWhite.svg";
import { HomeInput, HomeTextArea, RTextArea } from "../../common/Style";
import { editWaiting } from "../../../store/reducers/waitingReducer";
import { editLeadDetails } from "../../../store/reducers/leadsReducer";

const EditDetails = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { assetsLoading } = useSelector((state) => state.assetsReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [saleOrRentOrSold, setSaleOrRentOrSold] = useState(
    props.data.saleOrRentOrSold
  ); // 1 for sale, 2 for rent, 3 for sold
  let widthS = window.innerWidth; // 1728

  const onSubmit = async (data) => {
    const body = {
      details: data.details,
    };
    dispatch(editLeadDetails(body, props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init(false);
      }
    });
  };

  const deleteA = () => {
    dispatch(deleteAsset(props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "90%",
          margin: "10px auto",
        }}
      >
        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          הערות
        </RespFont>
        <HomeTextArea
          color={constants.fontColorZax}
          defaultValue={props.data.details}
          type="text"
          rows="3"
          style={{ margin: "0px auto" }}
          {...register("details", {
            minLength: 0,
            maxLength: 500,
          })}
        />
        {CFV("details", "עד 500 תווים", errors)}
        <FlexRowBetween style={{ margin: "0px auto", marginTop: "20px" }}>
          <GenericButton
            form="form1"
            background={user.settings.colors.buttonsColor}
            color={"#fff"}
            type="submit"
            style={{
              width: widthS > 768 ? "70%" : "60%",
              marginRight: "0px",
              padding: "10px",
              borderRadius: "5px",
              fontWeight: "bolder",
              fontSize: "16px",
              boxShadow: "none",
            }}
          >
            שמירה
          </GenericButton>

          <FlexRowBetween style={{ width: "min-content", marginLeft: "0px" }}>
            <div
              onClick={deleteA}
              style={{
                backgroundColor: constants.fontColorZax,
                borderRadius: "3px",
                cursor: "pointer",
                marginLeft: "0px",
              }}
            >
              <img
                src={delete1}
                alt="delete"
                style={{
                  width: widthS > 768 ? "40px" : "38px",
                  padding: "9px",
                }}
              />
            </div>
          </FlexRowBetween>
        </FlexRowBetween>
      </div>
      {assetsLoading && <OpacityLoading />}
    </PopupForm>
  );
};

export default EditDetails;
