import React, { useState, useMemo, useEffect } from "react";
import {
  constants,
  FlexRowBetween,
  GenericButton,
  GenericDiv,
  RespFont,
  Section,
} from "../Style";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../common/modals/Toast";
import { useForm } from "react-hook-form";
import { editDetails } from "../../store/reducers/authReducer";
import OpacityLoading from "../common/loader/OpacityLoading";
import {
  FlexRowColumn,
  FlexRowStart,
  HomeInput,
  HomeTextArea,
} from "../common/Style";
import line from "../../assets/zaks/lineB.svg";
import delete1 from "../../assets/nadlan/delete1.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../../assets/nadlan/copy.svg";

import ColorCheckbox from "../common/others/ColorCheckbox";
import EditPics from "./images/EditPics";
import GenericModal from "../common/modals/GenericModal";
import { deletePhoto } from "../../store/reducers/photosReducer";
import SketchColorPaletteG from "../common/sketchColor/SketchColorPaletteG";
import YesNoModal from "../common/modals/YesNoModal";
import GenericModalBottom from "../common/modals/GenericModalBottom";

const EditDetails = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [kind, setKind] = useState(false);
  const [allTheSame, setAllTheSame] = useState(false);
  const { user, editDetailsLoading, settingsFlag } = useSelector(
    (state) => state.authReducer
  );
  const [tempQues, setTempQues] = useState(user?.settings?.questions);

  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoOnly = async (key) => {
    await deletePhoto1(user[key]);
    dispatch(editDetails({ [key]: "" })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };
  const getd = (color, nName, pageToChange, setDisplay, kKKind) => (
    <div style={{ textAlign: "center" }}>
      <RespFont
        style={{
          fontFamily: "hebM",
          marginBottom: "5px",
          width: "max-content",
          margin: "0 auto",
          marginTop: "5px",
          textAlign: "center",
        }}
      >
        {nName}
      </RespFont>
      <div
        onClick={() => {
          setDisplayT(false);
          setDisplayP(false);
          setDisplayM(false);
          setChoosenColor(color);
          setPageToChange(pageToChange);
          if (name === nName && kKKind === kKind) {
            setName("null");
            setKKind("null");
            setDisplay(false);
          } else {
            setName(nName);
            setKKind(kKKind);
            setDisplay(true);
          }
          // setName(nName);
          // setDisplay(true);
        }}
        style={{
          border: "1px solid silver",
          padding: "3px",
          borderRadius: "3px",
          width: "min-content",
          margin: "0 auto",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            width: widthS > 768 ? "70px" : "30px",
            height: "30px",
            backgroundColor: color,
            borderRadius: "3px",
            position: "relative",
          }}
        />
      </div>
    </div>
  );
  const [displayT, setDisplayT] = useState(false);
  const [displayP, setDisplayP] = useState(false);
  const [displayM, setDisplayM] = useState(false);
  const [isLengthWasChanged, seIsLengthWasChanged] = useState(false);
  const [name, setName] = useState("null");
  const [kKind, setKKind] = useState("null");
  const [choosenColor, setChoosenColor] = useState(false);
  const [pageToChange, setPageToChange] = useState(false);
  const [showPage3, setShowPage3] = useState(user.settings.showPages.page3);
  const [showPage4, setShowPage4] = useState(user.settings.showPages.page4);
  const [showPage5, setShowPage5] = useState(user.settings.showPages.page5);
  const [showPage6, setShowPage6] = useState(user.settings.showPages.page6);

  useEffect(() => {
    setShowPage5(user.settings.showPages.page5);
  }, [user]);
  // const [showAllFeatures, setShowAllFeatures] = useState(false);
  let widthS = window.innerWidth; // 1728
  const deleteQ = (index) => {
    console.log(tempQues);
    let tt = [...tempQues];
    tt.splice(index, 1);
    setTempQues(tt);
    seIsLengthWasChanged(!isLengthWasChanged);
    console.log(tempQues);
  };
  let inputsArray = useMemo(() => {
    let inputs = [];
    let arrI = [];
    let arr = [];
    let qAAInputs = [];
    if (user.settings.about.part4.length > 0) {
      for (let i = 0; i < user.settings.about.part4.length; i++) {
        inputs.push(
          <div
            style={{
              width: "100%",
              maxWidth: "300px",
              marginRight: "10px",
              marginLeft: "10px",
              margin: "10px auto",
            }}
          >
            <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
              {`מאפיין ${i + 1}`}
            </RespFont>
            <HomeInput
              type="text"
              style={{ fontFamily: "hebM" }}
              defaultValue={user.settings.about.part4[i]["key"]}
              {...register(`about_part4_key_${i}`, {
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV(`about_part4_key_${i}`, "בין 2 ל 50 אותיות", errors)}
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              תוכן
            </RespFont>
            <HomeTextArea
              defaultValue={user.settings.about.part4[i]["value"]}
              type="text"
              rows="5"
              {...register(`about_part4_value_${i}`, {
                minLength: 2,
                maxLength: 500,
              })}
            />
            {CFV(`about_part4_value_${i}`, "בין 2 ל 500 אותיות", errors)}
          </div>
        );

        if (widthS > 768 && (i + 1) % 4 === 0) {
          arrI.push(
            <FlexRowBetween style={{ justifyContent: "center" }}>
              {[...inputs]}
            </FlexRowBetween>
          );
          inputs = [];
        }
      }
      arrI.push(
        <FlexRowBetween style={{ justifyContent: "center" }}>
          {[...inputs]}
        </FlexRowBetween>
      );
    }
    if (tempQues?.length > 0) {
      for (let i = 0; i < tempQues.length; i++) {
        // if (tempQues[i]?.ques?.length && tempQues[i]?.ans?.length)
        qAAInputs.push(
          <div
            style={{
              width: "100%",
              maxWidth: "300px",
              marginRight: "10px",
              marginLeft: "10px",
              margin: "10px auto",
            }}
          >
            <FlexRowBetween>
              <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
                {`שאלה ${i + 1}`}
              </RespFont>
            </FlexRowBetween>
            <HomeInput
              type="text"
              style={{ fontFamily: "hebM" }}
              defaultValue={tempQues[i].ques}
              {...register(`questions_ques_${i}`, {
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV(`questions_ques_${i}`, "בין 2 ל 50 אותיות", errors)}
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              תשובה
            </RespFont>
            <HomeTextArea
              defaultValue={tempQues[i].ans}
              type="text"
              rows="5"
              {...register(`questions_ans_${i}`, {
                minLength: 2,
                maxLength: 500,
              })}
            />
            {CFV(`questions_ans_${i}`, "בין 2 ל 500 אותיות", errors)}
          </div>
        );
        if (widthS > 768 && qAAInputs.length % 4 === 0) {
          arr.push(
            <FlexRowBetween style={{ justifyContent: "center" }}>
              {[...qAAInputs]}
            </FlexRowBetween>
          );
          qAAInputs = [];
        }
      }
      arr.push(
        <FlexRowBetween style={{ justifyContent: "center" }}>
          {[...qAAInputs]}
        </FlexRowBetween>
      );
    }
    return [widthS > 768 ? arrI : inputs, widthS > 768 ? arr : qAAInputs];
  }, [settingsFlag, isLengthWasChanged]);

  const handleAllTheSame = () => {
    const body = {
      settings: {
        ...user.settings,
        TitlePagesColor: {
          page1: "",
          page2: user.settings.colors.buttonsColor,
          page3: user.settings.colors.buttonsColor,
          page4: user.settings.colors.buttonsColor,
          page5: user.settings.colors.buttonsColor,
          page6: user.settings.colors.buttonsColor,
          page7: "",
        },
        pagesColors: {
          ...user.settings.pagesColors,
          page7: user.settings.colors.buttonsColor,
        },
      },
    };
    dispatch(editDetails(body)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        window.location.reload();
      } else GetToast(addToast, "error", res.payload.error);
    });
  };

  const onSubmit = async (data) => {
    const body = {
      settings: {
        ...user.settings,
        colors: {
          ...user.settings.colors,
          compNav: user.settings.colors.compNav,
          buttonsColor: user.settings.colors.buttonsColor,
        },
        showPages: {
          page1: true, // תמונה
          page2: true, // נכסים
          page3: showPage3, // מי אני
          page4: showPage4, // המלצות
          page5: showPage5, // שאלות נפוצות
          page6: showPage6, // כתבות ומאמרים
          page7: true, // רשימת המתנה וסושיאל
        },
        contacts: {
          ...user.settings.contacts,
          footer: data.footer,
        },
      },
    };
    dispatch(editDetails(body)).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      else GetToast(addToast, "error", res.payload.error);
    });
  };

  return (
    <form
      id="editDetails-id"
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "90%",
        margin: "10px auto",
      }}
    >
      <Section pSize={"28px"} cSize={"32px"} style={{ paddingTop: "30px" }}>
        עריכת פרטים
      </Section>

      <RespFont
        style={{ fontFamily: "hebB", marginBottom: "5px", textAlign: "center" }}
      >
        כתובת האתר שלי:
      </RespFont>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CopyToClipboard
          text={encodeURI(`https://nadlan.agency/agents/${user.addressName}`)}
          onCopy={() => GetToast(addToast, "info", "הקישור הועתק בהצלחה!")}
        >
          <div
            style={{
              padding: "10px 10px",
              // boxShadow: "0 1px 2px silver",
              borderRadius: "5px",
              border: "1px solid rgba(0, 0, 0, 0.11)",
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              alt={"copy"}
              src={copy}
              style={{
                width: widthS > 768 ? "20px" : "17px",
                marginLeft: "15px",
              }}
            />
            <RespFont
              cSize={"14px"}
              pSize={"12px"}
            >{`https://nadlan.agency/agents/${user.addressName}`}</RespFont>
          </div>
        </CopyToClipboard>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          margin: "0px 0px",
          height: "100%",
          overflow: "auto",
          paddingBottom: "30px",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            marginRight: "10px",
            marginLeft: "10px",
            margin: "10px auto",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כל הזכויות שמורות ל:
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.contacts.footer}
            {...register("footer", {
              required: true,
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`footer`, "בין 1 ל 50 תווים", errors)}
        </div>

        <FlexRowBetween
          style={{
            maxWidth: "100%",
            margin: "20px auto",
            justifyContent: "center",
          }}
        >
          <img src={line} style={{ width: "500px" }} />
          <Section
            pSize={"20px"}
            cSize={"24px"}
            style={{
              width: "max-content",
              margin: "0px 20px",
            }}
          >
            עמודים להצגה
          </Section>
          <img src={line} style={{ width: "500px" }} />
        </FlexRowBetween>
        <FlexRowBetween
          style={{ justifyContent: "flex-start", width: "min-content" }}
        >
          <ColorCheckbox
            checked={showPage3}
            color={user.settings.colors.buttonsColor}
            handleCHange={() => setShowPage3(!showPage3)}
          />
          <RespFont style={{ width: "max-content" }}>מי אני</RespFont>
        </FlexRowBetween>
        <FlexRowBetween
          style={{ justifyContent: "flex-start", width: "min-content" }}
        >
          <ColorCheckbox
            checked={showPage4}
            color={user.settings.colors.buttonsColor}
            handleCHange={() => setShowPage4(!showPage4)}
          />
          <RespFont style={{ width: "max-content" }}>המלצות</RespFont>
        </FlexRowBetween>
        <FlexRowBetween
          style={{ justifyContent: "flex-start", width: "min-content" }}
        >
          <ColorCheckbox
            checked={showPage5}
            color={user.settings.colors.buttonsColor}
            handleCHange={() => setShowPage5(!showPage5)}
          />
          <RespFont style={{ width: "max-content" }}>שאלות נפוצות</RespFont>
        </FlexRowBetween>
        <FlexRowBetween
          style={{ justifyContent: "flex-start", width: "min-content" }}
        >
          <ColorCheckbox
            checked={showPage6}
            color={user.settings.colors.buttonsColor}
            handleCHange={() => setShowPage6(!showPage6)}
          />
          <RespFont style={{ width: "max-content" }}>כתבות</RespFont>
        </FlexRowBetween>

        <FlexRowBetween
          style={{
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <SketchColorPaletteG
              wtc={"titles"}
              name={name}
              change={pageToChange}
              display={displayT}
              onClose={setDisplayT}
              cc={choosenColor}
            />
          </div>
        </FlexRowBetween>

        <FlexRowBetween
          style={{
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <SketchColorPaletteG
              wtc={"pages"}
              name={name}
              change={pageToChange}
              display={displayP}
              onClose={setDisplayP}
              cc={choosenColor}
            />
          </div>
        </FlexRowBetween>

        <FlexRowBetween
          style={{
            maxWidth: "100%",
            margin: "20px auto",
            justifyContent: "center",
          }}
        >
          <img src={line} style={{ width: "500px" }} />
          <Section
            pSize={"20px"}
            cSize={"24px"}
            style={{
              width: "max-content",
              margin: "0px 20px",
            }}
          >
            צבעי מערכת
          </Section>
          <img src={line} style={{ width: "500px" }} />
        </FlexRowBetween>

        {widthS > 768 ? (
          <FlexRowBetween>
            {getd(
              user.settings.colors.compNav,
              "רקע פוטר",
              "compNav",
              setDisplayM,
              "m"
            )}
            {getd(
              user.settings.colors.buttonsColor,
              "צבע אלמנטים",
              "buttonsColor",
              setDisplayM,
              "m"
            )}
          </FlexRowBetween>
        ) : (
          <>
            <FlexRowBetween style={{ justifyContent: "space-evenly" }}>
              {getd(
                user.settings.colors.compNav,
                "רקע פוטר",
                "compNav",
                setDisplayM,
                "m"
              )}
              {getd(
                user.settings.colors.buttonsColor,
                "צבע אלמנטים",
                "buttonsColor",
                setDisplayM,
                "m"
              )}
            </FlexRowBetween>
          </>
        )}
        <FlexRowBetween
          style={{
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <SketchColorPaletteG
              wtc={"user"}
              name={name}
              change={pageToChange}
              display={displayM}
              onClose={setDisplayM}
              cc={choosenColor}
            />
          </div>
        </FlexRowBetween>

        <FlexRowBetween
          style={{
            fontFamily: "hebM",
            width: "80%",
            margin: "0 auto",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <FlexRowStart style={{ margin: "0", marginBottom: "20px" }}>
            {user.logoPic !== "" && (
              <div
                onClick={() => deletePhotoOnly("logoPic")}
                style={{
                  backgroundColor: constants.fontColorZax,
                  borderRadius: "3px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                <img
                  src={delete1}
                  alt="delete"
                  style={{
                    width: widthS > 768 ? "32px" : "30px",
                    padding: "7px",
                  }}
                />
              </div>
            )}
            <GenericDiv
              background={user.settings.colors.buttonsColor}
              color={user.settings.colors.buttonsFontColor}
              style={{ borderRadius: "3px" }}
              onClick={() => {
                if (user.logoPic !== "")
                  GetToast(
                    addToast,
                    "warning",
                    "קודם יש למחוק את התמונה הקיימת ורק לאחר מכן להעלות חדשה"
                  );
                else setKind("logoPic");
              }}
            >
              לוגו
            </GenericDiv>
          </FlexRowStart>
          <GenericDiv
            background={user.settings.colors.buttonsColor}
            color={user.settings.colors.buttonsFontColor}
            style={{ borderRadius: "3px" }}
            onClick={() => setAllTheSame(true)}
          >
            צבע אחיד
          </GenericDiv>
        </FlexRowBetween>
        <FlexRowBetween
          style={{
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <GenericButton
            background={user.settings.colors.buttonsColor}
            color={user.settings.colors.buttonsFontColor}
            style={{
              width: widthS > 768 ? "50%" : "80%",
              margin: "0 auto",
              marginTop: "20px",
              fontWeight: "700",
              fontFamily: "monsterB",
              padding: "10px",
              marginBottom: "25px",
            }}
            type="submit"
          >
            <RespFont>שמירה</RespFont>
          </GenericButton>
        </FlexRowBetween>
      </div>
      {editDetailsLoading && (
        <div style={{ marginRight: "-20px" }}>
          <OpacityLoading />
        </div>
      )}

      {kind && (
        <GenericModalBottom
          init={setKind}
          div={<EditPics kind={kind} init={setKind} />}
        />
      )}

      {allTheSame && (
        <GenericModalBottom
          init={setAllTheSame}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"אחידות בצבעים"}
              body={"שינוי אלמנטים בכל המערכת לצבע אלמנטים המערכת שלך?"}
              no={setAllTheSame}
              yes={handleAllTheSame}
            />
          }
        />
      )}
    </form>
  );
};

export default EditDetails;
