import React from "react";
import PricePage from "../price/PricePage";
import { FlexRowBetween, OptionsFirst, RespFont } from "../../Style";
import NavBarMenu from "../../common/menus/NavBarMenu";
import BurgerMenu from "../../common/menus/BurgerMenu";
import Contact from "../../common/others/Contact";
import Right from "./Right";
import Left from "./Left";
import { DisplayNoneComputer, DisplayNonePhone } from "../../common/Style";
import Services from "../services/Services";
import PricePageComp from "../price/PricePageComp";
import QandARoy from "./QandARoy";
import ContactUs from "./ContactUs";
import StepsP from "../steps/StepsP";
import StepsC from "../steps/StepsC";
import ZaxLogo from "../../common/logo/ZaxLogo";
import Articles from "../articles/Articles";
import ArticlesP from "../articles/ArticlesP";
import ComingSoon from "../services/ComingSoon";
import { handleClickScroll } from "../../common/modals/Toast";
const Home = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(246, 246, 246, 1)",
        overflow: "hidden",
      }}
    >
      <NavBarMenu />
      <BurgerMenu />
      <DisplayNoneComputer
        style={{
          position: "absolute",
          top: "10px",
          left: "15px",
          zIndex: 1,
          width: "min-content",
        }}
      >
        <ZaxLogo width={"50px"} marginTop={"-20px"} mR={"-2px"} />
      </DisplayNoneComputer>
      <Contact />
      <DisplayNonePhone style={{ marginTop: "100px" }} />
      <OptionsFirst>
        <Right />
        <DisplayNoneComputer style={{ marginTop: "55px" }} />

        <Left />
      </OptionsFirst>
      <DisplayNoneComputer style={{ marginBottom: "35px" }} />

      <Services />
      <ComingSoon />

      <DisplayNoneComputer>
        <StepsP />
      </DisplayNoneComputer>
      <DisplayNonePhone>
        <StepsC />
      </DisplayNonePhone>

      <DisplayNoneComputer>
        <PricePage />
      </DisplayNoneComputer>
      <DisplayNonePhone>
        <PricePageComp />
      </DisplayNonePhone>

      <DisplayNoneComputer>
        <ArticlesP />
      </DisplayNoneComputer>
      <DisplayNonePhone>
        <Articles />
      </DisplayNonePhone>
      <QandARoy />
      <ContactUs />
      <FlexRowBetween
        onClick={() => handleClickScroll("right-id")}
        style={{
          backgroundColor: "#fff",
          flexDirection: "column",
          padding: "20px 0",
        }}
      >
        <ZaxLogo width={"90px"} marginTop={"-10px"} mR={"-2px"} />
        {/* <RespFont>חזרה למעלה</RespFont> */}
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.06)",
            width: "70%",
            margin: "5px auto",
            height: "2px",
          }}
        />
        <RespFont style={{ color: "grey" }}>
          כל הזכויות שמורות לחברת Be Simple Real Estate
        </RespFont>
      </FlexRowBetween>
    </div>
  );
};
export default Home;
