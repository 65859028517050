import { createSlice } from "@reduxjs/toolkit";
import ServerRoutes from "../api/routes";
import { get, post, get2, post2 } from "../api/http";
import { setUserReduxOnly } from "./authReducer";
import { setQaaReduxOnly } from "./qaaReducer";
import { setAssetsReduxOnly } from "./assetsReducer";
import { setArticleReduxOnly } from "./articleReducer";
import { setReferenceReduxOnly } from "./referenceReducer";

const initialState = {
  details: {},
  detailsError: null,
  detailsLoading: false,
};

const _actionStart = (state, action) => {
  state.detailsLoading = true;
  state.detailsError = null;
};
const _actionSuccess = (state, action) => {
  state.detailsLoading = false;
  state.detailsError = null;
};
const _actionFailed = (state, action) => {
  state.detailsLoading = false;
  state.detailsError = action.payload.error;
};

const _setDetails = (state, action) => {
  state.details = action.payload;
  state.detailsLoading = false;
};

const _cleanDetailsState = (state) => {
  state.details = {};
  state.detailsError = null;
  state.detailsLoading = false;
};

const _stopLoading = (state) => {
  state.authLoading = false;
};

const details = createSlice({
  name: "details",
  initialState,
  reducers: {
    setDetails: _setDetails,
    cleanDetailsState: _cleanDetailsState,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
  },
});

const { reducer, actions } = details;

const {
  setDetails,
  cleanDetailsState,
  actionStart,
  actionFailed,
  actionSuccess,
} = actions;

export default reducer;

export const cleanDetails = () => {
  return async (dispatch) => {
    return dispatch(cleanDetailsState());
  };
};

export const getDetails = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(
      ServerRoutes.Details.getById + id
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(setUserReduxOnly(data.user));
      dispatch(setReferenceReduxOnly(data.references));
      dispatch(setArticleReduxOnly(data.articles));
      // dispatch(setQaaReduxOnly(data.qaas));
      dispatch(setAssetsReduxOnly(data.assets));
      return dispatch(actionSuccess());
      // return dispatch(setDetails(data));
      // return dispatch(setDetails(data));
    }
  };
};
