import React, { useMemo, useEffect } from "react";
import GenericAccordion from "../../common/others/GenericAccordion";
import { constants, Section } from "../../Style";
import { useSelector, useDispatch } from "react-redux";
import { getAll, cleanAll } from "../../../store/reducers/qaaReducer";
import CommonEdit from "../others/CommonEdit";
import { handleClickScrollXY } from "../modals/Toast";

const QandA = ({ setEditQuesAndAns, editQuesAndAns }) => {
  const dispatch = useDispatch();

  const { user, authenticated } = useSelector((state) => state.authReducer);
  // useEffect(() => {
  //   dispatch(getAll());
  //   return () => {
  //     dispatch(cleanAll());
  //   };
  // }, []);

  let qaaArray = useMemo(() => {
    let fff = [];
    if (user?.settings?.questions?.length > 0)
      for (let i = 0; i < user.settings.questions.length; i++)
        if (
          user?.settings?.questions[i]?.ques?.length > 0 &&
          user?.settings?.questions[i]?.ans?.length > 0
        )
          fff.push({
            title: user.settings.questions[i].ques,
            content: user.settings.questions[i].ans,
          });
    // if (Object.keys(qaa).length > 0)
    //   for (const [key, value] of Object.entries(qaa))
    //     fff.push({ title: value.ques, content: value.ques });
    return fff;
  }, [user.settings.questions]);

  return (
    <div
      id={"quesAndAns-id"}
      style={{
        width: "100%",
        padding: "50px 0",
        backgroundColor: user.settings.pagesColors.page5,
        position: "relative",
      }}
    >
      {authenticated && (
        <CommonEdit
          bottom={"20px"}
          left={null}
          right={"20px"}
          func={() => {
            if (!editQuesAndAns) handleClickScrollXY(700);
            setEditQuesAndAns(!editQuesAndAns);
          }}
          color={user.settings.TitlePagesColor.page5}
        />
      )}
      <Section
        cSize={"38px"}
        pSize={"34px"}
        style={{ color: user.settings.TitlePagesColor.page5 }}
      >
        {user.settings.PagesTitle.page5}
      </Section>
      <div style={{ width: "80%", margin: "40px auto" }}>
        <GenericAccordion
          list={qaaArray}
          backgroundColor1={user.settings.pagesColors.page5}
          backgroundColor2={user.settings.pagesColors.page5}
          selectedColor={user.settings.TitlePagesColor.page5}
        />
      </div>
    </div>
  );
};

export default QandA;
