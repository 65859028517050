import React from "react";
import { FlexRowBetween, RespDiv, RespFont, Section } from "../../Style";
import leftArrow from "../../../assets/zaks/leftArrow.svg";
import { Responsive } from "../../common/Style";
import { useSelector } from "react-redux";
import { svgs } from "../../common/footer/coloredSvgs/svgs";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";

const GenericArticle = ({ setEditArticle, data }) => {
  const { user, authenticated } = useSelector((state) => state.authReducer);

  return (
    <Responsive
      width={true}
      margin={true}
      marginBottom={true}
      marBottomP={"5px"}
      widC={"20%"}
      widP={"90%"}
      marP={"0 auto"}
      marC={"15px"}
      style={{
        background: "#FFFFFF",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        borderRadius: "15px",
        height: "444px",
        minWidth: "300px",
        position: "relative",
      }}
    >
      <img
        src={data.pic}
        style={{
          height: "300px",
          width: "100%",
          borderRadius: "15px",
          padding: "5px",
        }}
      />
      <Section
        pSize={"17px"}
        cSize={"20px"}
        style={{ marginBottom: "0px", padding: "0 10px" }}
      >
        {data.title}
      </Section>
      {authenticated && user && user.role === 1 && (
        <div
          onClick={() => setEditArticle(data)}
          style={{
            width: "min-content",
            cursor: "pointer",
            filter: "brightness(80%)",
            position: "absolute",
            right: "20px",
            bottom: "20px",
          }}
        >
          <GenericSvg
            fill={user.settings.TitlePagesColor.page6}
            size={"22px"}
            svg={svgs.edit}
            viewBox={"0 0 55.25 55.25"}
          />
        </div>
      )}
      <a
        target="_blank"
        rel="noreferrer"
        href={data.link}
        style={{
          textDecoration: "none",
          color: "#38343D",
        }}
      >
        <RespFont
          pSize={"13px"}
          cSize={"15px"}
          style={{
            // width: "90%",
            // textAlign: "left",
            // margin: "10px auto",
            position: "absolute",
            left: "20px",
            bottom: "20px",
            direction: "rtl",
          }}
        >
          לכתבה המלאה
          <img src={leftArrow} style={{ width: "10px", marginRight: "5px" }} />
        </RespFont>
      </a>
    </Responsive>
  );
};

export default GenericArticle;
