import React, { useState, useMemo } from "react";
import { FlexRowBetween, GenericButton, RespFont } from "../Style";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../common/modals/Toast";
import { useForm } from "react-hook-form";
import { editDetails } from "../../store/reducers/authReducer";
import OpacityLoading from "../common/loader/OpacityLoading";
import { FlexRowStart, HomeInput, HomeTextArea } from "../common/Style";
import ColorCheckbox from "../common/others/ColorCheckbox";
import EditPics from "./images/EditPics";
import GenericModal from "../common/modals/GenericModal";
import { deletePhoto } from "../../store/reducers/photosReducer";
import SketchColorPaletteG from "../common/sketchColor/SketchColorPaletteG";

const EditDetailsContacts = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [kind, setKind] = useState(false);
  const { user, editDetailsLoading, settingsFlag } = useSelector(
    (state) => state.authReducer
  );
  const [tempQues, setTempQues] = useState(user?.settings?.questions);
  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoOnly = async (key) => {
    await deletePhoto1(user[key]);
    dispatch(editDetails({ [key]: "" })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };
  const getd = (color, nName, pageToChange, setDisplay, kKKind) => (
    <div
      style={{
        textAlign: "center",
        marginTop: widthS > 768 ? "-15px" : "0px",
        position: "relative",
        width: "100%",
        maxWidth: "300px",
        margin: "10px 20px",
      }}
    >
      <SketchColorPaletteG
        wtc={"pages"}
        name={name}
        change={pageToChange}
        display={displayP}
        onClose={setDisplayP}
        cc={choosenColor}
        top={widthS > 768 ? "44%" : "120%"}
      />
      <RespFont
        style={{
          fontFamily: "hebM",
          width: "max-content",
          textAlign: "center",
          margin: "auto",
        }}
      >
        {nName}
      </RespFont>
      <div
        onClick={() => {
          setDisplayT(false);
          setDisplayP(false);
          setDisplayM(false);
          setChoosenColor(color);
          setPageToChange(pageToChange);
          if (name === nName && kKKind === kKind) {
            setName("null");
            setKKind("null");
            setDisplay(false);
          } else {
            setName(nName);
            setKKind(kKKind);
            setDisplay(true);
          }
          // setName(nName);
          // setDisplay(true);
        }}
        style={{
          border: "1px solid silver",
          padding: "3px",
          borderRadius: "3px",
          width: "min-content",
          margin: "0 auto",
          marginTop: "5px",
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: widthS > 768 ? "70px" : "30px",
            height: "30px",
            backgroundColor: color,
            borderRadius: "3px",
            position: "relative",
          }}
        />
      </div>
    </div>
  );
  const [displayT, setDisplayT] = useState(false);
  const [displayP, setDisplayP] = useState(false);
  const [displayM, setDisplayM] = useState(false);
  const [isLengthWasChanged, seIsLengthWasChanged] = useState(false);
  const [name, setName] = useState("null");
  const [kKind, setKKind] = useState("null");
  const [choosenColor, setChoosenColor] = useState(false);
  const [pageToChange, setPageToChange] = useState(false);
  const [showPage5, setShowPage5] = useState(user.settings.showPages.page5);
  // const [showAllFeatures, setShowAllFeatures] = useState(false);
  let widthS = window.innerWidth; // 1728
  let inputsArray = useMemo(() => {
    let qAAInputs = [];

    if (tempQues?.length > 0) {
      for (let i = 0; i < tempQues.length; i++) {
        qAAInputs.push(
          <div
            style={{
              width: "100%",
              maxWidth: "300px",
              margin: "10px 20px",
            }}
          >
            <FlexRowBetween>
              <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
                {`שאלה ${i + 1}`}
              </RespFont>
            </FlexRowBetween>
            <HomeInput
              type="text"
              style={{ fontFamily: "hebM" }}
              defaultValue={tempQues[i].ques}
              {...register(`questions_ques_${i}`, {
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV(`questions_ques_${i}`, "בין 2 ל 50 אותיות", errors)}
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              תשובה
            </RespFont>
            <HomeTextArea
              defaultValue={tempQues[i].ans}
              type="text"
              rows="5"
              {...register(`questions_ans_${i}`, {
                minLength: 2,
                maxLength: 500,
              })}
            />
            {CFV(`questions_ans_${i}`, "בין 2 ל 500 אותיות", errors)}
          </div>
        );
      }
    }
    return widthS > 768 ? qAAInputs : qAAInputs;
  }, [settingsFlag, isLengthWasChanged]);

  const onSubmit = async (data) => {
    const body = {
      contactPhone: data.contactPhone,
      settings: {
        ...user.settings,
        contacts: {
          facebook: data.facebook,
          instagram: data.instagram,
          twitter: data.twitter,
          linkedin: data.linkedin,
          email: data.email,
          officeWaze: data.officeWaze,
          officeAddress: data.officeAddress,
          nameAndSubject: data.nameAndSubject,
          footer: user.settings.contacts.footer,
        },
        pagesColors: {
          ...user.settings.pagesColors,
          page7: user.settings.pagesColors.page7,
        },
        waitingList: {
          part1: data.waitingList_part1,
          part2: data.waitingList_part2,
        },
      },
    };
    dispatch(editDetails(body)).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      else GetToast(addToast, "error", res.payload.error);
    });
  };

  return (
    <form
      id="editDetails-id"
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "90%",
        margin: "10px auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          margin: "0px 0px",
          height: "100%",
          overflow: "auto",
          paddingBottom: "30px",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            פייסבוק
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.contacts.facebook}
            {...register("facebook", {
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`facebook`, "בין 1 ל 50 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            אינסטגרם
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.contacts.instagram}
            {...register("instagram", {
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`instagram`, "בין 1 ל 50 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            טוויטר
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.contacts.twitter}
            {...register("twitter", {
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`twitter`, "בין 1 ל 50 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            לינקדין
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.contacts.linkedin}
            {...register("linkedin", {
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`linkedin`, "בין 1 ל 50 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            אימייל
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.contacts.email}
            {...register("email", {
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`email`, "בין 1 ל 50 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כתובת משרד
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.contacts.officeAddress}
            {...register("officeAddress", {
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`officeAddress`, "בין 1 ל 50 תווים", errors)}
        </div>

        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            ניווט למשרד (ווייז)
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.contacts.officeWaze}
            {...register("officeWaze", {
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`officeWaze`, "בין 1 ל 50 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            שם + תפקיד
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.contacts.nameAndSubject}
            {...register("nameAndSubject", {
              required: true,
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`nameAndSubject`, "בין 1 ל 50 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            מס׳ טלפון
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.contactPhone}
            {...register("contactPhone", {
              required: true,
              pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
            })}
          />
          {CFV(`contactPhone`, "בין 1 ל 50 תווים", errors)}
        </div>
        <br />
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כותרת ראשית (טופס)
          </RespFont>
          <HomeTextArea
            defaultValue={user.settings.waitingList.part1}
            type="text"
            rows="5"
            {...register("waitingList_part1", {
              required: true,
              minLength: 1,
              maxLength: 500,
            })}
          />
          {CFV(`waitingList_part1`, "בין 1 ל 500 תווים", errors)}
        </div>

        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כותרת משנית (טופס)
          </RespFont>
          <HomeTextArea
            defaultValue={user.settings.waitingList.part2}
            type="text"
            rows="5"
            {...register("waitingList_part2", {
              required: true,
              minLength: 1,
              maxLength: 500,
            })}
          />
          {CFV(`waitingList_part2`, "בין 1 ל 500 תווים", errors)}
        </div>
        {getd(
          user.settings.pagesColors.page7,
          "צבע כפתורים + אייקונים + רקע",
          "page7",
          setDisplayP,
          "p"
        )}
        {/* <FlexRowBetween
          style={{
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <SketchColorPaletteG
              wtc={"pages"}
              name={name}
              change={pageToChange}
              display={displayP}
              onClose={setDisplayP}
              cc={choosenColor}
            />
          </div>
        </FlexRowBetween> */}

        <GenericButton
          background={user.settings.colors.buttonsColor}
          color={user.settings.colors.buttonsFontColor}
          style={{
            width: widthS > 768 ? "50%" : "80%",
            margin: "0 auto",
            marginTop: "20px",
            fontWeight: "700",
            fontFamily: "monsterB",
            padding: "10px",
            marginBottom: "25px",
          }}
          type="submit"
        >
          <RespFont>שמירה</RespFont>
        </GenericButton>
        {editDetailsLoading && (
          <div style={{ marginRight: "-20px" }}>
            <OpacityLoading />
          </div>
        )}
        {kind && (
          <GenericModal
            init={setKind}
            div={<EditPics kind={kind} init={setKind} />}
          />
        )}
      </div>
    </form>
  );
};

export default EditDetailsContacts;
