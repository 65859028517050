import React, { useState } from "react";
import PriceListPhoneMenu from "./PriceListPhoneMenu";
import Package2 from "./Package2";
import Package3 from "./Package3";
import Package4 from "./Package4";
// import { FlexRowBetween, OptionsFirst, RespImg, ServiceOption } from '../../../common/Style'
import GenericModal from "../../common/modals/GenericModal";
import TitleAndContent from "../../common/modals/TitleAndContent";
import i1 from "../home/assets/ilus4.svg";
import i2 from "../home/assets/ilus1.gif";
import {
  constants,
  FlexRowBetween,
  RespFont,
  Section,
  ServiceOption,
} from "../../Style";
import { OptionsFirst, RespImg } from "../../common/Style";
import Deal1 from "./deals/Deal1";
import Deal2 from "./deals/Deal2";
import Deal3 from "./deals/Deal3";
import Register from "../Register";
const PricePage = (props) => {
  const [package1, setPackage] = useState(3);
  const [commitment, setCommitment] = useState(1); // 1 - year, 2 - 6 months,, 3 - 3 months
  let widthS = window.innerWidth; // 1728

  const [reg, setReg] = useState(null);

  return (
    <div
      id="priceList-id"
      style={{
        backgroundColor: "#fff",
        width: "100%",
        margin: "20px auto",
        padding: "40px 0",
        position: "relative",
        // fontFamily: "monster",
        zIndex: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Section pSize={"36px"} cSize={"48px"} style={{ marginBottom: "10px" }}>
          חבילות
        </Section>

        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "60px",
            // padding: widthS > 768 ? "5px" : "3px",
            padding: "5px",
            fontWeight: "700",
            margin: "10px auto",
          }}
        >
          <RespFont
            onClick={() => setCommitment(1)}
            style={{
              padding: "7px 12px",
              cursor: "pointer",
              backgroundColor: commitment === 1 ? "#DE8556" : "inherit",
              transition: "all 0.2s",
              color: commitment === 1 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
              width: "max-content",
            }}
          >
            לחודש
          </RespFont>
          <RespFont
            onClick={() => setCommitment(2)}
            style={{
              padding: "7px 12px",
              cursor: "pointer",
              backgroundColor: commitment === 2 ? "#DE8556" : "inherit",
              transition: "all 0.2s",
              color: commitment === 2 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
              width: "max-content",
            }}
          >
            לשנה{" "}
          </RespFont>
        </FlexRowBetween>
        {package1 === 2 ? (
          <Deal1 setReg={setReg} reg={1} commitment={commitment} />
        ) : package1 === 3 ? (
          <Deal2 setReg={setReg} reg={2} commitment={commitment} />
        ) : package1 === 4 ? (
          <Deal3 setReg={setReg} reg={3} commitment={commitment} />
        ) : null}
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "60px",
            padding: "5px",
            fontWeight: "700",
            margin: "10px auto",
          }}
        >
          <RespFont
            onClick={() => setPackage(2)}
            style={{
              padding: "7px 12px",
              cursor: "pointer",
              backgroundColor: package1 === 2 ? "#DE8556" : "inherit",
              transition: "all 0.2s",
              color: package1 === 2 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            בסיסי
          </RespFont>
          <RespFont
            onClick={() => setPackage(3)}
            style={{
              padding: "7px 12px",
              cursor: "pointer",
              backgroundColor: package1 === 3 ? "#DE8556" : "inherit",
              transition: "all 0.2s",
              color: package1 === 3 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            אישי
          </RespFont>
          <RespFont
            onClick={() => setPackage(4)}
            style={{
              padding: "7px 12px",
              cursor: "pointer",
              backgroundColor: package1 === 4 ? "#DE8556" : "inherit",
              transition: "all 0.2s",
              color: package1 === 4 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            VIP
          </RespFont>
        </FlexRowBetween>
        {/* <PriceListPhoneMenu setPackage={setPackage} /> */}
      </div>
      {reg && (
        <GenericModal
          init={setReg}
          div={
            <Register paymentTag={reg} init={setReg} commitment={commitment} />
          }
        />
      )}

      {/* {reg && (
        <GenericModal
          init={setReg}
          div={<Register paymentTag={reg} init={setReg} />}
        />
      )} */}
    </div>
  );
};

export default PricePage;
