import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { uploadAssetPhoto } from "../../../store/reducers/photosReducer";
import { GetToast } from "../../common/modals/Toast";
import "../style.css";
import imageCompression from "browser-image-compression";
import { GenericButton, PopupForm } from "../../Style";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { editAsset } from "../../../store/reducers/assetsReducer";
import { editArticle } from "../../../store/reducers/articleReducer";
import ExitModal from "../../common/modals/ExitModal";
import {
  editDocDetails,
  editDocPic,
} from "../../../store/reducers/docsReducer";

const UploadArticlesPhotos = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.authReducer);

  async function postImage(image) {
    image = new File([image], `pic`, {
      type: image.type,
    });
    const formData = new FormData();
    formData.append("image", image);
    setLoading(true);

    dispatch(uploadAssetPhoto(formData, props.id)).then((data) => {
      setFile(null);
      if (image.name === data) {
        dispatch(editDocPic({ pic: data }, props.id)).then((res) => {
          if (!res.payload?.error) {
            GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
            props.handleInit();
          }
        });
      } else
        GetToast(addToast, "error", "הפעולה כשלה, נסה/י שנית או פנה/י לתמיכה");
    });
  }

  const submit = async (event) => {
    event.preventDefault();
    await postImage(file);
  };

  const aFileSelected = async (event) => {
    console.log("articles");
    let imageFile = event.target.files[0];
    if (imageFile.size > 1000000) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        setLoading(true);
        const compressedFile = await imageCompression(imageFile, options);
        setLoading(false);
        imageFile = new File([compressedFile], imageFile.name, {
          type: compressedFile.type,
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    setFile(imageFile);
  };

  return (
    <PopupForm onClick={(e) => e.stopPropagation()}>
      <ExitModal init={props.init} color={props.color} />
      <div style={{ marginTop: "5vh", fontSize: "1.1rem" }}>העלאת תמונה</div>
      <label
        htmlFor="file-upload-articles"
        className="custom-file-upload"
        style={{ marginBottom: "5vh" }}
      >
        בחירת תמונה
      </label>
      <input
        id="file-upload-articles"
        type="file"
        onChange={aFileSelected}
        accept="image/*"
      />
      {file !== null && (
        <GenericButton
          hoverBackground={"rgb(223, 223, 223)"}
          background={props.color}
          style={{
            borderRadius: "0px",
            width: "100%",
            padding: "13px",
            fontSize: "16px",
          }}
          onClick={(e) => submit(e)}
        >
          שמירה
        </GenericButton>
      )}
      {loading && <OpacityLoading />}
    </PopupForm>
  );
};

export default UploadArticlesPhotos;
