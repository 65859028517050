import { createSlice } from "@reduxjs/toolkit";
import { post2, get2 } from "../api/http";
import ServerRoutes from "../api/routes";
import { setUserReduxOnly } from "./authReducer";

const initialState = {
  assets: {},
  asset: {},
  assetsError: null,
  assetsLoading: false,
  getAssetsLoading: false,
  assetsChange: false,
};

const _cleanState = (state, action) => {
  state = { ...initialState };
};

const _addAsset = (state, action) => {
  let editedAssets = { ...state.assets };
  editedAssets[action.payload.id] = {
    ...action.payload.body,
    _id: action.payload.id,
    dateAdded: action.payload.dateAdded,
    photos: [],
  };
  state.assets = editedAssets;
};

const _setAssets = (state, action) => {
  state.assets = action.payload;
};

const _setAsset = (state, action) => {
  state.asset = action.payload;
};

const _editAsset = (state, action) => {
  let editedAssets = { ...state.assets };
  for (const [key, value] of Object.entries(action.payload.body))
    editedAssets[action.payload.id][key] = value;
  state.assets = editedAssets;
  state.assetsChange = !state.assetsChange;
};

const _deleteAsset = (state, action) => {
  let editedAssets = { ...state.assets };
  delete editedAssets[action.payload];
  state.assets = editedAssets;
  // state.assetsChange = !state.assetsChange;
};

const _actionStart = (state) => {
  state.assetsLoading = true;
  state.assetsError = null;
};

const _actionSuccess = (state) => {
  state.assetsLoading = false;
  state.assetsError = null;
};

const _actionFailed = (state, action) => {
  state.assetsLoading = false;
  state.assetsError = action.payload.error;
};

const _getAssetsStart = (state) => {
  state.getAssetsLoading = true;
  state.assetsError = null;
};

const _getAssetsSuccess = (state) => {
  state.getAssetsLoading = false;
  state.assetsError = null;
};

const _getAssetsFailed = (state, action) => {
  state.getAssetsLoading = false;
  state.assetsError = action.payload.error;
};

const _cleanAssetsState = (state) => {
  state.assets = {};
  state.assetsError = null;
  state.assetsLoading = false;
  state.getAssetsLoading = false;
};

const assets = createSlice({
  name: "assets",
  initialState,
  reducers: {
    addAsset1: _addAsset,
    cleanAssetsState: _cleanAssetsState,
    editAsset1: _editAsset,
    deleteAsset1: _deleteAsset,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    setAssets: _setAssets,
    setAsset: _setAsset,
    actionSuccess: _actionSuccess,
    getAssetsStart: _getAssetsStart,
    getAssetsSuccess: _getAssetsSuccess,
    getAssetsFailed: _getAssetsFailed,
    cleanState: _cleanState,
  },
});

const { reducer, actions } = assets;

const {
  actionStart,
  actionFailed,
  actionSuccess,
  addAsset1,
  editAsset1,
  deleteAsset1,
  setAssets,
  cleanAssetsState,
  getAssetsStart,
  getAssetsSuccess,
  getAssetsFailed,
  setAsset,
  cleanState,
} = actions;

export default reducer;

export const addAsset = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(ServerRoutes.Assets.add, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(
        addAsset1({ id: data.id, dateAdded: data.dateAdded, body })
      );
    }
  };
};

export const getAll = () => {
  return async (dispatch) => {
    dispatch(getAssetsStart());
    const { data, error, status } = await get2(ServerRoutes.Assets.getAll);
    if (status !== 200) return dispatch(getAssetsFailed({ error }));
    else {
      dispatch(getAssetsSuccess());
      return dispatch(setAssets(data));
    }
  };
};

export const getAsset = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(ServerRoutes.Assets.getAsset, {
      id,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      dispatch(setUserReduxOnly(data.user));
      dispatch(setAsset(data.asset));
      return data;
    }
  };
};

export const setAssetsReduxOnly = (data) => {
  return async (dispatch) => {
    return dispatch(setAssets(data));
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    return dispatch(cleanAssetsState());
  };
};

export const editAsset = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Assets.edit + id, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editAsset1({ id, body }));
    }
  };
};

export const cleanAssets = () => {
  return async (dispatch) => {
    dispatch(cleanState());
  };
};

export const deleteAsset = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Assets.delete, { id });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteAsset1(id));
    }
  };
};
