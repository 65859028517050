import { createSlice } from "@reduxjs/toolkit";
import { post2, get2 } from "../api/http";
import ServerRoutes from "../api/routes";

const initialState = {
  reference: {},
  referenceError: null,
  referenceLoading: false,
  getReferenceLoading: false,
  referenceChange: false,
};

const _addReference = (state, action) => {
  let editedReference = { ...state.reference };
  editedReference[action.payload.id] = {
    ...action.payload.body,
    _id: action.payload.id,
    picPosition: action.payload.picPosition,
    pic: action.payload.pic,
    photos: [],
  };
  state.reference = editedReference;
};

const _setReference = (state, action) => {
  state.reference = action.payload;
};

const _editReference = (state, action) => {
  let editedReference = { ...state.reference };
  for (const [key, value] of Object.entries(action.payload.body))
    editedReference[action.payload.id][key] = value;
  state.reference = editedReference;
  state.referenceChange = !state.referenceChange;
};

const _deleteReference = (state, action) => {
  let editedReference = { ...state.reference };
  delete editedReference[action.payload];
  state.reference = editedReference;
};

const _actionStart = (state) => {
  state.referenceLoading = true;
  state.referenceError = null;
};

const _actionSuccess = (state) => {
  state.referenceLoading = false;
  state.referenceError = null;
};

const _actionFailed = (state, action) => {
  state.referenceLoading = false;
  state.referenceError = action.payload.error;
};

const _getReferenceStart = (state) => {
  state.getReferenceLoading = true;
  state.referenceError = null;
};

const _getReferenceSuccess = (state) => {
  state.getReferenceLoading = false;
  state.referenceError = null;
};

const _getReferenceFailed = (state, action) => {
  state.getReferenceLoading = false;
  state.referenceError = action.payload.error;
};

const _cleanReferenceState = (state) => {
  state.reference = {};
  state.referenceError = null;
  state.referenceLoading = false;
  state.getReferenceLoading = false;
};

const reference = createSlice({
  name: "reference",
  initialState,
  reducers: {
    addReference1: _addReference,
    cleanReferenceState: _cleanReferenceState,
    editReference1: _editReference,
    deleteReference1: _deleteReference,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    setReference: _setReference,
    actionSuccess: _actionSuccess,
    getReferenceStart: _getReferenceStart,
    getReferenceSuccess: _getReferenceSuccess,
    getReferenceFailed: _getReferenceFailed,
  },
});

const { reducer, actions } = reference;

const {
  actionStart,
  actionFailed,
  actionSuccess,
  addReference1,
  editReference1,
  deleteReference1,
  setReference,
  cleanReferenceState,
  getReferenceStart,
  getReferenceSuccess,
  getReferenceFailed,
} = actions;

export default reducer;

export const addReference = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.References.add,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(
        addReference1({
          id: data.id,
          pic: "",
          picPosition: {
            height: 66.01218686526742,
            width: 100,
            x: 0,
            y: 9.234828496042216,
          },
          body,
        })
      );
    }
  };
};

export const getAll = () => {
  return async (dispatch) => {
    dispatch(getReferenceStart());
    const { data, error, status } = await get2(ServerRoutes.References.getAll);
    if (status !== 200) return dispatch(getReferenceFailed({ error }));
    else {
      dispatch(getReferenceSuccess());
      return dispatch(setReference(data));
    }
  };
};

export const setReferenceReduxOnly = (data) => {
  return async (dispatch) => {
    return dispatch(setReference(data));
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    return dispatch(cleanReferenceState());
  };
};

export const editReference = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.References.edit + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editReference1({ id, body }));
    }
  };
};

export const deleteReference = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.References.delete, {
      id,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteReference1(id));
    }
  };
};
