import React from "react";
import { useSelector } from "react-redux";
import {
  DarkenBackgroundImage,
  MainDiv,
  RespDiv,
  RespFont,
  Section,
} from "../../Style";
import { getImageUrl, handleClickScrollXY } from "../modals/Toast";
import CommonEdit from "../others/CommonEdit";
import { RespImgH, FlexRowColumnOpposite } from "../Style";

const Face = ({ setEditFace, editFace }) => {
  const { user, authenticated } = useSelector((state) => state.authReducer);
  let userPic =
    user.profilePic !== ""
      ? `${getImageUrl()}${user._id}/${user.profilePic}`
      : `${getImageUrl()}sys/defaultAgentPic.png`;
  let userBackgroundPic =
    user.backgroundPic !== ""
      ? `${getImageUrl()}${user._id}/${user.backgroundPic}`
      : `${getImageUrl()}sys/defaultBackgroundAgentPic.png`;
  return (
    <MainDiv
      id="faceAgentP-id"
      className="animate__animated animate__fadeIn"
      style={{
        position: "relative",
        backgroundImage: `url(${userBackgroundPic})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "800px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "-115px",
      }}
    >
      <DarkenBackgroundImage />
      <FlexRowColumnOpposite
        style={{ position: "relative", justifyContent: "space-evenly" }}
      >
        {authenticated && (
          <CommonEdit
            bottom={"-75px"}
            left={"20px"}
            right={null}
            shadow={
              "0 2px 2px rgba(255, 255, 255, 0.5), 0 -2px 2px rgba(255, 255, 255, 0.5)"
            }
            func={() => {
              if (!editFace) handleClickScrollXY(700);
              setEditFace(!editFace);
            }}
            color={user.settings.colors.buttonsColor}
          />
        )}
        <RespImgH
          cSize={"700px"}
          src={userPic}
          style={{ marginBottom: "-100px" }}
        />
        <RespDiv
          cSize={"40%"}
          pSize={"90%"}
          style={{
            direction: "rtl",
            textAlign: "center",
            color: user.settings.colors.mainContent,
          }}
        >
          <Section
            pSize={"34px"}
            cSize={"80px"}
            style={{
              margin: "0px auto",
              color: user.settings.colors.mainContent,
            }}
          >
            {user.settings.mainContent.part0}
          </Section>
          <Section pSize={"25px"} cSize={"33px"}>
            {user.settings.mainContent.part1.split("[שורה]").map((x) => (
              <div>{x}</div>
            ))}
          </Section>

          <RespFont pSize={"20px"} cSize={"28px"}>
            {user.settings.mainContent.part2.split("[שורה]").map((x) => (
              <div>{x}</div>
            ))}
          </RespFont>
          <Section pSize={"20px"} cSize={"28px"}>
            {user.settings.mainContent.part3.split("[שורה]").map((x) => (
              <div>{x}</div>
            ))}
          </Section>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://wa.me/972${
              user.contactPhone !== "" ? user.contactPhone : user.phone
            }/?text=היי, הגעתי דרך אתר הנדלן שלך, אשמח לפרטים`}
            style={{
              display: "flex",
              width: "400px",
              textDecoration: "none",
              margin: "0 auto",
            }}
          >
            <RespFont
              pSize={"20px"}
              cSize={"24px"}
              style={{
                background: user.settings.colors.buttonsColor,
                width: "100%",
                padding: "12px 10px",
                fontFamily: "hebM",
                fontWeight: "700",
                borderRadius: "2px",
                color: user.settings.colors.buttonsFontColor,
                boxShadow: "0px 7.2174px 10.3947px rgba(245, 245, 245, 0.2)",
              }}
            >
              {user.settings.mainContent.part4}
            </RespFont>
          </a>
        </RespDiv>
      </FlexRowColumnOpposite>
    </MainDiv>
  );
};

export default Face;
