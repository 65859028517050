import React, { useEffect, useMemo, useState } from "react";
import { Section } from "../../Style";
import { useSelector, useDispatch } from "react-redux";
import GenericCarousle from "../carousel/GenericCarousle";
import GenericArticle from "../../unAuth/articles/GenericArticle";
import { FlexColumnBetween, FlexRowBetween, RespFont } from "../Style";
import { noArticles, svgs } from "../footer/coloredSvgs/svgs";
import GenericSvg from "../footer/coloredSvgs/GenericSvg";
import GenericModal from "../modals/GenericModal";
import Add from "../../user/article/Add";
import { cleanAll, getAll } from "../../../store/reducers/articleReducer";
import Edit from "../../user/article/Edit";
import EditS from "../../user/article/EditS";
import CommonEdit from "../others/CommonEdit";
import { handleClickScrollXY } from "../modals/Toast";

const ArticlesC = ({ setEditArticle1, editArticle1 }) => {
  const dispatch = useDispatch();
  const [editArticle, setEditArticle] = useState(false);
  useEffect(() => {
    dispatch(getAll());
    return () => {
      dispatch(cleanAll());
    };
  }, []);
  let widthS = window.innerWidth; // 1728
  let height = window.innerHeight;
  const [addArticle, setAddArticle] = useState(false);

  const { user, authenticated } = useSelector((state) => state.authReducer);
  const { article, articleError, articleLoading, articleChange } = useSelector(
    (state) => state.simpleArticleReducer
  );
  let articleArray = useMemo(() => {
    let arr = [];
    let arrP = [];

    let sortedArray = [];
    if (Object.keys(article).length > 0) {
      for (const [key, value] of Object.entries(article))
        sortedArray.push(value);

      let temp = [];
      for (let i = 0; i < sortedArray.length; i++) {
        arrP.push(
          <GenericArticle
            setEditArticle={setEditArticle}
            data={sortedArray[i]}
          />
        );
        temp.push(
          <GenericArticle
            setEditArticle={setEditArticle}
            data={sortedArray[i]}
          />
        );
        if ((i + 1) % 3 === 0) {
          arr.push(
            <FlexRowBetween style={{ justifyContent: "center" }}>
              {[...temp]}
            </FlexRowBetween>
          );
          temp = [];
        }
      }
      arr.push(
        <FlexRowBetween style={{ justifyContent: "center" }}>
          {[...temp]}
        </FlexRowBetween>
      );
    }
    return [arr, arrP];
  }, [articleChange, article]);

  return (
    <div
      style={{
        backgroundColor: user.settings.pagesColors.page6,
        width: "100%",
        position: "relative",
        minHeight: "500px",
      }}
      id={"articleA-id"}
    >
      {authenticated && (
        <CommonEdit
          top={"20px"}
          bottom={null}
          left={"20px"}
          right={null}
          func={() => {
            if (!editArticle1) handleClickScrollXY(900);
            setEditArticle1(!editArticle1);
          }}
          color={user.settings.TitlePagesColor.page6}
        />
      )}
      {authenticated && user && user.role === 1 && (
        <FlexRowBetween
          onClick={() => setAddArticle(true)}
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "50px",
            padding: widthS > 768 ? "5px" : "3px",
            position: "absolute",
            fontWeight: "700",
            // marginRight: "30px",
            cursor: "pointer",
            top: "20px",
            right: "20px",
          }}
        >
          <div
            style={{
              padding: widthS > 768 ? "8px 8px" : "2.2px 3px",
              backgroundColor: user.settings.TitlePagesColor.page6,
              transition: "all 0.2s",
              borderRadius: "50px",
              width: "max-content",
            }}
          >
            <GenericSvg
              fill={"#fff"}
              size={widthS > 768 ? "23px" : "19px"}
              svg={svgs.plus}
              viewBox={"0 0 16 16"}
            />
          </div>
        </FlexRowBetween>
      )}
      <Section
        cSize={"38px"}
        pSize={"34px"}
        style={{
          color: user.settings.TitlePagesColor.page6,
          paddingTop: "40px",
        }}
      >
        {user.settings.PagesTitle.page6}
      </Section>
      {widthS > 768 ? (
        <div
          style={{
            height: `${height - 165}px`,
            overflow: "auto",
            padding: "40px 0",
            width: "100%",
          }}
        >
          {articleArray[0].length ? (
            articleArray[0]
          ) : (
            <FlexColumnBetween
              style={{
                justifyContent: "space-evenly",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <RespFont style={{ fontFamily: "hebB" }}>
                אין כתבות/מאמרים, התחילו להוסיף...
              </RespFont>
              {noArticles(
                widthS > 768 ? 432.88 : 216.44,
                widthS > 768 ? 341.445 : 170.7225,
                user.settings.TitlePagesColor.page6
              )}
            </FlexColumnBetween>
          )}
          {/* {articleArray[0]} */}
        </div>
      ) : articleArray[1].length ? (
        <GenericCarousle data={articleArray[1]} />
      ) : (
        <FlexColumnBetween
          style={{ justifyContent: "space-evenly", minHeight: "90vh" }}
        >
          <RespFont style={{ fontFamily: "hebB" }}>
            אין כתבות/מאמרים, התחילו להוסיף...
          </RespFont>
          {noArticles(
            widthS > 768 ? 432.88 : 216.44,
            widthS > 768 ? 341.445 : 170.7225,
            user.settings.TitlePagesColor.page6
          )}
        </FlexColumnBetween>
      )}
      {/* {widthS > 768 ? (
        <div
          style={{
            height: `${height - 165}px`,
            overflow: "auto",
            padding: "40px 0",
            width: "100%",
          }}
        >
          {articleArray[0]}
        </div>
      ) : (
        <GenericCarousle data={articleArray[1]} />
      )} */}
      {addArticle && (
        <GenericModal init={setAddArticle} div={<Add init={setAddArticle} />} />
      )}
      {editArticle && (
        <GenericModal
          init={setEditArticle}
          div={<EditS data={editArticle} init={setEditArticle} />}
        />
      )}
    </div>
  );
};

export default ArticlesC;
