import React, { useMemo, useEffect, useState } from "react";
import {
  getAll,
  cleanAll,
  deleteDoc,
  editStatus,
} from "../../../store/reducers/docsReducer";
import Switch from "react-switch";

import { postCsv } from "../../../store/reducers/dataReducer";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  addAlpha,
  availHeight,
  getImageUrl,
  GetToast,
  parseDate,
} from "../../common/modals/Toast";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  ClientCard,
  AssetPic,
  AssetsHolder,
  constants,
  FlexRowBetween,
  GenericDiv,
  ClosenessTitle2,
  PageTitle,
  DropOption,
  Section,
  RespFont,
  FlexColumnBetween,
  GenericLink,
} from "../../Style";
import sort from "../../../assets/nadlan/sort.svg";
import GenericModal from "../../common/modals/GenericModal";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import {
  deal,
  download,
  linkSvg,
  noWaitings,
  svgs,
  zaxEdit,
} from "../../common/footer/coloredSvgs/svgs";
import pdf from "../../../assets/nadlan/pdf.svg";
import downArrowB from "../../../assets/nadlan/downArrowB.svg";
import downArrowW from "../../../assets/nadlan/downArrowW.svg";
import upArrowB from "../../../assets/nadlan/upArrowB.svg";
import upArrowW from "../../../assets/nadlan/upArrowW.svg";

import YesNoModal from "../../common/modals/YesNoModal";
import OpacityLoading from "../../common/loader/OpacityLoading";
import AskForDownload from "../../common/modals/AskForDownload.js.js";

import readXlsxFile from "read-excel-file";
import { useExcelDownloder } from "react-xls";
import Edit from "./Edit";
import AddSell from "./AddSell";
import PDF from "./PDF";
let dic = {
  minSquareMeters: "מ״ר מינימלי",
  minRooms: "מס׳ חדרים מינימלי",
  minPrice: "מחיר מינימלי",
  maxSquareMeters: "מ״ר מקסימלי",
  maxRooms: "מס׳ חדרים מקסימלי",
  maxPrice: "מחיר מקסימלי",
  preferredCity: "עיר מועדפת",
};
let multyChoise = {};
let multyChoiseCounter = 0;
const Docs = (props) => {
  let widthS = window.innerWidth; // 1728
  const [addAsset, setAddAsset] = useState(false);

  // const handleSetAddAsset=

  const { encode } = require("url-encode-decode");
  const { ExcelDownloder, Type } = useExcelDownloder();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { docs, docsError, docsLoading } = useSelector(
    (state) => state.docsReducer
  );
  const { dataError } = useSelector((state) => state.dataReducer);

  const { user } = useSelector((state) => state.authReducer);
  const [askForDownload, setAskForDownload] = useState(false);
  const [deleteGuests, setDeleteGuests] = useState(false);
  const [deleteGuest, setDeleteGuest] = useState(false);
  const [showEditNote, setShowEditNote] = useState(false);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [selected, setSelected] = useState(0);

  const [sortBy, setSortBy] = useState("dateAdded");
  const [sortDirection, setSortDirection] = useState(false);
  const [editAsset, setEditAsset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  let height = window.innerHeight;

  useEffect(() => {
    dispatch(getAll());
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (docsError) GetToast(addToast, "error", docsError);
  }, [docsError]);

  const deleteOne = () => {
    multyChoise[deleteGuest._id] = true;
    deleteFunc();
    setDeleteGuest(null);
  };
  const initDeleteOne = () => {
    setDeleteGuest(null);
  };

  // const add_del = (item) => {
  //   if (multyChoise[item._id] !== undefined) {
  //     delete multyChoise[item._id];
  //     multyChoiseCounter--;
  //   } else {
  //     multyChoise[item._id] = `+972${item.phone}`;
  //     multyChoiseCounter++;
  //   }
  //   setSelected(multyChoiseCounter);
  //   setClosenessChosenFlag(!closenessChosenFlag);
  // };
  const deleteFunc = () => {
    dispatch(deleteDoc(Object.keys(multyChoise))).then(
      (data) => {
        setMultiSelect(false);
        if (!data.payload.error)
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        setDeleteGuests(false);
        multyChoise = {};
        multyChoiseCounter = 0;
        setSelected(0);
        setEditAsset(null);
      },
      (error) => {
        GetToast(addToast, "error", error);
        setDeleteGuests(false);
        setMultiSelect(false);
      }
    );
  };

  const setDone = async (id, done) => {
    dispatch(editStatus(id, { done })).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
      }
    });
  };

  // const handleClick = (e, data) => {
  //   if (e.type === "click") {
  //     if (multiSelect) add_del({ _id: data._id, phone: data.phone });
  //     else setEditAsset(data);
  //   } else if (e.type === "contextmenu") {
  //     e.preventDefault();
  //     console.log("Right click");
  //   }
  // };
  const phonesToList = () => Object.values(multyChoise).join(",");
  const handlePdf = (path) => {
    let newWindow = window.open();
    newWindow.location = path;
    // newWindow.close();
  };
  let guestsArray = useMemo(() => {
    let arr = [];
    let excel = [];
    let sortedArray = [];
    if (Object.keys(docs).length > 0) {
      // sortedArray.push({ ...value, dateAdded: parseDate(value.dateAdded) });
      for (const [key, value] of Object.entries(docs)) sortedArray.push(value);
      switch (sortBy) {
        case "dateAdded":
          if (sortDirection)
            sortedArray.sort(
              (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
            );
          else
            sortedArray.sort(
              (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
            );
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
          else sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
      }
      // let newSortedArray = [];
      // for (let j = 0; j < sortedArray.length; j++)
      //   newSortedArray.push({
      //     ...sortedArray[j],
      //     dateAdded: parseDate(sortedArray[j]["dateAdded"]),
      //   });
      for (let i = 0; i < sortedArray.length; i++) {
        let msg = encode(
          // `https://nadlan.agency/doc?uid=${user._id}&fullname=${user.fullname}&company=${user.company}&israelId=${user.israelId}&docId=${sortedArray[i]._id}&docKind=${sortedArray[i].docKind}`
          sortedArray[i].docKind === 3
            ? `https://nadlan.agency/freeSign?uid=${user._id}&docId=${sortedArray[i]._id}&pic=${sortedArray[i].pic}&fullname=${sortedArray[i].fullname}&done=${sortedArray[i].done}`
            : `https://nadlan.agency/doc?uid=${user._id}&docId=${sortedArray[i]._id}`
        );
        arr.push(
          <ClientCard
            key={i}
            // onClick={() => setEditTodo(value)}
            style={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.11)",
            }}
          >
            <FlexRowBetween style={{ alignItems: "flex-start" }}>
              <FlexColumnBetween>
                <FlexRowBetween style={{ justifyContent: "flex-start" }}>
                  <div
                    style={{
                      fontFamily: "hebM",
                      whiteSpace: "nowrap",
                      width: "min-content",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "7px 7px 7px 10px",
                      backgroundColor: addAlpha(
                        user.settings.colors.buttonsColor,
                        0.15
                      ),
                      marginTop: "-10px",
                      marginRight: "-10px",
                      borderRadius: "0 3px 0 5px",
                    }}
                  >
                    <RespFont pSize={"12px"} cSize={"14px"}>
                      {parseDate(sortedArray[i].dateAdded)}
                    </RespFont>
                  </div>
                </FlexRowBetween>
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", marginTop: "10px" }}
                >
                  <div
                    style={{
                      fontFamily: "hebM",
                      whiteSpace: "nowrap",
                      width: "min-content",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {sortedArray[i].fullname}
                  </div>
                </FlexRowBetween>
              </FlexColumnBetween>
              {sortedArray[i].done && (
                <FlexColumnBetween
                  style={{
                    width: "min-content",
                    height: "100%",
                    //   alignItems:"",
                    marginLeft: "0px",
                    padding: "10px",
                    // backgroundColor: addAlpha(
                    //   user.settings.colors.buttonsColor,
                    //   0.065
                    // ),
                  }}
                >
                  {/* <div
                    style={{ filter: "brightness(80%)" }}
                    // onClick={() =>
                    //   setDownloadPdf(
                    //     `${getImageUrl()}${user._id}/docs/${
                    //       sortedArray[i]._id
                    //     }/doc.png`
                    //   )
                    // }
                    // onClick={() =>
                    //   handlePdf(
                    //     `https://nadlan.agency/pdf?uid=${user._id}&docId=${sortedArray[i]._id}&fullname=${sortedArray[i].fullname}`
                    //   )
                    // }
                  >
                    <img src={pdf} style={{ width: "30px" }} />
                  </div> */}
                </FlexColumnBetween>
              )}
              <FlexColumnBetween
                style={{
                  width: "min-content",
                  height: "100%",
                  //   alignItems:"",
                  marginLeft: "0px",
                  padding: "10px",
                  backgroundColor: addAlpha(
                    user.settings.colors.buttonsColor,
                    0.065
                  ),
                }}
              >
                {sortedArray[i].phone !== "" &&
                  (sortedArray[i].pic !== "" ||
                    sortedArray[i].docKind != 3) && (
                    <a
                      style={{ filter: "brightness(80%)" }}
                      target="_blank"
                      //   style={{ marginLeft: widthS > 768 ? "15px" : "10px" }}
                      rel="noreferrer"
                      href={`https://wa.me/972${sortedArray[i].phone}/?text=${msg}`}
                    >
                      <GenericSvg
                        fill={user.settings.colors.buttonsColor}
                        //   size={widthS > 768 ? "35px" : "25px"}
                        size={"20px"}
                        svg={svgs.whatsapp}
                        viewBox={"0 0 24 24"}
                      />
                    </a>
                  )}
                <div
                  style={{ marginTop: "23px", filter: "brightness(80%)" }}
                  onClick={() => setShowEditNote(sortedArray[i])}
                >
                  {zaxEdit(20, user.settings.colors.buttonsColor)}
                </div>
                {sortedArray[i].done && (
                  <a
                    style={{ marginTop: "23px", filter: "brightness(80%)" }}
                    href={
                      sortedArray[i].docKind === 3
                        ? `${getImageUrl()}${user._id}/docs/${
                            sortedArray[i]._id
                          }/${sortedArray[i].fullname}-חתום.pdf`
                        : `${getImageUrl()}${user._id}/docs/${
                            sortedArray[i]._id
                          }/doc.png`
                    }
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <GenericSvg
                      fill={user.settings.colors.buttonsColor}
                      size={"25px"}
                      svg={svgs.gallery}
                      viewBox={"0 0 232.322 232.322"}
                    />
                  </a>
                )}
              </FlexColumnBetween>
              <FlexColumnBetween
                style={{
                  width: "min-content",
                  height: "100%",
                  //   alignItems:"",
                  marginLeft: "0px",
                  padding: "10px",
                  backgroundColor: addAlpha(
                    user.settings.colors.buttonsColor,
                    0.065
                  ),
                }}
              >
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      {sortedArray[i].done ? "נחתם!" : "ממתין לחתימה"}
                    </Tooltip>
                  }
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{ marginBottom: "-5px" }}
                  >
                    <Switch
                      width={30}
                      height={14}
                      onColor={"#b1b1b1"}
                      offColor={"#b1b1b1"}
                      onHandleColor={user.settings.colors.buttonsColor}
                      boxShadow={"0 2px 4px silver, 0 -1px 4px silver"}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      checked={sortedArray[i].done}
                      onChange={() =>
                        setDone(sortedArray[i]._id, !sortedArray[i].done)
                      }
                      handleDiameter={18}
                    />
                  </div>
                </OverlayTrigger>
                <div
                  style={{ marginTop: "25px", filter: "brightness(80%)" }}
                  onClick={() => setDeleteGuest(sortedArray[i])}
                >
                  <GenericSvg
                    fill={user.settings.colors.buttonsColor}
                    size={"20px"}
                    svg={svgs.delete}
                    viewBox={"0 0 297 297"}
                  />
                </div>
                {sortedArray[i].phone !== "" && (
                  <a
                    style={{ marginTop: "25px", filter: "brightness(80%)" }}
                    target="_blank"
                    // style={{ marginLeft: widthS > 768 ? "15px" : "15px" }}
                    rel="noreferrer"
                    href={`tel:972${sortedArray[i].phone}`}
                  >
                    <GenericSvg
                      fill={user.settings.colors.buttonsColor}
                      //   size={widthS > 768 ? "35px" : "25px"}
                      size={"18px"}
                      svg={svgs.phone}
                      //   viewBox={"0 0 24 24"}
                    />
                  </a>
                )}
              </FlexColumnBetween>
            </FlexRowBetween>
          </ClientCard>
        );
      }
    }
    return [arr, excel];
  }, [docs, sortBy, sortDirection, multiSelect, selected]);

  const fileSelected = (event) => {
    readXlsxFile(event.target.files[0]).then((rows) => {
      dispatch(postCsv({ rows })).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
          dispatch(getAll());
        }
      });
    });
  };

  return (
    <div
      id="docsListA-id"
      style={{
        width: "100%",
        margin: "0 auto",
        position: "relative",
        minHeight: "70vh",
        maxHeight: "100vh",
        backgroundColor: "white",
        // overflowY: "auto",
      }}
    >
      <Section
        cSize={"38px"}
        pSize={"34px"}
        style={{ color: constants.fontColor, marginTop: "40px" }}
      >
        הסכמים
      </Section>
      <FlexRowBetween style={{ width: "95%", margin: "10px auto" }}>
        {/* מיון */}
        <FlexRowBetween style={{ justifyContent: "flex-start" }}>
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "60px",
              padding: widthS > 768 ? "5px" : "3px",
              fontWeight: "700",
              marginRight: "0px",
              marginLeft: "0px",
            }}
          >
            <RespFont
              pSize={"12px"}
              onClick={() => setSortBy("dateAdded")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                width: "max-content",
                backgroundColor:
                  sortBy === "dateAdded"
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color: sortBy === "dateAdded" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              תאריך הוספה
            </RespFont>
            <RespFont
              pSize={"12px"}
              onClick={() => setSortBy("done")}
              style={{
                padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
                cursor: "pointer",
                width: "max-content",
                backgroundColor:
                  sortBy === "done"
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color: sortBy === "done" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              טופל?
            </RespFont>
          </FlexRowBetween>

          {/* כיוון */}
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "50px",
              padding: widthS > 768 ? "5px" : "3px",
              fontWeight: "700",
              marginRight: "20px",
            }}
          >
            <RespFont
              onClick={() => setSortDirection(true)}
              style={{
                padding: widthS > 768 ? "6px 10px" : "0px 4px",
                cursor: "pointer",
                backgroundColor: sortDirection
                  ? user.settings.colors.buttonsColor
                  : "inherit",
                transition: "all 0.2s",
                color: sortDirection ? "#fff" : constants.fontColorZax,
                borderRadius: "50px",
                width: "max-content",
              }}
            >
              <img
                src={sortDirection ? upArrowW : upArrowB}
                style={{ width: widthS > 768 ? "23px" : "13px" }}
              />
            </RespFont>
            <RespFont
              onClick={() => setSortDirection(false)}
              style={{
                padding: widthS > 768 ? "6px 10px" : "0px 4px",
                cursor: "pointer",
                backgroundColor: !sortDirection
                  ? user.settings.colors.buttonsColor
                  : "inherit",
                transition: "all 0.2s",
                color: !sortDirection ? "#fff" : constants.fontColorZax,
                borderRadius: "50px",
                width: "max-content",
              }}
            >
              <img
                src={sortDirection ? downArrowB : downArrowW}
                style={{ width: widthS > 768 ? "23px" : "13px" }}
              />
            </RespFont>
          </FlexRowBetween>
        </FlexRowBetween>
        <FlexRowBetween
          onClick={() => setAddAsset(true)}
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "50px",
            padding: "5px",
            fontWeight: "700",
            marginRight: "30px",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              padding: widthS > 768 ? "6px 6px" : "4px 4px",

              backgroundColor: user.settings.colors.buttonsColor,
              transition: "all 0.2s",
              borderRadius: "50px",
              width: "max-content",
            }}
          >
            <GenericSvg
              fill={"#fff"}
              size={widthS > 768 ? "23px" : "20px"}
              svg={svgs.plus}
              viewBox={"0 0 16 16"}
            />
          </div>
        </FlexRowBetween>
      </FlexRowBetween>

      <AssetsHolder style={{ height: `${height - 180}px`, marginTop: "20px" }}>
        {guestsArray[0].length ? (
          guestsArray[0]
        ) : (
          <FlexColumnBetween style={{ justifyContent: "space-evenly" }}>
            <RespFont style={{ fontFamily: "hebB" }}>
              נראה שעדיין לא הועלו מסמכים...
            </RespFont>
            {deal(
              widthS > 768 ? 432.88 : 216.44,
              widthS > 768 ? 341.445 : 170.7225,
              user.settings.colors.buttonsColor
            )}
          </FlexColumnBetween>
        )}
      </AssetsHolder>

      {docsLoading && <OpacityLoading />}

      {deleteGuests && (
        <GenericModal
          init={setDeleteGuests}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"מחיקת רשומות"}
              body={"האם את/ה בטוח/ה?"}
              no={setDeleteGuests}
              yes={deleteFunc}
            />
          }
        />
      )}

      {deleteGuest && (
        <GenericModal
          init={initDeleteOne}
          div={
            <YesNoModal
              color={user.settings.colors.buttonsColor}
              title={"מחיקת מסמך"}
              body={"האם את/ה בטוח/ה?"}
              no={initDeleteOne}
              yes={deleteOne}
            />
          }
        />
      )}
      {addAsset && (
        <GenericModal init={setAddAsset} div={<AddSell init={setAddAsset} />} />
      )}

      {showEditNote && (
        <GenericModal
          init={setShowEditNote}
          div={<Edit data={showEditNote} init={setShowEditNote} />}
        />
      )}
      {downloadPdf && (
        <GenericModal
          init={setDownloadPdf}
          div={<PDF src={downloadPdf} init={setDownloadPdf} />}
        />
      )}
    </div>
  );
};

export default Docs;
