import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RespFont, Thing } from "../Style";
import { constants } from "../../Style";

const GenericAccordion = ({
  list,
  backgroundColor1 = "#fff",
  backgroundColor2 = "#fff",
  selectedColor = constants.fontColorZax,
}) => {
  const arr = list.map((item, index) => {
    return (
      <Accordion style={{ background: backgroundColor1 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index + 1}a-content`}
          id={`panel${index + 1}a-header`}
          style={{
            backgroundColor: backgroundColor1,
            background: backgroundColor1,
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Typography>
            <RespFont
              cSize={"20px"}
              pSize={"18px"}
              style={{ fontFamily: "heb" }}
            >
              <Thing color={selectedColor}>{item.title}</Thing>
            </RespFont>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: backgroundColor2 }}>
          <Typography>
            <RespFont style={{ fontFamily: "heb" }}>{item.content}</RespFont>
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  });
  return <div>{arr}</div>;
};

export default GenericAccordion;
