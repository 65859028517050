const ClientRoutes = {
  Admin: {
    main: "/admin/main",
    potentials: "/admin/potentials",
    aviv: "/admin/aviv",
  },
  User: {
    user: "/user",
    face: "/face",
    editDetails: "/editDetails",
    qaa: "/qaa", // questions and answers
    articles: "/articles",
    calendar: "/calendar",
    successPage: "/successPage",
    failurePage: "/failurePage",
    doc: "/doc",
    pdf: "/pdf",
  },
  UnAuth: {
    home: "/",
    main: "/main",
    login: "/login",
    register: "/register",
    // agents: '/agents',
    agents: "/agents/:id",
    asset: "/asset",
    failurePage: "/failurePage",
    successPage: "/successPage",
    n: "/n",
    s: "/s",
    doc: "/doc",
    freeSign: "/freeSign",
  },
  Producers: {
    itay: "/itay",
    event4u: "/event4u",
    ohad: "/ohad",
    aviv: "/aviv",
  },
};

export default ClientRoutes;
