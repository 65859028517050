import React from "react";
import { RespFont } from "../../../Style";
import GenericDeal from "./GenericDeal";
import vi from "../../../../assets/zaks/vi.svg";
import sk from "../../../../assets/zaks/sk.svg";

const Deal2 = ({ setReg, reg, commitment }) => {
  return (
    <GenericDeal
      setReg={setReg}
      reg={reg}
      title={"אישי"}
      price={commitment === 1 ? "250₪" : "200₪"}
      extra={
        <>
          <RespFont style={{ marginTop: "18px" }}>
            <img
              src={vi}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "8px 6px",
                backgroundColor: "#F2C94C",
                marginLeft: "10px",
                textAlign: "right",
              }}
            />
            יכולת להעלות פרסומות ללקוחותיך <b>בלבד</b>
          </RespFont>
          <RespFont style={{ textAlign: "right", marginTop: "18px" }}>
            <img
              src={vi}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "8px 6px",
                backgroundColor: "#F2C94C",
                marginLeft: "10px",
              }}
            />
            <b>רק</b> לקוחותיך יקבלו התראות כאשר תוסיף נכס העונה למבוקשם
          </RespFont>
        </>
      }
    />
  );
};

export default Deal2;
