import styled from "styled-components";

const media = {
  inv: "@media only screen and (max-width:500px)",
  phone: "@media only screen and (max-width:480px)",
  phoneAndTablet: "@media only screen and (max-width:768px)",
  tablet: "@media only screen and (min-width:481px) and (max-width:768px)",
  bigTablet: "@media only screen and (min-width:769px) and (max-width:1024px)",
  comp: "@media only screen and (min-width:1025px)",
};

export const constants = {
  fontColor: "#3A3C3F",
  fontColorZax: "#38343D",
  orangeColor: "background: rgba(222, 133, 86, 1)",
  textColor: "rgba(56, 52, 61, 1)",
  red: "#E32326",
};

export const PDF = styled.div`
  overflow-x: hidden;
  position: relative;
  width: 595px;
  height: 842px;

  ${media.phoneAndTablet} {
    width: 210px;
    height: 297px;
  }
  ::before {
    opacity: ${(props) => (props.opacity ? props.opacity : 1)};
    content: "";
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    background-position: center;
    background-size: contain;
    background-image: url("${(props) => props.pic}");
  }
`;

export const MainPageBackgroundImage = styled.div`
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  ::before {
    opacity: ${(props) => (props.opacity ? props.opacity : 1)};
    content: "";
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    background-position: center;
    background-size: cover;
    background-image: url("${(props) => props.pic}");
  }
`;

export const MainPageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
`;

export const WelcomePageContentTitle = styled.div`
  color: white;
  font-weight: 700;
  font-size: 44px;
  text-shadow: 0px 3px 6px #00000029;
  letter-spacing: 0px;
  text-align: center;
`;

export const WelcomePageContentSubTitle = styled.div`
  color: white;
  font-weight: 700;
  font-size: 24px;
  text-shadow: 0px 3px 6px #00000029;
  letter-spacing: 0px;
  text-align: center;
  line-height: 30px;
`;

export const GenericDiv = styled.div`
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  border: none;
  min-width: max-content;
  background: ${(props) => (props.background ? props.background : "#3A3C3F")};
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    background: ${(props) =>
      props.hoverBackground
        ? props.hoverBackground
        : "rgb(218, 216, 216)"} !important;
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : "#3A3C3F"} !important;
  }
`;

export const GenericLink = styled.a`
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: none;
  min-width: max-content;
  background: ${(props) => (props.background ? props.background : "#3A3C3F")};
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    background: ${(props) =>
      props.hoverBackground
        ? props.hoverBackground
        : "rgb(218, 216, 216)"} !important;
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : "#3A3C3F"} !important;
  }
`;

export const GenericButton = styled.button`
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  border: none;
  min-width: max-content;
  background: ${(props) => (props.background ? props.background : "#3A3C3F")};
  box-shadow: 0px 2px 4px silver;
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  outline: none;
  &:hover {
    transition: all 0.2s;
    background: ${(props) =>
      props.hoverBackground
        ? props.hoverBackground
        : "rgb(218, 216, 216)"} !important;
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : "#3A3C3F"} !important;
    box-shadow: 2px 2px 8px gray;
  }
`;

export const MainPageButtons = styled(GenericDiv)`
  box-shadow: 0px 30px 60px #4975e940;
  width: 100%;
  padding: 20px 0;
  border-radius: 90px;
  font-weight: bolder;
`;

export const DarkenBackgroundImage = styled.div`
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.8),
    #fff
  );
  width: 100%;
  height: 100%;
  position: absolute;
  mix-blend-mode: darken;
`;

export const BrightBackgroundImage = styled.div`
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  mix-blend-mode: soft-light;
`;

export const DarkenBackgroundImagePhone = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.2)
  );
  width: 100%;
  height: 100%;
  position: absolute;
  mix-blend-mode: darken;
`;

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  /* height: 100vh; */
  max-height: 100%;
`;

export const InputBox = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .relativeBox {
    position: relative;
    width: 60%;
    margin-right: 20%;
    margin-bottom: 8px;
  }
  input {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 0px;
    color: ${(props) => (props.color ? props.color : "#fff")};
    border: none;
    border-bottom: 1px solid ${(props) => (props.color ? props.color : "#fff")};
    outline: none;
    background: transparent;
    transition: all 0.1s;
    &:focus {
      border-bottom: 1.5px solid
        ${(props) =>
          props.borderBottomColor ? props.borderBottomColor : "#fff"};
      transition: all 0.1s;
    }
    &::placeholder {
      color: ${(props) =>
        props.placeholderColor ? props.placeholderColor : "#ffffffd1"};
    }
  }
  label {
    position: absolute;
    top: 0;
    right: 0;
    padding: 26px 0;
    color: ${(props) => (props.color ? props.color : "#ffffff")};
    pointer-events: none;
    transition: 0.5s;
  }
  input:focus + label {
    top: -20px;
    right: 0;
    color: ${(props) => (props.labelColor ? props.labelColor : "#ffffffd1")};
    font-size: 12px;
  }
  input:valid + label {
    top: -20px;
    right: 0;
    color: ${(props) => (props.labelColor ? props.labelColor : "#ffffffd1")};
    font-size: 12px;
  }
`;

export const PlaceHolderImage = styled.img`
  position: absolute;
  left: 0px;
  top: ${(props) => (props.top ? props.top : "50%")};
  width: ${(props) => (props.fontSize ? props.fontSize : "50%")};
  transform: translate(0, -50%);
`;

export const DivLink = styled.div`
  color: ${constants.fontColor};
  text-decoration: none;
  &:hover {
    color: ${constants.fontColor};
  }
`;

export const RespDiv = styled.div`
  width: ${(props) => (props.cSize ? props.cSize : "500px")} !important;
  ${media.phone} {
    width: ${(props) => (props.pSize ? props.pSize : "100%")} !important;
  }
`;

export const DivTextAndPic = styled.div`
  box-shadow: 0px 2px 4px white;
  border-radius: 15px;
  text-align: center;
  padding: 5px 10px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  img {
    margin-top: 10px;
    width: 40px;
  }
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    transform: scale(1.1, 1.1);
  }
`;
export const Scale = styled.div`
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    transition: all 0.2s;
    transform: ${(props) => (props.scale ? props.scale : "scale(1.1, 1.1)")};
  }
`;

export const GalleryOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  img {
    padding: ${(props) => (props.padding ? props.padding : "8px 0")};
    width: ${(props) => (props.width ? props.width : "25px")};
    transition: 150ms;
    cursor: pointer;
  }
`;

export const PageTitle = styled.div`
  font-family: openSansMedium;
  margin: 20px auto;
  border-radius: 5px;
  color: black;
  border-bottom: ${(props) =>
    props.borderBottom ? `4px solid ${props.borderBottom}` : ""};
  width: ${(props) => (props.cWidth ? props.cWidth : "90px")};
  line-height: 30px;
  text-align: center;
  font-size: 30px;
  ${media.phoneAndTablet} {
    width: ${(props) => (props.pWidth ? props.pWidth : "70px")};
    line-height: 22px;
    font-size: 22px;
  }
`;

export const HandleLableAndInput = styled.div`
  width: "100%";
  max-width: "300px";
  margin-right: "10px";
  margin-left: "10px";
  margin: "10px auto";
  ${media.phoneAndTablet} {
    max-width: "90%";
  }
`;

export const TextWithPic = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: min-content;
  padding: 10px 20px;
  ${media.phone} {
    padding: 7px 14px;
  }
  border-radius: 3px;
  box-shadow: 0 2px 4px silver;
  background-color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  color: #3a3c3f;
`;

export const RespFont = styled.div`
  font-size: ${(props) =>
    props.cSize ? props.cSize : "16px"}; // computer size
  ${media.phoneAndTablet} {
    font-size: ${(props) => (props.pSize ? props.pSize : "14px")}; // phone size
  }
`;
export const Section = styled(RespFont)`
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  font-family: hebM;
`;

export const PhonePriceDiv = styled.div`
  width: 90%;
  margin: 0 auto;
  position: relative;
  height: max-content;
  text-align: center;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: 1px 3px 4px rgba(255, 255, 255, 0.3),
    -1px -3px 4px rgba(255, 255, 255, 0.3);
`;
export const CompPriceDiv = styled.div`
  width: 22%;
  min-width: 300px;
  max-width: 500px !important;
  text-align: center;
  height: 800px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white !important;
  /* position: relative; */
  border: 0.862879px solid #de8556;
  box-shadow: 0px 11.2174px 16.3947px rgba(0, 0, 0, 0.07);
  border-radius: 8.62879px;
  padding: 5px 15px;
  ${media.phoneAndTablet} {
    width: 90%;
    min-width: 200px;
  }
`;

export const EnteranceCompHolder = styled.div`
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 40px 40px;
  border-radius: 8px;
  width: 30%;
  ${media.phoneAndTablet} {
    padding: 0;
    width: 100%;
    background-color: transparent;
  }
`;

export const ServiceOption = styled.div`
  width: 96%;
  margin: 5px auto;
  position: relative;
  cursor: pointer;
  font-family: openSansRegular;
  border-radius: 5px;
  text-align: center;
  padding: 8px 5px;
  background: ${(props) =>
    props.include ? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.4)"};
  color: ${(props) => (props.include ? "#3A3C3F" : "white")};
  text-decoration: ${(props) => (props.include ? "none" : "line-through")};
  text-decoration-thickness: 1px;
`;

export const DropOption = styled.div`
  background: ${(props) => (props.background ? props.background : "white")};
  cursor: pointer;
  padding: 7px 12px;
  margin: 5px 0;
  border-radius: 2px;
  z-index: 1;
  width: max-content;
  box-shadow: 0 1px 2px ${(props) => (props.shadow ? props.shadow : "silver")};
`;

export const FlexRowBetween = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
`;
export const Ser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  height: 300px;
  border: 4px solid rgba(0, 0, 0, 0.11);
  border-radius: 12px;
  padding: 20px 20px;
  cursor: pointer;
  width: ${(props) => (props.cWidth ? props.cWidth : "25%")};
  margin: 10px 0;
  ${media.phoneAndTablet} {
    margin: 10px auto;
    width: ${(props) => (props.pWidth ? props.cWidth : "88%")};
  }
  transition: all 0.2s;
  &:hover {
    transition: all 0.4s;
    border-radius: 2px;
    transform: scale(1.05);
  }
`;
export const OptionsFirst = styled(FlexRowBetween)`
  height: 100%;
  justify-content: space-evenly;
  ${media.phoneAndTablet} {
    flex-direction: column-reverse;
  }
`;

export const UserMenuHolder = styled.div`
  width: 30%;
  ${media.phoneAndTablet} {
    width: 100%;
  }
`;

export const FlexColumnBetween = styled(FlexRowBetween)`
  flex-direction: column;
`;

export const FooterDiv = styled.div`
  width: 30%;
  border-radius: 50px 50px 0px 0px;
  ${media.phoneAndTablet} {
    width: 100%;
  }
  position: fixed;
  bottom: 0;
  z-index: 2;
  background-color: #f8f8f8;
  box-shadow: 0 -1px 10px rgb(215, 213, 213);
`;

export const ModalStyle = styled.div`
  display: flex;
  overflow: auto;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  // height: 100vh;
  height: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  /* justify-content: flex-start; */
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 5;
`;

export const ModalStyleB = styled.div`
  display: flex;
  overflow: auto;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  // height: 100vh;
  height: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: flex-end;
  /* justify-content: flex-start; */
  align-items: center;
  left: 0px;
  z-index: 3;
  bottom: 420px !important;
`;

export const PopupForm = styled.form`
  width: ${(props) => (props.cWidth ? props.cWidth : "30%")};
  p {
    color: red;
    text-align: right;
    margin-bottom: 5px;
  }

  ${media.phoneAndTablet} {
    width: ${(props) => (props.pWidth ? props.pWidth : "80%")};
  }
  border-radius: 10px;
  overflow: auto;
  /* box-shadow: 5px 20px 50px gray; */
  /* box-shadow: 0 2px 8px 2px ${(props) =>
    props.color ? props.color : "gray"}; */
  box-shadow: 5px 20px 50px silver;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: relative; */
  background-color: #f2f2f2;
  z-index: 5;
  transition: all 0.2s;
`;

// export const ArticlesPopup = styled(PopupForm)`
//     width:70%;
//     ${media.phoneAndTablet}{
//         width:90%;
//     }
// `;
export const FooterOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  svg {
    padding: ${(props) => (props.padding ? props.padding : "8px 0")};
    cursor: pointer;
  }
`;

export const RTextArea = styled.textarea`
  border: 1px solid #fff;
  color: #fff;
  padding: 5px;
  width: 100%;
  margin: 10px auto;
  border-radius: 5px;
  transition: 0.2s all;
  position: relative;
  outline: none;
  background: none;
  &::placeholder {
    color: #ffffffd1;
  }
  &:focus {
    border: 2px solid #fff;
    transition: 0.2s all;
  }
`;

export const ToastMsg = styled.div`
  color: black;
  display: flex;
  flex-direction: row;
  direction: rtl;
  justify-content: center;
  align-items: center;
`;

export const AssetsHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px 0px;
  overflow: auto;
  padding: 10px 0;
  padding-top: 0px;
  padding-bottom: 80px;
  margin-bottom: 10px;
  margin-top: -8px;
`;

function addAlpha(color, opacity) {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export const ClosenessTitle2 = styled.div`
  /* margin-bottom: -10px; */
  margin-top: 20px;
  background: ${(props) =>
    props.color
      ? `linear-gradient(to left, ${props.color}, ${addAlpha(
          props.color,
          0.75
        )}, 
        ${addAlpha(props.color, 0.55)}, ${addAlpha(
          props.color,
          0.25
        )}, rgba(255,255,255,0.1))`
      : constants.fontColor};
  cursor: ${(props) => (props.pointer === true ? "pointer" : "auto")};
  color: white;
  width: max-content;
  padding: 5px 7px;
  padding-left: 80px;
  border-radius: 20px 0px 0px 20px;
  font-size: 16px;
`;

export const ClientCard = styled.div`
  /* width: 21.85%; */
  /* margin-right: 2.5%; */
  /* margin-left: 0px; */
  min-width: 300px;
  max-width: 300px;
  font-size: 16px;
  ${media.phone} {
    font-size: 14px;
    min-width: 200px;
    max-width: 300px;
    width: 95%;
    /* margin-right: 2.5%; */
    /* margin-left: 2.5%; */
  }
  border: none;
  margin: 10px auto;
  border-radius: 5px;
  box-shadow: 0 1px 18px silver;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  cursor: pointer;
  transition: 200ms;
  height: min-content;
  padding: 10px;
  /* margin-top: 15px; */
  &:hover {
    transform: scale(1.02, 1.02);
    transition: 200ms;
  }
`;

export const AssetCard = styled.div`
  width: 21.85%;
  min-width: 364px;
  margin-right: 2.5%;
  margin-left: 0px;
  ${media.phone} {
    width: 95%;
    margin-right: 2.5%;
    margin-left: 2.5%;
  }
  border: none;
  border-radius: 10px;
  box-shadow: 0 1px 18px silver;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  cursor: pointer;
  transition: 200ms;
  height: min-content;
  margin-top: 15px;
  &:hover {
    transform: scale(1.03, 1.03);
    transition: 200ms;
  }
`;

export const AssetCLientCard = styled(AssetCard)`
  width: 95%;
  margin-right: 2.5%;
  margin-left: 2.5%;
  flex-direction: row;
  align-items: center;
`;

export const AssetPic = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 97%;
  margin: 0 auto;
  margin-top: 1.5%;
  height: 100%;
  border-radius: 10px 10px 0 0;
`;

export const AssetClientPic = styled(AssetPic)`
  border-radius: 0 20px 20px 0;
`;

export const Questions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  border-radius: 3px;
  min-height: 100px;
  height: min-content;
  background: rgba(255, 255, 255, 0.3);
  padding: 7px 10px;
  font-family: openSansRegular;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  width: 30%;
  ${media.phone} {
    width: 90%;
    margin: 12px 5%;
  }
  margin: 20px 10%;
  transition: all 0.2s;
  &:hover {
    transition: all 0.4s;
    box-shadow: 0 1px 10px white;
    border-radius: 2px;
    transform: scale(1.05);
  }
`;
