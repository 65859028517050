import styled from "styled-components";
import { addAlpha } from "./modals/Toast";

const media = {
  inv: "@media only screen and (max-width:500px)",
  phone: "@media only screen and (max-width:480px)",
  phoneAndTablet: "@media only screen and (max-width:768px)",
  tablet: "@media only screen and (min-width:481px) and (max-width:768px)",
  bigTablet: "@media only screen and (min-width:769px) and (max-width:1024px)",
  comp: "@media only screen and (min-width:1025px)",
};

const constants = {
  backgroundColor: "#f1ae6e",
  fontColor: "#3A3C3F",
  fontColorZax: "#38343D",
};

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: ${(props) =>
    props.background ? props.background : constants.fontColor};
  width: 100%;
  margin: 0 0;
  img {
    padding: 12px 0;
    width: 28px;
    cursor: pointer;
  }
`;
export const Toolbar1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  background: ${(props) =>
    `linear-gradient(to right, rgba(255,255,255,0.1), ${addAlpha(
      props.background,
      0.25
    )}, ${addAlpha(props.background, 0.55)},
    ${addAlpha(props.background, 0.75)}, ${props.background}, ${addAlpha(
      props.background,
      0.75
    )}, 
        ${addAlpha(props.background, 0.55)}, ${addAlpha(
      props.background,
      0.25
    )}, rgba(255,255,255,0.1))`};
  padding: 0 150px;
  div {
    margin: 0px 10px;
    padding: 10px;
  }
  transition: all 200ms;
  #opt {
    font-size: 20px;
    font-style: italic;
    ${media.phone} {
      font-size: 18px;
    }
    cursor: pointer;
    &:hover {
      transform: scale(1.1, 1.1);
      transition: all 200ms;
      font-weight: 700;
    }
  }
`;

export const TodosHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px 0px;
  overflow: auto;
  padding: 20px 0;
`;
export const ServicesHolder = styled(TodosHolder)`
  padding-top: 0px !important;
  height: 100%;
`;

export const PotentialCard = styled.div`
  /* position: relative; */
  border: none;
  border-radius: 10px;
  box-shadow: silver;
  width: 23%;
  margin-left: 0;
  margin-right: 1.6%;
  margin-top: 12px;
  ${media.bigTablet} {
    width: 30%;
    margin-right: 2.5%;
  }
  ${media.tablet} {
    width: 45%;
    margin-right: 3.3%;
  }
  ${media.phone} {
    width: 90%;
    margin-right: 5%;
  }
  .info {
    display: flex;
    justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
  }
  overflow: auto;
  cursor: pointer;
  padding: 20px 0;
  transition: 200ms;
  background: rgba(255, 255, 255, 0.7);
  height: min-content;
  &:hover {
    transform: scale(1.03, 1.03);
    transition: 200ms;
  }
`;

export const TodoCard1 = styled.div`
  /* position: relative; */
  border: none;
  border-radius: 10px;
  box-shadow: 0 1px 18px ${(props) => (props.today ? props.color : "silver")};
  width: 23%;
  margin-left: 0;
  margin-right: 1.6%;
  margin-top: 12px;
  ${media.bigTablet} {
    width: 30%;
    margin-right: 2.5%;
  }
  ${media.tablet} {
    width: 45%;
    margin-right: 3.3%;
  }
  ${media.phone} {
    width: 90%;
    margin-right: 5%;
  }
  overflow: auto;
  cursor: pointer;
  padding: 20px 0;
  transition: 200ms;
  background: rgba(255, 255, 255, 0.7);
  height: min-content;
  &:hover {
    transform: scale(1.03, 1.03);
    transition: 200ms;
  }
`;

export const TodoCard = styled.div`
  /* position: relative; */
  border: none;
  border-radius: 10px;
  box-shadow: 0 1px 18px ${(props) => (props.today ? props.color : "silver")};
  width: 23%;
  margin-left: 0;
  margin-right: 1.6%;
  margin-top: 12px;
  ${media.bigTablet} {
    width: 30%;
    margin-right: 2.5%;
  }
  ${media.tablet} {
    width: 45%;
    margin-right: 3.3%;
  }
  ${media.phone} {
    width: 90%;
    margin-right: 5%;
  }
  .info {
    display: flex;
    justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
  }
  overflow: auto;
  cursor: pointer;
  padding: 20px 0;
  transition: 200ms;
  background: rgba(255, 255, 255, 0.7);
  height: min-content;
  &:hover {
    transform: scale(1.03, 1.03);
    transition: 200ms;
  }
`;

export const PhoneInvitation = styled.div`
  width: 100%;
  /* display: none !important; */
  /* min-height: 60vh; */
  background: linear-gradient(to bottom, #f2f2f2, #cacaca);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* ${media.inv}{
        display: block !important;
    } */
`;

export const PhoneInvitationWi = styled.div`
  width: 100%;
  background: linear-gradient(to bottom, #f2f2f2, #cacaca);
`;

export const PhoneInvitationMsg = styled.div`
  display: block !important;
  width: 100%;
  min-height: 60vh;
  height: 100%;
  margin-top: 20vh;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.inv} {
    display: none !important;
  }
`;

export const GenericDiv = styled.div`
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  border: none;
  min-width: max-content;
  background: ${(props) => (props.background ? props.background : "#3A3C3F")};
  color: #fff;
  font-size: 16px;
  padding: 5px 10px;
  ${media.phoneAndTablet} {
    font-size: 14px;
  }
  ${media.bigTablet} {
    font-size: 14px;
  }
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    outline: none;
    background: ${(props) =>
      props.hoverBackground
        ? props.hoverBackground
        : "rgb(218, 216, 216)"} !important;
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : "#3A3C3F"} !important;
  }
`;

export const InvitationPic = styled.div`
  background: inherit;
  background-position: ${(props) => (props.center ? "top center" : "center")};
  background-size: ${(props) => (props.center ? "100% auto" : "cover")};
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  max-height: 39vh;
`;

export const InvitationPicNi = styled(InvitationPic)`
  height: 55vh;
`;
export const InvitationDiv = styled.div`
  width: 100%;
  max-width: 500px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  direction: rtl;
  a {
    color: black !important;
    text-decoration: none;
  }
`;

export const TableLine = styled.div`
  width: 100%;
  padding: 7px 3%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  font-size: 16px;
  ${media.phoneAndTablet} {
    font-size: 14px;
  }
  ${media.bigTablet} {
    font-size: 14px;
  }
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: 0.2s;
  background-color: ${(props) => (props.background ? props.background : null)};
  :hover {
    transition: 0.2s;
    background-color: rgba(192, 192, 192, 0.3);
  }
`;

export const TableCard = styled.div`
  border: none;
  border-radius: 10px;
  box-shadow: 0 1px 18px silver;
  width: 17%;
  margin-right: 1.5%;
  margin-left: 1.5%;
  margin-top: 12px;
  ${media.bigTablet} {
    width: 22%;
    margin-right: 1.5%;
    margin-left: 1.5%;
  }
  ${media.tablet} {
    width: 29.2%;
    margin-right: 2%;
    margin-left: 2%;
  }
  ${media.phone} {
    width: 45%;
    margin-right: 2.5%;
    margin-left: 2.5%;
  }
  height: 210px;
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  cursor: pointer;
  transition: 200ms;
  background: rgba(255, 255, 255, 0.9);
  &:hover {
    transform: scale(1.03, 1.03);
    transition: 200ms;
  }
`;

export const GuestOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: ${(props) =>
    props.color ? props.color : constants.backgroundColor};
  img {
    padding: ${(props) => (props.padding ? props.padding : "8px 0")};
    width: ${(props) => (props.width ? props.width : "31px")};
    transition: 150ms;
    cursor: pointer;
    /* &:hover{
            transition:150ms;
            transform: scale(1.2);
        } */
  }
`;

export const RTextArea = styled.textarea`
  border: 2px solid rgba(128, 128, 128, 0.2);
  color: #3a3c3f;
  padding: 5px;
  width: 100%;
  margin: 10px auto;
  border-radius: 5px;
  font-size: 16px;
  ${media.phoneAndTablet} {
    font-size: 14px;
  }
  background-color: #cbd3ffb6;
  transition: 1s;
  position: relative;
  outline: none;
  background: none;
  &:focus {
    border: 2px solid
      ${(props) => (props.color ? props.color : constants.fontColor)};
    transition: 1s;
  }
  &::placeholder {
    /* font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : "heb"}; */
  }
`;

export const Options = styled.div`
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  /* height: 7vh; */

  button,
  a {
    margin-left: 7px;
  }
`;

export const MyLabel = styled.div`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 0.8rem;
`;

export const CategoryRow = styled.div`
  width: 100%;
  font-weight: 700;
  z-index: -1;
  ${media.comp} {
    width: 97.5%;
  }
  ${media.tablet} {
    width: 97%;
  }
  /* margin-top:${(props) => (props.marginTop ? props.marginTop : "30px")}; */
  margin-top: 20px;
  background: none;
  div {
    /* width: 55% !important; */
    min-width: max-content !important;
    width: max-content !important;
    transform: skew(-40deg);
    ${media.comp} {
      /* width: 35% !important; */
      padding-right: 6% !important;
    }
    padding: 0.2vh 2vh;
    background: ${(props) => (props.color ? props.color : constants.fontColor)};
    padding-right: 7.2% !important;
    padding-left: 3vh !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    border-radius: 0px 8px 0px 0px;
    margin-right: -5%;
    font-size: 17px;
    ${media.phoneAndTablet} {
      font-size: 15px;
    }
    color: #f2f2f2;
    div {
      transform: skew(30deg);
      width: max-content !important;
    }
  }
`;

export const ClosenessTitle2 = styled.div`
  /* margin-bottom: -10px; */
  margin-top: 20px;
  background: ${(props) =>
    props.color
      ? `linear-gradient(to left, ${props.color}, ${addAlpha(
          props.color,
          0.75
        )}, 
        ${addAlpha(props.color, 0.55)}, ${addAlpha(
          props.color,
          0.25
        )}, rgba(255,255,255,0.1))`
      : constants.fontColor};
  cursor: ${(props) => (props.pointer === true ? "pointer" : "auto")};
  color: white;
  width: max-content;
  padding: 0.7vh 1vh;
  padding-left: 80px;
  border-radius: 20px 0px 0px 20px;
  font-size: 1.1rem;
`;
export const ClosenessTitle = styled(CategoryRow)`
  /* margin-top: 10px;
    margin-bottom: -10px;
    background: ${(props) => (props.color ? props.color : constants.fontColor)};
    color: white;
    padding: 0.7vh 1vh;
    padding-left: 5vh;
    font-size: 1.1rem;
    font-weight: 700; */
`;

export const GuestRowStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 18.9%;
  margin-right: 1%;
  margin-top: 10px;
  margin-left: 0px;
  ${media.bigTablet} {
    width: 23.8%;
    margin-right: 1%;
    margin-left: 0px;
  }
  ${media.tablet} {
    width: 31%;
    margin-right: 1.5%;
    margin-left: 0.5%;
  }
  ${media.phone} {
    width: 47%;
    margin-right: 1.5%;
    margin-left: 1%;
  }
  background-color: ${(props) =>
    props.background ? props.background : "rgb(245, 245, 245, 0.8)"};
  border-radius: 5px;
  box-shadow: 0 1px 2px silver;
  .first {
    flex: 5;
    padding-right: 7px;
    height: 87%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }
  .second {
    cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' width='28' height='28' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 55.25 55.25' style='enable-background:new 0 0 55.25 55.25;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M0.523,51.933l-0.497,2.085c-0.016,0.067-0.02,0.135-0.022,0.202C0.004,54.234,0,54.246,0,54.259 c0.001,0.114,0.026,0.225,0.065,0.332c0.009,0.025,0.019,0.047,0.03,0.071c0.049,0.107,0.11,0.21,0.196,0.296 c0.095,0.095,0.207,0.168,0.328,0.218c0.121,0.05,0.25,0.075,0.379,0.075c0.077,0,0.155-0.009,0.231-0.027l2.086-0.497 L0.523,51.933z'/%3E%3Cpath d='M52.618,2.631c-3.51-3.508-9.219-3.508-12.729,0L3.827,38.693C3.81,38.71,3.8,38.731,3.785,38.749 c-0.021,0.024-0.039,0.05-0.058,0.076c-0.053,0.074-0.094,0.153-0.125,0.239c-0.009,0.026-0.022,0.049-0.029,0.075 c-0.003,0.01-0.009,0.02-0.012,0.03l-2.495,10.48L5.6,54.182l10.48-2.495c0.027-0.006,0.051-0.021,0.077-0.03 c0.034-0.011,0.066-0.024,0.099-0.039c0.072-0.033,0.139-0.074,0.201-0.123c0.024-0.019,0.049-0.033,0.072-0.054 c0.008-0.008,0.018-0.012,0.026-0.02l36.063-36.063C56.127,11.85,56.127,6.14,52.618,2.631z M17.157,47.992l0.354-3.183 L39.889,22.43c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L16.097,43.395l-4.773,0.53l0.53-4.773l22.38-22.378 c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L10.44,37.738l-3.183,0.354L34.94,10.409l9.9,9.9L17.157,47.992z M46.254,18.895l-9.9-9.9l1.414-1.414l9.9,9.9L46.254,18.895z M49.082,16.067l-9.9-9.9l1.415-1.415l9.9,9.9L49.082,16.067z'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A")
        16 16,
      pointer;
    box-shadow: 2px 0px 2px silver, 0 -1px 2px silver;
    border-radius: 0px 10px 0px 10px;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  transition: 200ms;
  &:hover {
    transform: scale(1.03, 1.03);
    transition: 200ms;
  }
  font-size: 14px;
`;

export const GuestsHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px 0px;
  height: 100%;
  overflow: auto;
  padding-bottom: 30px;
`;

export const MyHr = styled.hr`
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  height: 1px;
  width: 90%;
  margin: 0 auto;
`;

export const OverlayDiv = styled.button`
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  padding: 4px 8px;
  ${media.phoneAndTablet} {
    font-size: 13px;
  }
  border-radius: 5px;
  margin-left: 5px;
  border: none;
  color: ${constants.fontColor};
`;

export const GenericButton = styled.button`
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  border: none;
  background: ${(props) => (props.background ? props.background : "#f1ae6e")};
  box-shadow: 2px 2px 8px silver;
  color: white;
  font-size: 16px;
  padding: 6px 11px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    outline: none;
    background: rgba(223, 223, 223) !important;
    color: #3a3c3f;
    box-shadow: 0px 2px 4px gray;
  }
`;

export const GenericLabel = styled.label`
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  border: none;
  background: ${(props) => (props.background ? props.background : "#f1ae6e")};
  box-shadow: 2px 2px 8px silver;
  color: white;
  font-size: 16px;
  padding: 6px 11px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    outline: none;
    background: rgba(223, 223, 223) !important;
    color: #3a3c3f;
    box-shadow: 0px 2px 4px gray;
  }
`;

export const BaseInput = styled.input`
  border: 2px solid rgba(50, 50, 50, 0.5);
  padding-right: 5px;
  width: 100%;
  border-radius: 5px;
  font-size: 1rem;
  transition: 1s;
  outline: none;
  font-family: heb;
  background: none;
  &:focus {
    border: 2px solid
      ${(props) => (props.color ? props.color : constants.fontColor)};
    transition: 1s;
  }
  &::placeholder {
    font-family: heb;
    font-size: 0.95rem;
  }
`;

export const RInput = styled.input`
  color: #3a3c3f;
  padding: 10px 0px;
  padding-right: 5px;
  width: 100%;
  border-radius: 5px;
  font-size: 1rem;
  transition: 0.1s;
  outline: none;
  border: 1px solid rgba(50, 50, 50, 0.3);
  font-family: heb;
  background: none;
  &:focus {
    border: 2px solid
      ${(props) => (props.color ? props.color : constants.fontColor)};
    transition: 0.1s;
  }
  &::placeholder {
    font-family: heb;
    font-size: 0.95rem;
  }
`;

export const HomeInput = styled.input`
  color: ${(props) => (props.color ? props.color : constants.fontColorZax)};
  padding: 10px 0px;
  padding-right: 5px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  transition: 0.1s;
  outline: none !important;
  border: none;
  ${media.phoneAndTablet} {
    font-size: ${(props) => (props.smaller ? "12px" : "14px")};
    padding: ${(props) => (props.smaller ? "5px 5px" : "10px 5px")};
  }
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "heb")};
  background: white;
  &::placeholder {
    font-family: ${(props) =>
      props.pHfontFamily ? props.pHfontFamily : "heb"};
    font-size: 14px;
    color: ${(props) => (props.pHcolor ? props.pHcolor : "#3a3c3f")};

    ${media.phoneAndTablet} {
      font-size: ${(props) => (props.smaller ? "12px" : "14px")};
    }
  }
`;

export const HomeTextArea = styled.textarea`
  color: "#38343D";
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  transition: 0.1s;
  outline: none !important;
  border: none;
  ${media.phoneAndTablet} {
    font-size: 14px;
  }
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "heb")};
  background: white;
  &:focus {
    border: 2px solid
      ${(props) => (props.color ? props.color : constants.fontColorZax)};
    transition: 0.1s;
  }
  &::placeholder {
    font-family: ${(props) => (props.fontFamily ? props.fontFamily : "heb")};
    font-size: 14px;
    color: ${(props) => (props.pHcolor ? props.pHcolor : "#3a3c3f")};
  }
`;

export const Thing = styled.div.attrs({ tabIndex: 0 })`
  /* color: blue;

  &:hover {
    color: red; // <Thing> when hovered
  }

  & ~ & {
    background: tomato; // <Thing> as a sibling of <Thing>, but maybe not directly next to it
  }

  & + & {
    background: lime; // <Thing> next to <Thing>
  }

  &.something {
    background: orange; // <Thing> tagged with an additional CSS class ".something"
  } */

  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded & {
    color: ${(props) => (props.color ? props.color : constants.fontColorZax)};
    font-weight: 700;
  }
`;

export const SignatureInput = styled.input`
  border: none;
  border-bottom: 1px solid #38343d;
  outline: none;
  width: ${(props) => (props.width ? props.width : "100px")};
  margin: 0;
  margin-right: 5px;
  height: 18px;
  font-family: "heb";
`;

export const RespFont = styled.div`
  font-size: ${(props) =>
    props.cSize ? props.cSize : "16px"}; // computer size
  ${media.phoneAndTablet} {
    font-size: ${(props) => (props.pSize ? props.pSize : "14px")}; // phone size
  }
`;

export const Responsive = styled.div`
  ${(props) => props.fontSize && `font-size: ${props.fontSizeC};`};
  ${(props) => props.width && `width: ${props.widC};`};
  ${(props) => props.margin && `margin: ${props.marC};`};
  ${(props) => props.marginTop && `margin-top: ${props.marTopC};`};
  ${(props) => props.marginBottom && `margin-bottom: ${props.marBottomC};`};
  ${(props) => props.padding && `padding: ${props.padC};`};
  ${media.phoneAndTablet} {
    ${(props) => props.fontSize && `font-size: ${props.fontSizeP};`};
    ${(props) => props.margin && `margin: ${props.marP};`};
    ${(props) => props.width && `width: ${props.widP};`};
    ${(props) => props.marginTop && `margin-top: ${props.marTopP};`};
    ${(props) => props.marginBottom && `margin-bottom: ${props.marBottomP};`};
    ${(props) => props.padding && `padding: ${props.padP};`};
  }
`;

export const Px16 = styled.div`
  font-size: ${(props) => (props.size ? props.size : "16px")};
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: auto;
  width: 100%;
  margin: 10px 0;
  padding: 10px 4px;
  a {
    border-radius: 5px;
    box-shadow: 0 2px 4px gray;
    background: rgb(223, 223, 223);
    margin-left: 7px;
    color: #3a3c3f;
    text-decoration: none;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
    padding: ${(props) => (props.padding ? props.padding : "7px 15px")};
    min-width: max-content;
    :hover {
      background: ${(props) =>
        props.color ? props.color : constants.backgroundColor};
      color: white;
    }
  }
`;
export const Rotate90 = styled.div`
  ${media.phoneAndTablet} {
    transform: rotate(90deg);
  }
`;

export const FlexRowBetween = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
`;

export const FlexColumnBetween = styled(FlexRowBetween)`
  display: flex;
  ${media.phoneAndTablet} {
    min-height: 220vh;
    flex-direction: column;
  }
`;

export const FlexRowStart = styled(FlexRowBetween)`
  justify-content: flex-start;
  margin-top: 10px;
  flex-wrap: wrap;
`;

export const FlexRowColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  ${media.phoneAndTablet} {
    flex-direction: column;
  }
`;

export const FlexRowColumnPhoneReverse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  ${media.phoneAndTablet} {
    flex-direction: column-reverse;
  }
`;

export const FlexRowColumnOpposite = styled(FlexRowColumn)`
  direction: rtl;
`;

export const RespImg = styled.img`
  width: ${(props) => (props.cSize ? props.cSize : "400px")} !important;
  ${media.phoneAndTablet} {
    width: ${(props) => (props.pSize ? props.pSize : "200px")} !important;
  }
`;

export const RespImgH = styled.img`
  height: ${(props) => (props.cSize ? props.cSize : "400px")} !important;
  width: max-content;
  object-fit: contain;
  object-position: bottom;
  ${media.phoneAndTablet} {
    height: ${(props) => (props.pSize ? props.pSize : "200px")} !important;
  }
`;

export const OptionsFirst = styled(FlexRowBetween)`
  height: 100%;
  justify-content: space-evenly;
  ${media.phoneAndTablet} {
    flex-direction: column;
  }
`;

export const OptionsSecond = styled(FlexRowBetween)`
  flex-direction: column;
  justify-content: space-evenly;

  ${media.phoneAndTablet} {
    flex-direction: row;
  }
`;

export const FlexRowBetween2 = styled(FlexRowBetween)`
  display: flex;
  width: 50%;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  input {
    width: 20%;
    ${media.phoneAndTablet} {
      width: 80%;
    }
  }
`;

export const BusinessMainOption = styled.div`
  width: 48%;
  background: #dedee0;
  height: 16vh;
  color: ${constants.fontColor};
  text-decoration: none;
  box-shadow: 1px 2px 4px gray;
  display: flex;
  font-size: 1.2rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  :hover {
    color: ${constants.fontColor};
  }
`;

export const BusinessButton = styled.div`
  min-width: 22%;
  background: #dedee0;
  height: 30px;
  padding: 7px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: rubLight;
  text-decoration: none;
  color: #3a3c3f;
`;

export const CenteredLogo = styled.div`
  background-position: center;
  background-size: cover;
  width: 13vh;
  height: 13vh;
  border-radius: 50%;
`;

export const CenteredImage = styled.div`
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 40vh;
  opacity: 0.6;
`;

export const Sketch = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
  width: 100%;
  img {
    height: 8vh;
    width: auto;
    /* width: 100%; */
    /* height: 100%; */
    transform-origin: 50% 0%;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(
        ${(props) => (props.scale ? props.scale[0] : 9)},
        ${(props) => (props.scale ? props.scale[1] : 9)}
      );
      ${media.phoneAndTablet} {
        transform: scale(
          ${(props) => (props.scale ? props.scale[2] : 3)},
          ${(props) => (props.scale ? props.scale[3] : 3)}
        );
      }
    }
  }
`;

export const MainOpt = styled.div`
  color: black;
`;

export const Service = styled.div`
  font-size: 18px;
  cursor: pointer;
  transition: all 200ms;
  &:hover {
    transform: scale(1.1, 1.1);
    transition: all 200ms;
    font-weight: 700;
  }
`;

export const ToastMsg = styled.div`
  color: black;
  display: flex;
  flex-direction: row;
  direction: rtl;
  font-family: heb;
  justify-content: center;
  align-items: center;
`;

export const ModalStyle = styled.div`
  color: black;
  display: flex;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

export const MainWithPhoto = styled.div`
  width: 100%;
  position: relative;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  color: #3a3c3f;
  ::before {
    opacity: ${(props) => (props.opacity ? props.opacity : 1)};
    content: "";
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    background-position: center;
    background-size: cover;
  }
`;

export const MainWithPhotoResponsive = styled(MainWithPhoto)`
  height: 94vh;
  margin-top: 6vh;
  /* bottom: 6vh; */
  ${media.phoneAndTablet} {
    height: 100vh;
    margin-top: -7vh;
    bottom: 0;
  }
`;

// export const GradientText = styled.div`
//   background-color: ${props => props.background ? props.background : "#f2f2f2"};
//   color: black;
//   font-size: ${props => props.fontSize ? props.fontSize : "16px"};
//   padding: ${props => props.padding ? props.padding : "7px 15px"};
//   font-weight: bold;
//   text-align: center;
//   mix-blend-mode: screen;
//   user-select: none;
//   cursor: pointer;
//   border-radius: 5px;
// `;

export const MainDiv = styled.div`
  direction: rtl;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  display: flex;
  overflow: auto;
  font-size: 1rem;
  font-family: heb !important;
  flex-direction: column;
  align-items: center;
  color: #3a3c3f;
  background: #f2f2f2;
  position: relative;
`;

export const AvivCardsHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 1vh auto;
  margin-top: 0;
  height: 100%;
  overflow: auto;
  padding-bottom: 30px;
`;

export const DisplayNoneComputer = styled.div`
  width: 100%;
  display: none;
  ${media.phoneAndTablet} {
    display: block;
  }
`;

export const DisplayNonePhone = styled.div`
  width: 100%;
  display: block;
  ${media.phoneAndTablet} {
    display: none;
  }
`;

export const AvivCard = styled.div`
  margin-top: 16px;
  font-size: 16px;
  ${media.phoneAndTablet} {
    font-size: 14px;
  }
  ${media.bigTablet} {
    font-size: 14px;
  }
  display: flex;
  flex-direction: column;
  width: 32%;
  margin-right: 1% !important;
  ${media.tablet} {
    width: 48% !important;
  }
  ${media.phone} {
    width: 98% !important;
  }
  height: max-content;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-radius: 10px;
  box-shadow: 1px 2px 8px ${(props) => (props.shadow ? props.shadow : "gray")};
  padding: ${(props) => (props.padding ? props.padding : "15px 10px")};
  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const M1 = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  /* ${media.phoneAndTablet}{
        flex-direction: row;
    } */
`;

export const M11 = styled.div`
  width: 70%;
  background-position: center;
  background-size: cover;
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255)
  );
  -webkit-mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left top, left bottom;
  ${media.phoneAndTablet} {
    display: none;
  }
`;

export const M11R = styled(M11)`
  width: 50%;
  -webkit-mask-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255)
  );
`;

export const M12 = styled.div`
  width: 30%;
  /* background: linear-gradient(to left, #fafafa, #f1ae6e, #d69355); */
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.phoneAndTablet} {
    width: 100%;
    background-position: center;
    background-size: cover;
  }
`;

export const M12R = styled.div`
  width: 50%;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.phoneAndTablet} {
    width: 100%;
    background-position: center;
    background-size: cover;
  }
`;

export const InputBox = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .relativeBox {
    position: relative;
    width: 60%;
    margin-right: 20%;
    margin-bottom: 8px;
  }
  input {
    width: 100%;
    font-size: 16px;
    ${media.phoneAndTablet} {
      font-size: 14px;
    }
    padding-top: 25px;
    padding-bottom: 0px;
    color: ${(props) => (props.color ? props.color : "#fff")};
    border: none;
    border-bottom: 1px solid ${(props) => (props.color ? props.color : "#fff")};
    outline: none;
    background: transparent;

    &:focus {
      border-bottom: 1px solid
        ${(props) =>
          props.borderBottomColor
            ? props.borderBottomColor
            : `${constants.fontColor}`};
      transition: 200ms;
    }
  }
  label {
    position: absolute;
    top: 0;
    right: 0;
    padding: 26px 0;
    font-size: 16px;
    ${media.phoneAndTablet} {
      font-size: 14px;
    }
    color: ${(props) => (props.color ? props.color : "#fff")};
    pointer-events: none;
    transition: 0.5s;
  }
  input:focus + label {
    top: -20px;
    right: 0;
    color: ${(props) => (props.labelColor ? props.labelColor : "#d5c5f3")};
    font-size: 12px;
    ${media.phoneAndTablet} {
      font-size: 11px;
    }
  }
  input:valid + label {
    top: -20px;
    right: 0;
    color: ${(props) => (props.labelColor ? props.labelColor : "#d5c5f3")};
    font-size: 12px;
    ${media.phoneAndTablet} {
      font-size: 11px;
    }
  }
`;

export const GradientText = styled.div`
  background: ${(props) => (props.background ? props.background : "#f2f2f2")};
  color: black;
  font-size: 18px;
  padding: 16px 15px;
  ${media.phoneAndTablet} {
    font-size: 16px;
  }
  font-weight: bold;
  text-align: center;
  mix-blend-mode: screen;
  user-select: none;
  text-decoration: none;

  cursor: pointer;
  border-radius: 5px;
  &:hover {
    color: initial;
  }
`;

export const CenteredForm = styled.form`
  width: 40%;
  ${media.bigTablet} {
    width: 50%;
  }
  ${media.tablet} {
    width: 60%;
  }
  ${media.phone} {
    width: 80%;
  }
  border-radius: 20px;
  overflow: auto;
  box-shadow: 0 7px 10px silver, 0 -7px 10px silver;
  box-shadow: 5px 20px 50px #000;
  width: 30%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #f2f2f2;
  transition: all 0.2s;
  /* z-index: 9000 !important; */
`;

export const PlaceHolderImage = styled.img`
  position: absolute;
  left: 0px;
  top: ${(props) => (props.top ? props.top : "50%")};
  width: ${(props) => (props.fontSize ? props.fontSize : "50%")};
  transform: translate(0, -50%);
`;

export const Span = styled.span`
  text-align: center;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.8);
`;

export const SpanRed = styled.span`
  text-align: center;
  color: red;
`;

export const Submit = styled.button`
  width: 60%;
  margin: 10px auto;
  padding: 10px;
  font-size: 1rem;
  border-radius: 20px;
  border: 1px solid gray;
  background: linear-gradient(to right, #5f575a, #4a393f);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  font-family: heb;
  &:hover {
    transition: all 0.2s;
    outline: none;
    background: rgba(250, 249, 249, 0.911) !important;
    border: 1px solid black;
    color: black;
  }
`;

export const Yet = styled.div`
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: heb;
  cursor: pointer;
  font-size: 0.8rem;
  &:hover {
    color: black;
  }
`;

export const MainCard = styled.div`
  border: none;
  border-radius: 3px;
  box-shadow: 0 2px 8px white, 0 -2px 6px white;
  width: 50vw;
  height: 20vw;
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin: 15px 25px;
  transition: 200ms;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(168, 168, 168, 0.2) 100%
  );

  &:hover {
    transform: translateY(-10px);
    transition: 200ms;
  }
`;

export const MenuButton = styled.div`
  box-shadow: 0 1px 4px white;
  padding: 7px 12px;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    transition: all 0.2s;
    transform: scale(1.1, 1.1);
    background: rgba(250, 249, 249, 0.911) !important;
    box-shadow: 0 1px 3px white, 0 -1px 3px white;
    color: #4a393f;
  }
`;

export const RowEvenly = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const WhiteInput = styled.input`
  padding: 7px;
  width: 60px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(255, 255, 255, 0.16);
  color: white;
  background: none;
  font-size: 1rem;
  outline: none;
  transition: all 0.5s;

  &:focus {
    transition: all 0.5s;
    border: 2px solid white;
    box-shadow: 0 2px 6px 0 rgba(255, 255, 255, 0.8);
  }

  &::placeholder {
    color: white;
    font-size: 1rem;
  }
`;

export const MyTable = styled.table`
  width: 100%;
  color: black;
  margin: 30px 0px;
  border-collapse: collapse;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
`;
