import { createSlice } from "@reduxjs/toolkit";
import { post2, get2 } from "../api/http";
import ServerRoutes from "../api/routes";

const initialState = {
  docs: {},
  doc: null,
  docsError: null,
  docsLoading: false,
};

const _addDoc = (state, action) => {
  let editedDocs = { ...state.docs };
  editedDocs[action.payload.id] = {
    ...action.payload.body,
    done: false,
    pic: "",
    _id: action.payload.id,
    dateAdded: action.payload.dateAdded,
  };
  state.docs = editedDocs;
};

const _setDoc = (state, action) => {
  state.doc = action.payload.doc;
  state.doc.user = action.payload.user;
};

const _setDocs = (state, action) => {
  state.docs = action.payload;
};

const _editDoc = (state, action) => {
  let editedDocs = { ...state.docs };
  for (const [key, value] of Object.entries(action.payload.body))
    editedDocs[action.payload.id][key] = value;
  state.docs = editedDocs;
};

const _deleteDoc = (state, action) => {
  let editedDocs = { ...state.docs };
  delete editedDocs[action.payload];
  state.docs = editedDocs;
};

const _actionStart = (state) => {
  state.docsLoading = true;
  state.docsError = null;
};

const _actionSuccess = (state) => {
  state.docsLoading = false;
  state.docsError = null;
};

const _actionFailed = (state, action) => {
  state.docsLoading = false;
  state.docsError = action.payload.error;
};

const _cleanDocsState = (state) => {
  state.docs = {};
  state.docsError = null;
  state.doc = null;
  state.docsLoading = false;
};

const docs = createSlice({
  name: "docs",
  initialState,
  reducers: {
    addDoc1: _addDoc,
    cleanDocsState: _cleanDocsState,
    editDoc1: _editDoc,
    deleteDoc1: _deleteDoc,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    setDocs: _setDocs,
    setDoc: _setDoc,
    actionSuccess: _actionSuccess,
  },
});

const { reducer, actions } = docs;

const {
  actionStart,
  actionFailed,
  actionSuccess,
  addDoc1,
  editDoc1,
  deleteDoc1,
  setDocs,
  setDoc,
  cleanDocsState,
} = actions;

export default reducer;

export const addDoc = (body) => {
  console.log(body);
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(ServerRoutes.Docs.add, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(addDoc1({ id: data._id, dateAdded: data.dateAdded, body }));
      return dispatch(actionSuccess());
    }
  };
};

export const getAll = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Docs.getAll);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setDocs(data));
    }
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    return dispatch(cleanDocsState());
  };
};

export const editDocDetails = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Docs.editDocDetails + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editDoc1({ id, body }));
    }
  };
};

export const editDocPic = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Docs.editPic + id, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editDoc1({ id, body }));
    }
  };
};

export const editStatus = (id, body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Docs.editStatus + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editDoc1({ id, body }));
    }
  };
};

export const unAuthSign = (id, body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Docs.editStatus + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
    }
  };
};

export const deleteDoc = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Docs.delete, {
      id,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteDoc1(id));
    }
  };
};

export const getDoc = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Docs.getDoc, {
      id,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setDoc(data));
    }
  };
};

// export const deleteDocs = (guestsToDelete) => {
//   return async (dispatch) => {
//     dispatch(actionStart());
//     const { error, status } = await post2(ServerRoutes.Docs.deleteMany, {
//       guestsToDelete,
//     });
//     if (status !== 200) return dispatch(actionFailed({ error }));
//     else {
//       dispatch(actionSuccess());
//       return dispatch(deleteDoc1(id));
//     }
//   };
// };
