import React from "react";
import { RespFont } from "../../../Style";
import GenericDeal from "./GenericDeal";
import vi from "../../../../assets/zaks/vi.svg";
import sk from "../../../../assets/zaks/sk.svg";

const Deal1 = ({ setReg, reg, commitment }) => {
  return (
    <GenericDeal
      setReg={setReg}
      reg={reg}
      title={"בסיסי"}
      // price={
      //   <div>
      //     <div>50₪</div>
      //     <div
      //       style={{
      //         color: "rgba(222, 133, 86, 0.7)",
      //         fontSize: "14px",
      //         fontFamily: "monsterB",
      //         marginTop: "-10px",
      //       }}
      //     >
      //       לחודש
      //     </div>
      //   </div>
      // }
      price={commitment === 1 ? "200₪" : "150₪"}
      extra={
        <>
          <RespFont style={{ marginTop: "18px" }}>
            <img
              src={vi}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "8px 6px",
                backgroundColor: "#F2C94C",
                marginLeft: "10px",
                textAlign: "right",
              }}
            />
            יכולת להעלות פרסומות ללקוחותיך <b>בלבד</b>
          </RespFont>
          <RespFont style={{ textAlign: "right", marginTop: "18px" }}>
            <img
              src={vi}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "8px 6px",
                backgroundColor: "#F2C94C",
                marginLeft: "10px",
              }}
            />
            <b>רק</b> לקוחותיך יקבלו התראות כאשר תוסיף נכס העונה למבוקשם
          </RespFont>
          <RespFont style={{ textAlign: "right", marginTop: "18px" }}>
            <img
              src={sk}
              style={{
                width: "27px",
                borderRadius: "50%",
                padding: "7px 12px",
                backgroundColor: "#EB5757",
                marginLeft: "10px",
              }}
            />
            <b>
              לקוחותיך יקבלו התראות כאשר משתמשי VIP יוסיפו נכס העונה למבוקשם
            </b>
          </RespFont>
        </>
      }
    />
  );
};

export default Deal1;
