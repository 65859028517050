import React, { useState } from "react";
import {
  constants,
  FlexRowBetween,
  GenericButton,
  GenericDiv,
  RespFont,
} from "../Style";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../common/modals/Toast";
import { useForm } from "react-hook-form";
import { editDetails } from "../../store/reducers/authReducer";
import OpacityLoading from "../common/loader/OpacityLoading";
import { HomeInput, HomeTextArea } from "../common/Style";
import delete1 from "../../assets/nadlan/delete1.svg";

import EditPics from "./images/EditPics";
import GenericModal from "../common/modals/GenericModal";
import { deletePhoto } from "../../store/reducers/photosReducer";

const EditDetailsFace = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [kind, setKind] = useState(false);
  const { user, editDetailsLoading, settingsFlag } = useSelector(
    (state) => state.authReducer
  );
  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoOnly = async (key) => {
    await deletePhoto1(user[key]);
    dispatch(editDetails({ [key]: "" })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };
  let widthS = window.innerWidth; // 1728

  const onSubmit = async (data) => {
    const body = {
      settings: {
        ...user.settings,
        mainContent: {
          part0: data.mainContent_part0,
          part1: data.mainContent_part1,
          part2: data.mainContent_part2,
          part3: data.mainContent_part3,
          part4: data.mainContent_part4,
        },
      },
    };
    dispatch(editDetails(body)).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      else GetToast(addToast, "error", res.payload.error);
    });
  };

  return (
    <form
      id="editDetails-id"
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "90%",
        margin: "10px auto",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          margin: "0px 0px",
          height: "100%",
          overflow: "auto",
          paddingBottom: "30px",
          overflowX: "hidden",
        }}
      >
        {/* <FlexRowBetween
          style={{
            maxWidth: "100%",
            margin: "20px auto",
            justifyContent: "center",
          }}
        >
          <img src={line} style={{ width: "500px" }} />
          <Section
            pSize={"20px"}
            cSize={"24px"}
            style={{
              width: "max-content",
              margin: "0px 20px",
            }}
          >
            עמוד ראשי
          </Section>
          <img src={line} style={{ width: "500px" }} />
        </FlexRowBetween> */}
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כותרת ראשית
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.mainContent.part0}
            {...register("mainContent_part0", {
              required: true,
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`mainContent_part0`, "בין 1 ל 50 אותיות", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כותרת משנית
          </RespFont>
          <HomeTextArea
            defaultValue={user.settings.mainContent.part1}
            type="text"
            rows="5"
            {...register("mainContent_part1", {
              // required: true,
              minLength: 0,
              maxLength: 500,
            })}
          />
          {CFV(`mainContent_part1`, "עד 500 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            תיאור
          </RespFont>
          <HomeTextArea
            defaultValue={user.settings.mainContent.part2}
            type="text"
            rows="5"
            {...register("mainContent_part2", {
              // required: true,
              minLength: 0,
              maxLength: 500,
            })}
          />
          {CFV(`mainContent_part2`, "עד 500 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            תיאור מודגש
          </RespFont>
          <HomeTextArea
            defaultValue={user.settings.mainContent.part3}
            type="text"
            rows="5"
            {...register("mainContent_part3", {
              // required: true,
              minLength: 0,
              maxLength: 500,
            })}
          />
          {CFV(`mainContent_part3`, "עד 500 תווים", errors)}
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כפתור הנעה לפעולה
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.mainContent.part4}
            {...register("mainContent_part4", {
              required: true,
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`mainContent_part4`, "בין 1 ל 50 אותיות", errors)}
        </div>
        <FlexRowBetween
          style={{ width: "min-content", margin: "0", marginRight: "20px" }}
        >
          {user.backgroundPic !== "" && (
            <div
              onClick={() => deletePhotoOnly("backgroundPic")}
              style={{
                backgroundColor: constants.fontColorZax,
                borderRadius: "3px",
                cursor: "pointer",
                marginLeft: "10px",
              }}
            >
              <img
                src={delete1}
                alt="delete"
                style={{
                  width: widthS > 768 ? "32px" : "30px",
                  padding: "7px",
                }}
              />
            </div>
          )}
          <GenericDiv
            background={user.settings.colors.buttonsColor}
            color={user.settings.colors.buttonsFontColor}
            style={{ borderRadius: "3px" }}
            onClick={() => {
              if (user.backgroundPic !== "")
                GetToast(
                  addToast,
                  "warning",
                  "קודם יש למחוק את התמונה הקיימת ורק לאחר מכן להעלות חדשה"
                );
              else setKind("backgroundPic");
            }}
          >
            תמונת רקע
          </GenericDiv>
        </FlexRowBetween>

        <FlexRowBetween
          style={{ width: "min-content", margin: "0", marginRight: "20px" }}
        >
          {user.profilePic !== "" && (
            <div
              onClick={() => deletePhotoOnly("profilePic")}
              style={{
                backgroundColor: constants.fontColorZax,
                borderRadius: "3px",
                cursor: "pointer",
                marginLeft: "10px",
              }}
            >
              <img
                src={delete1}
                alt="delete"
                style={{
                  width: widthS > 768 ? "32px" : "30px",
                  padding: "7px",
                }}
              />
            </div>
          )}
          <GenericDiv
            background={user.settings.colors.buttonsColor}
            color={user.settings.colors.buttonsFontColor}
            style={{ borderRadius: "3px" }}
            onClick={() => {
              if (user.profilePic !== "")
                GetToast(
                  addToast,
                  "warning",
                  "קודם יש למחוק את התמונה הקיימת ורק לאחר מכן להעלות חדשה"
                );
              else setKind("profilePic");
            }}
          >
            תמונת סוכן
          </GenericDiv>
        </FlexRowBetween>
        <FlexRowBetween style={{ marginTop: "20px" }}>
          <GenericButton
            background={user.settings.colors.buttonsColor}
            color={user.settings.colors.buttonsFontColor}
            style={{
              width: widthS > 768 ? "300px" : "200px",
              margin: "0 auto",
              // marginTop: "20px",
              fontWeight: "700",
              fontFamily: "monsterB",
              padding: "15px 20px",
              height: "min-content",
              // marginBottom: "25px",
            }}
            type="submit"
          >
            <RespFont>שמירה</RespFont>
          </GenericButton>
        </FlexRowBetween>
      </div>
      {editDetailsLoading && (
        <div style={{ marginRight: "-20px" }}>
          <OpacityLoading />
        </div>
      )}
      {kind && (
        <GenericModal
          init={setKind}
          div={<EditPics kind={kind} init={setKind} removeBg={"profile"} />}
        />
      )}
    </form>
  );
};

export default EditDetailsFace;
