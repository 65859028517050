import React, { useState } from "react";
import { DisplayNoneComputer, FlexRowBetween, RespImg } from "../Style";
import { HashLink } from "react-router-hash-link";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PersonIcon from "@material-ui/icons/Person";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import HomeIcon from "@material-ui/icons/Home";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import RecommendOutlinedIcon from "@mui/icons-material/RecommendOutlined";
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import ClientRoutes from "../../../navigation/Routes";
import { svgs } from "../footer/coloredSvgs/svgs";
import GenericSvg from "../footer/coloredSvgs/GenericSvg";
import l1 from "../../../assets/zaks/l1.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutSpecific } from "../../../store/reducers/authReducer";
import { constants } from "../../Style";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "rgba(255,255,255,0)",
    width: "min-content",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
    fontFamily: "logo",
    letterSpacing: "1px",
    color: "#3A3C3F",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

// const handleClickScroll = (id) => {
//     const element = document.getElementById(id);
//     if (element) {
//         element.scrollIntoView({ behavior: 'smooth' });
//     }
//     setOpen(false);
// };

const BurgerMenuUserAgent = ({ logoPic, user }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const logoutF = () => {
    dispatch(logoutSpecific()).then((res) => {
      // }
      history.push(ClientRoutes.UnAuth.home);
    });
  };
  const [logout, setLogout] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.scrollTop += 100;
      setOpen(false);
    }
  };

  return (
    // <DisplayNoneComputer>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <div onClick={handleDrawerOpen} className={clsx(open && classes.hide)}>
          <img
            src={l1}
            style={{
              width: "45px",
              backgroundColor: user.colors.buttonsColor,
              padding: "10px",
              borderRadius: "50%",
              boxShadow: "-1px 1px 15px rgba(9, 20, 49, 0.11)",
              marginTop: "15px",
              marginRight: "15px",
            }}
          />
        </div>
      </AppBar>
      <img
        src={logoPic}
        style={{
          width: "65px",
          position: "absolute",
          left: "5px",
          top: "5px",
          zIndex: 1,
        }}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {/* {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div onClick={() => handleClickScroll("assetsA-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText
                primary={user.titles.page2}
                style={{ display: "flex" }}
              />
              <ListItemIcon style={{ display: "flow-root" }}>
                <HomeIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div
            onClick={() => {
              handleClickScroll("steps");
              handleClickScroll("aboutMe-id");
            }}
          >
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText primary={"קצת עלי"} style={{ display: "flex" }} />
              <ListItemIcon style={{ display: "flow-root" }}>
                <PermContactCalendarIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div
            onClick={() => {
              handleClickScroll("refs");
            }}
          >
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText
                primary={user.titles.page4}
                style={{ display: "flex" }}
              />
              <ListItemIcon style={{ display: "flow-root" }}>
                <RecommendOutlinedIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div onClick={() => handleClickScroll("quesAndAns-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText
                primary={user.titles.page5}
                style={{ display: "flex" }}
              />
              <ListItemIcon style={{ display: "flow-root" }}>
                <QuestionAnswerIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div onClick={() => handleClickScroll("articleA-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText
                primary={user.titles.page6}
                style={{ display: "flex" }}
              />
              <ListItemIcon style={{ display: "flow-root" }}>
                <MenuBookIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div onClick={() => handleClickScroll("waitingListA-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText
                primary={"רשימת המתנה"}
                style={{ display: "flex" }}
              />
              <ListItemIcon style={{ display: "flow-root" }}>
                <HourglassBottomOutlinedIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div onClick={() => handleClickScroll("docsListA-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText primary={"הסכמים"} style={{ display: "flex" }} />
              <ListItemIcon style={{ display: "flow-root" }}>
                <HistoryEduOutlinedIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div onClick={() => handleClickScroll("todos-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText primary={"יומן"} style={{ display: "flex" }} />
              <ListItemIcon style={{ display: "flow-root" }}>
                <CalendarMonthOutlinedIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div onClick={() => handleClickScroll("clients-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText primary={"לקוחות"} style={{ display: "flex" }} />
              <ListItemIcon style={{ display: "flow-root" }}>
                <Groups2OutlinedIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div onClick={() => handleClickScroll("leadsListA-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText primary={"לידים"} style={{ display: "flex" }} />
              <ListItemIcon style={{ display: "flow-root" }}>
                <LeaderboardOutlinedIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div onClick={() => handleClickScroll("editDetails-id")}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
              }}
            >
              <ListItemText
                primary={"עריכת פרטים"}
                style={{ display: "flex" }}
              />
              <ListItemIcon style={{ display: "flow-root" }}>
                <SettingsOutlinedIcon />
              </ListItemIcon>
            </ListItem>
          </div>
          <div onClick={() => setLogout(true)}>
            <ListItem
              button
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                direction: "rtl",
                position: "relative",
              }}
            >
              {logout && (
                <div
                  style={{
                    position: "absolute",
                    top: "40px",
                    right: "0px",
                    padding: "10px 15px",
                    background: "#f2f2f2",
                    borderRadius: "15px px 15px 15px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    onClick={() => logoutF()}
                    style={{
                      border: "1px solid silver",
                      color: constants.fontColorZax,
                      padding: "2px 12px",
                      borderRadius: "5px",
                      marginLeft: "15px",
                      fontSize: "14px",
                    }}
                  >
                    כן
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setLogout(false);
                    }}
                    style={{
                      border: "1px solid silver",
                      color: constants.fontColorZax,
                      padding: "2px 12px",
                      borderRadius: "5px",
                      fontSize: "14px",
                      // marginRight: "20px",
                    }}
                  >
                    לא
                  </div>
                </div>
              )}
              <ListItemText primary={"התנתקות"} style={{ display: "flex" }} />
              <ListItemIcon style={{ display: "flow-root" }}>
                <LogoutIcon />
              </ListItemIcon>
            </ListItem>
          </div>
        </List>
      </Drawer>
    </div>
  );
};

export default BurgerMenuUserAgent;
