import React, { useState } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  RespFont,
} from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { addAsset } from "../../../store/reducers/assetsReducer";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast, parseIsoDate } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import Switcher from "../../common/switcher/Switcher";
import {
  FlexRowStart,
  HomeInput,
  HomeTextArea,
  RTextArea,
} from "../../common/Style";
import { addDoc } from "../../../store/reducers/docsReducer";
import GenericModal from "../../common/modals/GenericModal";
import GCalendar from "../../common/calendar/Calendar";
import ColorCheckbox from "../../common/others/ColorCheckbox";
const AddSell = ({ init }) => {
  let widthS = window.innerWidth; // 1728

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [docKind, setDocKind] = useState(1); // 1 - sell, 2 - buy, 3 - other
  const [isSale, setIsSale] = useState(true);
  const [isRent, setIsRent] = useState(false);
  const [showStartCalendar, setShowStartCalendar] = useState(null);
  const [showEndCalendar, setShowEndCalendar] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { user } = useSelector((state) => state.authReducer);
  const [editPic, setEditPic] = useState(false);

  // const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  // const deletePhotoOnly = async () => {
  //   await deletePhoto1(user.pic);
  //   dispatch(editUserPic({ pic: "" })).then(
  //     (res) => {
  //       if (!res.payload?.error)
  //         GetToast(addToast, "success", "הפעולה הצליחה!!");
  //     },
  //     (error) => {
  //       GetToast(addToast, "error", error);
  //     }
  //   );
  // };

  const { assetsLoading } = useSelector((state) => state.assetsReducer);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [saleOrRentOrSold, setSaleOrRentOrSold] = useState(1); // 1 for sale, 2 for rent, 3 for sold

  const onSubmit = async (data) => {
    const body = {
      fullname: data.fullname,
      phone: data.phone,
      details: data.details,
      notes: data.notes,
      gush: docKind === 1 ? parseInt(data.gush) : null,
      helka: docKind === 1 ? parseInt(data.helka) : null,
      tatHelka: docKind === 1 ? parseInt(data.tatHelka) : null,
      dealKind: isSale,
      fees: data.fees,
      assetOwner: data.assetOwner,
      assetKind: data.assetKind,
      assetAddress: data.assetAddress,
      assetPrice: data.assetPrice,
      startDate,
      endDate,
      docKind,
    };
    dispatch(addDoc(body)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  const handleSetStartDate = (e) => {
    let date = e;
    date.setHours(20);
    setStartDate(date);
  };

  const handleSetEndDate = (e) => {
    let date = e;
    date.setHours(20);
    setEndDate(date);
  };

  const handleSetIsSale = () => {
    if (!isSale) {
      setIsSale(true);
      setIsRent(false);
    }
  };

  const handleSetIsRent = () => {
    if (!isRent) setIsRent(true);
    setIsSale(false);
  };

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "90%",
          margin: "10px auto",
        }}
      >
        <FlexRowBetween
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "60px",
            padding: widthS > 768 ? "5px" : "3px",
            fontWeight: "700",
            margin: "10px auto",
          }}
        >
          <RespFont
            pSize={"12px"}
            onClick={() => setDocKind(1)}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                docKind === 1 ? user.settings.colors.buttonsColor : "inherit",
              transition: "all 0.2s",
              color: docKind === 1 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            מוכר/משכיר
          </RespFont>
          <RespFont
            pSize={"12px"}
            onClick={() => setDocKind(2)}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                docKind === 2 ? user.settings.colors.buttonsColor : "inherit",
              transition: "all 0.2s",
              color: docKind === 2 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            קונה/שוכר
          </RespFont>
          <RespFont
            pSize={"12px"}
            onClick={() => {
              reset({
                notes: "אין",
                gush: null,
                helka: null,
                tatHelka: null,
                dealKind: true,
                fees: "אין",
                assetOwner: "אין",
                assetKind: "אין",
                assetAddress: "אין",
                assetPrice: 1,
                startDate: new Date(),
                endDate: new Date(),
              });
              setDocKind(3);
            }}
            style={{
              padding: widthS > 768 ? "7px 12px" : "3.5px 6px",
              cursor: "pointer",
              width: "max-content",
              backgroundColor:
                docKind === 3 ? user.settings.colors.buttonsColor : "inherit",
              transition: "all 0.2s",
              color: docKind === 3 ? "#fff" : constants.fontColorZax,
              borderRadius: "60px",
            }}
          >
            אחר
          </RespFont>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "0px auto", marginTop: "20px" }}>
          <div style={{ width: "55%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שם מלא
            </RespFont>
            <HomeInput
              type="text"
              {...register("fullname", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("fullname", "בין 2 ל 50 תווים", errors)}
          </div>
          <div style={{ width: "40%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מס׳ פלאפון
            </RespFont>
            <HomeInput
              {...register("phone", {
                required: true,
                pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
              })}
            />
            {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)}
          </div>
        </FlexRowBetween>
        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          פרטים לשימוש עצמי
        </RespFont>
        <HomeTextArea
          color={constants.fontColorZax}
          name="details"
          type="text"
          rows="3"
          style={{ margin: "0px auto" }}
          {...register("details", {
            required: false,
            minLength: 0,
            maxLength: 500,
          })}
        />
        {docKind !== 3 ? (
          <>
            <RespFont
              style={{ borderBottom: "2px solid gray", margin: "10px auto" }}
            >
              תיאור הנכס
            </RespFont>

            <FlexRowStart style={{ marginTop: "20px" }}>
              <span style={{ fontFamily: "hebM" }}>סוג העסקה:</span>
              <FlexRowStart
                style={{
                  width: "max-content",
                  justifyContent: "flex-start",
                  marginRight: "15px",
                  marginLeft: "0",
                  marginTop: "0px",
                }}
              >
                <ColorCheckbox
                  checked={isSale}
                  color={"blue"}
                  handleCHange={handleSetIsSale}
                />
                <RespFont style={{ marginRight: "2px", width: "max-content" }}>
                  {docKind === 1 ? "מכירה" : "קניה"}
                </RespFont>
              </FlexRowStart>
              <FlexRowStart
                style={{
                  width: "max-content",
                  justifyContent: "flex-start",
                  marginRight: "15px",
                  marginTop: "0px",
                  //   marginLeft: "0",
                }}
              >
                <ColorCheckbox
                  checked={isRent}
                  color={"blue"}
                  handleCHange={handleSetIsRent}
                />
                <RespFont
                  style={{
                    marginRight: "2px",
                    width: "max-content",
                  }}
                >
                  השכרה
                </RespFont>
              </FlexRowStart>
            </FlexRowStart>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
                marginTop: "20px",
              }}
            >
              דמי תיווך
            </RespFont>
            <HomeInput
              type="text"
              {...register("fees", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("fees", "בין 2 ל 50 תווים", errors)}
            {docKind === 1 && (
              <FlexRowBetween style={{ margin: "0px auto" }}>
                <div style={{ width: "30%" }}>
                  <RespFont
                    style={{
                      fontFamily: "hebB",
                      marginBottom: "5px",
                      textAlign: "right",
                    }}
                  >
                    גוש
                  </RespFont>
                  <HomeInput
                    type="number"
                    {...register("gush", {
                      min: 0,
                      max: 10000000,
                    })}
                  />
                  {CFV("gush", "עד 8 ספרות", errors)}
                </div>
                <div style={{ width: "30%" }}>
                  <RespFont
                    style={{
                      fontFamily: "hebB",
                      marginBottom: "5px",
                      textAlign: "right",
                    }}
                  >
                    חלקה
                  </RespFont>
                  <HomeInput
                    type="number"
                    {...register("helka", {
                      min: 0,
                      max: 10000000,
                    })}
                  />
                  {CFV("helka", "עד 8 ספרות", errors)}
                </div>
                <div style={{ width: "30%" }}>
                  <RespFont
                    style={{
                      fontFamily: "hebB",
                      marginBottom: "5px",
                      textAlign: "right",
                    }}
                  >
                    תת חלקה
                  </RespFont>
                  <HomeInput
                    type="number"
                    {...register("tatHelka", {
                      min: 0,
                      max: 10000000,
                    })}
                  />
                  {CFV("tatHelka", "עד 8 ספרות", errors)}
                </div>
              </FlexRowBetween>
            )}
            <FlexRowBetween style={{ margin: "0px auto" }}>
              <div style={{ width: "45%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  סוג הנכס
                </RespFont>
                <HomeInput
                  type="text"
                  {...register("assetKind", {
                    required: true,
                    minLength: 2,
                    maxLength: 50,
                  })}
                />
                {CFV("assetKind", "בין 2 ל 50 תווים", errors)}
              </div>
              <div style={{ width: "50%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  שם הבעלים
                </RespFont>
                <HomeInput
                  {...register("assetOwner", {
                    required: true,
                    minLength: 2,
                    maxLength: 50,
                  })}
                />
                {CFV("assetOwner", "בין 2 ל 50 תווים", errors)}
              </div>
            </FlexRowBetween>
            <FlexRowBetween style={{ margin: "0px auto" }}>
              <div style={{ width: "55%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  כתובת הנכס
                </RespFont>
                <HomeInput
                  type="text"
                  {...register("assetAddress", {
                    required: true,
                    minLength: 2,
                    maxLength: 50,
                  })}
                />
                {CFV("assetAddress", "בין 2 ל 50 תווים", errors)}
              </div>
              <div style={{ width: "40%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  מחיר מבוקש
                </RespFont>
                <HomeInput
                  type="number"
                  {...register("assetPrice", {
                    required: true,
                    min: 0,
                    max: 1000000000,
                  })}
                />
                {CFV("assetPrice", "עד 9 ספרות", errors)}
              </div>
            </FlexRowBetween>
            <FlexRowBetween>
              <div style={{ width: "40%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  תאריך התחלה
                </RespFont>
                <HomeInput
                  onClick={() => setShowStartCalendar(true)}
                  value={parseIsoDate(startDate)}
                  onChange={() => {}}
                  type={"text"}
                />
                {CFV("null", "בין 2 ל 20 תווים", errors)}
              </div>
              <div style={{ width: "40%" }}>
                <RespFont
                  style={{
                    fontFamily: "hebB",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  תאריך סיום
                </RespFont>
                <HomeInput
                  onClick={() => setShowEndCalendar(true)}
                  value={parseIsoDate(endDate)}
                  onChange={() => {}}
                  type={"text"}
                />
                {CFV("null", "בין 2 ל 20 תווים", errors)}
              </div>
            </FlexRowBetween>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              הערות
            </RespFont>
            <HomeTextArea
              color={constants.fontColorZax}
              name="notes"
              type="text"
              rows="3"
              style={{ margin: "0px auto" }}
              {...register("notes", {
                required: false,
                minLength: 0,
                maxLength: 500,
              })}
            />
          </>
        ) : (
          <></>
          // <GenericDiv
          //   style={{ marginTop: "20px" }}
          //   background={user.color}
          //   onClick={() => {
          //     if (user.pic === "") {
          //       setEditPic(true);
          //     } else deletePhotoOnly();
          //   }}
          // >
          //   <img src={gallery} style={{ width: "20px", marginLeft: "10px" }} />
          //   <div>{user.pic === "" ? "העלאת תמונה" : "מחיקת תמונה"}</div>
          // </GenericDiv>
        )}
        <GenericButton
          form="form1"
          background={user.settings.colors.buttonsColor}
          color={"#fff"}
          type="submit"
          style={{
            width: "100%",
            margin: "0px auto",
            padding: "10px",
            borderRadius: "5px",
            fontWeight: "bolder",
            fontSize: "16px",
            boxShadow: "none",
            marginTop: "25px",
          }}
        >
          הוספה
        </GenericButton>
      </div>
      {assetsLoading && <OpacityLoading />}
      {showStartCalendar && (
        <GenericModal
          init={setShowStartCalendar}
          div={
            <GCalendar
              background={user.settings.colors.buttonsColor}
              setDate={handleSetStartDate}
              date={startDate}
              init={setShowStartCalendar}
            />
          }
        />
      )}
      {showEndCalendar && (
        <GenericModal
          init={setShowEndCalendar}
          div={
            <GCalendar
              background={user.settings.colors.buttonsColor}
              setDate={handleSetEndDate}
              date={endDate}
              init={setShowEndCalendar}
            />
          }
        />
      )}
      {/* {editPic && (
        <GenericModal init={setEditPic} div={<EditPics init={setEditPic} />} />
      )} */}
    </PopupForm>
  );
};

export default AddSell;
