import React, { useState, useMemo } from "react";
import { FlexRowBetween, GenericButton, RespFont } from "../Style";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../common/modals/Toast";
import { useForm } from "react-hook-form";
import { editDetails } from "../../store/reducers/authReducer";
import OpacityLoading from "../common/loader/OpacityLoading";
import { FlexRowStart, HomeInput, HomeTextArea } from "../common/Style";
import ColorCheckbox from "../common/others/ColorCheckbox";
import EditPics from "./images/EditPics";
import GenericModal from "../common/modals/GenericModal";
import { deletePhoto } from "../../store/reducers/photosReducer";
import SketchColorPaletteG from "../common/sketchColor/SketchColorPaletteG";

const EditDetailsQuesAndAns = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [kind, setKind] = useState(false);
  const [eee, setEee] = useState(null);
  const { user, editDetailsLoading, settingsFlag } = useSelector(
    (state) => state.authReducer
  );
  const [tempQues, setTempQues] = useState(user?.settings?.questions);
  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoOnly = async (key) => {
    await deletePhoto1(user[key]);
    dispatch(editDetails({ [key]: "" })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };
  const getd = (color, nName, pageToChange, setDisplay, kKKind, palette) => (
    <div
      style={{
        textAlign: "center",
        marginTop: widthS > 768 ? "-15px" : "0px",
        position: "relative",
        width: "100%",
        maxWidth: "300px",
        margin: "10px 20px",
      }}
    >
      {palette}
      <RespFont
        style={{
          fontFamily: "hebM",
          width: "max-content",
          textAlign: "center",
          margin: "auto",
        }}
      >
        {nName}
      </RespFont>
      <div
        onClick={() => {
          setDisplayT(false);
          setDisplayP(false);
          setDisplayM(false);
          setChoosenColor(color);
          setPageToChange(pageToChange);
          if (name === nName && kKKind === kKind) {
            setName("null");
            setKKind("null");
            setDisplay(false);
          } else {
            setName(nName);
            setKKind(kKKind);
            setDisplay(true);
          }
          // setName(nName);
          // setDisplay(true);
        }}
        style={{
          border: "1px solid silver",
          padding: "3px",
          borderRadius: "3px",
          width: "min-content",
          margin: "0 auto",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            width: widthS > 768 ? "70px" : "30px",
            height: "30px",
            backgroundColor: color,
            borderRadius: "3px",
            position: "relative",
          }}
        />
      </div>
    </div>
  );

  const [displayT, setDisplayT] = useState(false);
  const [displayP, setDisplayP] = useState(false);
  const [displayM, setDisplayM] = useState(false);
  const [isLengthWasChanged, seIsLengthWasChanged] = useState(false);
  const [name, setName] = useState("null");
  const [kKind, setKKind] = useState("null");
  const [choosenColor, setChoosenColor] = useState(false);
  const [pageToChange, setPageToChange] = useState(false);
  const [showPage5, setShowPage5] = useState(user.settings.showPages.page5);
  // const [showAllFeatures, setShowAllFeatures] = useState(false);
  let widthS = window.innerWidth; // 1728
  let inputsArray = useMemo(() => {
    let qAAInputs = [];

    if (tempQues?.length > 0) {
      for (let i = 0; i < tempQues.length; i++) {
        qAAInputs.push(
          <div
            style={{
              width: "100%",
              maxWidth: "300px",
              margin: "10px 20px",
            }}
          >
            <FlexRowBetween>
              <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
                {`שאלה ${i + 1}`}
              </RespFont>
            </FlexRowBetween>
            <HomeInput
              type="text"
              style={{ fontFamily: "hebM" }}
              defaultValue={tempQues[i].ques}
              {...register(`questions_ques_${i}`, {
                // minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV(`questions_ques_${i}`, "בין 2 ל 50 אותיות", errors)}
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              תשובה
            </RespFont>
            <HomeTextArea
              defaultValue={tempQues[i].ans}
              type="text"
              rows="5"
              {...register(`questions_ans_${i}`, {
                // minLength: 2,
                maxLength: 500,
              })}
            />
            {CFV(`questions_ans_${i}`, "בין 2 ל 500 אותיות", errors)}
          </div>
        );
      }
    }
    return widthS > 768 ? qAAInputs : qAAInputs;
  }, [settingsFlag, isLengthWasChanged]);

  const onSubmit = async (data) => {
    console.log("data");
    console.log(data);
    console.log("data");
    const body = {
      contactPhone: user.contactPhone,
      settings: {
        ...user.settings,
        questions: [
          { ques: data.questions_ques_0, ans: data.questions_ans_0 },
          { ques: data.questions_ques_1, ans: data.questions_ans_1 },
          { ques: data.questions_ques_2, ans: data.questions_ans_2 },
          { ques: data.questions_ques_3, ans: data.questions_ans_3 },
          { ques: data.questions_ques_4, ans: data.questions_ans_4 },
          { ques: data.questions_ques_5, ans: data.questions_ans_5 },
          { ques: data.questions_ques_6, ans: data.questions_ans_6 },
          { ques: data.questions_ques_7, ans: data.questions_ans_7 },
          { ques: data.questions_ques_8, ans: data.questions_ans_8 },
          { ques: data.questions_ques_9, ans: data.questions_ans_9 },
        ],
        showPages: {
          ...user.settings.showPages,
          page5: showPage5,
        },
        pagesColors: {
          ...user.settings.pagesColors,
          page5: user.settings.pagesColors.page5,
        },
        TitlePagesColor: {
          ...user.settings.TitlePagesColor,
          page5: user.settings.TitlePagesColor.page5,
        },
        PagesTitle: {
          ...user.settings.PagesTitle,
          page5: data.page5title,
        },
      },
    };
    dispatch(editDetails(body)).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      else GetToast(addToast, "error", res.payload.error);
    });
  };

  return (
    <form
      id="editDetails-id"
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "90%",
        margin: "10px auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          margin: "0px 0px",
          height: "100%",
          overflow: "auto",
          paddingBottom: "30px",
          overflowX: "hidden",
        }}
      >
        {inputsArray}
        <br />
        <FlexRowStart>
          <div
            style={{
              width: "100%",
              maxWidth: "300px",
              margin: "10px 20px",
            }}
          >
            <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
              כותרת העמוד
            </RespFont>
            <HomeInput
              type="text"
              defaultValue={user.settings.PagesTitle.page5}
              {...register("page5title", {
                required: true,
                minLength: 1,
                maxLength: 50,
              })}
            />
            {CFV(`page5title`, "בין 1 ל 50 תווים", errors)}
          </div>
          <FlexRowBetween
            style={{
              width: "min-content",
              marginRight: "0px",
              marginLeft: "0px",
            }}
          >
            <ColorCheckbox
              checked={showPage5}
              color={user.settings.colors.buttonsColor}
              handleCHange={() => setShowPage5(!showPage5)}
            />
            <RespFont style={{ width: "max-content" }}>הצגת העמוד</RespFont>
          </FlexRowBetween>
          {getd(
            user.settings.pagesColors.page5,
            "רקע העמוד",
            "page5",
            setDisplayP,
            "p",
            <SketchColorPaletteG
              wtc={"pages"}
              name={name}
              change={pageToChange}
              display={displayP}
              onClose={setDisplayP}
              cc={choosenColor}
            />
          )}
          {getd(
            user.settings.TitlePagesColor.page5,
            "צבע הכותרת",
            "page5",
            setDisplayT,
            "t",
            <SketchColorPaletteG
              wtc={"titles"}
              name={name}
              change={pageToChange}
              display={displayT}
              onClose={setDisplayT}
              cc={choosenColor}
            />
          )}
        </FlexRowStart>

        {/* <FlexRowBetween
          style={{
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <SketchColorPaletteG
              wtc={"pages"}
              name={name}
              change={pageToChange}
              display={displayP}
              onClose={setDisplayP}
              cc={choosenColor}
            />
          </div>
        </FlexRowBetween>
        <FlexRowBetween
          style={{
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <SketchColorPaletteG
              wtc={"titles"}
              name={name}
              change={pageToChange}
              display={displayT}
              onClose={setDisplayT}
              cc={choosenColor}
            />
          </div>
        </FlexRowBetween> */}

        <GenericButton
          background={user.settings.colors.buttonsColor}
          color={user.settings.colors.buttonsFontColor}
          style={{
            width: widthS > 768 ? "50%" : "80%",
            margin: "0 auto",
            marginTop: "20px",
            fontWeight: "700",
            fontFamily: "monsterB",
            padding: "10px",
            marginBottom: "25px",
          }}
          type="submit"
        >
          <RespFont>שמירה</RespFont>
        </GenericButton>
        {editDetailsLoading && (
          <div style={{ marginRight: "-20px" }}>
            <OpacityLoading />
          </div>
        )}
        {kind && (
          <GenericModal
            init={setKind}
            div={<EditPics kind={kind} init={setKind} />}
          />
        )}
      </div>
    </form>
  );
};

export default EditDetailsQuesAndAns;
