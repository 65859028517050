import React, { useMemo } from "react";
import { constants, FlexRowBetween, RespFont, Section } from "../../Style";
import whiteStar from "../../../assets/zaks/whiteStar.svg";
import { useSelector } from "react-redux";
import { getImageUrl, handleClickScrollXY } from "../modals/Toast";
import CommonEdit from "../others/CommonEdit";
const AboutP = ({ setEditAbout, editAbout, authenticated }) => {
  const { user, settingsFlag } = useSelector((state) => state.authReducer);

  let agentValues = useMemo(() => {
    const values = user.settings.about.part4;
    let fff = [];
    for (let i = 0; i < values.length; i++)
      if (values[i]["key"] !== "")
        fff.push(
          <FlexRowBetween
            style={{
              width: "90%",
              margin: "10px auto",
              justifyContent: "flex-start",
            }}
          >
            <img
              src={whiteStar}
              style={{
                width: "45px",
                padding: "10px",
                backgroundColor: user.settings.TitlePagesColor.page3,
                borderRadius: "50%",
                marginLeft: "15px",
              }}
            />
            <RespFont style={{ textAlign: "right" }}>
              <b>{`${values[i]["key"]} - `}</b>
              {values[i]["value"]}
            </RespFont>
          </FlexRowBetween>
        );
    return fff;
  }, [settingsFlag]);

  let aboutPic =
    user.aboutPic !== ""
      ? `${getImageUrl()}${user._id}/${user.aboutPic}`
      : `${getImageUrl()}sys/defaultAboutUsPic.png`;
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        margin: "0",
        background: user.settings.pagesColors.page3,
        position: "relative",
      }}
    >
      {authenticated && (
        <div style={{ position: "absolute", bottom: "20px", left: "20px" }}>
          <CommonEdit
            right={null}
            left={"0px"}
            bottom={"-50px"}
            func={() => {
              if (!editAbout) handleClickScrollXY(1200);
              setEditAbout(!editAbout);
            }}
            color={user.settings.TitlePagesColor.page3}
          />
        </div>
      )}
      <div
        id={"steps"}
        style={{
          width: "100%",
          margin: "50px auto",
        }}
      >
        <div style={{ width: "70%", margin: "0 auto" }}>
          <img src={aboutPic} style={{ width: "100%", margin: "0 auto" }} />
        </div>
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <Section
            pSize={"36px"}
            cSize={"48px"}
            style={{
              color: user.settings.TitlePagesColor.page3,
              marginBottom: "0px",
              marginTop: "10px",
            }}
          >
            {user.settings.about.part0}
          </Section>
          <RespFont
            pSize={"18px"}
            cSize={"22px"}
            style={{
              fontFamily: "monsterB",
              color: constants.fontColorZax,
              textAlign: "center",
            }}
          >
            {user.settings.about.part1}
          </RespFont>
          <RespFont
            pSize={"14px"}
            cSize={"16px"}
            style={{
              fontFamily: "monster",
              marginTop: "10px",
              fontWeight: "100",
              color: constants.fontColorZax,
              textAlign: "center",
            }}
          >
            {user.settings.about.part2}
          </RespFont>
          <Section
            pSize={"26px"}
            cSize={"38px"}
            style={{
              color: user.settings.TitlePagesColor.page3,
              width: "60%",
              marginBottom: "0px",
              marginTop: "10px",
            }}
          >
            {user.settings.about.part3}
          </Section>

          {agentValues}
        </div>
      </div>
    </div>
  );
};

export default AboutP;
