import React, { useEffect, useMemo } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuth } from '../store/reducers/authReducer';
import { ToastProvider } from "react-toast-notifications";
import AdminSwitch from '../navigation/AdminSwitch';
import UnAuthSwitch from '../navigation/UnAuthSwitch';
import UserSwitch from '../navigation/UserSwitch';
import { availHeight } from '../components/common/modals/Toast'
import FilledLoading from '../components/common/loader/FilledLoading';
import 'react-image-crop/dist/ReactCrop.css'
// import Loading from '../components/common/modals/Loading';

function App() {
  const { user, authenticated, authLoading } = useSelector(state => state.authReducer);
  // const auth = useSelector(state => state.authReducer);
  // const { loading } = useSelector(state => state.authReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user && localStorage['bs'])
      dispatch(checkAuth())
  }, [authenticated]);

  // useEffect(() => {
  //   if (!auth.user) {
  //     dispatch(checkAuth())
  //   }
  // }, [auth.authenticated]);

  // useEffect(() => {
  //   if (error)
  //     GetToast(addToast, "error", error)
  // }, [error]);

  let view = useMemo(() => {
    let tempView = <FilledLoading background={user ? user.controls : null} />;

    if (authenticated && user && user.role === 1 && !authLoading)
      return <UserSwitch />

    else if (authenticated && user && user.role === 2 && !authLoading)
      return <AdminSwitch />

    else if (!authenticated && !authLoading)
      return <UnAuthSwitch />

    return tempView;
  }, [authenticated])

  // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

  // let view;
  // if (authenticated && user && user.role === 1 && !authLoading)
  //   view = <UserSwitch />

  // else if (authenticated && user && user.role === 2 && !authLoading)
  //   view = <AdminSwitch />

  // else if (authenticated && user && user.role === 3 && !authLoading)
  //   view = <AvivSwitch />

  // else if (!authenticated && !authLoading)
  //   view = <UnAuthSwitch background={user ? user.controls : null} />

  return (
    <div className="App animate__animated animate__fadeIn">
      <ToastProvider placement="bottom-center">
        {view}
      </ToastProvider>
      {authLoading && <FilledLoading background={user ? user.controls : null} />}
    </div>
  )
}

export default App;
