import React from "react";
import v0 from "../../../assets/zaks/v0.svg";
import v1 from "../../../assets/zaks/v1.svg";
import v2 from "../../../assets/zaks/v2.svg";

const ZaxLogo = ({ width = "65px", marginTop = "-11px", mR = "0px" }) => {
  return (
    <div
      className="scale11"
      style={{ cursor: "pointer", marginTop: "5px", marginBottom: "-5px" }}
    >
      <div>
        <img src={v0} alt={"v0"} style={{ width: width }} />
      </div>
      <div style={{ height: "min-content", marginTop: mR }}>
        <img src={v1} alt={"v1"} style={{ width: width }} />
      </div>
      <img src={v2} alt={"v2"} style={{ width: width, marginTop: marginTop }} />
    </div>
  );
};

export default ZaxLogo;
