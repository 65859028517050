import { createSlice } from "@reduxjs/toolkit";
import { post2, get2 } from "../api/http";
import ServerRoutes from "../api/routes";

const initialState = {
  leads: {},
  leadsError: null,
  leadsLoading: false,
};

const _addLead = (state, action) => {
  let editedLeads = { ...state.leads };
  editedLeads[action.payload.id] = {
    ...action.payload.body,
    done: false,
    _id: action.payload.id,
    dateAdded: action.payload.dateAdded,
  };
  state.leads = editedLeads;
};

const _setLeads = (state, action) => {
  state.leads = action.payload;
};

const _editLead = (state, action) => {
  let editedLeads = { ...state.leads };
  console.log(action.payload.body);
  console.log(action.payload.id);
  for (const [key, value] of Object.entries(action.payload.body))
    editedLeads[action.payload.id][key] = value;
  state.leads = editedLeads;
};

const _deleteLead = (state, action) => {
  let editedLeads = { ...state.leads };
  delete editedLeads[action.payload];
  state.leads = editedLeads;
};

const _actionStart = (state) => {
  state.leadsLoading = true;
  state.leadsError = null;
};

const _actionSuccess = (state) => {
  state.leadsLoading = false;
  state.leadsError = null;
};

const _actionFailed = (state, action) => {
  state.leadsLoading = false;
  state.leadsError = action.payload.error;
};

const _cleanLeadsState = (state) => {
  state.leads = {};
  state.leadsError = null;
  state.leadsLoading = false;
};

const leads = createSlice({
  name: "leads",
  initialState,
  reducers: {
    addLead1: _addLead,
    cleanLeadsState: _cleanLeadsState,
    editLead1: _editLead,
    deleteLead1: _deleteLead,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    setLeads: _setLeads,
    actionSuccess: _actionSuccess,
  },
});

const { reducer, actions } = leads;

const {
  actionStart,
  actionFailed,
  actionSuccess,
  addLead1,
  editLead1,
  deleteLead1,
  setLeads,
  cleanLeadsState,
} = actions;

export default reducer;

export const addLead = (body) => {
  console.log(body);
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(ServerRoutes.Leads.add, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      return dispatch(actionSuccess());
      // return dispatch(addLead1({ id: data.id, dateAdded: data.dateAdded, body }));
    }
  };
};

export const getAll = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Leads.getAll);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setLeads(data));
    }
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    return dispatch(cleanLeadsState());
  };
};

export const editLeadDetails = (body, id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Leads.editLeadDetails + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editLead1({ id, body }));
    }
  };
};

export const editStatus = (id, body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(
      ServerRoutes.Leads.editStatus + id,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editLead1({ id, body }));
    }
  };
};

export const deleteLead = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Leads.delete, {
      id,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteLead1(id));
    }
  };
};

// export const deleteLeads = (guestsToDelete) => {
//   return async (dispatch) => {
//     dispatch(actionStart());
//     const { error, status } = await post2(ServerRoutes.Leads.deleteMany, {
//       guestsToDelete,
//     });
//     if (status !== 200) return dispatch(actionFailed({ error }));
//     else {
//       dispatch(actionSuccess());
//       return dispatch(deleteLead1(id));
//     }
//   };
// };
