import React from "react";
// import background from '../../../assets/shapes/s3.svg'
import contactBackC from "../../../assets/zaks/contactBackC.png";
import contactBackP from "../../../assets/zaks/contactBackP.png";
import {
  DisplayNoneComputer,
  DisplayNonePhone,
  HomeInput,
  RTextArea,
} from "../../common/Style";
import {
  constants,
  FlexRowBetween,
  GenericButton,
  InputBox,
  RespFont,
  Section,
} from "../../Style";
import { CenteredFormPage2 } from "./Style";
import { useForm } from "react-hook-form";
import { addPotential } from "../../../store/reducers/potentialsReducer";
import { CFV, GetToast, handleClickScroll } from "../../common/modals/Toast";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";

const ContactUs = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { addToast } = useToasts();

  const onSubmit = async (data) => {
    const body = {
      phone: data.phone,
      name: data.name,
      //   email: data.email,
      //   details: data.details,
    };
    dispatch(addPotential(body)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!", 8000);
        handleClickScroll("right-id");
      } else GetToast(addToast, "error", res.payload.error);
    });
  };
  let widthS = window.innerWidth; // 1728
  return (
    <div
      id={"contact-id"}
      style={{
        width: "100%",
        height: widthS > 768 ? "800px" : "600px",
        backgroundposition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          widthS > 768 ? `url(${contactBackC})` : `url(${contactBackP})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CenteredFormPage2
        onSubmit={handleSubmit(onSubmit)}
        style={{
          margin: "50px auto",
          borderRadius: "10px",
          boxShadow: "0px 0px 55px rgba(0, 0, 0, 0.5)",
        }}
      >
        <DisplayNoneComputer>
          <Section
            style={{
              width: "90%",
              margin: "20px auto",
            }}
            pSize={"24px"}
            cSize={"28px"}
          >
            חושבים שאתם מוכנים לקחת את העסק שלכם קדימה?
            <br /> דברו איתנו!
          </Section>
        </DisplayNoneComputer>
        <DisplayNonePhone>
          <Section
            style={{
              width: "80%",
              margin: "20px auto",
            }}
            pSize={"24px"}
            cSize={"28px"}
          >
            חושבים שאתם מוכנים לקחת את העסק שלכם קדימה? דברו איתנו!
            {/* <br /> דברו איתנו! */}
          </Section>
        </DisplayNonePhone>
        <div
          style={{
            width: "80%",
            margin: "20px auto",
          }}
        >
          <HomeInput
            fontFamily={"openSansRegular"}
            pHcolor={"rgba(56, 52, 61, 0.7)"}
            placeholder="שם מלא"
            name="name"
            style={{
              backgroundColor: "#f2f2f2",
              paddingRight: "10px",
            }}
            {...register("name", {
              required: true,
              minLength: 2,
              maxLength: 20,
            })}
          />
          {CFV("name", "שם מלא בין 2 ל 20 תווים", errors)}
          <HomeInput
            fontFamily={"openSansRegular"}
            pHcolor={"rgba(56, 52, 61, 0.7)"}
            placeholder="מס׳ פלאפון"
            style={{
              backgroundColor: "#f2f2f2",
              paddingRight: "10px",
            }}
            {...register("phone", {
              required: true,
              pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
            })}
          />
          {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)}
          <GenericButton
            background={"#DE8556"}
            color={"#fff"}
            type="submit"
            style={{
              width: "100%",
              margin: "20px auto",
              padding: "10px",
              borderRadius: "5px",
              fontWeight: "bolder",
              fontSize: "16px",
              boxShadow: "none",
              fontFamily: "monsterB",
            }}
          >
            שליחה
          </GenericButton>
        </div>
      </CenteredFormPage2>
    </div>
  );
};

export default ContactUs;
