import React from "react";
import { ModalStyleB } from "../../Style";

const GenericModalBottom = ({
  div,
  init,
  background = "radial-gradient(circle, #f2f2f2 0%, rgba(255,255,255,0.2) 100%)",
}) => {
  return (
    <ModalStyleB
      className="animate__animated animate__fadeIn"
      style={{ background: background, backdropFilter: "blur(4px)" }}
      onClick={(e) => {
        e.preventDefault();
        init(null);
      }}
    >
      {div}
    </ModalStyleB>
  );
};
export default GenericModalBottom;
