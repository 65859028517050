import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ClientRoutes from "./Routes";
import User from "../components/user/User";
import Face from "../components/user/Face";
import EditDetails from "../components/user/EditDetails";
import Qaa from "../components/user/qaa/Qaa";
import Articles from "../components/user/article/Articles";
import Calendar from "../components/user/calendar/Calendar";
import Asset from "../components/unAuth/agent/Asset";
import SuccessPage from "../components/common/views/SuccessPage";
import FailurePage from "../components/common/views/FailurePage";
import Doc from "../components/common/views/Doc";
import PDF from "../components/user/docs/PDF";
const UserSwitch = () => {
  return (
    <Switch>
      <Route
        path={ClientRoutes.User.face}
        component={(props) => <Face {...props} />}
      />
      <Route
        path={ClientRoutes.User.doc}
        component={(props) => <Doc {...props} />}
      />
      <Route
        path={ClientRoutes.User.pdf}
        component={(props) => <PDF {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.User.successPage}
        component={(props) => <SuccessPage {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.User.failurePage}
        component={(props) => <FailurePage {...props} />}
      />

      <Route
        path={ClientRoutes.User.user}
        component={(props) => <User {...props} />}
      />
      <Route
        path={ClientRoutes.User.editDetails}
        component={(props) => <EditDetails {...props} />}
      />
      <Route
        path={ClientRoutes.User.qaa}
        component={(props) => <Qaa {...props} />}
      />
      <Route
        path={ClientRoutes.User.articles}
        component={(props) => <Articles {...props} />}
      />
      <Route
        path={ClientRoutes.User.calendar}
        component={(props) => <Calendar {...props} />}
      />
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.asset}
        component={(props) => <Asset {...props} />}
      />
      <Redirect from="/*" to={ClientRoutes.User.user} />
    </Switch>
  );
};

export default UserSwitch;
