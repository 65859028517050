import React, { useState, useMemo } from "react";
import { FlexRowBetween, GenericButton, RespFont } from "../Style";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../common/modals/Toast";
import { useForm } from "react-hook-form";
import { editDetails } from "../../store/reducers/authReducer";
import OpacityLoading from "../common/loader/OpacityLoading";
import { FlexRowStart, HomeInput, HomeTextArea } from "../common/Style";

import ColorCheckbox from "../common/others/ColorCheckbox";
import EditPics from "./images/EditPics";
import GenericModal from "../common/modals/GenericModal";
import { deletePhoto } from "../../store/reducers/photosReducer";
import SketchColorPaletteG from "../common/sketchColor/SketchColorPaletteG";

const EditDetailsArticle = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [kind, setKind] = useState(false);
  const { user, editDetailsLoading, settingsFlag } = useSelector(
    (state) => state.authReducer
  );
  const [tempQues, setTempQues] = useState(user?.settings?.questions);
  const deletePhoto1 = async (id) => dispatch(deletePhoto(id));
  const deletePhotoOnly = async (key) => {
    await deletePhoto1(user[key]);
    dispatch(editDetails({ [key]: "" })).then(
      (res) => {
        if (!res.payload?.error)
          GetToast(addToast, "success", "הפעולה הצליחה!!");
      },
      (error) => {
        GetToast(addToast, "error", error);
      }
    );
  };
  const getd = (color, nName, pageToChange, setDisplay, kKKind, palette) => (
    <div
      style={{
        textAlign: "center",
        marginTop: widthS > 768 ? "-7px" : "0px",
        position: "relative",
        width: "100%",
        maxWidth: "300px",
        margin: "10px 20px",
      }}
    >
      {palette}
      <RespFont
        style={{
          fontFamily: "hebM",
          width: "max-content",
          textAlign: "center",
          margin: "auto",
        }}
      >
        {nName}
      </RespFont>
      <div
        onClick={() => {
          setDisplayT(false);
          setDisplayP(false);
          setDisplayM(false);
          setChoosenColor(color);
          setPageToChange(pageToChange);
          if (name === nName && kKKind === kKind) {
            setName("null");
            setKKind("null");
            setDisplay(false);
          } else {
            setName(nName);
            setKKind(kKKind);
            setDisplay(true);
          }
          // setName(nName);
          // setDisplay(true);
        }}
        style={{
          border: "1px solid silver",
          padding: "3px",
          borderRadius: "3px",
          width: "min-content",
          margin: "0 auto",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            width: widthS > 768 ? "70px" : "30px",
            height: "30px",
            backgroundColor: color,
            borderRadius: "3px",
            position: "relative",
          }}
        />
      </div>
    </div>
  );
  const [displayT, setDisplayT] = useState(false);
  const [displayP, setDisplayP] = useState(false);
  const [displayM, setDisplayM] = useState(false);
  const [isLengthWasChanged, seIsLengthWasChanged] = useState(false);
  const [name, setName] = useState("null");
  const [kKind, setKKind] = useState("null");
  const [choosenColor, setChoosenColor] = useState(false);
  const [pageToChange, setPageToChange] = useState(false);
  const [showPage6, setShowPage6] = useState(user.settings.showPages.page6);
  // const [showAllFeatures, setShowAllFeatures] = useState(false);
  let widthS = window.innerWidth; // 1728
  let inputsArray = useMemo(() => {
    let inputs = [];
    if (user.settings.about.part4.length > 0) {
      for (let i = 0; i < user.settings.about.part4.length; i++) {
        inputs.push(
          <div
            style={{
              width: "100%",
              maxWidth: "300px",
              margin: "10px 20px",
            }}
          >
            <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
              {`מאפיין ${i + 1}`}
            </RespFont>
            <HomeInput
              type="text"
              style={{ fontFamily: "hebM" }}
              defaultValue={user.settings.about.part4[i]["key"]}
              {...register(`about_part4_key_${i}`, {
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV(`about_part4_key_${i}`, "בין 2 ל 50 אותיות", errors)}
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              תוכן
            </RespFont>
            <HomeTextArea
              defaultValue={user.settings.about.part4[i]["value"]}
              type="text"
              rows="5"
              {...register(`about_part4_value_${i}`, {
                minLength: 2,
                maxLength: 500,
              })}
            />
            {CFV(`about_part4_value_${i}`, "בין 2 ל 500 אותיות", errors)}
          </div>
        );
      }
    }
    return widthS > 768 ? inputs : inputs;
  }, [settingsFlag, isLengthWasChanged]);

  const onSubmit = async (data) => {
    const body = {
      settings: {
        ...user.settings,
        showPages: {
          ...user.settings.showPages,
          page6: showPage6,
        },
        pagesColors: {
          ...user.settings.pagesColors,
          page6: user.settings.pagesColors.page6,
        },
        TitlePagesColor: {
          ...user.settings.TitlePagesColor,
          page6: user.settings.TitlePagesColor.page6,
        },
      },
    };
    dispatch(editDetails(body)).then((res) => {
      if (!res.payload?.error)
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
      else GetToast(addToast, "error", res.payload.error);
    });
  };

  return (
    <form
      id="editDetails-id"
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "90%",
        margin: "10px auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          margin: "0px 0px",
          height: "100%",
          overflow: "auto",
          paddingBottom: "30px",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "10px 20px",
          }}
        >
          <RespFont style={{ fontFamily: "hebB", marginBottom: "5px" }}>
            כתבות
          </RespFont>
          <HomeInput
            type="text"
            defaultValue={user.settings.PagesTitle.page6}
            {...register("page6title", {
              required: true,
              minLength: 1,
              maxLength: 50,
            })}
          />
          {CFV(`page6title`, "בין 1 ל 50 תווים", errors)}
        </div>
        {/* <FlexRowStart> */}
        <FlexRowBetween
          style={{
            width: "min-content",
            marginRight: "0px",
            marginLeft: "0px",
          }}
        >
          <ColorCheckbox
            checked={showPage6}
            color={user.settings.colors.buttonsColor}
            handleCHange={() => setShowPage6(!showPage6)}
          />
          <RespFont style={{ width: "max-content" }}>הצגת העמוד</RespFont>
        </FlexRowBetween>
        {getd(
          user.settings.pagesColors.page6,
          "רקע העמוד",
          "page6",
          setDisplayP,
          "p",
          <SketchColorPaletteG
            wtc={"pages"}
            name={name}
            change={pageToChange}
            display={displayP}
            onClose={setDisplayP}
            cc={choosenColor}
            top={widthS > 768 ? "70%" : "120%"}
          />
        )}
        {getd(
          user.settings.TitlePagesColor.page6,
          "צבע אלמנטים",
          "page6",
          setDisplayT,
          "t",
          <SketchColorPaletteG
            wtc={"titles"}
            name={name}
            change={pageToChange}
            display={displayT}
            onClose={setDisplayT}
            cc={choosenColor}
            top={widthS > 768 ? "70%" : "120%"}
          />
        )}
        {/* </FlexRowStart> */}

        <GenericButton
          background={user.settings.colors.buttonsColor}
          color={user.settings.colors.buttonsFontColor}
          style={{
            width: widthS > 768 ? "50%" : "80%",
            margin: "0 auto",
            marginTop: "20px",
            fontWeight: "700",
            fontFamily: "monsterB",
            padding: "10px",
            marginBottom: "25px",
          }}
          type="submit"
        >
          <RespFont>שמירה</RespFont>
        </GenericButton>
        {editDetailsLoading && (
          <div style={{ marginRight: "-20px" }}>
            <OpacityLoading />
          </div>
        )}
        {kind && (
          <GenericModal
            init={setKind}
            div={<EditPics kind={kind} init={setKind} />}
          />
        )}
      </div>
    </form>
  );
};

export default EditDetailsArticle;
