import React from "react";
import Calendar from "react-calendar";
import { GenericDiv, CenteredForm } from "../Style";
import "react-calendar/dist/Calendar.css";
import "./style.css";
import { PopupForm } from "../../Style";
const GCalendar = ({
  setDate,
  date,
  background,
  init,
  button = "שמירה",
  showButton = true,
}) => {
  return (
    <PopupForm onClick={(e) => e.stopPropagation()}>
      <Calendar
        locale={"he"}
        onChange={setDate}
        value={date}
        style={{ paddingBottom: "20px" }}
      />
      {showButton && (
        <GenericDiv
          background={background}
          style={{
            borderRadius: "0px",
            width: "100%",
            fontSize: "16px",
            padding: "10px",
          }}
          onClick={() => {
            init(null);
          }}
        >
          {button}
        </GenericDiv>
      )}
    </PopupForm>
  );
};
export default GCalendar;
