import React, { useState } from "react";
import {
  GenericButton,
  RespFont,
  Responsive,
  HomeInput,
  FlexRowBetween,
  DisplayNoneComputer,
  DisplayNonePhone,
} from "../../common/Style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { addPotential } from "../../../store/reducers/potentialsReducer";
import { CenteredFormPage1, MainRegister } from "./Style";

import t0 from "../../../assets/zaks/t0.svg";

const Right = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");

  const { potentialsLoading } = useSelector((state) => state.potentialsReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const regex = /^[0]{0,1}[5]{1}[0-9]{8}$/g;
    let tempPhone = phone.replaceAll(/[^\d]/g, "");
    const validatePhone = tempPhone.match(regex);
    const validateName = name.length >= 2 && name.length <= 50;
    if (validateName && validatePhone !== null) {
      const body = {
        phone,
        name,
      };
      dispatch(addPotential(body)).then((res) => {
        if (!res.payload?.error) {
          GetToast(addToast, "success", "הפעולה בוצעה בהצלחה!!");
        } else GetToast(addToast, "error", res.payload.error);
      });
    } else
      GetToast(
        addToast,
        "warning",
        "שם בין 2 ל 50 תווים, מספר טלפון ללא סימנים ורווחים, נא להקליד ידנית ספרה ספרה",
        8000
      );
  };
  return (
    <div
      id={"right-id"}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <LogoImg src={l1} /> */}
      <DisplayNoneComputer style={{ marginTop: "200px" }} />
      <img src={t0} style={{ width: "250px" }} />
      <RespFont
        cSize={"24px"}
        pSize={"18px"}
        style={{ textAlign: "center", width: "60%", margin: "20px auto" }}
      >
        שדרגו עוד היום לאתר פרטי משלכם שיציג את הנכסים והידע שלכם בצורה הטובה
        ביותר.
      </RespFont>
      {/* <img src={t1p} style={{ width: "320px", marginTop: "20px" }} /> */}
      {/* </DisplayNoneComputer> */}
      <CenteredFormPage1
        onSubmit={handleSubmit(onSubmit)}
        style={{ margin: "10px auto" }}
      >
        <MainRegister>
          <FlexRowBetween style={{ width: "90%", margin: "12px auto" }}>
            <HomeInput
              fontFamily={"openSansRegular"}
              onChange={(e) => setName(e.target.value)}
              placeholder="שם מלא"
              style={{ marginRight: "5.5%" }}
            />
            <HomeInput
              fontFamily={"openSansRegular"}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="מס׳ פלאפון"
              style={{ marginLeft: "7.3%" }}
            />
          </FlexRowBetween>
          <div>
            <GenericButton
              hoverBackground={"#5c3e94"}
              style={{
                padding: "10px",
                fontWeight: "700",
                background:
                  "linear-gradient(135deg, rgba(222, 133, 86, 1), rgb(240, 186, 157))",
              }}
              type="submit"
            >
              השארת פרטים
            </GenericButton>
          </div>
        </MainRegister>
      </CenteredFormPage1>
      {potentialsLoading && <OpacityLoading />}
    </div>
  );
};

export default Right;
