import React, { useEffect, useState } from "react";
import { FlexRowBetween, RespDiv, RespFont, Section } from "../../Style";
import leftArrow from "../../../assets/zaks/leftArrow.svg";
import filledStar from "../../../assets/nadlan/filledStar.svg";
import emptyStar from "../../../assets/nadlan/emptyStar.svg";
import { Responsive } from "../../common/Style";
import { useSelector } from "react-redux";
import { svgs } from "../../common/footer/coloredSvgs/svgs";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import Output from "./image/Output";

const GenericReference = ({ setEditReference, data }) => {
  const { user, authenticated } = useSelector((state) => state.authReducer);
  return (
    <Responsive
      width={true}
      margin={true}
      marginBottom={true}
      marBottomP={"5px"}
      widC={"20%"}
      widP={"85%"}
      marP={"0 auto"}
      marC={"15px"}
      style={{
        background: "#FFFFFF",
        boxShadow: "0px 13px 19px rgba(0, 0, 0, 0.07)",
        borderRadius: "10px",
        height: "444px",
        minWidth: "250px",
        position: "relative",
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.11)",
      }}
    >
      {data.pic === "" ? (
        <div style={{ margin: "20px auto" }}>
          <img
            src={
              "https://icon-library.com/images/no-user-image-icon/no-user-image-icon-0.jpg"
            }
            style={{
              // height: "300px",
              width: "100px",

              borderRadius: "50%",
              border: `3px solid ${user.settings.TitlePagesColor.page4}`,
            }}
          />
        </div>
      ) : (
        <Output />
      )}

      <RespFont style={{ padding: "0 10px", color: "rgba(56, 52, 61, 0.7)" }}>
        {data.description.split("??").map((x) => (
          <div>{x}</div>
        ))}
      </RespFont>
      <FlexRowBetween
        style={{ width: "min-content", margin: "10px auto", direction: "ltr" }}
      >
        <img
          src={1 <= data.rank ? filledStar : emptyStar}
          style={{ width: "20px" }}
        />
        <img
          src={2 <= data.rank ? filledStar : emptyStar}
          style={{ width: "20px", marginLeft: "5px" }}
        />
        <img
          src={3 <= data.rank ? filledStar : emptyStar}
          style={{ width: "20px", marginLeft: "5px" }}
        />
        <img
          src={4 <= data.rank ? filledStar : emptyStar}
          style={{ width: "20px", marginLeft: "5px" }}
        />
        <img
          src={5 <= data.rank ? filledStar : emptyStar}
          style={{ width: "20px", marginLeft: "5px" }}
        />
      </FlexRowBetween>
      <RespFont
        pSize={"17px"}
        cSize={"20px"}
        style={{ padding: "0 10px", fontFamily: "hebM" }}
      >
        {data.fullname}
      </RespFont>
      <RespFont
        style={{
          padding: "0 10px",
          fontFamily: "hebM",
          color: "rgba(56, 52, 61, 0.7)",
          marginTop: "10px",
        }}
      >
        {data.city}
      </RespFont>
      {authenticated && user && user.role === 1 && (
        <div
          onClick={() => setEditReference(data)}
          style={{
            width: "min-content",
            cursor: "pointer",
            filter: "brightness(80%)",
            position: "absolute",
            right: "20px",
            bottom: "20px",
          }}
        >
          <GenericSvg
            fill={user.settings.TitlePagesColor.page4}
            size={"22px"}
            svg={svgs.edit}
            viewBox={"0 0 55.25 55.25"}
          />
        </div>
      )}
    </Responsive>
  );
};

export default GenericReference;
