import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import {
  editSomePageColor,
  editSomeTitlePageColor,
  editSomeUserColor,
} from "../../../store/reducers/authReducer";
import cancelF from "../../../assets/nadlan/cancelF.svg";
import { RespFont } from "../Style";

const SketchColorPaletteG = ({
  change,
  name,
  onClose,
  display,
  cc,
  wtc = "user",
  top = "120%",
}) => {
  const [cc1, setCc] = useState("black");

  useEffect(() => {
    setCc(cc);
  }, [cc]);
  const dispatch = useDispatch();

  const setMainColor1 = (color) => {
    setCc(color);
    if (wtc === "user")
      dispatch(editSomeUserColor({ key: [change], value: color }));
    else if (wtc === "pages")
      dispatch(editSomePageColor({ key: [change], value: color }));
    else dispatch(editSomeTitlePageColor({ key: [change], value: color }));
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {display && (
        <div
          style={{
            top: top,
            position: "absolute",
            // left: "-110px",
            zIndex: 1,
            paddingBottom: "10px",
          }}
        >
          {/* <RespFont
            style={{
              fontFamily: "hebB",
              textAlign: "center",
              padding: "5px 0",
              background: "#f2f2f2",
              borderRadius: "3px 3px 0 0",
              border: "1px solid rgba(0, 0, 0, 0.11)",
            }}
          >
            {name}
          </RespFont> */}
          <SketchPicker
            disableAlpha={true}
            style={{
              width: "200px",
              border: "1px solid black",
            }}
            color={cc1}
            onChange={(c) =>
              setMainColor1(
                "#" +
                  ((1 << 24) + (c.rgb.r << 16) + (c.rgb.g << 8) + c.rgb.b)
                    .toString(16)
                    .slice(1)
              )
            }
          />
          <div
            style={{
              cursor: "pointer",
              background: "#f2f2f2",
              borderRadius: "50%",
              width: "min-content",
              padding: "10px",
              boxShadow: "0 2px 4px gray",
              margin: "7px auto",
            }}
            onClick={() => onClose(false)}
          >
            <img src={cancelF} style={{ width: "20px" }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SketchColorPaletteG;
