import React from "react";
import { ToastMsg } from "../../Style";
import bbb from "../../../assets/logo/bbb.png";
import vi from "../../../assets/nadlan/vi.svg";
const CROP_AREA_ASPECT = 4 / 4;

export const GetToast = (addToast, type, msg, duration = 4000) => {
  addToast(<ToastMsg>{typeof msg === "object" ? msg.error : msg}</ToastMsg>, {
    appearance: type,
    autoDismiss: true,
    autoDismissTimeout: duration,
  });
};
export const handleClickScroll = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};
export const handleClickScrollXY = (amount) => {
  window.scrollBy(0, amount);
};

export const addAlpha = (color, opacity) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

// check input validation
export const CFV = (key, msg, errors) => (
  <p style={{ color: "red" }}>
    {errors && errors[key] ? (
      msg
    ) : Object.keys(errors).length > 0 ? (
      <img src={vi} style={{ width: "20px" }} />
    ) : (
      <div style={{ height: "20px" }} />
    )}
  </p>
);

export const GenericLogo = ({ src }) => (
  <img style={{ width: "300px" }} src={src !== "" ? src : bbb} />
);

export const availHeight = (num = 1) => `${window.screen.availHeight * num}px`;

export const parseIsoDate = (date) =>
  date.toISOString().replace(/T.*/, "").split("-").reverse().join("/");
export const parseDate = (date) =>
  date.replace(/T.*/, "").split("-").reverse().join("/");

// export const Output = ({ croppedArea, pic }) => {
//     const scale = 100 / croppedArea.width;
//     const transform = {
//         x: `${-croppedArea.x * scale}%`,
//         y: `${-croppedArea.y * scale}%`,
//         scale,
//         width: "calc(100% + 0.5px)",
//         height: "auto"
//     };

//     const imageStyle = {
//         transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
//         width: transform.width,
//         height: transform.height
//     };

//     return (
//         <div
//             className="output"
//             style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
//         >
//             <img src={pic} alt="" style={imageStyle} />
//         </div>
//     );
// };

export const commafy = (num) => {
  // console.log(num);
  if (num) {
    let str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  } else return num;
};

export const getImageUrl = () =>
  "https://secret-mqr-upload.s3.eu-central-1.amazonaws.com/nadlan/";
export const MenuCss = (color) => ({
  bmBurgerBars: {
    background: color,
  },
  bmMenu: {
    background: color,
    paddingTop: "50px",
    width: "100%",
    padding: "50px 30px",
  },
  bmItem: {
    padding: "2px 0",
    marginTop: "10px",
    borderBottom: "1px solid #f2f2f2",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
