import React, { useEffect } from "react";
import { CenteredForm, FlexRowBetween, MainDiv, RespFont } from "../Style";
import queryString from "query-string";
import { setRecord } from "../../../store/reducers/paymentsReducer";
import { useDispatch } from "react-redux";
import { GetToast } from "../modals/Toast";
import { useToasts } from "react-toast-notifications";
import { successSvg } from "../footer/coloredSvgs/svgs";

const SuccessPage = (props) => {
  const { addToast } = useToasts();
  let widthS = window.innerWidth; // 1728
  console.log("Ddd");
  const dispatch = useDispatch();
  let params = queryString.parse(props.location.search);
  const { paymentTag, commitment, uid, paymentCode } = params;
  console.log(params);
  // useEffect(() => {
  //   dispatch(setRecord({ paymentTag, commitment, uid, paymentCode })).then(
  //     (res) => {
  //       if (res.payload?.error) {
  //         window.open(`${res.payload.error}`, "_blank");
  //         console.log(res.payload.error);
  //         GetToast(addToast, "error", "התשלום בוצע אך לא נרשם");
  //       }
  //     }
  //   );
  // }, []);
  return (
    <MainDiv
      style={{
        justifyContent: "center",
        background: `radial-gradient(circle, #ffffff 0%, #f2f2f2 100%)`,
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          fontFamily: "logo",
          fontWeight: "700",
          position: "absolute",
          top: "50px",
        }}
      >
        התשלום בוצע בהצלחה
      </RespFont>
      {successSvg(
        widthS > 768 ? 432.88 : 216.44,
        widthS > 768 ? 341.445 : 170.7225,
        "rgba(222, 133, 86, 1)"
      )}
    </MainDiv>
  );
};

export default SuccessPage;
