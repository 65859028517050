import React, { useMemo, useEffect, useState } from "react";
import { getAll, cleanAll } from "../../../store/reducers/assetsReducer";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import downArrowB from "../../../assets/nadlan/downArrowB.svg";
import downArrowW from "../../../assets/nadlan/downArrowW.svg";
import upArrowB from "../../../assets/nadlan/upArrowB.svg";
import upArrowW from "../../../assets/nadlan/upArrowW.svg";
import {
  getImageUrl,
  GetToast,
  handleClickScrollXY,
} from "../../common/modals/Toast";
import {
  constants,
  FlexColumnBetween,
  FlexRowBetween,
  RespFont,
  Section,
} from "../../Style";
import noPhoto from "../../../assets/nadlan/noPhoto1.png";
import GenericModal from "../../common/modals/GenericModal";
import Edit from "./Edit";
import Add from "./Add";
import AssetsPhotos from "./AssetsPhotos";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import { noAssets, svgs } from "../../common/footer/coloredSvgs/svgs";
import GenericAsset from "../../common/views/GenericAsset";
import CommonEdit from "../../common/others/CommonEdit";
const Assets = ({ setEditAssets, editAssets }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { assets, assetsError, assetsChange } = useSelector(
    (state) => state.assetsReducer
  );
  const { user, authenticated } = useSelector((state) => state.authReducer);
  const [addAsset, setAddAsset] = useState(false);

  const [sortBy, setSortBy] = useState("dateAdded");
  const [sortDirection, setSortDirection] = useState(false);
  const [ppp, setPPP] = useState("false");
  const [editAsset, setEditAsset] = useState(false);
  const [showPhotos, setShowPhotos] = useState(false);
  const [saleOrRentOrSold, setSaleOrRentOrSold] = useState(1); // 1 for sale, 2 for rent, 3 for sold
  let widthS = window.innerWidth; // 1728

  let height = window.innerHeight;

  useEffect(() => {
    dispatch(getAll());
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (assetsError) GetToast(addToast, "error", assetsError);
  }, [assetsError]);

  let assetsArray = useMemo(() => {
    let arr = [];
    let arrP = [];

    let sortedArray = [];
    if (Object.keys(assets).length > 0) {
      for (const [key, value] of Object.entries(assets))
        if (value.saleOrRentOrSold === saleOrRentOrSold)
          sortedArray.push(value);
      switch (sortBy) {
        case "dateAdded":
          if (sortDirection)
            sortedArray.sort(
              (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
            );
          else
            sortedArray.sort(
              (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
            );
          break;
        case "price":
          if (sortDirection)
            sortedArray.sort(
              (a, b) =>
                (a["salePrice"] === null ? a["price"] : a["salePrice"]) -
                (b["salePrice"] === null ? b["price"] : b["salePrice"])
            );
          else
            sortedArray.sort(
              (a, b) =>
                (b["salePrice"] === null ? b["price"] : b["salePrice"]) -
                (a["salePrice"] === null ? a["price"] : a["salePrice"])
            );
          break;
        // case "rent":
        //     if (sortDirection) sortedArray.sort((a, b) => Number(b[sortBy]) - Number(a[sortBy]));
        //     else sortedArray.sort((a, b) => Number(a[sortBy]) - Number(b[sortBy]));
        //     break;
        // case "rent":
        //     if (sortDirection) sortedArray.sort((a, b) => (b[sortBy]).localeCompare(a[sortBy]));
        //     else sortedArray.sort((a, b) => (a[sortBy]).localeCompare(b[sortBy]));
        //     break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
          else sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
      }

      let temp = [];
      for (let i = 0; i < sortedArray.length; i++) {
        arrP.push(
          <GenericAsset
            setEditAsset={setEditAsset}
            data={sortedArray[i]}
            src={
              sortedArray[i].photos.length
                ? `${getImageUrl()}${user._id}/${sortedArray[i]._id}/${
                    sortedArray[i].photos[0]
                  }`
                : `${noPhoto}`
            }
          />
        );
        temp.push(
          <GenericAsset
            setEditAsset={setEditAsset}
            marC={(i + 2) % 3 === 0 ? "0 25px" : "0px"}
            data={sortedArray[i]}
            src={
              sortedArray[i].photos.length
                ? `${getImageUrl()}${user._id}/${sortedArray[i]._id}/${
                    sortedArray[i].photos[0]
                  }`
                : `${noPhoto}`
            }
          />
        );
        if ((i + 1) % 3 === 0) {
          arr.push(
            <FlexRowBetween style={{ justifyContent: "center" }}>
              {[...temp]}
            </FlexRowBetween>
          );
          temp = [];
        }
      }
      arr.push(
        <FlexRowBetween style={{ justifyContent: "center" }}>
          {[...temp]}
        </FlexRowBetween>
      );
    }
    return widthS > 768 ? arr : arrP;
  }, [assetsChange, assets, sortBy, sortDirection, saleOrRentOrSold]);

  return (
    <div
      id="assetsA-id"
      style={{
        width: "100%",
        margin: "0 auto",
        position: "relative",
        backgroundColor: user.settings.pagesColors.page2,
      }}
    >
      {authenticated && (
        <CommonEdit
          top={"22px"}
          left={"20px"}
          right={null}
          bottom={null}
          func={() => {
            if (!editAssets) handleClickScrollXY(900);
            setEditAssets(!editAssets);
          }}
          color={user.settings.TitlePagesColor.page2}
          // pen={user.settings.TitlePagesColor.page2}
        />
      )}
      <Section
        pSize={"34px"}
        cSize={"38px"}
        style={{
          margin: "20px auto",
          color: user.settings.TitlePagesColor.page2,
        }}
      >
        {user.settings.PagesTitle.page2}
      </Section>

      {widthS > 768 ? (
        <FlexRowBetween
          style={{
            justifyContent: "flex-start",
            width: "min-content",
            margin: "10px 2.5%",
          }}
        >
          {/* נכסים להצגה */}
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "60px",
              padding: "5px",
              fontWeight: "700",
              marginRight: "0px",
            }}
          >
            <RespFont
              onClick={() => setSaleOrRentOrSold(1)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  saleOrRentOrSold === 1
                    ? user.settings.TitlePagesColor.page2
                    : "inherit",
                transition: "all 0.2s",
                color: saleOrRentOrSold === 1 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              למכירה
            </RespFont>
            <RespFont
              onClick={() => setSaleOrRentOrSold(2)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  saleOrRentOrSold === 2
                    ? user.settings.TitlePagesColor.page2
                    : "inherit",
                transition: "all 0.2s",
                color: saleOrRentOrSold === 2 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              להשכרה
            </RespFont>
            <RespFont
              onClick={() => setSaleOrRentOrSold(3)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  saleOrRentOrSold === 3
                    ? user.settings.TitlePagesColor.page2
                    : "inherit",
                transition: "all 0.2s",
                color: saleOrRentOrSold === 3 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              נסגרו
            </RespFont>
          </FlexRowBetween>

          {/* מיון */}
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "60px",
              padding: "5px",
              fontWeight: "700",
              marginRight: "30px",
            }}
          >
            <RespFont
              onClick={() => setSortBy("rooms")}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                width: "max-content",
                backgroundColor:
                  sortBy === "rooms"
                    ? user.settings.TitlePagesColor.page2
                    : "inherit",
                transition: "all 0.2s",
                color: sortBy === "rooms" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              מספר חדרים
            </RespFont>
            <RespFont
              onClick={() => setSortBy("price")}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  sortBy === "price"
                    ? user.settings.TitlePagesColor.page2
                    : "inherit",
                transition: "all 0.2s",
                color: sortBy === "price" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              מחיר
            </RespFont>
            <RespFont
              onClick={() => setSortBy("squareMeters")}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  sortBy === "squareMeters"
                    ? user.settings.TitlePagesColor.page2
                    : "inherit",
                transition: "all 0.2s",
                color:
                  sortBy === "squareMeters" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              מ״ר
            </RespFont>
            <RespFont
              onClick={() => setSortBy("dateAdded")}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                width: "max-content",
                backgroundColor:
                  sortBy === "dateAdded"
                    ? user.settings.TitlePagesColor.page2
                    : "inherit",
                transition: "all 0.2s",
                color: sortBy === "dateAdded" ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              תאריך הוספה
            </RespFont>
          </FlexRowBetween>

          {/* כיוון */}
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "50px",
              padding: "5px",
              fontWeight: "700",
              marginRight: "30px",
            }}
          >
            <RespFont
              onClick={() => setSortDirection(true)}
              style={{
                padding: widthS > 768 ? "6px 10px" : "0px 4px",
                cursor: "pointer",
                backgroundColor: sortDirection
                  ? user.settings.TitlePagesColor.page2
                  : "inherit",
                transition: "all 0.2s",
                color: sortDirection ? "#fff" : constants.fontColorZax,
                borderRadius: "50px",
                width: "max-content",
              }}
            >
              <img
                src={sortDirection ? upArrowW : upArrowB}
                style={{ width: widthS > 768 ? "23px" : "13px" }}
              />
            </RespFont>
            <RespFont
              onClick={() => setSortDirection(false)}
              style={{
                padding: widthS > 768 ? "6px 10px" : "0px 4px",
                cursor: "pointer",
                backgroundColor: !sortDirection
                  ? user.settings.TitlePagesColor.page2
                  : "inherit",
                transition: "all 0.2s",
                color: !sortDirection ? "#fff" : constants.fontColorZax,
                borderRadius: "50px",
                width: "max-content",
              }}
            >
              <img
                src={sortDirection ? downArrowB : downArrowW}
                style={{ width: widthS > 768 ? "23px" : "13px" }}
              />
            </RespFont>
          </FlexRowBetween>

          {/* הוספה */}
          {authenticated && user && user.role === 1 && (
            <FlexRowBetween
              onClick={() => setAddAsset(true)}
              style={{
                width: "min-content",
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "50px",
                padding: "5px",
                fontWeight: "700",
                marginRight: "30px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  padding: widthS > 768 ? "6px 6px" : "0px 4px",

                  backgroundColor: user.settings.TitlePagesColor.page2,
                  transition: "all 0.2s",
                  borderRadius: "50px",
                  width: "max-content",
                }}
              >
                <GenericSvg
                  fill={"#fff"}
                  size={"23px"}
                  svg={svgs.plus}
                  viewBox={"0 0 16 16"}
                />
              </div>
            </FlexRowBetween>
          )}
        </FlexRowBetween>
      ) : (
        <div style={{ width: "100%" }}>
          <FlexRowBetween style={{ width: "95%", margin: "0px auto" }}>
            <FlexRowBetween
              style={{
                width: "min-content",
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "60px",
                padding: "3px",
                fontWeight: "700",
                marginRight: "0px",
              }}
            >
              <RespFont
                pSize={"12px"}
                onClick={() => setSaleOrRentOrSold(1)}
                style={{
                  padding: "3.5px 6px",
                  cursor: "pointer",
                  backgroundColor:
                    saleOrRentOrSold === 1
                      ? user.settings.TitlePagesColor.page2
                      : "inherit",
                  transition: "all 0.2s",
                  color:
                    saleOrRentOrSold === 1 ? "#fff" : constants.fontColorZax,
                  borderRadius: "60px",
                }}
              >
                למכירה
              </RespFont>
              <RespFont
                pSize={"12px"}
                onClick={() => setSaleOrRentOrSold(2)}
                style={{
                  padding: "3.5px 6px",
                  cursor: "pointer",
                  backgroundColor:
                    saleOrRentOrSold === 2
                      ? user.settings.TitlePagesColor.page2
                      : "inherit",
                  transition: "all 0.2s",
                  color:
                    saleOrRentOrSold === 2 ? "#fff" : constants.fontColorZax,
                  borderRadius: "60px",
                }}
              >
                להשכרה
              </RespFont>
              <RespFont
                pSize={"12px"}
                onClick={() => setSaleOrRentOrSold(3)}
                style={{
                  padding: "3.5px 6px",
                  cursor: "pointer",
                  backgroundColor:
                    saleOrRentOrSold === 3
                      ? user.settings.TitlePagesColor.page2
                      : "inherit",
                  transition: "all 0.2s",
                  color:
                    saleOrRentOrSold === 3 ? "#fff" : constants.fontColorZax,
                  borderRadius: "60px",
                }}
              >
                נסגרו
              </RespFont>
            </FlexRowBetween>

            {authenticated && user && user.role === 1 && (
              <FlexRowBetween
                onClick={() => setAddAsset(true)}
                style={{
                  width: "min-content",
                  border: "2px solid rgba(0, 0, 0, 0.11)",
                  borderRadius: "50px",
                  padding: "3px",
                  fontWeight: "700",
                  marginLeft: "0px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    padding: widthS > 768 ? "6px 6px" : "1.5px 3px",
                    backgroundColor: user.settings.TitlePagesColor.page2,
                    transition: "all 0.2s",
                    borderRadius: "50px",
                    width: "max-content",
                  }}
                >
                  <GenericSvg
                    fill={"#fff"}
                    size={"17px"}
                    svg={svgs.plus}
                    viewBox={"0 0 16 16"}
                  />
                </div>
              </FlexRowBetween>
            )}
          </FlexRowBetween>
          <FlexRowBetween style={{ width: "95%", margin: "10px auto" }}>
            {/* מיון */}
            <FlexRowBetween
              style={{
                width: "min-content",
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "60px",
                padding: "3px",
                fontWeight: "700",
                marginRight: "0px",
              }}
            >
              <RespFont
                pSize={"12px"}
                onClick={() => setSortBy("rooms")}
                style={{
                  padding: "3.5px 6px",
                  cursor: "pointer",
                  width: "max-content",
                  backgroundColor:
                    sortBy === "rooms"
                      ? user.settings.TitlePagesColor.page2
                      : "inherit",
                  transition: "all 0.2s",
                  color: sortBy === "rooms" ? "#fff" : constants.fontColorZax,
                  borderRadius: "60px",
                }}
              >
                מספר חדרים
              </RespFont>
              <RespFont
                pSize={"12px"}
                onClick={() => setSortBy("price")}
                style={{
                  padding: "3.5px 6px",
                  cursor: "pointer",
                  backgroundColor:
                    sortBy === "price"
                      ? user.settings.TitlePagesColor.page2
                      : "inherit",
                  transition: "all 0.2s",
                  color: sortBy === "price" ? "#fff" : constants.fontColorZax,
                  borderRadius: "60px",
                }}
              >
                מחיר
              </RespFont>
              <RespFont
                pSize={"12px"}
                onClick={() => setSortBy("squareMeters")}
                style={{
                  padding: "3.5px 6px",
                  cursor: "pointer",
                  backgroundColor:
                    sortBy === "squareMeters"
                      ? user.settings.TitlePagesColor.page2
                      : "inherit",
                  transition: "all 0.2s",
                  color:
                    sortBy === "squareMeters" ? "#fff" : constants.fontColorZax,
                  borderRadius: "60px",
                }}
              >
                מ״ר
              </RespFont>
              <RespFont
                pSize={"12px"}
                onClick={() => setSortBy("dateAdded")}
                style={{
                  padding: "3.5px 6px",
                  cursor: "pointer",
                  width: "max-content",
                  backgroundColor:
                    sortBy === "dateAdded"
                      ? user.settings.TitlePagesColor.page2
                      : "inherit",
                  transition: "all 0.2s",
                  color:
                    sortBy === "dateAdded" ? "#fff" : constants.fontColorZax,
                  borderRadius: "60px",
                }}
              >
                תאריך הוספה
              </RespFont>
            </FlexRowBetween>

            {/* כיוון */}
            <FlexRowBetween
              style={{
                width: "min-content",
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "50px",
                padding: "3px",
                fontWeight: "700",
                marginLeft: "0px",
              }}
            >
              <RespFont
                onClick={() => setSortDirection(true)}
                style={{
                  padding: widthS > 768 ? "6px 10px" : "0px 4px",
                  cursor: "pointer",
                  backgroundColor: sortDirection
                    ? user.settings.TitlePagesColor.page2
                    : "inherit",
                  transition: "all 0.2s",
                  color: sortDirection ? "#fff" : constants.fontColorZax,
                  borderRadius: "50px",
                  width: "max-content",
                }}
              >
                <img
                  src={sortDirection ? upArrowW : upArrowB}
                  style={{ width: widthS > 768 ? "23px" : "13px" }}
                />
              </RespFont>
              <RespFont
                onClick={() => setSortDirection(false)}
                style={{
                  padding: widthS > 768 ? "6px 10px" : "0px 4px",
                  cursor: "pointer",
                  backgroundColor: !sortDirection
                    ? user.settings.TitlePagesColor.page2
                    : "inherit",
                  transition: "all 0.2s",
                  color: !sortDirection ? "#fff" : constants.fontColorZax,
                  borderRadius: "50px",
                  width: "max-content",
                }}
              >
                <img
                  src={sortDirection ? downArrowB : downArrowW}
                  style={{ width: widthS > 768 ? "23px" : "13px" }}
                />
              </RespFont>
            </FlexRowBetween>
          </FlexRowBetween>
        </div>
      )}

      <div
        style={{
          height: `${height - 165}px`,
          overflow: "auto",
          margin: "20px 0",
          padding: "0px 0",
        }}
      >
        {assetsArray.length ? (
          assetsArray
        ) : (
          <FlexColumnBetween
            style={{ justifyContent: "space-evenly", height: "100%" }}
          >
            <RespFont style={{ fontFamily: "hebB" }}>
              נראה כי אין נכסים, התחילו להוסיף...
            </RespFont>
            {noAssets(
              widthS > 768 ? 432.88 : 216.44,
              widthS > 768 ? 341.445 : 170.7225,
              user.settings.TitlePagesColor.page2
            )}
          </FlexColumnBetween>
        )}
        {/* {} */}
      </div>

      {showPhotos && (
        <GenericModal
          init={setShowPhotos}
          div={
            <AssetsPhotos
              scroll={false}
              isAsset={true}
              assetId={showPhotos.assetId}
              color={user.settings.TitlePagesColor.page2}
              photos={showPhotos.photos}
              init={setShowPhotos}
              uid={user._id}
            />
          }
        />
      )}
      {addAsset && (
        <GenericModal init={setAddAsset} div={<Add init={setAddAsset} />} />
      )}

      {editAsset && (
        <GenericModal
          init={setEditAsset}
          div={
            <Edit
              data={editAsset}
              setShowPhotos={setShowPhotos}
              init={setEditAsset}
            />
          }
        />
      )}
    </div>
  );
};

export default Assets;
