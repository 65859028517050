import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { uploadAssetPhoto } from "../../../store/reducers/photosReducer";
import { GetToast } from "../../common/modals/Toast";
import "../style.css";
import imageCompression from "browser-image-compression";
import { GenericButton, PopupForm } from "../../Style";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { editAsset } from "../../../store/reducers/assetsReducer";
import { editArticle } from "../../../store/reducers/articleReducer";
import ExitModal from "../../common/modals/ExitModal";

const UploadAssetsPhotos = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.authReducer);

  async function postImage(images) {
    let ppp = [...props.photos];
    setLoading(true);
    for (let i = 0; i < images.length; i++) {
      let image = new File(
        [images[i]],
        `p${parseInt(props.photos.length) + parseInt(i)}`,
        {
          type: images[i].type,
        }
      );
      const formData = new FormData();
      formData.append("image", image);
      dispatch(uploadAssetPhoto(formData, props.assetId)).then((data) => {
        setFile(null);
        if (image.name === data) {
          // if (props.isAsset)
          dispatch(editAsset({ photos: [...ppp, data] }, props.assetId)).then(
            (data1) => {
              if (!data1.payload.error) {
                ppp = [...ppp, data];
                GetToast(addToast, "success", "הפעולה הצליחה!");
              }
            }
          );
          // else
          //   dispatch(
          //     editArticle({ photos: [...props.photos, data] }, props.assetId)
          //   ).then((data) => {
          //     if (!data.payload.error) {
          //       GetToast(addToast, "success", "הפעולה הצליחה!");
          //       props.init(null);
          //     }
          //     setLoading(false);
          //   });
        } else
          GetToast(
            addToast,
            "error",
            "הפעולה כשלה, נסה/י שנית או פנה/י לתמיכה"
          );
      });
    }
    // window.location.reload();

    props.init();

    setLoading(false);
  }

  const submit = async (event) => {
    event.preventDefault();
    await postImage(files);
  };

  const fileSelected = async (event) => {
    setLoading(true);
    let arr = [];
    for (let i = 0; i < event.target.files.length; i++) {
      let imageFile = event.target.files[i];
      if (imageFile.size > 1000000) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(imageFile, options);
          setLoading(false);
          imageFile = new File([compressedFile], imageFile.name, {
            type: compressedFile.type,
          });
        } catch (error) {
          console.log(error);
        }
      }
      arr.push(imageFile);
    }
    console.log(arr);
    // let imageFile = event.target.files[0];
    // if (imageFile.size > 1000000) {
    //   const options = {
    //     maxSizeMB: 1,
    //     maxWidthOrHeight: 1920,
    //     useWebWorker: true,
    //   };
    //   try {
    //     setLoading(true);
    //     const compressedFile = await imageCompression(imageFile, options);
    //     setLoading(false);
    //     imageFile = new File([compressedFile], imageFile.name, {
    //       type: compressedFile.type,
    //     });
    //   } catch (error) {
    //     setLoading(false);
    //     console.log(error);
    //   }
    // }
    setLoading(false);
    setFiles(arr);
    // setFile(imageFile);
  };

  return (
    <PopupForm onClick={(e) => e.stopPropagation()}>
      {/* <GenericSvg fill={user.controls} size={"20px"} svg={svgs.facebook} viewBox={"0 0 24 24"} /> */}
      {/* <PlaceHolderImage src={exitModalLogo} alt="exitModalLogo" style={{ top: "8px", left: "8px", transform: "translate(0,0)", width: "32px", cursor: "pointer" }} onClick={() => props.init(null)} /> */}
      <ExitModal init={props.init} color={user.settings.colors.buttonsColor} />
      <div style={{ marginTop: "5vh", fontSize: "1.1rem" }}>העלאת תמונה</div>
      <label
        htmlFor="file-upload"
        className="custom-file-upload"
        style={{ marginBottom: "5vh" }}
      >
        בחירת תמונה
      </label>
      <input
        id="file-upload"
        type="file"
        onChange={fileSelected}
        accept="image/*"
        multiple
      />
      {files !== null && (
        <GenericButton
          hoverBackground={"rgb(223, 223, 223)"}
          background={props.color}
          style={{
            borderRadius: "0px",
            width: "100%",
            padding: "13px",
            fontSize: "16px",
          }}
          onClick={(e) => submit(e)}
        >
          שמירה
        </GenericButton>
      )}
      {loading && <OpacityLoading />}
    </PopupForm>
  );
};

export default UploadAssetsPhotos;
