import React from "react";
import { PopupForm, GenericButton, RespFont } from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import { addReference } from "../../../store/reducers/referenceReducer";
import { HomeInput, HomeTextArea } from "../../common/Style";

const Add = ({ init }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { referenceLoading } = useSelector((state) => state.referenceReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const body = {
      fullname: data.fullname,
      city: data.city,
      rank: data.rank,
      description: data.description,
    };
    dispatch(addReference(body)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "80%",
          margin: "0px auto",
        }}
      >
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            שם מלא
          </RespFont>
          <HomeInput
            style={{ paddingRight: "10px" }}
            type={"text"}
            {...register("fullname", {
              required: true,
              minLength: 2,
              maxLength: 200,
            })}
          />
          {CFV("fullname", "בין 2 ל 200 תווים", errors)}
        </div>
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            עיר
          </RespFont>
          <HomeInput
            style={{ paddingRight: "10px" }}
            type={"text"}
            {...register("city", {
              required: true,
              minLength: 2,
              maxLength: 200,
            })}
          />
          {CFV("city", "בין 2 ל 200 תווים", errors)}
        </div>
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            ציון
          </RespFont>
          <HomeInput
            type={"number"}
            {...register("rank", {
              required: true,
              min: 1,
              max: 5,
            })}
          />
          {CFV("rank", "מספר 1 ל 5", errors)}
        </div>
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            תיאור
          </RespFont>
          <HomeTextArea
            type="text"
            rows="5"
            {...register("description", {
              required: true,
              minLength: 1,
              maxLength: 500,
            })}
          />
          {CFV("description", "בין 1 ל 500 תווים", errors)}
        </div>
        <GenericButton
          form="form1"
          background={user.settings.colors.buttonsColor}
          color={"#fff"}
          type="submit"
          style={{
            width: "100%",
            margin: "0px auto",
            padding: "10px",
            borderRadius: "5px",
            fontWeight: "bolder",
            fontSize: "16px",
            boxShadow: "none",
            marginTop: "25px",
          }}
        >
          הוספה
        </GenericButton>
      </div>
      {referenceLoading && <OpacityLoading />}
      {/* <UploadReferencePhotos assetId={assetId} color={user.color} init={init} /> */}
    </PopupForm>
  );
};

export default Add;
