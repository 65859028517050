import React from "react";
import { useSelector } from "react-redux";

const Output = ({ pic, picPosition, cropAreaAspect }) => {
  const { user } = useSelector((state) => state.authReducer);

  const scale = 100 / picPosition.width;
  const transform = {
    x: `${-picPosition.x * scale}%`,
    y: `${-picPosition.y * scale}%`,
    scale,
    width: "calc(100% + 0.5px)",
    height: "auto",
  };

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  };

  return (
    <div
      className="output"
      style={{
        paddingBottom: `${100 / (cropAreaAspect.x / cropAreaAspect.y)}%`,
      }}
    >
      <img src={pic} alt="pic" style={imageStyle} />
    </div>
  );
};

export default Output;
