import React from "react";
import { RespFont } from "../../../Style";
import GenericDeal from "./GenericDeal";
import plus from "../../../../assets/zaks/plus.svg";

const Deal3 = ({ setReg, reg, commitment }) => {
  return (
    <GenericDeal
      setReg={setReg}
      reg={reg}
      title={"VIP"}
      price={commitment === 1 ? "300₪" : "250₪"}
      extra={
        <>
          <RespFont style={{ textAlign: "right", marginTop: "18px" }}>
            <img
              src={plus}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "0px",
                backgroundColor: "rgb(68, 124, 245)",
                marginLeft: "10px",
                textAlign: "right",
              }}
            />
            יכולת להעלות פרסומות ללקוחותיך <b>וברשת הפרסום שלנו!!</b>
          </RespFont>
          <RespFont style={{ textAlign: "right", marginTop: "18px" }}>
            <img
              src={plus}
              style={{
                width: "25px",
                borderRadius: "50%",
                padding: "0px",
                backgroundColor: "rgb(68, 124, 245)",
                marginLeft: "10px",
              }}
            />
            לקוחותיך <b> + לקוחות של משתמשים אשר רכשו חבילה בסיסית</b> יקבלו
            התראות כאשר תוסיף נכס העונה למבוקשם
          </RespFont>
        </>
      }
    />
  );
};

export default Deal3;
