import React, { useState } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  RTextArea,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  RespFont,
} from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { editAsset } from "../../../store/reducers/assetsReducer";
import delete1 from "../../../assets/nadlan/delete1.svg";

import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CFV, GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import Switcher from "../../common/switcher/Switcher";
import {
  deleteArticle,
  editArticle,
} from "../../../store/reducers/articleReducer";
import { CopyToClipboard } from "react-copy-to-clipboard";

import alignCenter from "../../../assets/nadlan/alignCenter.svg";
import textItalic from "../../../assets/nadlan/textItalic.svg";
import { HomeInput } from "../../common/Style";

const EditS = (props) => {
  console.log(props.data);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { articleLoading } = useSelector((state) => state.articleReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let widthS = window.innerWidth; // 1728

  const onSubmit = async (data) => {
    const body = { title: data.title, link: data.link, pic: data.pic };
    dispatch(editArticle(body, props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init();
      }
    });
  };
  const deleteA = () => {
    dispatch(deleteArticle(props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  return (
    <PopupForm
      cWidth={"70%"}
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "80%",
          margin: "10px auto",
        }}
      >
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            כותרת
          </RespFont>
          <HomeInput
            name="title"
            defaultValue={props.data.title}
            type={"text"}
            {...register("title", {
              required: true,
              minLength: 2,
              maxLength: 200,
            })}
          />
          {CFV("title", "בין 2 ל 200 תווים", errors)}
        </div>
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            קישור
          </RespFont>
          <HomeInput
            name="link"
            defaultValue={props.data.link}
            type={"text"}
            {...register("link", {
              required: true,
              minLength: 2,
              maxLength: 500,
            })}
          />
          {CFV("link", "בין 2 ל 50 תווים", errors)}
        </div>
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            כתובת תמונה
          </RespFont>
          <HomeInput
            name="pic"
            defaultValue={props.data.pic}
            type={"text"}
            {...register("pic", {
              required: true,
              minLength: 2,
              maxLength: 500,
            })}
          />
          {CFV("pic", "בין 2 ל 500 תווים", errors)}
        </div>
        <FlexRowBetween style={{ margin: "0px auto", marginTop: "25px" }}>
          <GenericButton
            form="form1"
            background={user.settings.TitlePagesColor.page6}
            color={"#fff"}
            type="submit"
            style={{
              width: "70%",
              padding: "10px",
              borderRadius: "5px",
              fontWeight: "bolder",
              fontSize: "16px",
              boxShadow: "none",
            }}
          >
            שמירה
          </GenericButton>
          <div
            onClick={deleteA}
            style={{
              backgroundColor: constants.fontColorZax,
              borderRadius: "3px",
              cursor: "pointer",
              marginLeft: "0px",
            }}
          >
            <img
              src={delete1}
              alt="delete"
              style={{ width: widthS > 768 ? "40px" : "38px", padding: "9px" }}
            />
          </div>
        </FlexRowBetween>
      </div>
      {articleLoading && <OpacityLoading />}
    </PopupForm>
  );
};

export default EditS;
