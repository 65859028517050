import React, { useState } from "react";
import {
  PopupForm,
  InputBox,
  GenericButton,
  FlexRowBetween,
  constants,
  FlexColumnBetween,
  GalleryOptions,
  RespFont,
} from "../../Style";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { editAsset, deleteAsset } from "../../../store/reducers/assetsReducer";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { addAlpha, CFV, GetToast } from "../../common/modals/Toast";
import OpacityLoading from "../../common/loader/OpacityLoading";
import Switcher from "../../common/switcher/Switcher";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import GenericSvg from "../../common/footer/coloredSvgs/GenericSvg";
import { svgs } from "../../common/footer/coloredSvgs/svgs";
import delete1 from "../../../assets/nadlan/delete1.svg";
import galleryWhite from "../../../assets/nadlan/galleryWhite.svg";
import {
  FlexRowStart,
  HomeInput,
  HomeTextArea,
  RTextArea,
} from "../../common/Style";

const Edit = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { user } = useSelector((state) => state.authReducer);
  const { assetsLoading } = useSelector((state) => state.assetsReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [saleOrRentOrSold, setSaleOrRentOrSold] = useState(
    props.data.saleOrRentOrSold
  ); // 1 for sale, 2 for rent, 3 for sold
  const [kind, setKind] = useState(props.data.kind); // 1 - prati, 2 - dira, 3 - migrash, 4 - aher
  let widthS = window.innerWidth; // 1728

  const onSubmit = async (data) => {
    const body = {
      name: data.name,
      city: data.city,
      address: data.address,
      squareMeters: data.squareMeters,
      salePrice: data.salePrice,
      phone: data.phone !== undefined ? data.phone : props.data.phone,
      rooms: data.rooms,
      waze: data.waze,
      description: data.description,
      price: data.price,
      saleOrRentOrSold,
      kind,
    };
    dispatch(editAsset(body, props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init(false);
      }
    });
  };

  const deleteA = () => {
    dispatch(deleteAsset(props.data._id)).then((res) => {
      if (!res.payload?.error) {
        GetToast(addToast, "success", "הפעולה בוצעה בהצלחה");
        props.init(false);
      }
    });
  };
  let height = window.screen.availHeight;

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      id="form1"
      style={{ overflow: "auto", maxHeight: `${0.8 * height}px` }}
    >
      <div
        style={{
          padding: "25px 0",
          width: "90%",
          margin: "10px auto",
        }}
      >
        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          שם / כותרת
        </RespFont>
        <HomeInput
          type="text"
          defaultValue={props.data.name}
          {...register("name", {
            required: true,
            minLength: 2,
            maxLength: 50,
          })}
        />
        {CFV("name", "בין 2 ל 50 תווים", errors)}
        <FlexRowBetween style={{ margin: "0 auto" }}>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מחיר מקורי (₪)
            </RespFont>
            <HomeInput
              type="number"
              defaultValue={props.data.price}
              {...register("price", {
                required: true,
                min: 1,
              })}
            />
            {CFV("price", "מינימום ספרה אחת", errors)}
          </div>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מחיר עדכני (₪)
            </RespFont>
            <HomeInput
              type="number"
              defaultValue={props.data.salePrice}
              {...register("salePrice", {
                min: 1,
              })}
            />
            {CFV("salePrice", "מינימום ספרה אחת", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "0 auto" }}>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              עיר
            </RespFont>
            <HomeInput
              defaultValue={props.data.city}
              type={"text"}
              {...register("city", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />
            {CFV("city", "בין 2 ל 50 תווים", errors)}
          </div>
          <div style={{ width: "45%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              כתובת
            </RespFont>
            <HomeInput
              defaultValue={props.data.address}
              type={"text"}
              {...register("address", {
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
            />
            {CFV("address", "בין 2 ל 30 תווים", errors)}
          </div>
        </FlexRowBetween>
        <FlexRowBetween style={{ margin: "0 auto" }}>
          <div style={{ width: "20%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              מ״ר
            </RespFont>
            <HomeInput
              type="number"
              defaultValue={props.data.squareMeters}
              {...register("squareMeters", {
                required: true,
                min: 1,
                max: 5000,
              })}
            />
            {CFV("squareMeters", "מספר בין 1 ל 5000", errors)}
          </div>
          <div style={{ width: "20%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              חדרים
            </RespFont>
            <HomeInput
              type="number"
              defaultValue={props.data.rooms}
              {...register("rooms", {
                required: true,
                min: 0,
                max: 20,
              })}
            />
            {CFV("rooms", "מספר בין 0 ל 20", errors)}
          </div>
          <div style={{ width: "20%" }}>
            <RespFont
              style={{
                fontFamily: "hebB",
                marginBottom: "5px",
                textAlign: "right",
              }}
            >
              שירותים
            </RespFont>
            <HomeInput
              type="number"
              defaultValue={props.data.bathroom}
              {...register("bathroom", {
                required: true,
                min: 0,
                max: 20,
              })}
            />
            {CFV("bathroom", "מספר בין 0 ל 20", errors)}
          </div>
        </FlexRowBetween>
        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          נייד ליצירת קשר עבור נכס זה
        </RespFont>
        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip>{"שינוי מתאפשר רק עבור חברה - פנו למנהל המערכת"}</Tooltip>
          }
        >
          <HomeInput
            // onClick={() => console.log("dfd")}
            disabled={!user.isCompany}
            defaultValue={props.data.phone}
            {...register("phone", {
              // required: true,
              pattern: /^[0]{1}[5]{1}[0-9]{8}$/,
            })}
          />
        </OverlayTrigger>
        {CFV("phone", "פורמט מספר פלאפון לא חוקי", errors)}
        <div>
          <RespFont
            style={{
              fontFamily: "hebB",
              marginBottom: "5px",
              textAlign: "right",
            }}
          >
            ניווט - מומלץ לצפות במדריך
          </RespFont>
          <HomeInput
            defaultValue={props.data.waze}
            type={"text"}
            {...register("waze", {
              minLength: 2,
              maxLength: 50,
            })}
          />
          {CFV("waze", "בין 2 ל 50 תווים", errors)}
        </div>
        <FlexRowStart style={{ marginBottom: "20px" }}>
          <RespFont style={{ fontFamily: "hebM" }}>סטטוס:</RespFont>
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "60px",
              padding: "5px",
              fontWeight: "700",
              marginRight: "20px",
            }}
          >
            <RespFont
              onClick={() => setSaleOrRentOrSold(1)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  saleOrRentOrSold === 1
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color: saleOrRentOrSold === 1 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              למכירה
            </RespFont>
            <RespFont
              onClick={() => setSaleOrRentOrSold(2)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  saleOrRentOrSold === 2
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color: saleOrRentOrSold === 2 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              להשכרה
            </RespFont>
            <RespFont
              onClick={() => setSaleOrRentOrSold(3)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  saleOrRentOrSold === 3
                    ? user.settings.colors.buttonsColor
                    : "inherit",
                transition: "all 0.2s",
                color: saleOrRentOrSold === 3 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              נסגרו
            </RespFont>
          </FlexRowBetween>
        </FlexRowStart>
        <FlexRowStart style={{ marginBottom: "20px" }}>
          <RespFont style={{ fontFamily: "hebM" }}>סוג הנכס:</RespFont>
          <FlexRowBetween
            style={{
              width: "min-content",
              border: "2px solid rgba(0, 0, 0, 0.11)",
              borderRadius: "60px",
              padding: "5px",
              fontWeight: "700",
              marginRight: "20px",
            }}
          >
            <RespFont
              onClick={() => setKind(1)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  kind === 1 ? user.settings.colors.buttonsColor : "inherit",
                transition: "all 0.2s",
                color: kind === 1 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              פרטי
            </RespFont>
            <RespFont
              onClick={() => setKind(2)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  kind === 2 ? user.settings.colors.buttonsColor : "inherit",
                transition: "all 0.2s",
                color: kind === 2 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              דירה
            </RespFont>
            <RespFont
              onClick={() => setKind(3)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  kind === 3 ? user.settings.colors.buttonsColor : "inherit",
                transition: "all 0.2s",
                color: kind === 3 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              מגרש
            </RespFont>
            <RespFont
              onClick={() => setKind(4)}
              style={{
                padding: "7px 12px",
                cursor: "pointer",
                backgroundColor:
                  kind === 4 ? user.settings.colors.buttonsColor : "inherit",
                transition: "all 0.2s",
                color: kind === 4 ? "#fff" : constants.fontColorZax,
                borderRadius: "60px",
              }}
            >
              אחר
            </RespFont>
          </FlexRowBetween>
        </FlexRowStart>

        <RespFont
          style={{
            fontFamily: "hebB",
            marginBottom: "5px",
            textAlign: "right",
          }}
        >
          תיאור הנכס
        </RespFont>
        <HomeTextArea
          color={constants.fontColorZax}
          defaultValue={props.data.description}
          type="text"
          rows="3"
          style={{ margin: "0px auto", marginBottom: "20px" }}
          {...register("description", {
            minLength: 0,
            maxLength: 500,
          })}
        />
        {CFV("description", "עד 500 תווים", errors)}
        <FlexRowBetween style={{ margin: "0px auto", marginTop: "20px" }}>
          <GenericButton
            form="form1"
            background={user.settings.colors.buttonsColor}
            color={"#fff"}
            type="submit"
            style={{
              width: widthS > 768 ? "70%" : "60%",
              marginRight: "0px",
              padding: "10px",
              borderRadius: "5px",
              fontWeight: "bolder",
              fontSize: "16px",
              boxShadow: "none",
            }}
          >
            שמירה
          </GenericButton>

          <FlexRowBetween style={{ width: "min-content", marginLeft: "0px" }}>
            <div
              onClick={() => {
                props.setShowPhotos({
                  photos: props.data.photos,
                  assetId: props.data._id,
                });
                props.init(false);
              }}
              style={{
                backgroundColor: constants.fontColorZax,
                borderRadius: "3px",
                cursor: "pointer",
                marginLeft: widthS > 768 ? "20px" : "15px",
              }}
            >
              <img
                src={galleryWhite}
                alt="delete"
                style={{
                  width: widthS > 768 ? "40px" : "38px",
                  padding: "9px",
                }}
              />
            </div>
            <div
              onClick={deleteA}
              style={{
                backgroundColor: constants.fontColorZax,
                borderRadius: "3px",
                cursor: "pointer",
                marginLeft: "0px",
              }}
            >
              <img
                src={delete1}
                alt="delete"
                style={{
                  width: widthS > 768 ? "40px" : "38px",
                  padding: "9px",
                }}
              />
            </div>
          </FlexRowBetween>
        </FlexRowBetween>
      </div>
      {assetsLoading && <OpacityLoading />}
    </PopupForm>
  );
};

export default Edit;
