import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import exitModalLogo from "../../../assets/nadlan/cancel.svg";

import { uploadPhoto } from "../../../store/reducers/photosReducer";
import { editDetails } from "../../../store/reducers/authReducer";
import { GetToast } from "../../common/modals/Toast";
import "../style.css";
import imageCompression from "browser-image-compression";
import { GenericButton, PlaceHolderImage, PopupForm } from "../../Style";
import OpacityLoading from "../../common/loader/OpacityLoading";
import ExitModal from "../../common/modals/ExitModal";
import axios from "axios";
const EditPics = ({ kind, init, style, removeBg = "false" }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.authReducer);
  async function postImage(image) {
    const rnd = Math.floor(Math.random() * 899999) + 100000;
    image = new File([image], `${kind}${rnd}`, {
      type: image.type,
    });
    const formData = new FormData();
    formData.append("image", image);
    setLoading(true);
    dispatch(uploadPhoto(formData, removeBg)).then((data) => {
      setFile(null);
      if (image.name === data)
        dispatch(editDetails({ [kind]: data })).then((data) => {
          if (!data.payload.error) {
            GetToast(addToast, "success", "הפעולה הצליחה!");
            init(null);
            if (removeBg === "profile") window.location.reload();
          }
          setLoading(false);
        });
      else
        GetToast(addToast, "error", "הפעולה כשלה, נסה/י שנית או פנה/י לתמיכה");
    });
    // const form = new FormData();
    // form.append("image_file", image);
    // form.append("format", "png");
    // form.append("channels", "rgba");
    // form.append("bg_color", "#ffffff00");
    // form.append("size", "medium");
    // form.append("crop", "true");

    // const options = {
    //   method: "POST",
    //   url: "https://sdk.photoroom.com/v1/segment",
    //   headers: {
    //     "Content-Type":
    //       "multipart/form-data; boundary=---011000010111000001101001",
    //     Accept: "image/png, application/json",
    //     "x-api-key": "cb120dac04004ebcc9306e6b726dc2e0daf669f2",
    //   },
    //   data: form,
    // };

    // try {
    //   const { data } = await axios.request(options);
    //   console.log(data);
    // } catch (error) {
    //   console.error(error);
    // }
  }

  const submit = async (event) => {
    event.preventDefault();
    await postImage(file);
  };

  const fileSelected = async (event) => {
    let imageFile = event.target.files[0];
    if (imageFile.size > 1000000) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        setLoading(true);
        const compressedFile = await imageCompression(imageFile, options);
        setLoading(false);
        imageFile = new File([compressedFile], "image.jpeg", {
          type: compressedFile.type,
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    setFile(imageFile);
  };

  return (
    <PopupForm
      onClick={(e) => e.stopPropagation()}
      style={{ position: "absolute", ...style }}
    >
      <ExitModal init={init} color={user.settings.colors.buttonsColor} />
      <div style={{ marginTop: "5vh", fontSize: "1.1rem" }}>העלאת תמונה</div>
      <label
        htmlFor="file-upload-image"
        className="custom-file-upload"
        style={{ marginBottom: "5vh", color: "black" }}
      >
        בחירת תמונה
      </label>
      <input
        id="file-upload-image"
        type="file"
        onChange={fileSelected}
        accept="image/*"
      />
      {file !== null && (
        <GenericButton
          hoverBackground={"rgb(223, 223, 223)"}
          background={user.settings.colors.buttonsColor}
          style={{
            borderRadius: "0px",
            width: "100%",
            padding: "13px",
            fontSize: "16px",
          }}
          onClick={(e) => submit(e)}
        >
          שמירה
        </GenericButton>
      )}
      {loading && <OpacityLoading />}
    </PopupForm>
  );
};

export default EditPics;
