import React, { useEffect, useMemo, useState } from "react";
import { FlexColumnBetween, Section } from "../../Style";
import { useSelector, useDispatch } from "react-redux";
import GenericCarousle from "../carousel/GenericCarousle";
import { FlexRowBetween, RespFont } from "../Style";
import { noRefs, svgs, todosSvg } from "../footer/coloredSvgs/svgs";
import GenericSvg from "../footer/coloredSvgs/GenericSvg";
import GenericModal from "../modals/GenericModal";
import Add from "../../user/reference/Add";
import { cleanAll, getAll } from "../../../store/reducers/referenceReducer";
import Edit from "../../user/reference/Edit";
import { addAlpha, handleClickScrollXY } from "../modals/Toast";
import GenericReference from "../others/GenericReference";
import CommonEdit from "../others/CommonEdit";

const ReferencesC = ({ setEditRefs, editRefs }) => {
  const dispatch = useDispatch();
  const [editReference, setEditReference] = useState(false);
  useEffect(() => {
    dispatch(getAll());
    return () => {
      dispatch(cleanAll());
    };
  }, []);
  let widthS = window.innerWidth; // 1728
  let height = window.innerHeight;
  const [addReference, setAddReference] = useState(false);

  const { user, authenticated } = useSelector((state) => state.authReducer);
  const { reference, referenceError, referenceLoading, referenceChange } =
    useSelector((state) => state.referenceReducer);
  let referenceArray = useMemo(() => {
    let arr = [];
    let arrP = [];

    let sortedArray = [];
    if (Object.keys(reference).length > 0) {
      for (const [key, value] of Object.entries(reference))
        sortedArray.push(value);

      let temp = [];
      for (let i = 0; i < sortedArray.length; i++) {
        arrP.push(
          <GenericReference
            setEditReference={setEditReference}
            data={sortedArray[i]}
          />
        );
        temp.push(
          <GenericReference
            setEditReference={setEditReference}
            data={sortedArray[i]}
          />
        );
        if ((i + 1) % 3 === 0) {
          arr.push(
            <FlexRowBetween style={{ justifyContent: "center" }}>
              {[...temp]}
            </FlexRowBetween>
          );
          temp = [];
        }
      }
      arr.push(
        <FlexRowBetween style={{ justifyContent: "center" }}>
          {[...temp]}
        </FlexRowBetween>
      );
    }
    return [arr, arrP];
  }, [referenceChange, reference]);

  return (
    <div
      id={"refs"}
      style={{
        backgroundColor: user.settings.pagesColors.page4,
        width: "100%",
        position: "relative",
        minHeight: "500px",
      }}
    >
      {authenticated && (
        <CommonEdit
          top={"20px"}
          left={"20px"}
          bottom={null}
          right={null}
          func={() => {
            if (!editRefs) handleClickScrollXY(1000);
            setEditRefs(!editRefs);
          }}
          color={user.settings.TitlePagesColor.page4}
        />
      )}
      {authenticated && user && user.role === 1 && (
        <FlexRowBetween
          onClick={() => setAddReference(true)}
          style={{
            width: "min-content",
            border: "2px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "50px",
            padding: widthS > 768 ? "5px" : "3px",
            position: "absolute",
            fontWeight: "700",
            // marginRight: "30px",
            cursor: "pointer",
            top: "20px",
            right: "20px",
          }}
        >
          <div
            style={{
              padding: widthS > 768 ? "6px 6px" : "1.5px 3px",
              backgroundColor: user.settings.TitlePagesColor.page4,
              transition: "all 0.2s",
              borderRadius: "50px",
              width: "max-content",
            }}
          >
            <GenericSvg
              fill={"#fff"}
              size={widthS > 768 ? "23px" : "17px"}
              svg={svgs.plus}
              viewBox={"0 0 16 16"}
            />
          </div>
        </FlexRowBetween>
      )}
      <Section
        cSize={"38px"}
        pSize={"34px"}
        style={{
          color: user.settings.TitlePagesColor.page4,
          paddingTop: "50px",
        }}
      >
        {user.settings.PagesTitle.page4}
      </Section>
      <Section
        cSize={"20px"}
        pSize={"17px"}
        style={{
          color: addAlpha(user.settings.TitlePagesColor.page4, 0.7),
          paddingTop: "40px",
          fontWeight: "100",
          padding: "0 20%",
        }}
      >
        {user.settings.PagesSubTitle.page4}
      </Section>
      {
        widthS > 768 ? (
          <div
            style={{
              height: `${height - 165}px`,
              overflow: "auto",
              padding: "40px 0",
              width: "100%",
            }}
          >
            {referenceArray[0].length ? (
              referenceArray[0]
            ) : (
              <FlexColumnBetween
                style={{ justifyContent: "space-evenly", height: "100%" }}
              >
                <RespFont style={{ fontFamily: "hebB" }}>
                  אין המלצות, התחילו להוסיף...
                </RespFont>
                {noRefs(
                  widthS > 768 ? 432.88 : 216.44,
                  widthS > 768 ? 341.445 : 170.7225,
                  user.settings.TitlePagesColor.page4
                )}
              </FlexColumnBetween>
            )}
            {/* {referenceArray[0]} */}
          </div>
        ) : referenceArray[1].length ? (
          <GenericCarousle data={referenceArray[1]} />
        ) : (
          <FlexColumnBetween
            style={{ justifyContent: "space-evenly", height: "100%" }}
          >
            <RespFont style={{ fontFamily: "hebB" }}>
              אין המלצות, התחילו להוסיף...
            </RespFont>
            {noRefs(
              widthS > 768 ? 432.88 : 216.44,
              widthS > 768 ? 341.445 : 170.7225,
              user.settings.TitlePagesColor.page4
            )}
          </FlexColumnBetween>
        )
        // <GenericCarousle data={referenceArray[1]} />
      }
      {addReference && (
        <GenericModal
          init={setAddReference}
          div={<Add init={setAddReference} />}
        />
      )}
      {editReference && (
        <GenericModal
          init={setEditReference}
          div={<Edit data={editReference} init={setEditReference} />}
        />
      )}
    </div>
  );
};

export default ReferencesC;
