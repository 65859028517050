import React from "react";
import ColorCheckbox from "../../others/ColorCheckbox";
import {
  FlexRowStart,
  HomeTextArea,
  RespFont,
  SignatureInput,
} from "../../Style";

const BuyDoc = ({ doc, isRent, isSale, israelId, fullname, company }) => {
  return (
    <>
      <RespFont
        pSize={"16px"}
        cSize={"18px"}
        style={{
          textAlign: "center",
          fontWeight: "700",
          margin: "20px auto",
          fontFamily: "hebM",
        }}
      >
        הזמנת שירותי תיווך בבלעדיות
      </RespFont>
      <div style={{ textAlign: "center", margin: "20px auto" }}>
        (על פי חוק המתווכים במקרקעין)
      </div>
      <FlexRowStart>
        אנו פונים ל״
        <div
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
          }}
        >
          {company}
        </div>
        ״ או באמצעות נציגם, שם: ״
        <div
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
          }}
        >
          {fullname}
        </div>
        ״
      </FlexRowStart>
      <FlexRowStart>
        ת.ז <SignatureInput value={israelId} disabled={true} />
      </FlexRowStart>
      <FlexRowStart>
        (להלן ביחד ולחוד: "<span style={{ fontFamily: "hebM" }}>המתווך</span>")
        ו/או עובדיו ו/או מי מטעמו.
      </FlexRowStart>
      <FlexRowStart>
        לקבלת שירותי תיווך עבור הנכס/ים הרשומים להלן ונדווח לכם על כל מו"מ או
        הצעה בקשר לעסקה לגביהן
      </FlexRowStart>
      <FlexRowStart>
        <span style={{ fontFamily: "hebM", marginLeft: "3px" }}>
          1) פרטי הלקוח:
        </span>{" "}
        שם: <SignatureInput required={true} /> ת.ז.{" "}
        <SignatureInput required={true} />
      </FlexRowStart>
      <FlexRowStart style={{ paddingRight: "10px" }}>
        <span style={{ fontFamily: "hebM", marginLeft: "3px" }}>
          פרטי הלקוח:
        </span>{" "}
        שם: <SignatureInput /> ת.ז. <SignatureInput />
      </FlexRowStart>
      <FlexRowStart>
        <span style={{ fontFamily: "hebM" }}>כתובת:</span> <SignatureInput />{" "}
        טלפון: <SignatureInput /> נייד: <SignatureInput required={true} />
      </FlexRowStart>
      <FlexRowStart>
        <span style={{ fontFamily: "hebM" }}>2) סוג העסקה:</span>
        <FlexRowStart
          style={{
            width: "max-content",
            justifyContent: "flex-start",
            marginRight: "15px",
            marginLeft: "0",
            marginTop: "0px",
          }}
        >
          <ColorCheckbox
            checked={isSale}
            color={"blue"}
            handleCHange={() => {}}
          />
          <RespFont style={{ marginRight: "2px" }}>קניה</RespFont>
        </FlexRowStart>
        <FlexRowStart
          style={{
            width: "max-content",
            justifyContent: "flex-start",
            marginRight: "15px",
            marginTop: "0px",
          }}
        >
          <ColorCheckbox
            checked={isRent}
            color={"blue"}
            handleCHange={() => {}}
          />
          <RespFont style={{ marginRight: "2px" }}>השכרה</RespFont>
        </FlexRowStart>
      </FlexRowStart>
      <FlexRowStart style={{ fontFamily: "hebM" }}>
        סוג הנכס:{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.assetKind}
        </span>{" "}
        כתובת:{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.assetAddress}
        </span>{" "}
        מחיר מבוקש:{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.assetPrice}
        </span>
      </FlexRowStart>

      <HomeTextArea
        type="text"
        rows="3"
        disabled={true}
        value={doc?.notes}
        style={{
          border: "1px solid silver",
          maxWidth: "700px",
          margin: "10px auto",
        }}
        placeholder={"הערות"}
        pHcolor={"silver"}
      />
      <FlexRowStart>
        1. אני הח"מ מבקש/ת בזה כי תפעלו בשמי ועבורי למציאת נכס כמפורט להלן.
        התחייבותי זו הינה לתשלום דמי תיווך בסכום המצויין מטה, מיד לאחר חתימת
        הסכם מחייב לרכישה/ שכירת נכס בנדל״ן / מקרקעין.
      </FlexRowStart>
      <FlexRowStart>
        2. הנני מצהיר/ה כי הנכס המצוין מטה הוצע לי לראשונה ע״י משרדכם.
      </FlexRowStart>
      <FlexRowStart>
        3. ידוע לי כי הנתונים שמסרתם לי ביחס לנכסים שהצעתם לי לרכישה / השכרה,
        לקוחים מכל מדיה אפשרית ואין לי כל התנגדות לכך.
      </FlexRowStart>
      <FlexRowStart>
        4. במידה ואני ו/או מטעמי, באופן ישיר או עקיף, יקנה או ישכור את הנכס,
        מבלי שתקבלו ממני את דמי התיווך, הנני מתחייב/ת לשלם לכם את כפל דמי התיווך
        המצויינים בבעיף 6 להלן, זאת כדמי פיצוי ו/או נזק מוסכם מראש.
      </FlexRowStart>
      <FlexRowStart>
        5. הריני מאשר/ת מצהיר/ה בזה כי אתם הייתם הגורם המרכזי שהביא לתקשורת ביני
        ובין בעלי הנכס וכי אם וככל שיהא נכס שברצוני לרכוש ו/או לשכור, תחול עלי
        החובה להתייעץ עם בעלי מקצוע שונים, לרבות עו״ד ו/או מהנדס וכן לבדוק בעצמי
        ברשויות השונות את הזכויות בנכס, לרבות מצבו המשפטי ו/או הפיסי ו/או
        התכנוני.
      </FlexRowStart>
      <FlexRowStart>
        6.{" "}
        <span
          style={{ fontFamily: "hebM", marginRight: "5px", marginLeft: "5px" }}
        >
          דמי תיווך:
        </span>{" "}
        {doc?.fees}
      </FlexRowStart>
      <FlexRowStart>
        7. הנני מצהיר/ה כי במידה ואחתום על הסכם מחייב לעניין נכס שהוצע לי על
        ידכם, אזי חובת תשלום דמי התיווך תחול עלי גם אם מכל סיבה שהיא יבוטל אותו
        הסכם.
      </FlexRowStart>
      <FlexRowStart>
        8. הנני מתחייב/ת שלא למסור כל מידע לצד ג׳ שהוא ביחס למידע שקיבלתי מכם,
        אלא לשם קיום העסקה על ידי או מטעמי ותוך התחייבות מלאה לתשלום דמי התיווך
        כאמור בהתחייבות זו
      </FlexRowStart>
      <FlexRowStart>
        <span style={{ fontFamily: "hebM", marginLeft: "3px" }}>
          5) בלעדיות:
        </span>{" "}
        שתחל בתאריך{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.startDate.replace(/T.*/, "").split("-").reverse().join("/")}{" "}
        </span>
        עד לתאריך{" "}
        <span
          style={{
            borderBottom: "1px solid black",
            height: "21px",
            marginTop: "-4px",
            marginRight: "5px",
            marginLeft: "13px",
          }}
        >
          {doc?.endDate.replace(/T.*/, "").split("-").reverse().join("/")}
        </span>
      </FlexRowStart>
      <FlexRowStart>(להלן תקופת בלעדיות)</FlexRowStart>
    </>
  );
};
export default BuyDoc;
