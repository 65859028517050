import React, { useState } from "react";
import { constants, FlexRowBetween, RespFont, Ser, Section } from "../../Style";
import Register from "../Register";
import GenericModal from "../../common/modals/GenericModal";
import Deal1 from "./deals/Deal1";
import Deal2 from "./deals/Deal2";
import Deal3 from "./deals/Deal3";

const PricePageComp = () => {
  const [reg, setReg] = useState(null);
  const [commitment, setCommitment] = useState(1); // 1 - year, 2 - 6 months,, 3 - 3 months
  return (
    <div
      id="priceListC-id"
      style={{
        backgroundColor: "#fff",
        width: "100%",
        margin: "20px auto",
        padding: "40px 0",
        fontFamily: "monster",
        position: "relative",
      }}
    >
      {reg && (
        <GenericModal
          init={setReg}
          div={
            <Register paymentTag={reg} init={setReg} commitment={commitment} />
          }
        />
      )}
      <Section pSize={"36px"} cSize={"48px"} style={{ marginBottom: "10px" }}>
        חבילות
      </Section>
      <FlexRowBetween
        style={{
          width: "min-content",
          border: "2px solid rgba(0, 0, 0, 0.11)",
          borderRadius: "60px",
          padding: "5px",
          fontWeight: "700",
          margin: "10px auto",
        }}
      >
        <RespFont
          onClick={() => setCommitment(1)}
          style={{
            padding: "7px 12px",
            cursor: "pointer",
            backgroundColor: commitment === 1 ? "#DE8556" : "inherit",
            transition: "all 0.2s",
            color: commitment === 1 ? "#fff" : constants.fontColorZax,
            borderRadius: "60px",
            width: "max-content",
          }}
        >
          לחודש
        </RespFont>
        <RespFont
          onClick={() => setCommitment(2)}
          style={{
            padding: "7px 12px",
            cursor: "pointer",
            backgroundColor: commitment === 2 ? "#DE8556" : "inherit",
            transition: "all 0.2s",
            color: commitment === 2 ? "#fff" : constants.fontColorZax,
            borderRadius: "60px",
            width: "max-content",
          }}
        >
          לשנה
        </RespFont>
      </FlexRowBetween>
      <FlexRowBetween style={{ textAlign: "right", justifyContent: "center" }}>
        <Deal1 commitment={commitment} setReg={setReg} reg={1} />
        <div style={{ marginLeft: "30px" }} />
        <Deal2 commitment={commitment} setReg={setReg} reg={2} />
        <div style={{ marginLeft: "30px" }} />
        <Deal3 commitment={commitment} setReg={setReg} reg={3} />
      </FlexRowBetween>
    </div>
  );
};

export default PricePageComp;
