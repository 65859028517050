import React, { useMemo } from "react";
import { FlexRowBetween, Section } from "../../Style";
import { useSelector, useDispatch } from "react-redux";
import GenericCarousle from "../../common/carousel/GenericCarousle";
import { addAlpha } from "../../common/modals/Toast";
import GenericReference from "../../common/others/GenericReference";

const ReferencesC = () => {
  const dispatch = useDispatch();

  let widthS = window.innerWidth; // 1728
  let height = window.innerHeight;

  const { user, authenticated } = useSelector((state) => state.authReducer);
  const { reference, referenceError, referenceLoading, referenceChange } =
    useSelector((state) => state.referenceReducer);
  let referenceArray = useMemo(() => {
    let arr = [];
    let arrP = [];

    let sortedArray = [];
    if (reference.length > 0) {
      for (let i = 0; i < reference.length; i++) sortedArray.push(reference[i]);

      let temp = [];
      for (let i = 0; i < sortedArray.length; i++) {
        arrP.push(
          <GenericReference setEditReference={() => {}} data={sortedArray[i]} />
        );
        temp.push(
          <GenericReference setEditReference={() => {}} data={sortedArray[i]} />
        );
        if ((i + 1) % 3 === 0) {
          arr.push(
            <FlexRowBetween style={{ justifyContent: "center" }}>
              {[...temp]}
            </FlexRowBetween>
          );
          temp = [];
        }
      }
      arr.push(
        <FlexRowBetween style={{ justifyContent: "center" }}>
          {[...temp]}
        </FlexRowBetween>
      );
    }
    return [arr, arrP];
  }, [referenceChange, reference]);

  return (
    <div
      id={"rrr"}
      style={{
        backgroundColor: user.settings.pagesColors.page4,
        width: "100%",
        position: "relative",
        minHeight: "500px",
      }}
    >
      <Section
        cSize={"38px"}
        pSize={"34px"}
        style={{
          color: user.settings.TitlePagesColor.page4,
          paddingTop: "40px",
        }}
      >
        {user.settings.PagesTitle.page4}
      </Section>
      <Section
        cSize={"20px"}
        pSize={"17px"}
        style={{
          color: addAlpha(user.settings.TitlePagesColor.page4, 0.7),
          paddingTop: "40px",
          fontWeight: "100",
          padding: "0 20%",
        }}
      >
        {user.settings.PagesSubTitle.page4}
      </Section>
      {widthS > 768 ? (
        <div
          style={{
            height: `${height - 165}px`,
            overflow: "auto",
            padding: "40px 0",
            width: "100%",
          }}
        >
          {referenceArray[0]}
        </div>
      ) : (
        <GenericCarousle data={referenceArray[1]} />
      )}
    </div>
  );
};

export default ReferencesC;
