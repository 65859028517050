import React, { useState } from "react";
import GCalendar from "../../common/calendar/Calendar";
import { MainDiv } from "../../Style";
import { useSelector } from "react-redux";

const Calendar = () => {
    const [date, setDate] = useState(null);
    const handleChooseDate = () => {
        console.log(date);
    }

    const { user } = useSelector(state => state.authReducer);
    return (<MainDiv>
        <GCalendar background={user.controls} setDate={setDate} date={date} init={handleChooseDate} />
    </MainDiv>)
}

export default Calendar;